import React from "react";
import { Button as AntButton } from "antd";
import "./index.less";

const Button = ({
  icon,
  children,
  onClick,
  className = "gama-button",
  ...rest
}) => {
  return (
    <AntButton
      className={className}
      onClick={onClick}
      type={rest.type || "primary"}
      {...rest}
    >
      {icon}
      {children}
    </AntButton>
  );
};

export default Button;
