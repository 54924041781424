import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAssignmentCustomersFilter = createAsyncThunk(
  "customers/getAssignmentCustomers",
  async (data, { rejectWithValue }) => {
    try {
      const filters = localStorage.getItem("gama.customers.filter");
      if(filters) return JSON.parse(filters);
      
      localStorage.setItem("gama.customers.filter", JSON.stringify(data.map(c => ({name: c.name}))));
      return data.map(c => ({name: c.name}));
      
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);