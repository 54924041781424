import React, { useEffect } from "react";

import myPdf from "../../assets/Terminos y Condiciones RAC 20-04-2022.pdf";

function DownloadPDF() {
  useEffect(() => {
    document.getElementById("thebutton").click();
    window.location.replace("/");
  });
  return (
    <a
      id="thebutton"
      href={myPdf}
      download="Terminos-y-Condiciones-RAC-20-04-2022.pdf"
    >
      {" "}
      Download Here{" "}
    </a>
  );
}

export default DownloadPDF;
