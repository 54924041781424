import react from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { Checkbox, Row, Table } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { useMediaPredicate } from "react-media-hook";
import Button from "../../components/Button";

export default function DriverNotifications() {
  const biggerThan900 = useMediaPredicate("(min-width: 900px)");

  const dataSource = [
    {
      key: "1",
      name: "Seleccionar todo",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: <span style={{ fontWeight: "600" }}>Siniestros</span>,
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "3",
      name: "Notificaciones de tareas de vehículos",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "4",
      name: "Notificación de encuesta",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "5",
      name: <span style={{ fontWeight: "600" }}>Agendamiento</span>,
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "5",
      name: "Notificaciones de tareas de vehículos",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "5",
      name: "Notificación de encuesta ",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Notitifación de tareas",
      dataIndex: "name",
      key: "name",
      width: "70%",
    },
    {
      title: "Portal Gama",
      dataIndex: "age",
      align: "center",
      key: "age",
      render: (text, record) => (
        <Checkbox style={{ marginTop: "9px", marginBottom: "9px" }} />
      ),
    },
    {
      title: "Correo",
      dataIndex: "age",
      align: "center",
      key: "age",
      render: (text, record) => <Checkbox />,
    },
    {
      title: "Whatsapp",
      dataIndex: "age",
      align: "center",
      key: "age",
      render: (text, record) => <Checkbox />,
    },
  ];
  return (
    <AppLayout title="Notificaciones">
      <Table
        style={{ marginTop: "50px", marginLeft: biggerThan900 ? "" : "16px" }}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <Row justify={"end"}>
        <Button
          style={{
            marginRight: "10px",
            width: biggerThan900 ? "" : "100%",
            marginBottom: biggerThan900 ? "" : "16px",
            marginLeft: biggerThan900 ? "" : "32px",
          }}
          className="gama-outline"
          type="primary"
          onClick={() => {}}
        >
          Salir
        </Button>

        <Button
          style={{
            marginRight: "3%",
            width: biggerThan900 ? "" : "100%",
            marginLeft: biggerThan900 ? "" : "32px",
          }}
          type="primary"
          onClick={() => {}}
        >
          Guardar cambios
        </Button>
      </Row>
    </AppLayout>
  );
}
