import React from "react";
import pdfUrl from "../../assets/declaracionjuradamemoria2023.pdf";
import declaracion1 from "../../assets/declaracionjuradamemoria2023.82a84b94-1.png";
import declaracion2 from "../../assets/declaracionjuradamemoria2023.82a84b94-2.png";
import declaracion3 from "../../assets/declaracionjuradamemoria2023.82a84b94-3.png";
import { useMediaPredicate } from "react-media-hook";

export default function SeePDF() {
  const biggerThan900 = useMediaPredicate("(min-width: 900px)");

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      {biggerThan900 ? (
        <iframe
          style={{ width: "100%", height: "100vh", border: "none" }}
          src={pdfUrl}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "gray",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "500px",
                marginBottom: "16px",
                marginTop: "16px",
              }}
              src={declaracion1}
            />
            <img
              style={{ width: "100%", height: "500px", marginBottom: "16px" }}
              src={declaracion2}
            />
            <img
              style={{ width: "100%", height: "500px", marginBottom: "16px" }}
              src={declaracion3}
            />
          </div>
        </div>
      )}
    </div>
  );
}
