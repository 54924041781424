import React, { useEffect, useState } from "react";
import {
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Button as AntdButton,
  Col,
  Spin,
  Upload,
  Modal,
  DatePicker,
  ConfigProvider,
} from "antd";
import { format, validate } from "rut.js";
import {
  CaretDownOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  addUserToCompanyRequest,
  findUserByEmail,
  findUserByRut,
  getAssignedPlates,
  updateUserRequest,
} from "../../../requests/users";
import Notifications from "../../../components/Notifications";
import Button from "../../../components/Button";
import IconButton from "../../../components/IconButton";
import Tooltip from "../../../components/Tooltip";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import defaultLogo from "../../../assets/img/Default_pfp.webp";
import { getUserData } from "../../../store/auth";
import { useSelector } from "react-redux";
import moment from "moment/dist/moment";

import {
  getProfilePicture,
  updateProfilePicture,
} from "../../../requests/profile";
import { isSuperAdmin } from "../../../utils/roles";
import { getCustomerAssignmentRequest } from "../../../requests/customers";

import esES from "antd/lib/locale/es_ES";
import "moment/locale/es";

const { Option } = Select;
const { RangePicker } = DatePicker;

var stopClickEvent = 1;

const UserForm = ({
  onClose,
  open,
  customers = [],
  userToUpdate,
  onFormFinish,
  tab,
  documentType,
  setDocumentType,
}) => {
  const [form] = Form.useForm();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [password, setPassword] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [toolTipMessage, setToolTipMessage] = useState(false);
  const [roleNames, setRoleNames] = useState({});

  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const { accesses } = useSelector(getUserData);
  const userData = useSelector(getUserData);
  const [customersData, setCustomersData] = useState([]);
  const [hasConductor, setHasConductor] = useState(false);

  const [customerRut, setCustomerRut] = useState();
  const [platesFound, setPlatesFound] = useState();
  const [platesByRut, setPlatesByRut] = useState([]);
  const [loadingPlates, setLoadingPlates] = useState(false);

  let interval;

  console.log(userToUpdate, " user to update");
  const onSelectCustomer = ({ key, value }) => {
    // Actualiza el estado de selectedCustomers
    setSelectedCustomers((prevState) => {
      const isItemExist = prevState.some((item) => item.key === key);
      if (isItemExist) {
        return prevState.map((item) => {
          if (item.key === key) {
            return { key, value }; // Actualiza el valor del cliente
          }
          return item;
        });
      }
      return [...prevState, { key, value }]; // Agrega un nuevo cliente si no existe
    });

    const accesses = form.getFieldValue("accesses");
    if (accesses) {
      form.setFieldsValue({
        accesses: accesses.map((access) => {
          if (access.customerId === value) {
            return {
              ...access,
              roleId: null, // Limpiar el rol para que se vuelva a seleccionar
              assignedPlates: [], // Limpiar assignedPlates al cambiar de empresa
            };
          }
          return access; // Retorna los accesos sin cambios
        }),
      });
    }

    // Verifica si el rol seleccionado previamente era "Conductor"
    const currentRoleName = roleNames && roleNames[key];
    if (currentRoleName === "Conductor") {
      // Si el rol es "Conductor" y la empresa cambia, limpiamos el estado de conductor
      setHasConductor(false); // Desaparecer los inputs si era "Conductor"
    }

    // Limpiar roleNames para el índice correspondiente
    setRoleNames((prevRoleNames) => ({
      ...prevRoleNames,
      [key]: undefined, // O eliminar la propiedad si es necesario
    }));
  };

  const cleanData = () => {
    form.resetFields();
    setSelectedCustomers([]);
    setRoleNames();
  };

  const passwordGenerator = () => {
    const array = new Uint32Array(1);
    setPassword(window.crypto.getRandomValues(array).toString());
  };

  const getProfileData = () => {
    const getProfileImage = async (userId) => {
      const { data } = await getProfilePicture(userId);
      setProfileImage(data);
    };

    if (userToUpdate) getProfileImage(userToUpdate.id);
  };

  useEffect(() => {
    const getassignedPlates = async () => {
      try {
        const { data } = await getAssignedPlates(
          userToUpdate.accesses?.[0]?.customer?.rut
        );
        setPlatesFound(data);

        console.log(data, "response from plates");
      } catch (error) {
        console.error(error);
      }
    };
    getassignedPlates();
  }, [userToUpdate]);

  useEffect(() => {
    getProfileData();
  }, [userToUpdate]);

  const handleProfileChange = async (key, value) => {
    console.log(key, value);

    // Filtramos el cliente basado en el key
    const arr = formCustomers?.filter((customer) => {
      const selectedCustomer = selectedCustomers.find(
        (item) => item.key === key
      );
      return customer.id === selectedCustomer.value;
    });

    // Verificamos si el array no es vacío antes de acceder a arr[0]
    if (arr && arr.length > 0) {
      const encontrarId = arr[0]?.roles.find((item) => item.id === value);

      // Actualizamos los roleNames con el código del rol seleccionado
      setRoleNames((prevRoleNames) => ({
        ...prevRoleNames,
        [key]: encontrarId?.code,
      }));

      // Si el código del rol es "Conductor", actualizamos el estado `hasConductor`
      if (encontrarId?.code === "Conductor") {
        setHasConductor(true);
      } else {
        // Verificamos si todavía hay algún "Conductor" seleccionado
        const conductorStillExists = Object.values({
          ...roleNames,
          [key]: encontrarId?.code,
        }).includes("Conductor");

        setHasConductor(conductorStillExists);
      }
    } else {
      console.error("No se encontró el cliente seleccionado.");
    }
  };

  useEffect(() => {
    if (userToUpdate) {
      userToUpdate.accesses.forEach((access, index) => {
        onSelectCustomer({ key: index, value: access.customer.id });

        // Establece el rol para cada acceso en roleNames
        setRoleNames((prevRoleNames) => ({
          ...prevRoleNames,
          [index]: access.role.code, // Asigna el rol correspondiente a su índice
        }));
      });

      form.setFieldsValue({
        ...userToUpdate,
        pasaporte: userToUpdate?.passportNumber,
        documentType: userToUpdate?.identifierType,
        driverValidityStart: userToUpdate?.sessionStartDate
          ? moment(userToUpdate.sessionStartDate)
          : null,
        driverValidityEnd: userToUpdate?.sessionExpirationDate
          ? moment(userToUpdate.sessionExpirationDate)
          : null,
        accesses: userToUpdate.accesses.map((access) => {
          return {
            customerId: access.customer.id,
            roleId: access.role.id,
            assignedPlates: access?.assignedPlates || [],
          };
        }),
      });

      // Si deseas verificar el rol de la primera empresa
      if (userToUpdate.accesses[0]?.role?.code === "Conductor") {
        setLoadingPlates(false);
      } else {
        setLoadingPlates(true); // O el estado que desees establecer
      }
    } else {
      cleanData();
      passwordGenerator();
    }
  }, [form, userToUpdate]);

  useEffect(() => {}, []);

  useEffect(() => {
    form.setFieldsValue({
      password: password,
      phonePrefix: "56",
    });
  }, [password, passwordGenerator]);

  const onCloseModal = () => {
    cleanData();
    onClose();
  };
  const cleanRut = (text) => text?.replace(/\./g, "");

  const onCreateUser = async (values) => {
    await addUserToCompanyRequest({
      ...values,
      rut: cleanRut(values.rut),
    });
  };

  const handleDocumentChange = (value) => {
    setDocumentType(value);
  };

  useEffect(async () => {
    try {
      const { data } = await getCustomerAssignmentRequest({
        pageSize: 9999,
      });
      setCustomersData(data);
    } catch (error) {
      console.error(error);
    }
  }, [userToUpdate]);

  const onUpdateUser = async ({ rut, ...values }) => {
    values.phoneNumber = values.phoneNumber.toString();
    await updateUserRequest({ userId: userToUpdate.id, data: values });
  };
  const onSubmit = async (values) => {
    console.log(values, "values");

    const creationObj = {
      identifierType: values?.documentType,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phonePrefix: "56",
      phoneNumber: values?.phoneNumber,
      accesses: values?.accesses,
      password: values?.password,
      rut: values?.rut || null,
      sessionStartDate: values?.driverValidityStart,
      sessionExpirationDate: values?.driverValidityEnd,
      passportNumber: values?.pasaporte,
    };

    try {
      if (userToUpdate) {
        await onUpdateUser(creationObj);
      } else {
        await onCreateUser(creationObj);
      }

      Notifications.success(
        "Listo",
        userToUpdate ? "Usuario actualizado" : "Usuario creado"
      );

      onFormFinish();
      onCloseModal();
    } catch (error) {
      console.error(error, "error");
      if (error?.status === 401) {
        Notifications.error(
          "Error",
          "No tienes los permisos para realizar esa accion."
        );
      } else if (error.status === 409) {
        Notifications.error(
          "Error",
          "El correo que intentas ingresar ya existe."
        );
      } else {
        Notifications.error(
          "Error",
          "Ha ocurrido un error al añadir el usuario"
        );
      }
    }
  };

  console.log(platesFound, "platesFoundplatesFoundplatesFound");

  const fileTypes = ["image/jpeg", "image/png"];

  const handleChange = async (info) => {
    try {
      if (info.fileList[0].originFileObj > 1) {
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(info?.fileList[0]?.originFileObj?.type)) {
        return Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, png.</span>
            </>
          ),
        });
      } else if (info?.fileList[0]?.originFileObj?.size === 15000000) {
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 15MB."
        );
      } else {
        if (stopClickEvent === 3) {
          await updateProfilePicture(
            info.fileList[0].originFileObj,
            userToUpdate?.id
          );
          Notifications.success(
            "Cambio de imagen",
            "Se ha actualizado su imagen de perfil satisfactoriamente."
          );
          stopClickEvent = 1;
          getProfileData();
        }
      }
    } catch (error) {
      console.error(error);
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar la imagen."
      );
    }
  };
  const filteredCustomers =
    tab === "client"
      ? customersData?.customers &&
        customersData?.customers?.filter(
          (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
        )
      : customersData?.customers && customersData?.customers;

  const formCustomers = isSuperAdmin(accesses)
    ? customers.customers
      ? customers.customers
      : customers
    : filteredCustomers;

  const disablePastDates = (current) => {
    // Disable dates before today
    return current && current < moment().startOf("day");
  };

  return (
    <Drawer
      title={
        <div>
          <Row justify="space-between" align="middle">
            <Col>{userToUpdate ? "Editar usuario" : "Crear nuevo usuario"}</Col>
            <Col>
              <Button
                onClick={onClose}
                style={{
                  border: "none",
                  padding: "2px 8px",
                  height: "30px",
                  color: "#FF3200",
                  borderRadius: "6px",
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                }}
              >
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      }
      placement="right"
      onClose={onCloseModal}
      visible={open}
      destroyOnClose
      closable={false}
      width={biggerThan900 ? 480 : "100%"}
    >
      <Form
        name="create-user"
        form={form}
        initialValues={{
          accesses: [""],
        }}
        layout="vertical"
        onFinish={onSubmit}
      >
        <div
          style={{
            position: "relative",
            marginBottom: "30px",
            display: "inline-block",
            width: "auto",
          }}
        >
          {isSuperAdmin(accesses) ||
          userData?.accesses[0]?.role.code === "Administrador" ? (
            <Upload
              showUploadList={false}
              onChange={(info) => {
                if (stopClickEvent === 3) {
                  handleChange(info);
                  stopClickEvent = 1;
                } else {
                  stopClickEvent = stopClickEvent + 1;
                }
              }}
              accept=".png,.jpg,.jpeg,.webp"
            >
              <img
                className="profile_image"
                style={{ height: "80px", width: "80px", borderRadius: "50px" }}
                src={userToUpdate ? profileImage : defaultLogo}
              />
              <div
                className="container-plus"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  background: "#FFFF",
                  display: "table",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <PlusCircleFilled
                  style={{
                    color: "#FF3200",
                    height: "1.2rem",
                    display: "table-row",
                    fontSize: "1.2rem",
                  }}
                />
              </div>
            </Upload>
          ) : (
            <>
              <img
                className="profile_image"
                style={{ height: "80px", width: "80px", borderRadius: "50px" }}
                src={userToUpdate ? profileImage : defaultLogo}
              />
            </>
          )}
        </div>
        <Form.Item
          name="documentType"
          rules={[{ required: true, message: "Ingrese el tipo de documento." }]}
          label="Tipo Documento"
          style={{
            fontWeight: "bold",
            marginBottom: "20px",
            width: biggerThan900 ? "100%" : "100%",
            marginRight: biggerThan900 ? "50px" : "",
          }}
        >
          <Select
            disabled={!!userToUpdate}
            onChange={(value) => handleDocumentChange(value)}
            placeholder="Seleccionar"
            suffixIcon={<CaretDownOutlined />}
            id="vehicle-selector"
          >
            <Select.Option value="RUT">Carnet de identidad</Select.Option>
            {/* <Select.Option value="Número de pasaporte">Pasaporte</Select.Option> */}
          </Select>
        </Form.Item>
        {documentType === "RUT" ? (
          <Form.Item
            name="rut"
            style={{ width: "100%" }}
            rules={[
              {
                required: documentType === "Número de pasaporte" ? false : true,
                message: "Ingrese el rut.",
              },
              {
                validator: async (_, value) => {
                  if (!validate(value))
                    return Promise.reject("El rut ingresado es inválido.");
                  if (userToUpdate) return;

                  const { data } = await findUserByRut(cleanRut(value));
                  if (data)
                    return Promise.reject("El rut ingresado ya existe.");
                },
              },
            ]}
            normalize={(value) => format(value)}
          >
            <Input disabled={!!userToUpdate} placeholder="RUT" />
          </Form.Item>
        ) : null}
        {documentType === "Número de pasaporte" ? (
          <Form.Item
            name="pasaporte"
            style={{ width: "100%" }}
            rules={[
              {
                required: documentType === "RUT" ? false : true,
                message: documentType === "RUT" ? "" : "Ingrese el pasaporte.",
              },
              {
                max: 20,
                message: "Debe tener máximo 20 caracteres",
              },
            ]}
          >
            <Input disabled={!!userToUpdate} placeholder="Pasaporte" />
          </Form.Item>
        ) : null}

        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el nombre",
            },
            {
              whitespace: true,
            },
            {
              min: 3,
              message: "Debe tener al menos 3 caracteres",
            },
            {
              max: 50,
              message: "Debe tener máximo 50 caracteres",
            },
            {
              //Mayusculas, minusculas, acentos, espacio, apostrofe, guines
              pattern: /^[A-Za-z\'\s\.\,Ññ]+$/,
              message: "El nombre solo debe incluir letras.",
            },
          ]}
        >
          <Input placeholder="Nombre" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el apellido",
            },
            {
              whitespace: true,
            },
            {
              min: 3,
              message: "Debe tener al menos 3 caracteres",
            },
            {
              max: 50,
              message: "Debe tener máximo 50 caracteres",
            },
            {
              //Mayusculas, minusculas, acentos, espacio, apostrofe
              pattern: /^[A-Za-z\'\s\.\,Ññ]+$/,
              message: "El apellido solo debe incluir letras.",
            },
          ]}
        >
          <Input placeholder="Apellido" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "Por favor ingrese un correo válido",
            },
            {
              required: true,
              message: "Por favor ingrese el correo",
            },
            {
              max: 50,
              message: "No puede ingresar más de 50 caracteres en un correo",
            },

            {
              whitespace: true,
            },
            {
              validator: async (_, value) => {
                if (userToUpdate) return;
                if (value.length === 0) return;
                if (interval) window.clearInterval(interval);
                if (value.includes("/")) return;
                try {
                  const { data } = await findUserByEmail(value);
                  if (data) {
                    return Promise.reject("El correo ingresado ya existe.");
                  } else {
                    Promise.resolve();
                  }
                } catch (error) {
                  console.error(error);
                }
              },
            },
          ]}
        >
          <Input placeholder="Correo electrónico" />
        </Form.Item>

        <Input.Group compact>
          <Form.Item
            name="phonePrefix"
            rules={[
              { required: true, message: "Por favor ingresa el prefijo" },
            ]}
          >
            <Select suffixIcon={<CaretDownOutlined />} style={{ width: 100 }}>
              <Option value={"56"}>+56</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el número de telefono",
              },
              {
                //Numeros, signo +
                pattern: /^[+]*\d{8,9}$/,
                message:
                  "Ingrese un número válido, sin letras y entre 8 y 9 dígitos.",
              },
            ]}
          >
            <Input placeholder="Teléfono" />
          </Form.Item>
        </Input.Group>

        <span style={{ fontWeight: "bold", margin: 5 }}>
          Asignar perfiles y empresas
        </span>
        <Form.List
          rules={[
            {
              required: true,
              message: "Por favor selecciona una Empresa",
            },
          ]}
          name="accesses"
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                    flexDirection: "column",
                  }}
                  align="baseline"
                >
                  <div style={{ display: "inline-flex" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "customerId"]}
                      style={{
                        marginRight: 5,
                        width: biggerThan900 ? 400 : 250,
                        height: 10,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona una Empresa",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<CaretDownOutlined />}
                        notFoundContent={
                          !customers ? <Spin size="small" /> : null
                        }
                        showSearch
                        onChange={(value) =>
                          onSelectCustomer({ key: key, value: value })
                        }
                        style={{
                          width: "100%",
                        }}
                        placeholder="Empresa"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label.toLowerCase() ?? "").includes(
                            input.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={
                          formCustomers &&
                          formCustomers
                            ?.filter(
                              (customer) =>
                                !selectedCustomers.some(
                                  (currCustomer) =>
                                    customer.id === currCustomer.value &&
                                    key !== currCustomer.key
                                )
                            )
                            .map((customer) => {
                              return {
                                label: customer.name,
                                value: customer.id,
                              };
                            })
                        }
                      />
                    </Form.Item>
                    {index > 0 && (
                      <DeleteOutlined
                        style={{
                          marginTop: 10,
                          color: "#FF3200",
                          backgroundColor: "rgba(255, 50, 0, 0.06)",
                          padding: "5px 5px",
                          borderRadius: "6px",
                        }}
                        onClick={() => remove(name)}
                      />
                    )}
                  </div>
                  {selectedCustomers.find((item) => item.key === key) && (
                    <Form.Item
                      {...restField}
                      name={[name, "roleId"]}
                      style={{
                        marginRight: 5,
                        width: biggerThan900 ? 400 : 250,
                        height: 10,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona un perfil",
                        },
                      ]}
                    >
                      <Select
                        onChange={(value) =>
                          handleProfileChange(key, value, name, restField)
                        }
                        suffixIcon={<CaretDownOutlined />}
                        placeholder="Perfil"
                      >
                        {formCustomers &&
                          formCustomers
                            ?.filter((customer) => {
                              const selectedCustomer = selectedCustomers.find(
                                (item) => item.key === key
                              );
                              return customer.id === selectedCustomer.value;
                            })
                            .map((customer) => {
                              return customer?.roles
                                ?.filter((rol) => rol.enabled === true)
                                .map((role) => (
                                  <Option key={role.code} value={role.id}>
                                    {role.code}
                                  </Option>
                                ));
                            })}
                      </Select>
                    </Form.Item>
                  )}
                  {roleNames && roleNames[key] === "Conductor" ? (
                    <Form.Item
                      name={[name, "assignedPlates"]}
                      {...restField}
                      style={{ width: "100%" }}
                    >
                      <Select
                        notFoundContent={
                          loadingPlates && (
                            <Row align="center">
                              <Spin />
                            </Row>
                          )
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setLoadingPlates(true);
                          setPlatesFound(); // Limpiar el estado de las placas encontradas

                          const arr = formCustomers?.filter((customer) => {
                            const selectedCustomer = selectedCustomers.find(
                              (item) => item.key === key
                            );
                            return customer.id === selectedCustomer.value;
                          });
                          const newCustomerRut = arr?.[0]?.rut;

                          const fetchPlates = async () => {
                            try {
                              const { data } = await getAssignedPlates(
                                newCustomerRut
                              );
                              if (data && data.length > 0) {
                                setPlatesFound(data);
                                setLoadingPlates(false); // Detener el loading solo cuando haya datos
                                console.log(data, "response from plates");
                              } else {
                                setTimeout(fetchPlates, 5000); // Reintentar cada 5 segundos
                              }
                            } catch (error) {
                              setLoadingPlates(false);
                              if (error?.status === 404) {
                                setPlatesFound([]); // Limpiar si hay error 404
                                Notifications.error(
                                  "Error al cargar",
                                  error?.data.Mensaje
                                );
                              }
                            }
                          };

                          fetchPlates();
                        }}
                        mode="multiple"
                        allowClear
                        style={{
                          width: biggerThan900 ? 400 : 250,
                        }}
                        placeholder="Seleccionar patente"
                      >
                        {platesFound &&
                          platesFound?.map((plate) => (
                            <Option key={plate} value={plate}>
                              {plate}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                </Space>
              ))}
              <Divider style={{ marginTop: "50px" }} />
              <Form.Item>
                <div
                  style={{ cursor: "pointer", color: "#FF5C00" }}
                  onClick={() => add()}
                >
                  <PlusCircleOutlined />
                  <span style={{ marginLeft: 5 }}>Agregar otro</span>
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>

        {hasConductor && (
          <Row>
            <ConfigProvider locale={esES}>
              <Form.Item
                rules={[
                  {
                    required: hasConductor,
                    message: "Por favor ingrese las fechas.",
                  },
                ]}
                name="driverValidityStart"
              >
                <DatePicker
                  disabledDate={disablePastDates}
                  format={"DD-MM-YYYY"}
                  placeholder={["Fecha inicio"]}
                />
              </Form.Item>
            </ConfigProvider>
            <ConfigProvider locale={esES}>
              <Form.Item
                rules={[
                  {
                    required: hasConductor,
                    message: "Por favor ingrese las fechas.",
                  },
                ]}
                name="driverValidityEnd"
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  disabledDate={disablePastDates}
                  style={{ marginLeft: biggerThan900 ? "10px" : "" }}
                  placeholder={["Fecha termino"]}
                />
              </Form.Item>
            </ConfigProvider>
          </Row>
        )}

        {!userToUpdate && (
          <Input.Group compact>
            <p className="title-password-generate">Contraseña temporal</p>
            <p className="info-password-generate">
              Esta contraseña tendrá una validez de 72 horas. Por favor copie
              esta contraseña y envíesela al usuario como respaldo.
            </p>
            <Form.Item name="password">
              <Input
                disabled={true}
                style={{
                  color: "#262626",
                }}
              />
            </Form.Item>
            <Tooltip
              onChangeMessage={toolTipMessage}
              defaultMessage="Copiar el password"
              actionMessage="¡ copiado !"
            >
              <AntdButton
                icon={<CopyOutlined />}
                onClick={() => {
                  setToolTipMessage(true);
                  navigator.clipboard.writeText(password);
                  setTimeout(() => setToolTipMessage(false), 1000);
                }}
              />
            </Tooltip>

            <div
              style={{
                marginLeft: ".5rem",
              }}
            >
              <IconButton
                icon={<ReloadOutlined />}
                onClick={() => passwordGenerator()}
                defaultMessage="Generar un nuevo password"
                actionMessage="¡ password generado !"
              />
            </div>
          </Input.Group>
        )}
      </Form>

      <Row style={{ marginTop: 30 }}>
        <Button
          className="gama-outline"
          onClick={() => onCloseModal()}
          style={{ width: "40%", margin: 5 }}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          style={{ width: "50%", margin: 5 }}
          onClick={() => form.submit()}
        >
          {userToUpdate ? "Editar usuario" : "Crear nuevo usuario"}
        </Button>
      </Row>
    </Drawer>
  );
};
export default UserForm;
