import request from "../request";
import {
  BASE_SHAREPOINT_API_URL,
} from '../url';

export const getDocumentsByRut = async (data) => {
  return await request({
    url: `${BASE_SHAREPOINT_API_URL}/documents`,
    method: "POST",
    data,
  });
};

export const viewDocument = async (filename) => {
  return await request({
    url: `${BASE_SHAREPOINT_API_URL}/documents/view?file=${filename.replaceAll(';','%3B')}`,
    method: "GET",
    responseType: "blob",
  });
};

export const downloadDocument = async (filename) => {
  return await request({
    url: `${BASE_SHAREPOINT_API_URL}/documents/download?file=${filename.replaceAll(';','%3B')}`,
    method: "GET",
    responseType: "blob",
  });
};
