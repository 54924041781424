import { CloseOutlined } from "@ant-design/icons";
import { Divider, Drawer, Form, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import IconButton from "../../../components/IconButton";
import Notifications from "../../../components/Notifications";
import { getMineUserRequest, passwordChange } from "../../../requests/users";
import ProfileUserCard from "../ProfileUserCard";
import { roles } from "../../../utils/roles";
import { authUserRequest } from "../../../requests/auth";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import ReCAPTCHA from "react-google-recaptcha";

const PasswordForm = ({ open, onClose, userDetails, userData }) => {
  const [user, setUser] = useState();
  const reRef = useRef();

  const onCloseModal = () => {
    onClose();
  };
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const onSubmit = async (values) => {
    try {
      const data = { password: values.password };
      const { id } = userDetails;

      if (user?.accesses[0].role.code.toUpperCase() !== roles.superAdmin)
        throw new Error();

      await passwordChange({ userId: id, data });
      onCloseModal();
      Notifications.success("Terminado", "Se ha actualizado la contraseña");
    } catch (error) {
      if (error) {
        return Notifications.error(
          "Error al cambiar contraseña",
          "Ha ocurrido al cambiar la contraseña"
        );
      }
    }
  };

  const onSubmitSelf = async (values) => {
    try {
      const { id, rut } = userData;
      const data = { password: values.password };
      const token = await reRef.current.executeAsync();
      reRef.current.reset();

      const isValid = await authUserRequest({
        rut,
        password: values.currentPassword,
        reCaptcha: token,
      });

      if (isValid.data.error)
        return Notifications.error(
          "Error al cambiar contraseña",
          "La contraseña actual es invalida"
        );
      await passwordChange({ userId: id, data });
      onCloseModal();
      return Notifications.success(
        "Terminado",
        "Se ha actualizado la contraseña"
      );
    } catch (error) {
      if (error) {
        return Notifications.error(
          "Error al cambiar contraseña",
          "Ha ocurrido un error al cambiar la contraseña"
        );
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getMineUserRequest();
      setUser(response?.data);
    };

    fetchUser();
  }, []);

  return (
    <>
      <Drawer
        title="Cambiar contraseña"
        placement="right"
        onClose={onCloseModal}
        visible={open}
        width={biggerThan900 ? 480 : "100%"}
        destroyOnClose
        className="password-change-drawer"
      >
        <IconButton
          className={"close-button"}
          icon={<CloseOutlined />}
          onClick={onCloseModal}
        />
        <ProfileUserCard
          name={`${userDetails?.firstName || ""} ${
            userDetails?.lastName || ""
          }`}
          rut={userDetails?.rut}
          urlImage={userDetails?.profileImageUrl}
        />

        <Divider />

        <p className="title-password-generate">
          ¿Problemas con el inicio de sesión?
        </p>
        <p className="info-password-generate ">
          Crea una nueva contraseña a continuación. La nueva contraseña debe
          tener al menos 8 caracteres y tiene que contener al menos una letra
          mayúscula, una letra minúscula y un número.
        </p>

        <Form onFinish={user?.id === userDetails?.id ? onSubmitSelf : onSubmit}>
          {user?.id === userDetails?.id && (
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Debes ingresar tu password actual",
                },
              ]}
            >
              <Input.Password placeholder="Contraseña actual" />
            </Form.Item>
          )}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Debes ingresar un password",
              },
              {
                pattern: /^(?=.{8,})/,
                message: "La contraseña debe tener al menos 8 caracteres",
              },
              {
                pattern: /[A-Z]/,
                message:
                  "La contraseña debe tener al menos una letra mayúscula",
              },
              {
                pattern: /[a-z]/,
                message:
                  "La contraseña debe tener al menos una letra minúscula",
              },
              {
                pattern: /\d/,
                message: "La contraseña debe tener al menos un número",
              },
            ]}
          >
            <Input.Password placeholder="Nueva contraseña" />
          </Form.Item>
          <Form.Item
            name="confirm-password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Por favor confirma tu contraseña",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "Las contraseñas ingresadas no coinciden, por favor vuelve a intentarlo"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirmar nueva contraseña" />
          </Form.Item>

          <Row justify="space-around">
            <Button className="gama-outline" onClick={() => onCloseModal()}>
              Cancelar
            </Button>
            <Button htmlType="submit">Crear nueva contraseña</Button>
          </Row>
        </Form>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          ref={reRef}
          size="invisible"
        />
      </Drawer>
    </>
  );
};

export default PasswordForm;
