import React, { useEffect, useState } from "react";
import HomeImage from "../../assets/img/home.webp";
import { useMediaPredicate } from "react-media-hook";
import { Form } from "antd";
import Header from "../../components/layouts/HomeLayout/Header";
import AuthForm from "../../components/layouts/HomeLayout/AuthForm";
import { authUser } from "../../store/auth/thunks";
import { useDispatch, useSelector } from "react-redux";
import { error, getStatus, getUserData } from "../../store/auth";
import { STATUS } from "../../utils/status";
import { useNavigate } from "react-router-dom";

function Login() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [form] = Form.useForm();
  const status = useSelector(getStatus);
  const isLoading = () => status === STATUS.PENDING;
  const dispatch = useDispatch();
  const [showAuthForm, setShowAuthForm] = useState(false);
  const closeAuthForm = () => setShowAuthForm(false);
  const userData = useSelector(getUserData);

  const hasError = useSelector(error);
  const navigate = useNavigate();

  const onFinishForm = (values) => {
    try {
      const { rut, password, remember, reCaptcha } = values;
      dispatch(authUser({ rut, password, remember, reCaptcha }));
    } catch (error) {
      throw new Error("ERROR DE INICIO DE SESION");
    }
  };

  const redirectToDashboard = () => navigate("/dashboard");
  useEffect(() => {
    console.log("userData", userData);
    if (status === STATUS.SUCCESS) {
      redirectToDashboard();
    }
  }, [redirectToDashboard, status, hasError, dispatch, showAuthForm]);

  return (
    <div>
      <section
        className="home-banner-container"
        id="header"
        style={{
          height: `100vh`,
        }}
      >
        <Header header={false} urlImage={HomeImage} />
        <div
          style={{ marginTop: biggerThan900 ? "3rem" : "0px" }}
          className="banner-title-container"
        >
          <AuthForm
            onFinish={onFinishForm}
            form={form}
            open={showAuthForm}
            close={closeAuthForm}
            loading={isLoading()}
          />
        </div>
      </section>
    </div>
  );
}

export default Login;
