import React from "react";
import {
    Col,
    Modal,
    Row,
    Button,
} from "antd";
import {
    CloseOutlined,
} from "@ant-design/icons";
import Notifications from "../../../components/Notifications";
import { useMediaPredicate } from "react-media-hook";
import {
  changeStatusInvoicePropose,
} from "../../../requests/invoicePropose";



export const ResendApproveInvoiceModal = ({
    open,
    setOpen,
    recordModal,
    setUpdatedInvoicePropose,
}) => {

    const biggerThan900 = useMediaPredicate("(min-width: 992px)");

    const cancelApproveInvoice = () => {
      setOpen(false);
    }

    const secureRandomNumber = () => {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      return array[0] / 4294967295; // normalize the value between 0 and 1
    };

    const PendingInvoicePropose = async (record) => {
      const {id, displayId} = record;
      try {
        await changeStatusInvoicePropose(id, 0, '');
        Notifications.success(
          "Se cambio el estado correctamente",
          `Se cambio el estado a pendiente la propuesta de facturación ${displayId || id}.`
        );
      } catch (error) {
        Notifications.error(
          "Error al Cambiar estado",
          `Hubo un error al cambiar el estado de la propuesta de facturación ${displayId || id}.`
        );
      }
      setUpdatedInvoicePropose(secureRandomNumber());
      
    }

    return (
        <>
          <Modal
          bodyStyle={{ borderRadius: "60px" }}
          footer={false}
          closable={false}
          title={
            <>
              <Row align="middle" justify="space-between">
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: biggerThan900 ? "20px" : "15px",
                  }}
                >
                  Reenviar la propuesta {recordModal?.displayId}
                </span>
                <Col>
                  <CloseOutlined
                    onClick={cancelApproveInvoice}
                    style={{
                      color: "#FF3200",
                      backgroundColor: "rgba(255, 50, 0, 0.06)",
                      padding: "7px 7px 7px 7px",
                      marginRight: "15px",
                      fontSize: "16px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </>
          }
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
        >
          <Row
            justify="center"
            className="button-upload-modal"
          >
              <Col >
                  <p>
                    Recuerde que después de cambiar el estado, no es posible volver atrás ¿Está de acuerdo con reenviar la propuesta?
                  </p>
              </Col>

              <Col className="buttons-mobile">
                  <Button
                  onClick={cancelApproveInvoice}
                  style={{ height: "40px" }}
                  className="gama-outline"
                  >
                    Cancelar
                  </Button>
              </Col>
              <Col className="buttons-mobile">
                  <Button
                    onClick={() => {
                      setOpen(false);
                      PendingInvoicePropose(recordModal);
                    }}
                    style={{ marginLeft: "16px", height: "40px" }}
                    className="gama-button"
                  >
                    Reenviar
                  </Button>
              </Col>
          </Row>
          </Modal>

        </>
    )
}