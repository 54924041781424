import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../../utils/status";
import {
  getGamaLeasingBranchesContent,
  getGamaLeasingContactContent,
  getGamaLeasingFAQContent,
  getGamaLeasingFleetContent,
  getGamaLeasingHomeContent,
  getGamaLeasingPaymentContent,
  getGamaLeasingPostsContent,
  getGamaLeasingSinisterContent,
} from "./thunks";

const initialState = {
  common: {
    navbar: null,
    footer: null,
  },
  home: {
    header: null,
    sections: [],
  },
  fleet: {
    header: null,
    gallery: null,
  },
  contact: {
    header: null,
    sections: [],
  },
  branch: {
    header: null,
    branches: null,
  },
  sinister: {
    header: null,
    sections: null,
  },
  payment: {
    header: null,
    sections: null,
  },
  faq: {
    header: null,
    sections: null,
  },
  posts: {
    header: null,
    sections: null,
  },
  status: STATUS.IDLE,
  error: null,
};

export const gamaleasingSlice = createSlice({
  name: "gamaleasing",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGamaLeasingHomeContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingHomeContent.fulfilled, (state, action) => {
        state.common.navbar = action.payload.navbar;
        state.home.header = action.payload.header;
        state.home.sections = action.payload.sections;
        state.common.footer = action.payload.footer;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingHomeContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingFleetContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingFleetContent.fulfilled, (state, action) => {
        state.fleet.header = action.payload.header;
        state.fleet.gallery = action.payload.gallery;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingFleetContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingContactContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingContactContent.fulfilled, (state, action) => {
        state.contact.header = action.payload.header;
        state.contact.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingContactContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingBranchesContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingBranchesContent.fulfilled, (state, action) => {
        state.branch.header = action.payload.header;
        state.branch.branches = action.payload.branches;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingBranchesContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingSinisterContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingSinisterContent.fulfilled, (state, action) => {
        state.sinister.header = action.payload.header;
        state.sinister.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingSinisterContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingPaymentContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingPaymentContent.fulfilled, (state, action) => {
        state.payment.header = action.payload.header;
        state.payment.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingPaymentContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingFAQContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingFAQContent.fulfilled, (state, action) => {
        state.faq.header = action.payload.header;
        state.faq.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingFAQContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaLeasingPostsContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaLeasingPostsContent.fulfilled, (state, action) => {
        state.posts.header = action.payload.header;
        state.posts.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaLeasingPostsContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      });
  },
});
//Commons
export const getNavbarContent = (state) => state.gamaleasing.common.navbar;
export const getFooterContent = (state) => state.gamaleasing.common.footer;
//Home
export const getHomeHeaderContent = (state) => state.gamaleasing.home.header;
export const getHomeSectionsContent = (state) =>
  state.gamaleasing.home.sections;
//Fleet
export const getFleetHeaderContent = (state) => state.gamaleasing.fleet.header;
export const getFleetGalleryContent = (state) =>
  state.gamaleasing.fleet.gallery;
//Contact
export const getContactHeaderContent = (state) =>
  state.gamaleasing.contact.header;
export const getContactSiderContent = (state) =>
  state.gamaleasing.contact.sections;
//Branches
export const getBranchHeaderContent = (state) =>
  state.gamaleasing.branch.header;
export const getBranchesContent = (state) => state.gamaleasing.branch.branches;

export const getSinisterHeaderContent = (state) =>
  state.gamaleasing.sinister.header;
export const getSinisterSectionsContent = (state) =>
  state.gamaleasing.sinister.sections;

export const getPaymentHeaderContent = (state) =>
  state.gamaleasing.payment.header;
export const getPaymentSectionsContent = (state) =>
  state.gamaleasing.payment.sections;

export const getFAQSectionContent = (state) => state.gamaleasing.faq.sections;
export const getFAQHeaderContent = (state) => state.gamaleasing.faq.header;

export const getPostsSectionContent = (state) =>
  state.gamaleasing.posts.sections;
export const getPostsHeaderContent = (state) => state.gamaleasing.posts.header;

export const getStatus = (state) => state.gamaleasing.status;

export default gamaleasingSlice.reducer;
