import React, { useEffect, useState } from "react";
import { Col, Divider, Drawer, Row } from "antd";
import CustomerCard from "../../../components/CustomerCard";
import "./index.less";
import Button from "../../../components/Button";
import { getUserRequest } from "../../../requests/users";
import { useMediaPredicate } from "react-media-hook";

const ShowCustomer = ({ open, onClose, customerToShow, customers }) => {
  const [customer, setCustomer] = useState();
  const [executive, setExecutive] = useState(null);
  const [supervisor, setSupervisor] = useState(null);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  useEffect(() => {
    const getData = async () => {
      setExecutive(null);
      setSupervisor(null);

      if (customerToShow) {
        try {  
          const responseExecutive = await getUserRequest(customerToShow?.auxData?.executive);
          setExecutive(responseExecutive?.data);
        } catch (error) {
          console.error(error)
        }
        try {
          const responseSupervisor = await getUserRequest(customerToShow?.auxData?.supervisor);
          setSupervisor(responseSupervisor?.data);
        } catch (error) {
          console.error(error)
        }

      }
    };

    setCustomer(customerToShow);
    getData();
  }, [customerToShow]);

  return (
    <>
      <Drawer
        title="Detalle"
        placement="right"
        onClose={onClose}
        visible={open}
        className="container-show-customer"
        width={biggerThan900 ? 450 : "100%"}
      >
        {customer && (
          <>
            {/* <Row>
              <Col>
                <Avatar shape="square" size={48} icon={<IdcardOutlined />} />
              </Col>

              <Col style={{ marginLeft: '.5rem', display: 'flex', alignItems: 'center' }}>
                <div>
                  <h3>{customer.name.toUpperCase()}</h3>
                  <span>{customer.rut}</span>
                </div>
              </Col>
            </Row> */}
            <CustomerCard
              name={customer.name.toUpperCase()}
              rut={customer.rut}
            />

            <Divider />

            {/* <Row>
              <Col span={24}>
                <h4>Subempresas</h4>
                <div className="container-subempresas">
                  {customers
                    .filter(({ parentId }) => parentId === customer.rut)
                    .map(({ name, rut }) => (
                      <p>
                        {name} · {rut}
                      </p>
                    ))}
                </div>
              </Col>
            </Row> */}

            {/* Seccion de ejecutivo asignado */}
            <Row style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Row align="middle">
                  <Col span={8}>
                    <h4>Ejecutivo asignado</h4>
                  </Col>
                  <Col span={16}>
                    <p>
                      {executive?.firstName === undefined ||
                      executive?.lastName === undefined
                        ? "No aplica"
                        : executive?.firstName + " " + executive?.lastName}
                    </p>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col span={8}>
                    <h4>Correo</h4>
                  </Col>
                  <Col
                    span={16}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p>{executive ? executive?.email : "No aplica"}</p>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col span={8}>
                    <h4>Teléfono</h4>
                  </Col>
                  <Col span={16}>
                    <p>
                      {executive
                        ? "+56 9 " + executive?.phoneNumber
                        : "No aplica"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />
            {/* sección de supervidpr */}

            <Row style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Row align="middle">
                  <Col span={8}>
                    <h4>Supervisor</h4>
                  </Col>
                  <Col span={16}>
                    <p>
                      {supervisor?.firstName === undefined ||
                      supervisor?.lastName === undefined
                        ? "No aplica"
                        : supervisor?.firstName + " " + supervisor?.lastName}
                    </p>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col span={8}>
                    <h4>Correo</h4>
                  </Col>
                  <Col span={16}>
                    <p>{supervisor ? supervisor?.email : "No aplica"}</p>
                  </Col>
                </Row>

                <Row align="middle">
                  <Col span={8}>
                    <h4>Teléfono</h4>
                  </Col>
                  <Col span={16}>
                    <p>{supervisor ? supervisor?.phoneNumber : "No aplica"}</p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="center">
              <Button
                type="primary"
                onClick={() => onClose()}
                style={{
                  minWidth: "13.8rem",
                  marginTop: "2rem",
                }}
              >
                Cerrar
              </Button>
            </Row>
          </>
        )}
      </Drawer>
    </>
  );
};

export default ShowCustomer;
