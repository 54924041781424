import { Tooltip as AntdTooltip } from 'antd';
import React from 'react';

const Tooltip = ({ children, onChangeMessage, defaultMessage = '' , actionMessage = '' }) => {
  return (
    <AntdTooltip title={!onChangeMessage ? defaultMessage : actionMessage}>
      {children}
    </AntdTooltip>
  )
}

export default Tooltip