import React from 'react';
import { Pie } from '@ant-design/plots';

const DemoPie = ({data = []}) => {

  const config = {
    appendPadding: 10,
    data: data && data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    color: ['#FF6742', '#FF3200', '#D02900', "#981E00", "#FFD2C7", "#FFB19E", "#FF9980", "#FF6742"],
    innerRadius: 0.6,
    label: {
      type: 'outer',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: "" ,
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
    },
  };
  return (
    <div style={{height: "180px"}}>
        <Pie {...config} />
    </div>
  )
};
export default DemoPie

