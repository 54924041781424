import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Typography, Dropdown, Menu } from "antd";
import Sider from "../AppLayout/Sider";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, getHasAccess, signOut } from "../../../store/auth";
import Header from "../AppLayout/Header";
import "../AppLayout/index.less";
import {
  attachAccess,
  getAuthProfile,
  getPermissions,
  refreshAuth,
} from "../../../store/auth/thunks";
import { useMediaPredicate } from "react-media-hook";
import { CaretDownOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import Button from "../../Button";
import { updateDefaultCompany } from "../../../requests/users";

const { Text } = Typography;

const InvoiceProposeLayout = ({ children, title }) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const hasAccess = useSelector(getHasAccess);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    dispatch(getAuthProfile());
    dispatch(getPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (userData.accessToCustomers.length) {
      const currentId = !userData.defaultCompany
        ? userData.accessToCustomers[0].id
        : userData.defaultCompany;

      onSelectCustomer(currentId);
      renderCompanies(userData);
    }
  }, [userData]);

  const onSelectCustomer = (customerId) => dispatch(attachAccess(customerId));

  const onSignOut = () => dispatch(signOut());

  const renderCompanies = (data) => {
    const orderData = [];
    data.accessToCustomers.forEach((el) => {
      el.id === userData.defaultCompany
        ? orderData.unshift(el)
        : orderData.push(el);
    });
    setCompanies(orderData);
  };

  const getCompanyName = (id) => {
    const company = userData?.accessToCustomers.find((el) => el.id === id);
    return company ? company.name : userData?.accessToCustomers[0].name;
  };

  const dataLoad = async (companyId) => {
    try {
      await updateDefaultCompany(companyId);
      dispatch(getAuthProfile());
      dispatch(refreshAuth());
    } catch (error) {
      console.error(error);
    }
  };

  const data = companies.map((customer, index) => {
    return {
      label: (
        <a
          onClick={() => {
            dataLoad(customer?.id);
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#262626",
            }}
          >
            {customer.id === userData.defaultCompany ? (
              <StarFilled style={{ marginRight: ".5rem", color: "#FF3200" }} />
            ) : (
              <StarOutlined style={{ marginRight: ".5rem" }} />
            )}
            {`${customer.name} · ${customer.rut}`}
            <p
              style={{
                marginLeft: "1.4rem",
                color: "#8C8C8C",
                fontSize: "14px",
              }}
            >
              {userData.defaultCompany === customer.id && "(predeterminada)"}
            </p>
          </div>
        </a>
      ),
      key: customer.id,
    };
  });

  const menu = (
    <Menu items={data} className="customers-list" id="customers-list" />
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header hasAccess={hasAccess} user={userData} onSignOut={onSignOut} />

      <Layout className="layout-container">
        <>
          <Sider hasAccess={hasAccess} />
          <Layout style={{ padding: biggerThan900 ? "0 24px 24px" : "0px" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text
                  style={{
                    fontSize: "24px",
                    marginTop: "15px",
                  }}
                  strong
                >
                  {title}
                </Text>
              </Col>
              {userData?.type === "CLIENT" && (
                <Row>
                  <Row>
                    <Col style={{ marginRight: "16px" }}>
                      <Text>Empresa: </Text>
                    </Col>
                  </Row>
                  <Dropdown
                    trigger={["click"]}
                    overlay={menu}
                    placement="bottomRight"
                  >
                    <Button
                      style={{
                        width: "328px",
                        textAlign: "left",
                        backgroundColor: "#FFFFFF",
                        color: "#262626",
                        borderRadius: "6px",
                        border: "none",
                      }}
                    >
                      <Row justify="space-between">
                        {userData.defaultCompany
                          ? getCompanyName(
                              userData.defaultCompany.length >= 35
                                ? userData.defaultCompany.length.slice(0, 35) +
                                    "..."
                                : userData.defaultCompany.length
                            )
                          : userData.accessToCustomers?.[0]?.name.length >= 35
                          ? userData.accessToCustomers?.[0]?.name?.slice(
                              0,
                              35
                            ) + "..."
                          : userData.accessToCustomers?.[0]?.name}
                        <CaretDownOutlined />
                      </Row>
                    </Button>
                  </Dropdown>
                </Row>
              )}
            </Row>

            <>{children}</>
          </Layout>
        </>
      </Layout>
    </Layout>
  );
};

export default InvoiceProposeLayout;
