import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Col, Divider, Input, Row, Select, Table, Typography } from "antd";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import React, { useEffect, useState } from "react";
import moment from "moment/dist/moment";
import AppLayout from "../../components/layouts/AppLayout";
import useTableQuery from "../../hooks/useTableQuery";
import * as DOMPurify from "dompurify";
import circleTable from "../../assets/img/circletable.png";
import recepcionIcon from "../../assets/img/recepcionadoIcon.png";
import redCircle from "../../assets/img/redCircle.png";

import {
  getServices,
  getServicesQuery,
  getWorkShops,
} from "../../requests/vehicles";
import { useSelector } from "react-redux";
import { getUserData } from "../../store/auth";

export default function Services() {
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [total, setTotal] = useState();
  const [services, setServices] = useState();
  const [queryData, setQueryData] = useState();
  const [workshops, setWorkshops] = useState();
  const [inputValue, setInputValue] = useState("");

  const handleFilters = (key, value) => {
    setFilters((prevValue) => ({ ...prevValue, [key]: value }));
  };
  const userData = useSelector(getUserData);
  const customerId = userData?.accesses[0]?.customer?.rut;

  const handleSearch = (value) => {
    setCurrentPage(1);
    let cleanValue = DOMPurify.sanitize(value);
    handleFilters("keyword", cleanValue.replace(/\./g, ""));
    setInputValue(value);
  };
  const handleFilterByStatus = (value) => handleFilters("status", value);
  const handleFilterByWorkshop = (value) => handleFilters("workshop", value);
  const handleFilterByService = (value) => handleFilters("service", value);
  const handleClearFilters = () => {
    setFilters({});
    setInputValue("");
  };

  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    onSearch,
    query,
    setCurrentPage,
  } = useTableQuery();

  moment.locale("es");

  const onClearFilters = () => {
    handleClearFilters();
  };

  const { Text } = Typography;

  let expandedRowKeys = [];

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.OSPADRE;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  useEffect(() => {
    const getServicesType = async () => {
      try {
        const { data } = await getServices();
        setServices(data);
      } catch (error) {
        console.error(error);
      }
    };
    const getWorkShopType = async () => {
      try {
        const { data } = await getWorkShops();
        setWorkshops(data);
      } catch (error) {
        console.error(error);
      }
    };

    const getQueryFromServices = async () => {
      try {
        setLoading(true);
        const { data } = await getServicesQuery(query, customerId, filters);
        const newObj = data?.services?.map((item) => ({
          ...item,
          isExpandable: true,
        }));
        setTotal(data?.count);
        console.log(data, "DATA");
        setQueryData(newObj);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getQueryFromServices();
    getServicesType();
    getWorkShopType();
  }, [filters, customerId, query]);

  const mobileColumns = [
    {
      key: "OSPADRE",
      title: (
        <Text style={{ fontSize: "12px" }} strong>
          Orden de servicio
        </Text>
      ),
      dataIndex: "OSPADRE",
      sorter: {
        compare: (a, b) => a?.OSPADRE?.localeCompare(b?.OSPADRE),
      },
    },
    {
      key: "DEVICEID",
      title: (
        <Text style={{ fontSize: "13px", width: "100%" }} strong>
          Patente
        </Text>
      ),
      dataIndex: "DEVICEID",
      width: "40%",
      sorter: {
        compare: (a, b) => a?.DEVICEID?.localeCompare(b?.DEVICEID),
      },
      render: (text, record) => {
        return <Text style={{ fontSize: "13px" }}>{record?.DEVICEID}</Text>;
      },
    },
  ];

  const columns = [
    {
      title: <Text strong>Orden de Servicio</Text>,
      dataIndex: "OSPADRE",
      key: "OSPADRE",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) =>
          a?.OSPADRE?.toString().localeCompare(b?.OSPADRE?.toString()),
      },
    },
    {
      title: <Text strong>Empresa</Text>,
      dataIndex: "ORGANIZATIONNAME",
      key: "ORGANIZATIONNAME",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) =>
          a?.ORGANIZATIONNAME?.localeCompare(b?.ORGANIZATIONNAME),
      },
    },
    {
      title: <Text strong>Patente</Text>,
      dataIndex: "DEVICEID",
      key: "DEVICEID",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.DEVICEID?.localeCompare(b?.DEVICEID),
      },
    },
    {
      title: <Text strong>Fecha recepcion</Text>,
      dataIndex: "FECHARECEPCION",
      key: "FECHARECEPCION",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.FECHARECEPCION - b?.FECHARECEPCION,
      },
      render: (text, record) =>
        record?.RECEPCION
          ? moment(record?.FECHARECEPCION, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: <Text strong>Servicio</Text>,
      dataIndex: "SERVICIO",
      key: "grupo_servicio",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.SERVICIO?.localeCompare(b?.SERVICIO),
      },
    },
    {
      title: <Text strong>Talleres</Text>,
      dataIndex: "TALLER",
      key: "TALLER",
      align: "left",
      sorter: {
        compare: (a, b) => a?.TALLER?.localeCompare(b?.TALLER),
      },
    },
    {
      title: <Text strong>Ciudad</Text>,
      dataIndex: "NONBRECIUDAD",
      key: "NONBRECIUDAD",
      align: "left",
      sorter: {
        compare: (a, b) => a.NONBRECIUDAD?.localeCompare(b?.NONBRECIUDAD),
      },
    },
    {
      title: <Text strong>Fecha actualizacion</Text>,
      dataIndex: "syncStartDateTime",
      key: "syncStartDateTime",
      align: "left",
      width: 170,
      render: (text, record) =>
        record?.syncStartDateTime
          ? moment(record?.syncStartDateTime, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "-",
    },

    {
      title: <Text strong>Estado</Text>,
      dataIndex: "ESTADO",
      key: "ESTADO",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a?.ESTADO?.localeCompare(b?.ESTADO),
      },
      render: (text, record) => {
        return (
          <span
            style={{
              backgroundColor:
                record?.ESTADO?.toUpperCase() === "RECEPCIONADO"
                  ? "#E6FFFB"
                  : record?.ESTADO?.toUpperCase() === "EN PROCESO"
                  ? "#FFFBE6"
                  : "#FFF1F0",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                record?.ESTADO?.toUpperCase() === "RECEPCIONADO"
                  ? "1px solid #13C2C2"
                  : record?.ESTADO?.toUpperCase() === "EN PROCESO"
                  ? "1px solid #FAAD14"
                  : "1px solid #FFA39E",
              color:
                record?.ESTADO?.toUpperCase() === "RECEPCIONADO"
                  ? "#13C2C2"
                  : record?.ESTADO?.toUpperCase() === "EN PROCESO"
                  ? " #FAAD14"
                  : " #FF4D4F",
            }}
          >
            {record?.ESTADO}
          </span>
        );
      },
    },
  ];

  const { Search } = Input;
  return (
    <AppLayout agendamiento={true} title="Servicios">
      <div className="container-vehicles-table">
        {width <= 640 ? (
          <>
            <Row align="middle">
              <Col span={24}>
                <Search
                  placeholder="Buscar Orden de servicio/Patente"
                  onSearch={handleSearch}
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  style={{
                    width: "100%",
                    marginTop: "16px",
                  }}
                />
              </Col>
              {/* <Col
                style={{
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                  padding: "2px 8px 2px 8px",
                  borderRadius: "6px",
                  cursor: "pointer",
                  marginTop: "15px",
                }}
              >
                <img alt="filter-request" src={requestLogo} />
              </Col> */}
            </Row>

            <Table
              columns={mobileColumns}
              loading={loading}
              expandedRowRender={(record) => (
                <Row>
                  <>
                    {/* <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Empresa</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.id}
                    </Col> */}
                    <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Empresa</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.ORGANIZATIONNAME}
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Fecha recepción</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.RECEPCION
                        ? moment(record?.RECEPCION, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Servicio</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.SERVICIO}
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Talleres</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.WORKSHOP}
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Ciudad</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.NOMBREREGION}
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={14}>
                      <Text strong>Fecha actualización</Text>
                    </Col>
                    <Col style={{ marginBottom: "14px" }} span={10}>
                      {record?.syncStartDateTime
                        ? moment(
                            record?.syncStartDateTime,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")
                        : "-"}
                    </Col>
                    <Col span={14}>
                      <Text style={{ fontSize: "15px" }} strong>
                        Estado
                      </Text>
                    </Col>
                  </>
                  <Col
                    style={{
                      backgroundColor:
                        record?.ESTADO?.toUpperCase() === "RECEPCIONADO"
                          ? "#E6FFFB"
                          : record?.ESTADO?.toUpperCase() === "EN PROCESO"
                          ? "#FFFBE6"
                          : "#FFF1F0",
                      padding: "4px 7px 4px 7px",
                      borderRadius: "6px",
                      border:
                        record?.ESTADO?.toUpperCase() === "RECEPCIONADO"
                          ? "1px solid #13C2C2"
                          : record?.ESTADO?.toUpperCase() === "EN PROCESO"
                          ? "1px solid #FAAD14"
                          : "1px solid #FFA39E",
                      color:
                        record?.ESTADO?.toUpperCase() === "RECEPCIONADO"
                          ? "#13C2C2"
                          : record?.ESTADO?.toUpperCase() === "EN PROCESO"
                          ? " #FAAD14"
                          : " #FF4D4F",
                    }}
                  >
                    {record?.ESTADO}
                  </Col>
                </Row>
              )}
              expandIcon={(props) => {
                if (props.record.isExpandable) {
                  if (props.expanded) {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MinusSquareOutlined
                          style={{ width: "50px" }}
                          onClick={(e) => {
                            props.onExpand(props.record, e);
                          }}
                        />
                        <span>
                          {props?.record?.ESTADO?.toUpperCase() ===
                          "EN PROCESO" ? (
                            <img src={circleTable} />
                          ) : props?.record?.ESTADO?.toUpperCase() ===
                            "RECEPCIONADO" ? (
                            <img src={recepcionIcon} />
                          ) : (
                            <img src={redCircle} />
                          )}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PlusSquareOutlined
                          style={{ width: "50px" }}
                          onClick={(e) => {
                            props.onExpand(props.record, e);
                          }}
                        />
                        <span>
                          {props?.record?.ESTADO?.toUpperCase() ===
                          "EN PROCESO" ? (
                            <img src={circleTable} />
                          ) : props?.record?.ESTADO?.toUpperCase() ===
                            "RECEPCIONADO" ? (
                            <img src={recepcionIcon} />
                          ) : (
                            <img src={redCircle} />
                          )}
                        </span>
                      </div>
                    );
                  }
                }
              }}
              rowClassName={(record) =>
                record?.isExpandable ? "show" : "hidden"
              }
              expandedRowKeys={expandedRowKeys}
              onExpand={handleRowKeys}
              rowKey={(record) => record?.OSPADRE}
              dataSource={queryData || []}
              showSorterTooltip={false}
              pagination={{
                current: currentPage,
                pageSize,
                total,
                position: ["bottomCenter"],
              }}
              onChange={handlePaginationChange}
              sortDirection={["ASC", "DESC"]}
            />
          </>
        ) : (
          <>
            <Row
              justify="space-between"
              style={{ marginBottom: "20px", marginTop: "15px", width: "100%" }}
            >
              <Row>
                <Col sm={6}>
                  <Search
                    placeholder="Buscar Orden de servicio/Patente"
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                    onSearch={handleSearch}
                    style={{
                      width: 280,
                      paddingLeft: "15px",
                      marginRight: "15px",
                    }}
                  />
                </Col>
                <Divider
                  style={{
                    height: "40px",
                    marginRight: "20px",
                    marginLeft: "50px",
                  }}
                  type="vertical"
                />
                <Col>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={filters?.workshop || undefined}
                    showSearch
                    onChange={(value) => {
                      handleFilterByWorkshop(value);
                      setCurrentPage(1);
                    }}
                    style={{
                      width: 200,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                    placeholder="Taller"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={workshops?.map((workshop) => {
                      return {
                        label: workshop,
                        value: workshop,
                      };
                    })}
                  />
                </Col>
                <Col>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={filters?.service || undefined}
                    placeholder="Servicio"
                    style={{
                      width: 150,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                    onChange={(value) => {
                      handleFilterByService(value);
                      setCurrentPage(1);
                    }}
                  >
                    {services?.map((service) => {
                      return (
                        <Select.Option key={service} value={service}>
                          {service}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={filters?.status || undefined}
                    placeholder="Estado"
                    style={{
                      width: 150,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                    onChange={(value) => {
                      handleFilterByStatus(value);
                      setCurrentPage(1);
                    }}
                  >
                    <Select.Option value="Recepcionado">
                      Recepcionado
                    </Select.Option>
                    <Select.Option value="En proceso">En proceso</Select.Option>
                    <Select.Option value="En espera">En espera</Select.Option>
                  </Select>
                </Col>
                <Col>
                  <a
                    style={{
                      border: "none",
                      textAlign: "bottom",
                      color: "#FF3200",
                      textDecoration: "underline",
                      fontSize: "16px",
                    }}
                    onClick={onClearFilters}
                  >
                    Limpiar
                  </a>
                </Col>
              </Row>
            </Row>

            <Table
              className="sius"
              dataSource={queryData || []}
              onSearch={onSearch}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total: total || 1,
                position: ["bottomCenter"],
              }}
              onChange={handlePaginationChange}
              sortDirection={["ASC", "DESC"]}
              columns={columns}
              scroll={{ x: "max-content" }}
              showSorterTooltip={false}
            />
          </>
        )}
      </div>
    </AppLayout>
  );
}
