import {
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Space,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { format, validate } from "rut.js";
import Button from "../../../components/Button";
import {
  createCustomerRequest,
  getCustomerRequest,
  updateCustomerRequest,
} from "../../../requests/customers";

const CreateCustomer = ({
  open,
  onClose,
  customerToUpdate,
  onFormFinish,
  customers,
}) => {
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const onChange = (checked) => {
    setChecked(checked);
  };

  const onCloseModal = () => {
    form.resetFields();
    onClose();
    setChecked(false);
  };

  const cleanRut = (text) => text.replace(/\./g, "");

  const onSubmit = async (values) => {
    try {
      const { rut, name, childCompanies } = values;

      if (customerToUpdate) {
        await updateCustomer(customerToUpdate.id, rut, name);
      } else {
        const newCustomer = await createCustomerRequest({
          ...{ rut, name },
          rut: cleanRut(rut),
        });
        const parentId = newCustomer.data.rut;

        if (childCompanies) {
          await Promise.all(
            childCompanies.map(({ subCompanyRut, subCompanyName }) =>
              createSubCompany(subCompanyRut, subCompanyName, parentId)
            )
          );
        }
      }

      onFormFinish();
      onCloseModal();

      const successMessage = customerToUpdate
        ? "Empresa actualizada con éxito"
        : "Empresa agregada con éxito";

      message.success({
        content: successMessage,
        duration: 5,
      });
    } catch (error) {
      console.log("error", error);
      const errorMessage = customerToUpdate
        ? "No se ha podido actualizar la empresa"
        : "No se ha podido añadir la empresa";

      message.error({
        content: errorMessage,
        duration: 5,
      });
    }
  };

  const getCustomer = async (id) => {
    try {
      const { data } = await getCustomerRequest({ id });
      form.setFieldsValue(data);
    } catch (error) {
      message.error({
        content: "Error al cargar el usuario",
        duration: 5,
      });
    }
  };

  const countSubCompanies = (rut) => {
    const result = customers.filter(({ parentId }) => parentId === rut);
    setChecked(result.length > 0);
  };

  useEffect(() => {
    if (customerToUpdate) {
      getCustomer(customerToUpdate.id);
      countSubCompanies(customerToUpdate.rut);
    }
    form.resetFields();
  }, [customerToUpdate]);

  const updateCustomer = async (customerId, rut, name) => {
    const requestData = {
      rut: cleanRut(rut),
      name,
    };

    await updateCustomerRequest(customerId, requestData);

    const subCompanies = customers.filter(
      (customer) => customer.parentId === customerToUpdate.rut
    );

    await Promise.all(
      subCompanies.map(({ rut: subCompanyRut, name: subCompanyName }) =>
        updateOrCreateSubCompany(customerId, subCompanyRut, subCompanyName)
      )
    );
  };

  const createSubCompany = async (rut, name, parentId) => {
    const requestData = {
      rut: cleanRut(rut),
      name,
      parentId,
    };

    await createCustomerRequest(requestData);
  };

  const updateOrCreateSubCompany = async (parentId, rut, name) => {
    const isExist = customers.find((customer) => customer.rut === rut);

    if (isExist) {
      await updateCustomerRequest(parentId, {
        rut: cleanRut(rut),
        name,
        parentId,
      });
    } else {
      await createCustomerRequest({
        rut: cleanRut(rut),
        name,
        parentId,
      });
    }
  };

  return (
    <>
      <Drawer
        title={customerToUpdate ? "Agregar Subempresa" : "Crear empresa"}
        placement="right"
        onClose={onCloseModal}
        visible={open}
        width={biggerThan900 ? 480 : "100%"}
      >
        <Form
          style={{ marginTop: 0 }}
          name="create-user"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
        >
          <Form.Item
            name="rut"
            style={{ width: 225 }}
            normalize={(value) => format(value)}
            rules={[
              { required: true, message: "Ingrese el rut." },
              {
                validator: customerToUpdate
                  ? async (_, value) => {
                      if (!validate(value))
                        return Promise.reject("El rut ingresado es inválido.");
                      if (cleanRut(customerToUpdate.rut) !== cleanRut(value)) {
                        if (
                          customers.find(
                            (el) => cleanRut(el.rut) === cleanRut(value)
                          )
                        )
                          return Promise.reject("El rut ingresado ya existe.");
                      }
                    }
                  : async (_, value) => {
                      if (!validate(value))
                        return Promise.reject("El rut ingresado es inválido.");
                      if (
                        customers.find(
                          (el) => cleanRut(el.rut) === cleanRut(value)
                        )
                      )
                        return Promise.reject("El rut ingresado ya existe.");
                    },
              },
            ]}
          >
            <Input disabled placeholder="RUT" />
          </Form.Item>

          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el nombre",
              },
              {
                pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9.]+$/,
                message: "Los nombres no pueden incluir caracteres especiales",
              },
              {
                validator: customerToUpdate
                  ? async (_, name) => {
                      if (name.length > 50)
                        return Promise.reject(
                          new Error(
                            "El nombre no debe tener más de 50 caracteres"
                          )
                        );
                      if (name.length < 3)
                        return Promise.reject(
                          new Error(
                            "El nombre debe tener al menos 3 caracteres"
                          )
                        );
                      if (
                        name.toLowerCase() !==
                        customerToUpdate.name.toLowerCase()
                      ) {
                        if (
                          customers.find(
                            (el) => el.name.toLowerCase() === name.toLowerCase()
                          )
                        )
                          return Promise.reject(
                            "El nombre ingresado ya existe"
                          );
                      }
                    }
                  : async (_, name) => {
                      if (name.length > 50)
                        return Promise.reject(
                          new Error(
                            "El nombre no debe tener más de 50 caracteres"
                          )
                        );
                      if (name.length < 3)
                        return Promise.reject(
                          new Error(
                            "El nombre debe tener al menos 3 caracteres"
                          )
                        );
                      if (
                        customers.find(
                          (el) => el.name.toLowerCase() === name.toLowerCase()
                        )
                      )
                        return Promise.reject("El nombre ingresado ya existe");
                    },
              },
            ]}
          >
            <Input disabled placeholder="Nombre empresa" />
          </Form.Item>

          <Form.Item name="switch" label="" valuePropName="checked">
            <Switch onChange={() => onChange(checked)} checked={checked} />
            <span style={{ marginLeft: ".5rem" }}>
              Esta empresa tiene subempresas
            </span>
          </Form.Item>

          {checked && (
            <Form.List name="childCompanies">
              {(fields, { add, remove }, { errors }) => {
                return (
                  <>
                    {customerToUpdate &&
                      customers
                        .filter(
                          ({ parentId }) => parentId === customerToUpdate.rut
                        )
                        .map(({ name, rut }, index) => {
                          return (
                            <Space
                              key={rut}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 30,
                              }}
                              align="baseline"
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <Row
                                  style={{
                                    marginRight: 5,
                                    width: 250,
                                    height: "auto",
                                    marginBottom: 0,
                                    display: "flex",
                                  }}
                                >
                                  <Form.Item
                                    normalize={(value) => format(value)}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Ingrese el rut.",
                                      },
                                      {
                                        validator: customerToUpdate
                                          ? async (_, value) => {
                                              if (!validate(value))
                                                return Promise.reject(
                                                  "El rut ingresado es inválido."
                                                );
                                              if (
                                                cleanRut(
                                                  customerToUpdate.rut
                                                ) !== cleanRut(value)
                                              ) {
                                                if (
                                                  customers.find(
                                                    (el) =>
                                                      cleanRut(el.rut) ===
                                                      cleanRut(value)
                                                  )
                                                )
                                                  return Promise.reject(
                                                    "El rut ingresado ya existe."
                                                  );
                                              }
                                            }
                                          : async (_, value) => {
                                              if (!validate(value))
                                                return Promise.reject(
                                                  "El rut ingresado es inválido."
                                                );
                                              if (
                                                customers.find(
                                                  (el) =>
                                                    cleanRut(el.rut) ===
                                                    cleanRut(value)
                                                )
                                              )
                                                return Promise.reject(
                                                  "El rut ingresado ya existe."
                                                );
                                            },
                                      },
                                    ]}
                                  >
                                    <Input defaultValue={rut}></Input>
                                  </Form.Item>

                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Por favor ingrese el nombre",
                                      },
                                      {
                                        pattern:
                                          /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                                        message:
                                          "Los nombres no pueden incluir caracteres especiales",
                                      },
                                      {
                                        validator: customerToUpdate
                                          ? async (_, name) => {
                                              if (name.length > 50)
                                                return Promise.reject(
                                                  new Error(
                                                    "El nombre no debe tener más de 50 caracteres"
                                                  )
                                                );
                                              if (name.length < 3)
                                                return Promise.reject(
                                                  new Error(
                                                    "El nombre debe tener al menos 3 caracteres"
                                                  )
                                                );
                                              if (
                                                name.toLowerCase() !==
                                                customerToUpdate.name.toLowerCase()
                                              ) {
                                                if (
                                                  customers.find(
                                                    (el) =>
                                                      el.name.toLowerCase() ===
                                                      name.toLowerCase()
                                                  )
                                                )
                                                  return Promise.reject(
                                                    "El nombre ingresado ya existe"
                                                  );
                                              }
                                            }
                                          : async (_, name) => {
                                              if (name.length > 50)
                                                return Promise.reject(
                                                  new Error(
                                                    "El nombre no debe tener más de 50 caracteres"
                                                  )
                                                );
                                              if (name.length < 3)
                                                return Promise.reject(
                                                  new Error(
                                                    "El nombre debe tener al menos 3 caracteres"
                                                  )
                                                );
                                              if (
                                                customers.find(
                                                  (el) =>
                                                    el.name.toLowerCase() ===
                                                    name.toLowerCase()
                                                )
                                              )
                                                return Promise.reject(
                                                  "El nombre ingresado ya existe"
                                                );
                                            },
                                      },
                                    ]}
                                  >
                                    <Input defaultValue={name}></Input>
                                  </Form.Item>
                                </Row>

                                {index > 0 && (
                                  <DeleteOutlined
                                    style={{
                                      marginLeft: 30,
                                      color: "#FF5C00",
                                      alignSelf: "center",
                                    }}
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </div>
                            </Space>
                          );
                        })}

                    {fields.map(({ key, name }, index) => {
                      return (
                        <>
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: 30,
                            }}
                            align="baseline"
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <Row
                                style={{
                                  marginRight: 5,
                                  width: "100%",
                                  height: "auto",
                                  marginBottom: 0,
                                  display: "flex",
                                }}
                              >
                                <Form.Item
                                  name={[name, "subCompanyRut"]}
                                  normalize={(value) => format(value)}
                                  style={{ width: "100%" }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Ingrese el rut.",
                                    },
                                    {
                                      validator: async (_, value) => {
                                        if (!validate(value))
                                          return Promise.reject(
                                            "El rut ingresado es inválido."
                                          );
                                        if (
                                          customers.find(
                                            (el) =>
                                              cleanRut(el.rut) ===
                                              cleanRut(value)
                                          )
                                        )
                                          return Promise.reject(
                                            "El rut ingresado ya existe."
                                          );
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Rut subempresa" />
                                </Form.Item>

                                <Form.Item
                                  name={[name, "subCompanyName"]}
                                  style={{ width: "100%", marginBottom: 0 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Por favor ingrese el nombre",
                                    },
                                    {
                                      pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                                      message:
                                        "Los nombres no pueden incluir caracteres especiales",
                                    },
                                    {
                                      validator: customerToUpdate
                                        ? async (_, name) => {
                                            if (name.length > 50)
                                              return Promise.reject(
                                                new Error(
                                                  "El nombre no debe tener más de 50 caracteres"
                                                )
                                              );
                                            if (name.length < 3)
                                              return Promise.reject(
                                                new Error(
                                                  "El nombre debe tener al menos 3 caracteres"
                                                )
                                              );
                                            if (
                                              name.toLowerCase() !==
                                              customerToUpdate.name.toLowerCase()
                                            ) {
                                              if (
                                                customers.find(
                                                  (el) =>
                                                    el.name.toLowerCase() ===
                                                    name.toLowerCase()
                                                )
                                              )
                                                return Promise.reject(
                                                  "El nombre ingresado ya existe"
                                                );
                                            }
                                          }
                                        : async (_, name) => {
                                            if (name.length > 50)
                                              return Promise.reject(
                                                new Error(
                                                  "El nombre no debe tener más de 50 caracteres"
                                                )
                                              );
                                            if (name.length < 3)
                                              return Promise.reject(
                                                new Error(
                                                  "El nombre debe tener al menos 3 caracteres"
                                                )
                                              );
                                            if (
                                              customers.find(
                                                (el) =>
                                                  el.name.toLowerCase() ===
                                                  name.toLowerCase()
                                              )
                                            )
                                              return Promise.reject(
                                                "El nombre ingresado ya existe"
                                              );
                                          },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Nombre Subempresa" />
                                </Form.Item>
                              </Row>

                              <DeleteOutlined
                                style={{
                                  marginLeft: 30,
                                  color: "#FF5C00",
                                  alignSelf: "center",
                                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                                  paddingTop: "40px",
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                                onClick={() => remove(name)}
                              />
                            </div>
                          </Space>
                        </>
                      );
                      // }
                    })}
                    <Divider style={{ margin: ".5rem" }} />
                    {fields.length < 5 && (
                      <Form.Item>
                        <div
                          id="add-subcompany"
                          style={{ cursor: "pointer", color: "#FF5C00" }}
                          onClick={() => add()}
                        >
                          <PlusCircleOutlined />
                          <span style={{ marginLeft: 5 }}>
                            Añadir subempresa
                          </span>
                        </div>
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.List>
          )}

          <Row style={{ marginTop: 50 }}>
            <Button
              className="gama-outline"
              onClick={() => onCloseModal()}
              style={{ width: "40%", margin: 5 }}
            >
              Cancelar
            </Button>

            <Button
              type="primary"
              onClick={() => form.submit()}
              style={{ width: "50%", margin: 5 }}
            >
              {customerToUpdate ? "Guardar cambios" : "Crea nueva empresa"}
            </Button>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateCustomer;
