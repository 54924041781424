import React from "react";
import { Spin } from "antd";

const KiaRedirect = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" style={{ marginBottom: "50px" }} />
      <h1>Redireccionando, espere por favor</h1>
      {/* <Link to="dashboard" style={{ fontSize: "1.4rem" }}>
        Volver al inicio
      </Link> */}
    </div>
  );
};

export default KiaRedirect;
