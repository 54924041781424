import React, { useEffect } from "react";
import PdfPage1 from "../../assets/9efae8bd_1.png"
import PdfPage2 from "../../assets/9efae8bd_2.png"

function DownloadPDFAuto() {
  return (
    <div style={{backgroundColor: "gray"}}>
    <img alt="pdf-first-page" style={{width: "100%", height: "100%", padding: "16px 16px"}} src={PdfPage1} />
    <img alt="pdf-second-page" style={{width: "100%", height: "100%", padding: "16px 16px"}} src={PdfPage2} />
    </div>
  );
}

export default DownloadPDFAuto;