import request from "../request";

import {
  BASE_USERS_API_URL,
} from '../url';


export const getRolesRequest = async ({
  orderBy = "DESC",
  orderByProperty = "createdAt",
  ...query
}) => {
  return request({
    url:
      `${BASE_USERS_API_URL}/roles?page=${query?.currentPage || 1}&pageSize=${query?.pageSize || 7}&orderBy=${orderBy || "DESC"}&orderByProperty=${orderByProperty || "createdAt"}&search=${query?.search || ""}`, 
    method: "GET",
  });
};

export const getRoleRequest = async (roleId) => {
  return await request({
    url: `${BASE_USERS_API_URL}/roles/${roleId}`,
    method: "GET",
  });
};

export const getMineRoleRequest = async (roleId) => {
  return await request({
    url: `${BASE_USERS_API_URL}/roles/mine/${roleId}`,
    method: "GET",
  });
};

export const getPermissionsRequest = async () => {
  return await request({
    url: `${BASE_USERS_API_URL}/permissions`,
    method: "GET",
  });
};

export const getAllPermissionsRequest = async () => {
  return await request({
    url: `${BASE_USERS_API_URL}/all-permissions`,
    method: "GET",
  });
};

export const createRoleRequest = async (payload) => {
  return await request({
    url: `${BASE_USERS_API_URL}/roles`,
    method: "POST",
    data: payload,
  });
};

export const updateRoleRequest = async (roleId, payload) => {
  return await request({
    url: `${BASE_USERS_API_URL}/roles/${roleId}`,
    method: "PATCH",
    data: payload,
  });
};

export const removeRoleRequest = async (roleId) => {
  return await request({
    url: `${BASE_USERS_API_URL}/roles/${roleId}`,
    method: "DELETE",
  });
};
