import request from "../request";
import { BASE_VEHICULE_API_URL, BASE_SHAREPOINT_API_URL } from "../url";

export const getVehicles = async (filters, page = 1, query ) => {
  const { orderBy = "DESC", orderByProperty = "deviceContract.id" } = query;
  const queryParams = new URLSearchParams(filters).toString();
  return request({
    url:
      `${BASE_VEHICULE_API_URL}/vehicles/devices/lines?page=${page}&orderBy=${orderBy}&orderByProperty=${orderByProperty}&` +
      queryParams,
    method: "GET",
  });
};

export const getVehicleBrandFilter = async () => {
  return request({
    url: `${BASE_VEHICULE_API_URL}/vehicles/brands`,
    method: "GET",
  });
};

export const getVehicleCustomerFilter = async () => {
  return request({
    url: `${BASE_VEHICULE_API_URL}/documents/customers`,
    method: "GET",
  });
};

export const getVehicleModelFilter = async () => {
  return request({
    url: `${BASE_VEHICULE_API_URL}/vehicles/models`,
    method: "GET",
  });
};

export const getDevicesId = async () => {
  return request({
    url: `${BASE_VEHICULE_API_URL}/vehicles/patents`,
    method: "GET",
  });
};

export const getAllDocuments = async (filters, page = 1, query ) => {
  const queryParams = new URLSearchParams(filters).toString();
  return request({
    url:
      `${BASE_VEHICULE_API_URL}/documents/contracts?page=${page}&orderBy=${
        query?.orderBy || "ASC"
      }&orderByProperty=${query?.orderByProperty || "rentId"}&` + queryParams,
    method: "GET",
  });
};

export const getSharepointDoc = async (deviceId, folder) => {
  return request({
    url: `${BASE_SHAREPOINT_API_URL}/documents/ssm/${deviceId}?list=Vehiculo - Documentos&folder=${folder}`,
    method: "GET",
  });
};

export const downloadDocument = async (fileName, detail) => {
  return request({
    url: `${BASE_SHAREPOINT_API_URL}/documents/ssm/download?list=Vehiculo - Documentos&folder=${detail}&file=${fileName}`,
    method: "GET",
  });
};

export const exportVehiclesTemplate = async ({ page = 1 }) => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/vehicles/export?page=${page}`,
    method: "GET",
  });
};

export const getServices = async () => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/workshops/services`,
    method: "GET",
  });
};
export const getWorkShops = async () => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/workshops/branches`,
    method: "GET",
  });
};

export const getVehiclesCount = async () => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/vehicles/fleet`,
    method: "GET",
  });
}

export const getReportsTypes = async (customer) => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/reports/types?customer=${customer}`,
    method: "GET",
  });
};

export const getReportsBrands = async (customer) => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/reports/brands?customer=${customer}`,
    method: "GET",
  });
};

export const getReportsTransmissions = async (customer) => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/reports/trasmissions?customer=${customer}`,
    method: "GET",
  });
};

export const getReportsTypesClient = async () => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/reports/types`,
    method: "GET",
  });
};

export const getReportsBrandsClient = async () => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/reports/brands`,
    method: "GET",
  });
};

export const getReportsTransmissionsClient = async () => {
  return await request({
    url: `${BASE_VEHICULE_API_URL}/reports/trasmissions`,
    method: "GET",
  });
};

export const getServicesQuery = async (query, customerId, filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return await request({
    url:
      `${BASE_VEHICULE_API_URL}/workshops/custaccount/${customerId}?orderByProperty=${
        query?.orderByProperty || "OSPADRE"
      }&orderBy=${query?.orderBy || "DESC"}&pageSize=7&page=${
        query?.currentPage || 1
      }&` + queryParams,
    method: "GET",
  });
};
