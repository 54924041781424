import RoutesWrapper from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getHasAccess,
  getPermissionsData,
  getUserData,
  loadToken,
  setAcceses,
} from "../store/auth";
import {
  isAnotherGamaRole,
  isExecutive,
  isSuperAdmin,
  isSupervisor,
} from "../utils/roles";
import {
  getCustomerAssignmentRequest,
  getRelatedCustomersRequest,
} from "../requests/customers";
import { setCustomers } from "../store/customers";
import { getFiltersData } from "../store/vehicles/thunk";
import Notifications from "../components/Notifications";
import { getAssignmentCustomersFilter } from "../store/customers/thunks";
import useTableQuery from "../hooks/useTableQuery";
import { getToken } from "../utils/jwt";
import { isValidAuthToken } from "../requests/auth";

function App() {
  const dispatch = useDispatch();
  const hasAccess = useSelector(getHasAccess);
  const { accesses, type } = useSelector(getUserData);
  const userData = useSelector(getUserData);
  const { query } = useTableQuery();
  const permissions = useSelector(getPermissionsData);

  const adminModule = permissions.find(
    (item) => item.code === "PLOP_ADMIN_MODULE"
  );

  useEffect(() => {
    if (window.location.pathname === "/kiajustdrive") {
      window.location.replace("https://tripp.cl/kiajustdrive/");
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    const fetchRelatedCustomers = async (query) => {
      if (accesses.length === 0) return;
      try {
        if (isSuperAdmin(accesses) || isAnotherGamaRole(accesses, type)) {
          const { data } = await getRelatedCustomersRequest();
          dispatch(setCustomers(data));
          dispatch(getAssignmentCustomersFilter(data));
          return;
        }

        if (isSupervisor(accesses) || isExecutive(accesses)) {
          const { data } = await getCustomerAssignmentRequest(query);
          dispatch(setCustomers(data));
          dispatch(getAssignmentCustomersFilter(data));
          dispatch(getFiltersData());
          return;
        }

        if (
          !(
            isSuperAdmin(accesses) ||
            isSupervisor(accesses) ||
            isExecutive(accesses)
          )
        ) {
          if (adminModule?.code === "PLOP_ADMIN_MODULE") {
            const { data } = await getRelatedCustomersRequest();
            dispatch(setCustomers(data));
            dispatch(getAssignmentCustomersFilter(data));
            dispatch(getFiltersData());
          }
        }
      } catch (error) {
        Notifications.error(
          "Error al cargar",
          "Error al cargar las compañías, intentalo más tarde."
        );
      }
    };

    dispatch(loadToken());

    if (hasAccess && accesses) {
      fetchRelatedCustomers(query);
    }
  }, [hasAccess, dispatch, accesses]);
  return (
    <div>
      <RoutesWrapper />
    </div>
  );
}

export default App;
