import jwtDecode from "jwt-decode";

const TOKEN_NAME = "gama.token";

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (!token) {
    return;
  }
  return token;
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_NAME, token);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
};

export const getTokenPayload = () => {
  const token = getToken();

  return jwtDecode(token);
};

export const isExpiredToken = (token) => {
  const decodedJwt = parseJwt(token);

  return decodedJwt.exp * 1000 < Date.now() ? false : true;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}