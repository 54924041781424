import React, { useEffect, useState } from "react";
import { Menu, Layout, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getLoading,
  getPermissionsData,
  getUserData,
} from "../../../../store/auth";
import { useSelector } from "react-redux";
import { removeDuplicatesFromArray } from "../../../../utils/removeDuplicates";
import { LoadingOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const LayoutSider = ({ hasAccess }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const [selectedItem, setSelectedItem] = useState("1");
  const permissions = useSelector(getPermissionsData);
  const [loading, setLoading] = useState(false);
  const loadingData = useSelector(getLoading);

  const [current] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );

  const redirect = (route) => navigate(route);

  const renderItem = (item) => {
    if (item.key.startsWith("sub")) {
      return (
        <Menu.SubMenu key={item.key} title={item.label}>
          {item.children.map((child) => (
            <Menu.Item key={child.key} onClick={() => redirect(child.redirect)}>
              {child.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={item.key} onClick={() => redirect(item.redirect)}>
        {item.label}
      </Menu.Item>
    );
  };

  const getItem = (label, key, redirect, children, group = "") => {
    return {
      key,
      redirect,
      children,
      label,
      group,
    };
  };

  const handleClick = (e) => {
    localStorage.setItem("dashboardSelector", e.key);
  };

  useEffect(() => {
    const menuItems = renderMenuItems();
    const findData = menuItems.find((item) => item.label === "Mis datos");

    if (
      userData?.accesses?.[0]?.role.code === "Conductor" &&
      window.location.pathname === "/dashboard"
    ) {
      navigate("/mis datos");
      localStorage.setItem("dashboardSelector", findData?.key);
    }
  }, [userData]);

  const spinIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  useEffect(() => {
    const localStorageSelector = localStorage.getItem("dashboardSelector");

    if (localStorageSelector && current !== "/dashboard") {
      setSelectedItem(localStorageSelector);
    }
    renderMenuItems();
  }, [userData]);

  useEffect(() => {}, []);

  const renderMenuItems = () => {
    let count = 1;
    let adminItems = [];
    let menuItems = [];

    for (let permission of userData?.permissions || []) {
      const foundPermission = permissions.find((p) => p.id === permission);
      const description = foundPermission?.description.toLowerCase();

      if (
        description === "usuarios" ||
        description === "crear / editar usuarios" ||
        description === "ver / listar usuarios" ||
        description === "desactivar usuarios"
      ) {
        adminItems.push(getItem("Usuarios", `${++count}`, "/usuarios"));
      } else if (description === "roles" || description === "empresas") {
        adminItems.push(
          getItem(foundPermission.description, `${++count}`, `/${description}`)
        );
      } else {
        const itemName = capitalizeFirstLetter(description);
        const itemPath =
          description === "vehículos" ? "/vehiculos" : `/${description}`;

        menuItems.push(getItem(itemName, `${++count}`, itemPath));
      }
    }

    menuItems = [
      getItem("Dashboard", "1", "/dashboard"),
      getItem(
        "Administrador",
        "sub1",
        "",
        removeDuplicatesFromArray(adminItems),
        "group"
      ),
      ...menuItems,
      getItem("Declarar siniestro", "17", "/siniestros"),
    ];

    if (adminItems.length === 0) menuItems.splice(1, 1);

    const updatedMenuItems = menuItems.map((item) => {
      if (item.label === "Ver documentos") {
        return { ...item, label: "Documentos vehículos" };
      }
      return item;
    });

    if (userData?.accesses?.[0]?.role.code === "Conductor") {
      return updatedMenuItems
        .filter(
          (item) =>
            item.redirect !== "/dashboard" &&
            item.redirect !== "/notificaciones"
        )
        .sort((a, b) => a.redirect.localeCompare(b.redirect));
    } else {
      return userData?.type === "CLIENT"
        ? menuItems.filter(
            (item) =>
              item.redirect !== "/administrador" &&
              item.label !== "Servicios" &&
              item.redirect !== "/undefined"
          )
        : menuItems.filter(
            (item) =>
              item.redirect !== "/administrador" &&
              item.redirect !== "/ver documentos" &&
              item.label !== "Siniestros"
          );
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      width={250}
      className="sidebar site-layout-background"
    >
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultOpenKeys={["sub1"]}
        forceSubMenuRender={true}
        onClick={handleClick}
        selectedKeys={[selectedItem]}
      >
        {hasAccess &&
          userData &&
          renderMenuItems()?.map((item) => renderItem(item))}
      </Menu>

      {loadingData && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 1)",
            zIndex: 9999,
          }}
        >
          <Spin indicator={spinIcon} size="large" />
        </div>
      )}
    </Sider>
  );
};

export default LayoutSider;
