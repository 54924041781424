import request from "../request";
import {
  BASE_VEHICULE_API_URL,
  BASE_SHAREPOINT_API_URL,
} from '../url';
import moment from "moment/dist/moment";
import axios from "axios";
export const client = axios.create();

moment.locale("es");

export const MonthsNames = {
  '01': 'Enero',
  '02': 'Febrero',
  '03': 'Marzo',
  '04': 'Abril',
  '05': 'Mayo',
  '06': 'Junio',
  '07': 'Julio',
  '08': 'Agosto',
  '09': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre',
};

export const getAllInvoicePropose = async (year, month, search) => {
    try {

        let params = {
          year,
          month,
        };

        if (search) {
          params.search = search;
        }

        const response = await request({
          url:
            `${BASE_VEHICULE_API_URL}/invoiceattempt/all?`
            + new URLSearchParams(params).toString(),
            method: "GET",
          });
          
          return response.data.items?.map((r) => {
            const period = `${MonthsNames[r.periodMonth]} ${r?.periodYear}`;
            return {
                ...r,
                period,
            }
          });
    } catch (error) {
        return error;
    }
};

export const getInvoiceProposeById = async (id) => {
  return request({
    url: `${BASE_VEHICULE_API_URL}/invoiceattempt?`
    + new URLSearchParams({
      id,
    }).toString(),
    method: "GET",
  });

};


export const changeStatusInvoicePropose = async (id, state, comments = null) => {
  return request({
    url:
      `${BASE_VEHICULE_API_URL}/invoiceattempt`,
      method: "PUT",
      data: {
        id,
        state,
        comments,
      },
    });
};

export const changeToPendingInvoicePropose = async (id) => {
  return request({
    url:
      `${BASE_VEHICULE_API_URL}/invoiceattempt/newfrom`,
      method: "POST",
      data: {
        id
      },
    });
};


export const deleteFileInvoicePropose = async (id, filename) => {
  return request({
    url:
      `${BASE_VEHICULE_API_URL}/invoiceattempt/doc?` + new URLSearchParams({
        id,
        filename,
      }).toString(),
      method: "DELETE",
    });
};

export const getFilesByRut = async (tag, year, month, rut) => {
  return request({
    url: `${BASE_SHAREPOINT_API_URL}/documents?`
    + new URLSearchParams({
      subFolder: `${tag}/${year}/${month.toUpperCase()}`,
      rut
    }).toString(),
    method: "GET",
  });
};


export const downloadFile = async (filename, subFolder) => {
  return await request({
    url: `${BASE_SHAREPOINT_API_URL}/documents/download?file=${filename}&subFolder=${subFolder}&site=GestionComercial&list=Documentos compartidos&folder=Respaldos`,
    method: "GET",
    responseType: "blob",
  });
};

export const downloadFileToInvoicePropose = async (url) => {
  return client({
    url,
    method: "GET",
    responseType: "blob",
    Headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

export const uploadFile = async (file, id) => {
  const formData = new FormData();
  formData.append('file',file);
  formData.append('id', id);

  return request({
    url: `${BASE_VEHICULE_API_URL}/invoiceattempt/doc`,
    method: "POST",
    data: formData,
    withCredentials: false,
    Headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    }
  });
};


export const getBlobType = (filename) => {

  if (filename.indexOf('.pdf') > -1) {
    return "application/pdf";
  }

  if (filename.indexOf('.jpg') > -1 || filename.indexOf('.jpeg') > -1) {
    return "image/jpeg";
  }

  if (filename.indexOf('.png') > -1) {
    return "image/png";
  }

  return null;
}
