import {
  Col,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import GoToTop from "../../components/GoToTop";
import Burger from "../../components/layouts/HomeLayout/Burger";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-mobility.webp";
import Footer from "../../components/layouts/HomeLayout/Footer";
import { format } from "rut.js";
import "./index.less";
import { CaretDownOutlined, UploadOutlined } from "@ant-design/icons";
import {
  sendReportChannelPayload,
  sendReportChannelPayloadWithFile,
} from "../../requests/contact";
import ReCAPTCHA from "react-google-recaptcha";

function Complaints() {
  const [isHome, setIsHome] = useState(false);
  const [form] = Form.useForm();
  const [files, setFiles] = useState();
  const [loadingFile, setLoadingFile] = useState(false);
  const reRef = useRef();

  const fileTypes = ['image/jpeg',
    'image/png',

    'application/pdf',

    'application/msword',

    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

    'application/vnd.ms-powerpoint',

    'application/vnd.openxmlformats-officedocument.presentationml.presentation',

    'audio/mpeg',

    'audio/wav',

    'video/mp4',

    'video/quicktime']

  const burguerData = [
    {
      title: "Sucursales",
      link: "/branches",
    },
    {
      title: "Nuestros servicios",
      link: "#services",
    },
    {
      title: "Quienes Somos",
      link: "#us",
    },
    { title: "Beneficios", link: "#benefits" },
    { title: "Publicaciones", link: "#posts" },
  ];

  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (window.location.pathname === "/legalWarning") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const props = {
    name: "files",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const { scrollDirection } = useScroll();

  const onFinish = async (values) => {
    if (files) {
      setLoadingFile(true)
    } else {
      setLoadingFile(false)
    }
    try {
      const token = await reRef.current.executeAsync();
      reRef.current.reset();
      
      values.rut = format(values.rut, { dots: false });
      values.phoneNumber = +values.phoneNumber;
      delete values.complaintProof;
      const payload = { ...values, reCaptcha: token };
      const { data } = files
        ? await sendReportChannelPayloadWithFile(payload, files)
        : await sendReportChannelPayload(payload);

      if (data.success)
        return Modal.success({
          content: "Datos enviados con éxito",
          onOk() {
            window.location.replace("/");
          },
        });
      setFiles(null);
      setLoadingFile(false)
    } catch (error) {
      setLoadingFile(false)
      console.error(error)
      if (error?.status === 409) {
        return Modal.error({
          content:
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, png, pdf, msword, xml, powerpoint, mpeg, wav, mp4, quicktime.</span>
            </>,
        });
      }
      if (error?.status === 403) {
        return Modal.error({
          content: "Ha superado la cantidad máxima de archivos (5)",
        });
      }
      if (error?.status === 413) {
        return Modal.error({
          content: "El peso del archivo supera máximo 10MB",
        });
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setFiles(null);
      return e;
    }

    const sum = e.fileList.reduce((acc, item) => acc + parseInt(item.size), 0);

    console.log(sum, "sum")

    if (sum > 7000000) {
      e.fileList.pop()
      return Modal.error({
        content: `El peso total de todos los archivos supera el máximo de 10MB`,
      });
    }

    if (e.fileList.length === 1) setFiles(e.fileList[0].originFileObj)

    if (e.fileList.length > 5) {
      e.fileList.pop()
      return Modal.error({
        content: "Ha superado la cantidad máxima de archivos (5)",
      });
    }

    e.fileList.map(file => {
      if (file.size > 10000000) {
        e.fileList.pop()
        return Modal.error({
          content: `El peso del archivo ${file?.name} supera el máximo de 10MB`,
        });
      } else {
        setFiles(e.fileList.map(file => file.originFileObj));
      }
    })

    e.fileList.map(file => {
      if (fileTypes?.includes(file?.type)) {
        setFiles(e.fileList.map(file => file.originFileObj));
      } else {
        e.fileList.pop()
        return Modal.error({
          content:
            <div>
              <div>El Archivo {file.name} no tiene un formato valido.</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, png, pdf, msword, xml, powerpoint, mpeg, wav, mp4, quicktime.</span>
            </div>,
        });
      }

    })

    return e?.fileList;
  };

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const { TextArea } = Input;


  const { Option } = Select;

  return (
    <div className="required-none">
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `12vh`,
          }}
        >
          <Row className="header-contain">
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      style={{
                        color:
                          window.location.pathname === "/mobility"
                            ? "#FF3200"
                            : "white",
                      }}
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image preview={false} src={Logo} width={120} />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contactMobility">
                        <Button
                          onClick={() => { }}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {!isHome ? (
                            <>
                              <a smooth href="/branches">
                                Sucursales
                              </a>
                              <a smooth href="#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#us">
                                Quienes Somos
                              </a>
                              <a smooth href="#benefits">
                                Beneficios
                              </a>
                              <a smooth href="#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/branches">Sucursales</Link>
                              <a smooth href="/mobility#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="/mobility#us">
                                Quienes Somos
                              </a>
                              <a href="/mobility#benefits">Beneficios</a>
                              <a href="/mobility#posts">Publicaciones</a>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
        </section>
      </Layout>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
          fontSize: "32px",
          fontWeight: "600",
        }}
      >
        <Col>CANAL DE DENUNCIAS</Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              border: "1px solid rgba(255, 50, 0, 0.1)",
              margin: biggerThan900
                ? "50px 30px 50px 60px"
                : "30px 15px 30px 15px",
              paddingTop: "50px",
              paddingLeft: "40px",
              borderRadius: "17px",
              width: "700px",
            }}
          >
            <Col>
              <Form
                name="complaints"
                initialValues={{ complaints: [[{}]] }}
                form={form}
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                layout="horizontal"
              >
                <Row>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      name="rut"
                      normalize={(value) => format(value)}
                      label="Rut/Número documento (Opcional)"
                      style={{ fontWeight: "bold", marginBottom: "40px" }}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10} style={{ marginRight: "30px" }}>
                    <Form.Item
                      rules={[
                        {
                          whitespace: true,
                        },
                        {
                          min: 2,
                          message: "Debe tener al menos 2 caracteres",
                        },
                        {
                          max: 50,
                          message: "Debe tener máximo 50 caracteres",
                        },
                        {
                          pattern: /^[A-Za-z\s.'Ññ]+$/,
                          message: "El nombre solo debe incluir letras.",
                        },
                      ]}
                      name="name"
                      label="Nombre Completo (Opcional)"
                      labelCol={{
                        span: 24,
                      }}
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="email"
                      label="Correo electrónico (Opcional)"
                      rules={[
                        {
                          type: "email",
                          message: "Por favor ingrese un correo válido",
                        },
                        {
                          max: 40,
                          message:
                            "No puede ingresar más de 40 caracteres en un correo",
                        },
                        {
                          whitespace: true,
                        },
                        {
                          pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9@.]+$/,
                          message: "No puede incluir caracteres especiales",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      rules={[
                        {
                          pattern: /^[+]*\d{9}$/,
                          message:
                            "Ingrese un número válido, sin letras y de 9 dígitos.",
                        },
                      ]}
                      name="phoneNumber"
                      label="Teléfono Contacto (Opcional)"
                      labelCol={{
                        span: 24,
                      }}
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Este campo es obligatorio",
                        },
                      ]}
                      name="complaint"
                      label="Tipo Denuncia"
                      style={{ fontWeight: "bold", marginBottom: "40px" }}
                    >
                      <Select suffixIcon={<CaretDownOutlined />}>
                        <Select.Option value="Acoso Laboral">
                          Acoso Laboral
                        </Select.Option>
                        <Select.Option value="Acoso Sexual">
                          Acoso Sexual
                        </Select.Option>
                        <Select.Option value="Delitos Ley 20.393">
                          <Tooltip title=" Ejemplo: Cohecho, Lavado de activos, Financiamiento del Terrorismo, Receptación, Negociación incompatible, Corrupción entre privados, Apropiación indebida, Administración desleal, Contaminación de Agua, entre otros.">
                            <span style={{ fontSize: "14px" }}>
                              Delitos Ley 20.393
                            </span>
                          </Tooltip>
                        </Select.Option>

                        <Select.Option value="Conducta Antiética">
                          <Tooltip title=" (Robo, Hurto, Soborno, Fraudes, Falsificación de contratos, Adulteración de información, Desvío de Recursos).">
                            <span style={{ fontSize: "14px" }}>
                              Conducta Antiética
                            </span>
                          </Tooltip>
                        </Select.Option>
                        <Select.Option value="Otros">Otros</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Este campo es obligatorio",
                        },
                        {
                          max: 20,
                          message: "Debe tener máximo 20 caracteres",
                        },
                        {
                          pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                          message:
                            "La razón social no puede incluir caracteres especiales",
                        },
                      ]}
                      name="personalComplaint"
                      label="Tipo personal"
                      labelCol={{
                        span: 24,
                      }}
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Select suffixIcon={<CaretDownOutlined />}>
                        <Select.Option value="Interno">Interno</Select.Option>

                        <Select.Option value="Externo proveedor">
                          Externo proveedor
                        </Select.Option>
                        <Select.Option value="Externo Cliente">
                          Externo Cliente
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Este campo es obligatorio",
                        },
                      ]}
                      name="complaintRealization"
                      label="¿Cómo se dió cuenta de esta infracción?"
                      style={{ fontWeight: "bold", marginBottom: "40px" }}
                    >
                      <Select suffixIcon={<CaretDownOutlined />}>
                        <Select.Option value=" Me sucedio">
                          Me sucedio
                        </Select.Option>
                        <Select.Option value="Lo observe">
                          Lo observe
                        </Select.Option>
                        <Select.Option value="Un compañero de trabajo me conto sobre el tema">
                          Un compañero de trabajo me conto sobre el tema
                        </Select.Option>
                        <Select.Option value=" Lo escuche">
                          Lo escuche
                        </Select.Option>
                        <Select.Option value="Alguien fuera de la empresa me lo conto">
                          Alguien fuera de la empresa me lo conto
                        </Select.Option>
                        <Select.Option value="Encontre accidentalmente un documento o archivo">
                          Encontre accidentalmente un documento o archivo
                        </Select.Option>
                        <Select.Option value="Otro">Otro</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      name="others"
                      label="Otros asuntos"
                      style={{ fontWeight: "bold", marginBottom: "40px" }}
                    >
                      <Select style={{ marginRight: "50px" }}>
                        <Select.Option value=" Me sucedio">
                          Me sucedio
                        </Select.Option>
                        <Select.Option value="Lo observe">
                          Lo observe
                        </Select.Option>
                        <Select.Option value="Un compañero de trabajo me conto sobre el tema">
                          Un compañero de trabajo me conto sobre el tema
                        </Select.Option>
                        <Select.Option value=" Lo escuche">
                          Lo escuche
                        </Select.Option>
                        <Select.Option value="Alguien fuera de la empresa me lo conto">
                          Alguien fuera de la empresa me lo conto
                        </Select.Option>
                        <Select.Option value="Encontre accidentalmente un documento o archivo">
                          Encontre accidentalmente un documento o archivo
                        </Select.Option>
                        <Select.Option value="Otro">Otro</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                </Row>

                <Row>

                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      rules={[
                        {
                          max: 9999,
                          message: "Debe tener máximo 9999 caracteres",
                        },
                        {
                          pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                          message: "No puede incluir caracteres especiales",
                        },
                      ]}
                      name="complaintDetails"
                      label="

                      Detalles de la denuncia *"
                      labelCol={{
                        span: 24,
                      }}
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10} style={{ marginRight: "50px" }}>
                    <Form.Item
                      name="complaintProof"
                      label="Aporte las envidencias en su poder"
                      getValueFromEvent={normFile}
                      labelCol={{
                        span: 24,
                      }}
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Upload
                        beforeUpload={() => false}
                        style={{ height: "50px!important" }}
                        {...props}
                      >
                        <Button icon={<UploadOutlined />}>
                          Subir Archivo
                        </Button>
                        {loadingFile && (
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <span>Cargando archivo, por favor espere.</span>
                            <Spin />
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  align={biggerThan900 ? "middle" : ""}
                  justify={biggerThan900 ? "center" : ""}
                  style={{ marginTop: "10px", marginBottom: "30px" }}
                >
                  <Button
                    style={{ width: "40%" }}
                    type="primary"
                    onClick={() => form.submit()}
                  >
                    Enviar
                  </Button>
                </Row>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  ref={reRef}
                  size="invisible"
                />
              </Form>
            </Col>
          </Row>
        </Row>
      </Row>
      <Footer />
    </div>
  );
}

export default Complaints;
