import React from "react";
import { Ring } from "@uiball/loaders";
import loadingLogo from "../../../assets/img/loadingG.png";
import { useMediaPredicate } from "react-media-hook";

function Spinner() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  return (
    <div>
      <Ring size={100} lineWeight={5} speed={2} color="#FF5F00" />
      <img
        style={{
          position: "absolute",
          left: biggerThan900 ? "49.1%" : "47%",
          top: biggerThan900 ? "50.4%" : "48.5%",
        }}
        src={loadingLogo}
        alt="loading-main-logo"
      />
    </div>
  );
}

export default Spinner;
