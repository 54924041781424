import React, { useEffect, useState } from "react";
import {
  Menu,
  Table,
  Tag,
  Typography,
  Row,
  Col,
  Input,
  Select,
  Modal,
} from "antd";
import { format } from "rut.js";
import {
  CaretDownOutlined,
  CloseOutlined,
  EllipsisOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import { Option } from "antd/lib/mentions";
import { getPermissionsData, getUserData } from "../../store/auth";
import { useSelector } from "react-redux";
import {
  isSuperAdmin,
  isExecutive,
  isSupervisor,
  isAnotherGamaRole,
} from "../../utils/roles";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import "./index.less";
import Notifications from "../../components/Notifications";
import { deleteUser } from "../../requests/users";
import { useMediaPredicate } from "react-media-hook";

const { Search } = Input;

const UsersTable = ({
  users,
  loading,
  total,
  onChange,
  onSelectUpdate,
  onSelectDetails,
  onSelectPassword,
  onToggleAccess,
  onOpenForm,
  pageSize,
  currentPage,
  handleFilterByCustomer,
  handleFilterByRole,
  handleSearch,
  filters,
  handleClearFilters,
  customers = [],
  onOpenBulkUploadForm,
  tab,
  onDeleteUser,
  setInputValue,
  inputValue,
}) => {
  const { accesses } = useSelector(getUserData);
  const onSelectUpdateButton = (userId) => onSelectUpdate(userId);
  const { height, width } = useWindowDimensions();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toDeleteUser, setToDeleteUser] = useState();
  const onSelectDetailsButton = (userId) => onSelectDetails(userId);
  const userData = useSelector(getUserData);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const isAccessActive = (user) => user?.access?.status === 1;
  const { Text } = Typography;
  const permissions = useSelector(getPermissionsData);
  const [showButton, setShowButton] = useState(false);
  const [removeAction, setRemoveAction] = useState(true);
  const handleDeleteUser = async (values) => {
    try {
      await deleteUser(values.id);
      Notifications.success(
        "Usuario eliminado con éxito",
        `Se ha eliminado el usuario con el rut ${values.rut} exitosamente.`
      );
      setIsModalOpen(false);
      onDeleteUser();
    } catch (error) {
      console.error(error);
    }
  };
  const showModal = (user) => {
    setToDeleteUser(user);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderMenu = (user) => (
    <Menu>
      {isExecutive(accesses) ||
      userData.type === "CLIENT" ||
      isSuperAdmin(accesses) ||
      isSupervisor(accesses)
        ? showEdit && (
            <Menu.Item onClick={() => onSelectUpdateButton(user.id)} key={"1"}>
              Editar
            </Menu.Item>
          )
        : null}
      {userData.type === "CLIENT" ||
      isSuperAdmin(accesses) ||
      isSupervisor(accesses) ||
      isExecutive(accesses)
        ? showDeactivate && (
            <Menu.Item key={"2"} onClick={() => onToggleAccess(user.access.id)}>
              {isAccessActive(user) ? "Desactivar" : "Activar"}
            </Menu.Item>
          )
        : null}
      {isSuperAdmin(accesses) ? (
        <Menu.Item key={"3"} onClick={() => onSelectPassword(user.id)}>
          Cambiar contraseña
        </Menu.Item>
      ) : null}
      {isSuperAdmin(accesses) ? (
        <Menu.Item key={"4"} onClick={() => showModal(user)}>
          Eliminar Usuario
        </Menu.Item>
      ) : null}
    </Menu>
  );

  useEffect(() => {
    for (let permission of userData?.permissions || []) {
      const foundPermission = permissions?.find((p) => p.id === permission);
      const description = foundPermission?.description.toLowerCase();

      if (description === "crear / editar usuarios") {
        setShowButton(true);
        setShowEdit(true);
      }

      if (description === "desactivar usuarios") {
        setShowDeactivate(true);
      }

      if (
        description === "crear / editar usuarios" ||
        description === "desactivar usuarios"
      ) {
        setRemoveAction(false);
      }
    }
  }, [accesses]);

  let expandedRowKeys = [];

  const roles = [
    "Administrador",
    "Super Administrador",
    "Ejecutivo",
    "Supervisor",
  ];

  function handleRowKeys(isExpansion, record) {
    console.log(record);
    let rowKey = record.access.id;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  const columns = [
    {
      title: <Text strong>Empresa</Text>,
      key: "customer",
      sorter: {
        compare: (a, b) => a.customer.name.localeCompare(b.customer.name),
      },
      render: (user) => (
        <span className={isAccessActive(user) ? "" : "disabled-row"}>
          {user.customer.name}
        </span>
      ),
    },
    {
      title: <Text strong>Nombre</Text>,
      key: "firstName",
      sorter: {
        compare: (a, b) => a.firstName.localeCompare(b.firstName),
      },
      render: (user) => (
        <span className={isAccessActive(user) ? "" : "disabled-row"}>
          {`${user.firstName} ${user.lastName}`}
        </span>
      ),
    },
    {
      title: <Text strong>ID</Text>,
      key: "rut",
      width: 150,
      sorter: (a, b) => a?.rut?.slice(0, -2) - b?.rut?.slice(0, -2),
      render: (user) => (
        <span className={isAccessActive(user) ? "" : "disabled-row"}>
          {/-\w$/.test(user.identifierNumber)
            ? format(user.identifierNumber)
            : user.identifierNumber}
        </span>
      ),
    },
    {
      title: <Text strong>Correo</Text>,
      key: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
      render: (user) => (
        <span className={isAccessActive(user) ? "" : "disabled-row"}>
          {user.email}
        </span>
      ),
    },
    {
      title: <Text strong>Perfil</Text>,
      dataIndex: "role",
      key: "role",
      sorter: {
        compare: (a, b) => a.role.code.localeCompare(b.role.code),
      },
      render: (role) => <Tag>{role.code}</Tag>,
    },
    {
      title: <Text strong>Detalle</Text>,
      key: "detalle",
      render: (user) => (
        <u
          style={{ color: "#FF5300", cursor: "pointer" }}
          onClick={() => onSelectDetailsButton(user.id)}
        >
          Ver detalle
        </u>
      ),
    },
    {
      title: <Text strong>Acción</Text>,
      key: "id",
      render: (user) => (
        <>
          <Dropdown overlay={renderMenu(user)} placement="bottomLeft">
            <Button className="action-table-button">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const mobileColumns = [
    {
      title: <Text strong>Empresa</Text>,
      key: "customer",
      width: 120,
      sorter: {
        compare: (a, b) => a.customer.name.localeCompare(b.customer.name),
      },
      render: (user) => (
        <span className={isAccessActive(user) ? "" : "disabled-row"}>
          {user.customer.name}
        </span>
      ),
    },
    {
      key: "firstName",
      title: <Text strong>Nombre</Text>,
      dataIndex: "firstName",
      width: 100,
      sorter: {
        compare: (a, b) => a.firstName.localeCompare(b.firstName),
      },
    },
  ];

  const filteredColumns = columns.filter((column) => column.key !== "id");
  const formCustomers = customers.customers ? customers.customers : customers;

  return (
    <div
      className="responsive-users"
      style={{
        padding: "24px 24px",
      }}
    >
      {width <= 640 ? (
        <>
          <Row span={8} align="start" style={{ paddingBottom: "15px" }}>
            <Col span={24}>
              <Search
                placeholder="Busqueda por Rut / Nombre"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onSearch={handleSearch}
                style={{
                  width: "100%",
                  marginBottom: "15px",
                }}
              />
            </Col>
            {userData?.type === "CLIENT" ? null : (
              <Col span={24}>
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  showSearch
                  value={filters.customer || undefined}
                  onChange={handleFilterByCustomer}
                  style={{
                    width: "100%",
                    marginBottom: "15px",
                  }}
                  placeholder="Empresa"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    formCustomers &&
                    formCustomers.map((customer) => {
                      return {
                        label: customer.name,
                        value: customer.name,
                      };
                    })
                  }
                />
              </Col>
            )}
            {userData.type === "CLIENT" || tab === "client" ? null : (
              <Col span={24}>
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  value={filters.role || undefined}
                  placeholder="Rol"
                  style={{
                    width: "100%",
                    borderRadius: "50px",
                    marginRight: "15px",
                  }}
                  onChange={handleFilterByRole}
                >
                  {roles.map((rol) => (
                    <Option key={rol} value={rol}>
                      {rol}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
            <Row align="middle">
              <Col>
                <a
                  style={{
                    border: "none",
                    textAlign: "bottom",
                    color: "#FF3200",
                    textDecoration: "underline",
                    fontSize: "16px",
                    marginTop: "15px",
                    marginLeft: "5px",
                  }}
                  onClick={handleClearFilters}
                >
                  Limpiar filtros
                </a>
              </Col>
            </Row>

            <Row align="middle" style={{ width: "100%" }}>
              {(showButton && tab !== "gama" && !isExecutive(accesses)) ||
              (showButton && tab !== "gama" && !isSupervisor(accesses)) ||
              (showButton && isSuperAdmin(accesses)) ||
              (showButton && isAnotherGamaRole(accesses)) ? (
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{ height: "40px", width: "100%", marginTop: "5px" }}
                    onClick={onOpenForm}
                    icon={<PlusOutlined />}
                  >
                    Crear nuevo usuario
                  </Button>
                </Col>
              ) : null}
              {isSuperAdmin(accesses) || isSupervisor(accesses) ? (
                <Col span={24}>
                  <Button
                    className="gama-outline"
                    style={{ height: "40px", width: "100%", marginTop: "15px" }}
                    onClick={onOpenBulkUploadForm}
                    icon={<UploadOutlined />}
                  >
                    Carga masiva
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Row>

          <Table
            columns={mobileColumns}
            expandedRowRender={(record) => (
              <Row>
                <Col span={13}>
                  <Text strong>ID</Text>
                </Col>
                <Col span={10}>
                  {/-\w$/.test(record.identifierNumber)
                    ? format(record.identifierNumber)
                    : record.identifierNumber}
                </Col>
                <Col span={13}>
                  <Text strong>Correo</Text>
                </Col>
                <Col span={11}>{record?.email}</Col>
                <Col span={13}>
                  <Text strong>Supervisor</Text>
                </Col>
                <Col span={10}>No disponible</Col>
                <Col span={13}>
                  <Text strong>Perfil</Text>
                </Col>
                <Col span={10}>{record?.role.code}</Col>
                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <Button
                      onClick={() => onSelectDetailsButton(record.id)}
                      style={{ width: "180px" }}
                    >
                      Ver detalles
                    </Button>
                  </Col>
                  {!(tab === "gama" && isExecutive(accesses)) && (
                    <Col style={{ marginLeft: "15px" }}>
                      <Dropdown
                        overlay={renderMenu(record)}
                        placement="bottomLeft"
                        trigger="click"
                      >
                        <Button className="action-table-button">
                          <EllipsisOutlined />
                        </Button>
                      </Dropdown>
                    </Col>
                  )}
                </Row>
              </Row>
            )}
            expandIcon={(props) => {
              if (props.record.isExpandable) {
                if (props.expanded) {
                  return (
                    <MinusSquareOutlined
                      style={{ width: "50px" }}
                      onClick={(e) => {
                        props.onExpand(props.record, e);
                      }}
                    />
                  );
                } else {
                  return (
                    <PlusSquareOutlined
                      style={{ width: "50px" }}
                      onClick={(e) => {
                        props.onExpand(props.record, e);
                      }}
                    />
                  );
                }
              }
            }}
            rowClassName={(record) => (record.isExpandable ? "show" : "hidden")}
            expandedRowKeys={expandedRowKeys}
            onExpand={handleRowKeys}
            rowKey={(record) => record.access.id}
            dataSource={users}
            showSorterTooltip={false}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              position: ["bottomCenter"],
            }}
            sortDirection={["ASC", "DESC"]}
            loading={loading}
            onChange={onChange}
          />
        </>
      ) : (
        <>
          <Row justify="space-between" style={{ marginBottom: "24px" }}>
            <Row>
              <Col>
                <Search
                  placeholder="Busqueda por Rut / Nombre"
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  onSearch={handleSearch}
                  style={{
                    width: 240,
                    marginRight: "20px",
                  }}
                />
              </Col>
              {userData.type === "CLIENT" || tab === "client" ? null : (
                <Col>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={filters.role || undefined}
                    placeholder="Rol"
                    style={{
                      width: 150,
                      borderRadius: "50px",
                      marginRight: "15px",
                    }}
                    onChange={handleFilterByRole}
                  >
                    {roles?.map((rol) => (
                      <Option key={rol} value={rol}>
                        {rol}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
              <Col>
                <a
                  style={{
                    border: "none",
                    textAlign: "bottom",
                    color: "#FF3200",
                    textDecoration: "underline",
                    fontSize: "16px",
                  }}
                  onClick={handleClearFilters}
                >
                  Limpiar filtros
                </a>
              </Col>
            </Row>

            <Row justify="end">
              {isSuperAdmin(accesses) || isSupervisor(accesses) ? (
                <Col>
                  <Button
                    className="gama-outline"
                    style={{ height: "40px" }}
                    onClick={onOpenBulkUploadForm}
                    icon={<UploadOutlined />}
                  >
                    Carga masiva
                  </Button>
                </Col>
              ) : null}
              {(showButton && tab !== "gama" && !isExecutive(accesses)) ||
              (showButton && tab !== "gama" && !isSupervisor(accesses)) ||
              (showButton && isSuperAdmin(accesses)) ||
              (showButton && isAnotherGamaRole(accesses)) ? (
                <Col>
                  <Button
                    type="primary"
                    style={{ height: "40px", marginLeft: "15px" }}
                    onClick={onOpenForm}
                    icon={<PlusOutlined />}
                  >
                    Crear nuevo usuario
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Row>
          <Table
            dataSource={users}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              position: ["bottomCenter"],
            }}
            sortDirection={["ASC", "DESC"]}
            columns={
              removeAction
                ? filteredColumns
                : tab === "gama" && isExecutive(accesses)
                ? filteredColumns
                : columns
            }
            onChange={onChange}
            showSorterTooltip={false}
          />
        </>
      )}

      <Modal
        bodyStyle={{ borderRadius: "60px" }}
        style={{ marginTop: "200px" }}
        footer={false}
        closable={false}
        title={
          <>
            <Row align="middle" justify="space-between">
              <span
                style={{
                  fontWeight: "600",
                  fontSize: biggerThan900 ? "20px" : "15px",
                }}
              >
                ¿Esta seguro que desea eliminar el usuario?
              </span>
              <Col>
                <CloseOutlined
                  onClick={() => handleCancel()}
                  style={{
                    color: "#FF3200",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                    padding: "7px 7px 7px 7px",
                    marginRight: "15px",
                    fontSize: "16px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </Row>
          </>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col>
          <Typography>
            Esta a punto de eliminar al usuario de rut:{" "}
            <span style={{ fontWeight: 700 }}>{toDeleteUser?.rut}</span>
          </Typography>
        </Col>
        <Row
          justify="center"
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          <Col>
            <Button
              onClick={handleCancel}
              style={{ height: "40px" }}
              className="gama-outline"
            >
              Cancelar
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => handleDeleteUser(toDeleteUser)}
              style={{ marginLeft: "16px", height: "40px" }}
            >
              Eliminar
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default UsersTable;
