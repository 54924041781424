import React from "react";

import loadingBg from "../../assets/img/loadingBG.png";
import Spinner from "./components/Spinner";
import { useMediaPredicate } from "react-media-hook";

function LoadingStrapi() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  return (
    <>
      {biggerThan900 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: "#2D2D2D",
            color: "white",
            paddingRight: "120px",
            position: "relative",
            zIndex: "9999",
          }}
        >
          <div
            style={{ margin: "0px 15px", fontSize: "12px", fontWeight: "400" }}
          >
            GAMA MOBILITY
          </div>
          
          <div
            style={{
              margin: "0px 15px",
              fontSize: "12px",
              fontWeight: "400",
              color: "#FF5F00",
            }}
          >
            GAMA LEASING
          </div>
          
          <div
            style={{ margin: "0px 15px", fontSize: "12px", fontWeight: "400" }}
          >
            RENT A CAR
          </div>
          
          <div
            style={{ margin: "0px 15px", fontSize: "12px", fontWeight: "400" }}
          >
            TRIPP
          </div>
          
          <div
            style={{ margin: "0px 15px", fontSize: "12px", fontWeight: "400" }}
          >
            USADOS
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${loadingBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            display: "flex",
            height: biggerThan900 ? "95.9vh" : "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(20, 20, 20, 0.7)",
          }}
        >
          <Spinner />
        </div>
      </div>
    </>
  );
}

export default LoadingStrapi;
