import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import customerReducer from "./customers";
import vehiclesReducer from "./vehicles";
import gamaleasingReducer from "./strapi/gamaleasing";
import gamausadosReducer from "./strapi/gamausados";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customers: customerReducer,
    vehicles: vehiclesReducer,
    gamaleasing: gamaleasingReducer,
    gamausados: gamausadosReducer,
  },
});
