import React, { useState } from "react";
import * as XLSX from "xlsx";

import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button as LinkButton,
  Button as DashedButton,
  Drawer,
  Form,
  message,
  Row,
  Upload,
  Select,
} from "antd";
import Button from "../../../components/Button";
import "./index.less";
import {
  bulkDriversDataDownloadRequest,
  bulkDriversDataUploadRequest,
  bulkUsersDataDownloadRequest,
  bulkUsersDataUploadRequest,
} from "../../../requests/users";
import Notifications from "../../../components/Notifications";
import { getUserData } from "../../../store/auth";
import { useSelector } from "react-redux";

const BulkUploadUsersForm = ({ open, onClose, onFormFinish }) => {
  const [file, setFile] = useState();
  const [userType, setUserType] = useState("Usuario");
  const userData = useSelector(getUserData);

  const bulkTemplateDownload = async () => {
    if (userType === "Usuario") {
      const { data } = await bulkUsersDataDownloadRequest("insert");

      const fileURL = window.URL.createObjectURL(data);

      let fileLink = document.createElement("a");
      fileLink.href = fileURL;

      fileLink.download = "insertar-usuarios";

      fileLink.click();
    }

    if (userType === "Conductor") {
      const { data } = await bulkDriversDataDownloadRequest();

      const fileURL = window.URL.createObjectURL(data);

      let fileLink = document.createElement("a");
      fileLink.href = fileURL;

      fileLink.download = "insertar-usuarios";

      fileLink.click();
    }
  };

  const closeActions = () => {
    onClose();
    setFile(null);
  };

  const onSubmit = async () => {
    if (userType === "Usuario") {
      try {
        const response = await bulkUsersDataUploadRequest({ file }, "insert");

        message.success({
          content: response.data.message,
          duration: 5,
        });

        closeActions();
        onFormFinish();
        setFile(null);

        return false;
      } catch (error) {
        if (error?.status === 413) {
          return message.error({
            content:
              "El archivo que intentas subir excede el tamaño máximo de 1 MB",
            duration: 5,
          });
        }

        if (error?.data) {
          return message.error({
            content:
              error?.data?.message ||
              "Ups, algo salió mal. Inténtalo más tarde.",
            duration: 5,
          });
        } else if (error?.request) {
          return message.error({
            content: "Lo sentimos, ha ocurrido un error.",
            duration: 5,
          });
        } else {
          return message.error({
            content: "Ups, algo salió mal. Inténtalo más tarde.",
            duration: 5,
          });
        }
      }
    }

    if (userType === "Conductor") {
      console.log("Entra en Conductor");

      const expectedColumns = [
        "tipo_documento",
        "nombre",
        "apellido",
        "rut",
        "email",
        "numero_telefono",
        "rut_empresa",
        "perfil",
        "listado_patentes",
        "fecha_inicio",
        "fecha_termino",
      ];

      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convertir la hoja en JSON
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Verificar si las columnas esperadas están presentes
        const [headerRow, ...dataRows] = rows;
        const missingColumns = expectedColumns.filter(
          (col) => !headerRow.includes(col)
        );

        if (missingColumns.length > 0) {
          return message.error({
            content: `Error al subir el Excel: Faltan las siguientes columnas: ${missingColumns.join(
              ", "
            )}`,
            duration: 5,
          });
        }

        // Crear conjuntos para verificar duplicados en cada columna clave
        const uniqueValuesByColumn = expectedColumns.reduce((acc, col) => {
          acc[col] = new Set();
          return acc;
        }, {});

        // Validar valores en cada fila
        try {
          dataRows.forEach((row, rowIndex) => {
            // Verificar si la fila está completamente vacía
            const isRowEmpty = row.every((cell) => !cell);
            if (isRowEmpty) {
              throw new Error(
                `La fila ${rowIndex + 2} está completamente vacía.`
              );
            }

            expectedColumns.forEach((col, colIndex) => {
              const value = row[colIndex];

              // Verificar si el valor está vacío
              if (!value) {
                throw new Error(
                  `Falta el valor en la columna ${col}, fila ${rowIndex + 2}`
                );
              }

              // Verificar duplicados en cada columna clave
              if (uniqueValuesByColumn[col].has(value)) {
                throw new Error(
                  `Valor duplicado en la columna "${col}" en la fila ${
                    rowIndex + 2
                  }`
                );
              }

              // Agregar valor al conjunto de valores únicos de la columna
              uniqueValuesByColumn[col].add(value);
            });
          });

          console.log("Archivo Excel validado correctamente.");

          // Si la validación es exitosa, intenta la carga
          const response = await bulkDriversDataUploadRequest(
            { file },
            "insert"
          );

          message.success({
            content: response.data.message,
            duration: 5,
          });

          closeActions();
          onFormFinish();
          setFile(null);
        } catch (validationError) {
          console.log(validationError, "validation error");
          if (validationError?.status === 409) {
            return message.error({
              content: validationError?.data.message,
              duration: 5,
            });
          }
        }
      };

      reader.onerror = () => {
        message.error({
          content: "Error al leer el archivo Excel.",
          duration: 5,
        });
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setFile(null);
      return e;
    }

    if (e.fileList[0]) setFile(e.fileList[0].originFileObj);
    else setFile(null);

    return e?.fileList;
  };

  return (
    <Drawer
      title="Carga masiva de usuarios"
      placement="right"
      onClose={closeActions}
      visible={open}
      className="container-bulk-drawer"
      destroyOnClose={true}
      width={480}
    >
      <Form>
        <Form.Item>
          <p>Aquí podrás cargar varios usuarios a la vez.</p>
        </Form.Item>

        <Form.Item>
          <h5>Cargar usuarios</h5>
          <p>
            Descarga la plantilla, llénala con los datos requeridos y sube el
            archivo.
          </p>

          <Select
            defaultValue="Usuario"
            onChange={(value) => setUserType(value)}
            style={{ marginTop: "8px", marginBottom: "8px" }}
            placeholder="Seleccionar tipo de usuario"
          >
            <Select.Option value="Usuario">
              Carga masiva (Usuarios)
            </Select.Option>
            {/* <Select.Option value="Conductor">
                Carga masiva (Conductores)
              </Select.Option> */}
          </Select>

          <LinkButton
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => bulkTemplateDownload()}
          >
            Descargar plantilla
          </LinkButton>
        </Form.Item>

        <Form.Item
          name={"upload"}
          valuePropName="list"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Es necesario cargar un archivo csv",
            },
          ]}
        >
          <Upload
            accept=".csv"
            showUploadList={true}
            beforeUpload={() => false}
            maxCount={1}
            listType="csv-file"
            style={{
              display: "block",
              width: "100%",
            }}
          >
            <DashedButton
              type="dashed"
              style={{
                borderColor: "#FF3200",
                color: "#FF3200",
                marginRight: "1rem",
                width: "100%",
              }}
              size="large"
              icon={<UploadOutlined />}
            >
              Subir archivo CSV
            </DashedButton>
          </Upload>
        </Form.Item>

        <Row justify="center">
          <Button
            type="primary"
            style={{
              width: "60%",
              marginTop: "1rem",
            }}
            onClick={() => onSubmit()}
          >
            Guardar
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};

export default BulkUploadUsersForm;
