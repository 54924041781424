import {
  CarOutlined,
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Col, Divider, Drawer, Row, Spin, Typography } from "antd";
import React from "react";
import moment from "moment/dist/moment";
import "moment/dist/locale/es";
import { useMediaPredicate } from "react-media-hook";
import Button from "../../../components/Button";
import { downloadDocument } from "../../../requests/vehicles";
import { saveAs } from "file-saver";
import Notifications from "../../../components/Notifications";
export default function VehicleDocumentDetail({
  vehicleData,
  open,
  onClose,
  documentsData,
  loadingDocs,
}) {
  moment.locale("es");
  const { Text } = Typography;
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const openCircularPermitResponse = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    const downloadDoc = await downloadDocument(
      documentsData?.[0]?.data?.filename,
      documentsData?.[0]?.data?.detail
    );
    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );
    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openSoapResponse = async () => {
    const downloadDoc = await downloadDocument(
      documentsData?.[1]?.data?.filename,
      documentsData?.[1]?.data?.detail
    );

    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );
    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openApprovedResponse = async () => {
    const downloadDoc = await downloadDocument(
      documentsData?.[2]?.data?.filename,
      documentsData?.[2]?.data?.detail
    );
    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );

    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };
  const openPadronResponse = async () => {
    const downloadDoc = await downloadDocument(
      documentsData?.[3]?.data?.filename,
      documentsData?.[3]?.data?.detail
    );
    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );
    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };
  const openTechnicalReviewResponse = async () => {
    const downloadDoc = await downloadDocument(
      documentsData?.[5]?.data?.filename,
      documentsData?.[5]?.data?.detail
    );
    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );
    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openGasCertificateResponse = async () => {
    const downloadDoc = await downloadDocument(
      documentsData?.[6]?.data?.filename,
      documentsData?.[6]?.data?.detail
    );
    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );
    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  const openFirstInscriptionResponse = async () => {
    const downloadDoc = await downloadDocument(
      documentsData?.[4]?.data?.filename,
      documentsData?.[4]?.data?.detail
    );
    var binary = atob(
      downloadDoc?.data?.substr(downloadDoc?.data?.indexOf(",") + 1)
    );
    var bytes = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  const convertBase64ToFile = (base64String, fileName) => {
    let arr = base64String.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  };

  const downloadCircularPermit = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[0]?.data?.filename,
        documentsData?.[0]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[0]?.data?.filename
      );
      saveAs(file, documentsData?.[0]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadSoap = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[1]?.data?.filename,
        documentsData?.[1]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[1]?.data?.filename
      );
      saveAs(file, documentsData?.[1]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadApproved = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[2]?.data?.filename,
        documentsData?.[2]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[2]?.data?.filename
      );
      saveAs(file, documentsData?.[2]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadPadron = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[3]?.data?.filename,
        documentsData?.[3]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[3]?.data?.filename
      );
      saveAs(file, documentsData?.[3]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadTechnical = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[5]?.data?.filename,
        documentsData?.[5]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[5]?.data?.filename
      );
      saveAs(file, documentsData?.[5]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadGas = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[6]?.data?.filename,
        documentsData?.[6]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[6]?.data?.filename
      );
      saveAs(file, documentsData?.[6]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFirstInscription = async () => {
    Notifications.info("Cargando", "Por favor espere un momento.");
    try {
      const downloadDoc = await downloadDocument(
        documentsData?.[4]?.data?.filename,
        documentsData?.[4]?.data?.detail
      );
      let file = convertBase64ToFile(
        downloadDoc?.data,
        documentsData?.[4]?.data?.filename
      );
      saveAs(file, documentsData?.[4]?.data?.filename);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Drawer
        title={
          <div>
            <Row justify="space-between" align="middle">
              <Col>Documentos del vehículo</Col>
              <Col>
                <Button
                  onClick={onClose}
                  style={{
                    border: "none",
                    padding: "5px 8px",
                    color: "#FF3200",
                    borderRadius: "6px",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        }
        width={biggerThan900 ? "480px" : "100%"}
        closable={false}
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CarOutlined
            style={{
              color: "#ff3200",
              backgroundColor: "rgba(255, 50, 0, 0.08)",
              padding: "7px 7px 7px 7px",
              marginRight: "15px",
              fontSize: "26px",
            }}
          />
          <div style={{ lineHeight: "5px" }}>
            <Text strong style={{ margin: "0" }}>
              {vehicleData?.deviceId}
            </Text>
            <div style={{ display: "flex" }}>
              <p style={{ margin: "0", marginRight: "5px" }}>
                {vehicleData?.brandId?.toUpperCase()}
              </p>
              <p style={{ margin: "0" }}>
                {vehicleData?.classId?.toUpperCase()}
              </p>
            </div>
          </div>
        </div>
        <Divider />
        <Spin spinning={loadingDocs} tip="Cargando...">
          <Row>
            <Col span={15}>
              <Text strong>Nombre de documento</Text>
            </Col>
            <Col span={3}>
              <Text strong>Ver</Text>
            </Col>
            <Col span={6}>
              <Text strong>Descargar</Text>
            </Col>
          </Row>
          {documentsData && documentsData[0] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={12} style={{ fontSize: "14px" }}>
                Permiso de circulacion
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openCircularPermitResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    marginRight: "50px",
                    cursor: "pointer",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadCircularPermit}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </Row>
          )}

          {documentsData && documentsData[1] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={12} style={{ fontSize: "14px" }}>
                SOAP
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openSoapResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    marginRight: "50px",
                    cursor: "pointer",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadSoap}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                ></DownloadOutlined>
              </Col>
            </Row>
          )}
          {documentsData && documentsData[2] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={12} style={{ fontSize: "14px" }}>
                Homologado
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openApprovedResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    marginRight: "50px",
                    cursor: "pointer",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadApproved}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                ></DownloadOutlined>
              </Col>
            </Row>
          )}
          {documentsData && documentsData[3] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={12} style={{ fontSize: "14px" }}>
                Padrón
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openPadronResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    marginRight: "50px",
                    cursor: "pointer",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadPadron}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                ></DownloadOutlined>
              </Col>
            </Row>
          )}
          {documentsData && documentsData[4] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={12} style={{ fontSize: "14px" }}>
                Primera Inscripción
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openFirstInscriptionResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    marginRight: "50px",
                    cursor: "pointer",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadFirstInscription}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                ></DownloadOutlined>
              </Col>
            </Row>
          )}
          {documentsData && documentsData[5] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={14} style={{ fontSize: "14px" }}>
                Revisión Técnica
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openTechnicalReviewResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                    marginRight: "50px",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadTechnical}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                ></DownloadOutlined>
              </Col>
            </Row>
          )}
          {documentsData && documentsData[6] === undefined ? null : (
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#FAFAFA",
                padding: "5px 5px 5px 15px",
                borderRadius: "8px",
              }}
              align="middle"
              justify="space-between"
            >
              <Col span={14} style={{ fontSize: "14px" }}>
                Certificado de Gases
              </Col>
              <Col span={9}>
                <EyeOutlined
                  onClick={openGasCertificateResponse}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                    marginRight: "50px",
                  }}
                />
                <DownloadOutlined
                  onClick={downloadGas}
                  style={{
                    fontSize: "20px",
                    color: "#FF3200",
                    cursor: "pointer",
                  }}
                ></DownloadOutlined>
              </Col>
            </Row>
          )}
        </Spin>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button onClick={onClose} style={{ width: "300px" }}>
            Cerrar
          </Button>
        </Row>
      </Drawer>
    </>
  );
}
