import request from "../request";
import {
  BASE_AUTH_API_URL,
  BASE_USERS_API_URL,
} from '../url';

export const authUserRequest = async (data) => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth`,
    method: "POST",
    data,
  });
};

export const getUserProfileRequest = async () => {
  return await request({
    url: `${BASE_USERS_API_URL}/auth/profile`,
    method: "GET",
  });
};

export const attachAccessRequest = async ({ customerId }) => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth/attach`,
    method: "POST",
    data: {
      customerId: customerId,
    },
  });
};

export const setClientHash = async ({ rut, password }) => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth/remember`,
    method: "POST",
    data: {
      rut,
      password,
    },
  });
};

export const decodeClientHash = async ({ hash }) => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth/decode`,
    method: "POST",
    data: {
      hash,
    },
  });
};

export const refreshTokenAuth = async () => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth/refresh`,
    method: "POST",
  });
};

export const isValidAuthToken =  async (token) => {
  return await request({
    url: `${BASE_AUTH_API_URL}/auth/verify`,
    method: "POST",
    data: {
      token
    }
  });
}