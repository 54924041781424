import { CarOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Divider, Drawer, Row, Typography } from "antd";
import React from "react";
import moment from "moment/dist/moment";
import "moment/dist/locale/es";
import { useMediaPredicate } from "react-media-hook";
import Button from "../../../components/Button";

export default function VehicleDetails({ vehicleData, open, onClose }) {
  moment.locale("es");
  const { Text } = Typography;
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  return (
    <>
      <Drawer
        title={
          <div>
            <Row justify="space-between" align="middle">
              <Col>Detalles</Col>
              <Col>
                <Button
                  onClick={onClose}
                  style={{
                    border: "none",
                    padding: "5px 8px",
                    color: "#FF3200",
                    borderRadius: "6px",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        }
        width={biggerThan900 ? "480" : "100%"}
        closable={false}
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CarOutlined
            style={{
              color: "#ff3200",
              backgroundColor: "rgba(255, 50, 0, 0.08)",
              padding: "7px 7px 7px 7px",
              marginRight: "15px",
              fontSize: "26px",
            }}
          />
          <div style={{ lineHeight: "5px" }}>
            <Text strong style={{ margin: "0" }}>
              {vehicleData?.deviceId}
            </Text>
            <div style={{ display: "flex" }}>
              <p style={{ margin: "0", marginRight: "5px" }}>
                {vehicleData?.brandId?.toUpperCase()}
              </p>
              <p style={{ margin: "0" }}>
                {vehicleData?.classId?.toUpperCase()}
              </p>
            </div>
          </div>
        </div>
        <Divider />
        <Row>
          <Col span={12}>
            <Text strong>Cliente</Text>
          </Col>
          <Col span={12}>{vehicleData?.rentName}</Col>
          <Col span={12}>
            <Text strong>Fecha de entrega</Text>
          </Col>
          <Col span={12}>
            {moment(vehicleData?.startDate).format(
              "DD/MM/YYYY"
            )}
          </Col>
          <Col span={12}>
            <Text strong>Fecha de devolución</Text>
          </Col>
          <Col span={12}>
            {moment(vehicleData?.endDate).format(
              "DD/MM/YYYY"
            )}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Text strong>Tipo de vehículo</Text>
          </Col>
          <Col span={12}>{vehicleData?.deviceGroupId}</Col>
          <Col span={12}>
            <Text strong>Año</Text>
          </Col>
          <Col span={12}>{vehicleData?.modelYear}</Col>
          <Col span={12}>
            <Text strong>Color</Text>
          </Col>
          <Col span={12}>{vehicleData?.exteriorId}</Col>
          <Col span={12}>
            <Text strong>Combustible</Text>
          </Col>
          <Col span={12}>{vehicleData?.fuelId}</Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button onClick={onClose} style={{ width: "300px" }}>
            Cerrar
          </Button>
        </Row>
      </Drawer>
    </>
  );
}
