import React from "react";
import { Col, Menu, Row, Table, Tag, Typography } from "antd";
import Button from "../../../components/Button";
import {
  EllipsisOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import Dropdown from "../../../components/Dropdown";
import useWindowDimensions from "../../../hooks/useWindowsDimensions";

const RolesTable = ({
  roles,
  loading = false,
  onChange,
  currentPage = 1,
  pageSize = 10,
  total = 0,
  onSelectDetails,
  onSelectEdit,
  onRemove,
  mobileColumns,
}) => {
  const isRoleEnabled = (role) => role?.enabled;
  const { height, width } = useWindowDimensions();

  const renderMenu = (role) => {
    return (
      <Menu>
        <Menu.Item onClick={() => onSelectEdit(role.id)} key={"1"}>
          Editar
        </Menu.Item>
        <Menu.Item onClick={() => onRemove(role.id)} key={"2"}>
          {isRoleEnabled(role) ? "Desactivar" : "Activar"}
        </Menu.Item>
      </Menu>
    );
  };
  const { Text } = Typography;

  let expandedRowKeys = [];

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.id;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  const columns = [
    {
      title: "Rol",
      key: "code",

      sorter: {
        compare: (a, b) => a.code.localeCompare(b.code),
      },
      render: (role) => {
        return (
          <span className={isRoleEnabled(role) ? "" : "disabled-row"}>
            {role.code}
          </span>
        );
      },
    },
    {
      title: "Estado",
      key: "enabled",
      render: (role) => (
        <span>
          {isRoleEnabled(role) ? (
            <Tag color="green">Activo</Tag>
          ) : (
            <Tag className="disabled-color">Inactivo</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Empresa",
      key: "name",
      sorter: {
        compare: (a, b) => a.customer.name.localeCompare(b.customer.name),
      },
      render: (role) => (
        <span className={isRoleEnabled(role) ? "" : "disabled-row"}>
          {role?.customer.name}
        </span>
      ),
    },
    {
      title: "Usuarios asociados",
      key: "relatedUsersCount",
      sorter: true,
      render: (role) => (
        <span className={isRoleEnabled(role) ? "" : "disabled-row"}>
          {role.relatedUsersCount}
        </span>
      ),
    },
    {
      title: "Detalle",
      key: "detalle",
      render: (role) => (
        <u
          className={
            isRoleEnabled(role) ? "primary-pointer-text" : "disabled-row"
          }
          onClick={() => onSelectDetails(role.id)}
        >
          Ver detalle
        </u>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (role) => (
        <Dropdown overlay={renderMenu(role)} placement="bottomLeft">
          <Button className="action-table-button">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      {width <= 640 ? (
        <Table
          rowKey={(record) => record.id}
          expandedRowKeys={expandedRowKeys}
          onExpand={handleRowKeys}
          rowClassName={(customer) => !customer.enabled && "disabled-row"}
          showSorterTooltip={false}
          expandedRowRender={(record) => (
            <Row>
              <Col span={14}>
                <Text strong>Empresa</Text>
              </Col>
              <Col span={10}>{record.customer.name}</Col>
              <Col span={14}>
                <Text strong>Usuarios asociados</Text>
              </Col>
              <Col span={10}>{record.relatedUsersCount}</Col>

              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Button
                    className={"gama-button"}
                    onClick={() => onSelectDetails(record.id)}
                    style={{ width: "180px" }}
                  >
                    Ver detalles
                  </Button>
                </Col>
                <Col style={{ marginLeft: "15px" }}>
                  <Dropdown trigger={["click"]} overlay={renderMenu(record)} placement="bottomLeft">
                    <Button className="action-table-button">
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            </Row>
          )}
          expandIcon={(props) => {
            if (props.record.isExpandable) {
              if (props.expanded) {
                return (
                  <MinusSquareOutlined
                    style={{ width: "50px" }}
                    onClick={(e) => {
                      props.onExpand(props.record, e);
                    }}
                  />
                );
              } else {
                return (
                  <PlusSquareOutlined
                    style={{ width: "50px" }}
                    onClick={(e) => {
                      props.onExpand(props.record, e);
                    }}
                  />
                );
              }
            }
          }}
          className="cantainer-roles-table"
          dataSource={roles}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize,
            total,
            position: ["bottomCenter"],
          }}
          columns={mobileColumns}
          onChange={onChange}
          sortDirection={["ASC", "DESC"]}
        />
      ) : (
        <Table
          className="cantainer-roles-table"
          showSorterTooltip={false}
          dataSource={roles}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize,
            total,
            position: ["bottomCenter"],
          }}
          columns={columns}
          onChange={onChange}
          sortDirection={["ASC", "DESC"]}
        />
      )}
    </>
  );
};

export default RolesTable;
