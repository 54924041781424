import {
  EyeOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  FunnelPlotOutlined,
  MenuOutlined,
  FileOutlined,
  CaretDownOutlined,
  DownloadOutlined,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useMemo, useState } from "react";
import VehicleDetails from "./VehicleDetails";
import moment from "moment/dist/moment";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers, setCustomers } from "../../store/customers";
import { getFilters, getStatus } from "../../store/vehicles";
import "./index.less";
import VehicleDocumentDetail from "./VehicleDocumentDetail";
import {
  exportVehiclesTemplate,
  getSharepointDoc,
  getVehiclesCount,
} from "../../requests/vehicles";
import { getCustomerCount } from "../../requests/customers";
import { getUserCount } from "../../requests/users";
import flotaLogo from "../../assets/img/flota.png";
import usuariosLogo from "../../assets/img/usuarios.png";
import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as TableLogo } from "../../assets/img/vehicleTable.svg";
import { getUserData } from "../../store/auth";

export default function ClientTable({
  data,
  loading,
  onSearch,
  currentPage,
  pageSize,
  handlePaginationChange,
  total,
  handleSearch,
  handleFilterByBrand,
  handleFilterByCustomer,
  handleFilterByModel,
  handleClearFilters,
  filters,
  setCurrentPage,
  userData,
  inputValue,
  setInputValue,
}) {
  const [vehicleData, setVehicleData] = useState(data);
  const [showDetail, setShowDetail] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const biggerThan1500 = useMediaPredicate("(min-width: 1530px)");
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const customers = useSelector(getCustomers);
  const formCustomers = customers.customers ? customers.customers : customers;
  const filteredCustomers =
    formCustomers &&
    formCustomers?.filter(
      (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
    );
  const { brand, model } = useSelector(getFilters);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const status = useSelector(getStatus);
  const { accesses } = useSelector(getUserData);
  const [showVehicleDocuments, setShowVehicleDocuments] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState("false");
  const [userCount, setUserCount] = useState();
  const [fleetCount, setFleetCount] = useState();
  const [loadTemplate, setLoadTemplate] = useState(false);
  const [documentsData, setDocumentsData] = useState();
  const [filterTable, setFilterTable] = useLocalStorage("filterTable", [
    "deviceId",
    "brandId",
    "classId",
    "rentname",
    "fuelId",
    "modelYear",
    "trasmissionId",
    "exteriorId",
    "rentId",
    "rentStatus",
    "startDate",
    "endDate",
    "documents",
    "details",
    "contractId",
    "rentDeviceStatus",
    "driveTrainId",
    "salesPrice",
    "currencyCode",
    "deductible",
    "maxTravel",
    "pickUpLocationId",
    "transferLocation",
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  moment.locale("es");
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    if (userData?.type === "CLIENT") {
      localStorage.setItem(
        "gama.customers.filter",
        JSON.stringify({ name: userData?.accesses?.[0]?.customer?.name })
      );
      localStorage.setItem("gama.vehicles.filter", "");
    }
  }, [accesses]);

  const generateExcelBuffer = (data) => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, "Sheet1");
    const buffer = XLSX.write(book, { type: "buffer", bookType: "xlsx" });
    return buffer;
  };

  const downloadExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };

  const downloadVehiclesTemplate = async () => {
    setLoadTemplate(true);
    try {
      let result = [];
      const pageSize = 1000;
      const { data } = await exportVehiclesTemplate({ page: 1 });
      let totalPages = Math.trunc(data.count / pageSize) + 1;
      for (let page = 1; page <= totalPages; page++) {
        const { data } = await exportVehiclesTemplate({ page });
        result = [...result, ...data.data];
      }
      const bufferData = generateExcelBuffer(result);
      downloadExcelFile(bufferData, "Reporte de Vehiculos");
    } catch (error) {
      console.log(error);
    } finally {
      setLoadTemplate(false);
    }
  };

  const onClearFilters = () => {
    handleClearFilters();
    setSelectedCustomer(null);
    setSelectedBrand(null);
  };

  const { Text } = Typography;

  let expandedRowKeys = [];

  useEffect(() => {
    const vehicleCount = async () => {
      try {
        const response = await getVehiclesCount();
        const fleetCountObj = {
          fleetCount: response?.data,
        };
        setFleetCount(fleetCountObj);
      } catch (error) {
        console.error(error);
      }
    };
    const customerCount = async () => {
      try {
        await getCustomerCount();
      } catch (error) {
        console.error(error);
      }
    };
    const userCount = async () => {
      try {
        const response = await getUserCount();
        setUserCount(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    vehicleCount();
    customerCount();
    userCount();
  }, [accesses]);

  useEffect(() => {
    if (userData?.type === "CLIENT") {
      setSelectedCustomer(userData.accesses[0].customer.name);
      handleFilterByCustomer(userData.accesses[0].customer.name);
    }
  }, [userData, accesses]);

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.deviceId;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  const handleVehicleDetail = (vehicleData) => {
    setVehicleData(vehicleData);
    setShowDetail(true);
  };

  const handleDrawerClose = () => {
    setShowDetail(false);
    setShowVehicleDocuments(false);

    setVehicleData(null);
  };
  //test
  const handleVehicleDocuments = async (vehicleData) => {
    const documentsData = [];
    setLoadingDocs(true);
    setVehicleData(vehicleData);
    setShowVehicleDocuments(true);

    try {
      const circularPermitResponse = await getSharepointDoc(
        vehicleData?.deviceId,
        "Permiso de Circulación"
      );
      documentsData.push(circularPermitResponse);
    } catch (error) {
      console.error(error);
      documentsData.push(undefined);
    }
    try {
      const soapResponse = await getSharepointDoc(
        vehicleData?.deviceId,
        "Soap"
      );
      documentsData.push(soapResponse);
    } catch (error) {
      console.error(error);
      documentsData.push(undefined);
    }
    try {
      const homologado = await getSharepointDoc(
        vehicleData?.deviceId,
        "Homologado"
      );
      documentsData.push(homologado);
    } catch (error) {
      console.error(error);
      documentsData.push(undefined);
    }
    try {
      const padronResponse = await getSharepointDoc(
        vehicleData?.deviceId,
        "Padrón"
      );
      documentsData.push(padronResponse);
    } catch (error) {
      console.error(error);
      documentsData.push(undefined);
    }
    try {
      const firstInscriptionResponse = await getSharepointDoc(
        vehicleData?.deviceId,
        "Primera Inscripción"
      );
      documentsData.push(firstInscriptionResponse);
    } catch (error) {
      console.error(error);
      documentsData.push(undefined);
    }
    try {
      const technicalReviewResponse = await getSharepointDoc(
        vehicleData?.deviceId,
        "Revisión Técnica"
      );
      documentsData.push(technicalReviewResponse);
    } catch (error) {
      documentsData.push(undefined);
      console.error(error);
    }
    try {
      const GasCertificateResponse = await getSharepointDoc(
        vehicleData?.deviceId,
        "Certificado de Gases"
      );
      documentsData.push(GasCertificateResponse);
    } catch (error) {
      documentsData.push(undefined);
      console.error(error);
    }

    setDocumentsData(documentsData);
    setLoadingDocs(false);
  };

  const dashBoardColumns = [
    {
      title: <Text strong>Patente</Text>,
      dataIndex: "deviceId",
      key: "device.deviceId",
      align: "left",
      isExpandable: true,
      width: 120,
      sorter: {
        compare: (a, b) => a.deviceId.localeCompare(b.deviceId),
      },
    },
    {
      title: <Text strong>Marca</Text>,
      dataIndex: "brandId",
      key: "device.brandId",
      align: "left",
      width: 120,
      sorter: {
        compare: (a, b) => a.brandId.localeCompare(b.brandId),
      },
    },
    {
      title: <Text strong>Modelo</Text>,
      dataIndex: "classId",
      key: "device.classId",
      align: "left",
      width: 120,
      sorter: {
        compare: (a, b) => a.classId.localeCompare(b.classId),
      },
    },
    {
      title: <Text strong>Tarifa</Text>,
      dataIndex: "salesPrice",
      key: "deviceContract.salesPrice",
      align: "left",
      width: 120,
      sorter: {
        compare: (a, b) =>
          a.salesPrice.toString().localeCompare(b.salesPrice.toString()),
      },
    },
    {
      title: <Text strong>Divisa</Text>,
      dataIndex: "currencyCode",
      key: "deviceContract.currencyCode",
      align: "left",
      width: 150,
      sorter: {
        compare: (a, b) => a?.currencyCode?.localeCompare(b?.currencyCode),
      },
    },
    {
      title: <Text strong>Fecha entrega</Text>,
      dataIndex: "startDate",
      key: "contract.startDate",
      align: "left",
      width: 180,
      sorter: {
        compare: (a, b) => a.startDate.localeCompare(b.startDate),
      },
      render: (text, record) =>
        moment(record?.startDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
    },
    {
      title: <Text strong>Fecha devolucion</Text>,
      dataIndex: "endDate",
      key: "contract.endDate",
      align: "left",
      width: 180,
      sorter: {
        compare: (a, b) => a.endDate.localeCompare(b.endDate),
      },
      render: (text, record) =>
        moment(record?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
    },
  ];

  const subString = "PRY";

  const mobileColumns = [
    {
      key: "deviceId",
      title: <Text strong>Patente</Text>,
      dataIndex: "deviceId",
      align: "left",
      sorter: {
        compare: (a, b) => a?.deviceId?.localeCompare(b?.deviceId),
      },
    },
    {
      key: "rentname",
      title: <Text strong>Empresa</Text>,
      dataIndex: "rentname",
      align: "left",
      sorter: {
        compare: (a, b) => a?.rentname.localeCompare(b?.rentname),
      },
    },
  ];

  const columns = [
    {
      title: <Text strong>Patente</Text>,
      dataIndex: "deviceId",
      key: "device.deviceId",
      align: "left",
      width: 150,
      sorter: {
        compare: (a, b) => a.deviceId.localeCompare(b.deviceId),
      },
    },
    {
      title: <Text strong>Orden de alquiler</Text>,
      dataIndex: "rentId",
      key: "contract.rentId",
      align: "left",
      width: 200,
      sorter: {
        compare: (a, b) => a.rentId.localeCompare(b.rentId),
      },
    },
    {
      title: <Text strong>Estado de vehículo</Text>,
      dataIndex: "rentDeviceStatus",
      key: "deviceContract.rentDeviceStatus",
      align: "left",
      width: 200,
      sorter: {
        compare: (a, b) => a.rentStatus.localeCompare(b.rentStatus),
      },
    },
    {
      title: <Text strong>Empresa</Text>,
      dataIndex: "rentname",
      key: "contract.rentName",
      align: "left",
      width: 230,
      sorter: {
        compare: (a, b) => a.rentname?.localeCompare(b.rentname),
      },
    },
    {
      title: <Text strong>Tarifa</Text>,
      dataIndex: "salesPrice",
      key: "deviceContract.salesPrice",
      align: "left",
      width: 200,
      sorter: {
        compare: (a, b) =>
          a.salesPrice.toString().localeCompare(b.salesPrice.toString()),
      },
    },
    {
      title: <Text strong>Divisa</Text>,
      dataIndex: "currencyCode",
      key: "deviceContract.currencyCode",
      align: "left",
      width: 200,
      sorter: {
        compare: (a, b) => a?.currencyCode?.localeCompare(b?.currencyCode),
      },
    },
    {
      title: <Text strong>Kilometraje</Text>,
      dataIndex: "maxTravel",
      key: "deviceContract.maxTravel",
      align: "left",
      width: 120,
      sorter: (a, b) => a.maxTravel - b.maxTravel,
    },
    {
      title: <Text strong>Deducible</Text>,
      dataIndex: "deductible",
      key: "deviceContract.deductible",
      align: "left",
      width: 250,
      sorter: {
        compare: (a, b) => a.deductible.localeCompare(b.deductible),
      },
      render: (text, record) =>
        record?.rentId.includes(subString) ? "" : record?.deductible,
    },
    {
      title: <Text strong>Ubicación de origen</Text>,
      dataIndex: "pickUpLocationId",
      key: "deviceContract.pickupLocationId",
      align: "left",
      width: 180,
      sorter: {
        compare: (a, b) =>
          a?.pickUpLocationId?.localeCompare(b?.pickUpLocationId),
      },
    },
    {
      title: <Text strong>Lugar de operación</Text>,
      dataIndex: "transferLocation",
      key: "deviceContract.transferLocation",
      align: "left",
      width: 180,
      sorter: {
        compare: (a, b) =>
          a?.transferLocation?.localeCompare(b?.transferLocation),
      },
    },

    // {
    //   title: <Text strong>Orden de contrato</Text>,
    //   dataIndex: "contractId",
    //   key: "contract.rentId",
    //   align: "left",
    //   width: 230,
    //   fixed: "left",
    //   sorter: {
    //     compare: (a, b) => a.contractId.localeCompare(b.contractId),
    //   },
    // },
    {
      title: <Text strong>Marca</Text>,
      dataIndex: "brandId",
      key: "device.brandId",
      align: "left",
      width: 150,
      sorter: {
        compare: (a, b) => a.brandId.localeCompare(b.brandId),
      },
    },
    {
      title: <Text strong>Modelo</Text>,
      dataIndex: "classId",
      key: "device.classId",
      align: "left",
      sorter: {
        compare: (a, b) => a.classId.localeCompare(b.classId),
      },
    },
    {
      title: <Text strong>Tipo de combustible</Text>,
      dataIndex: "fuelId",
      key: "device.fuelId",
      align: "left",
      sorter: {
        compare: (a, b) => a.fuelId.localeCompare(b.fuelId),
      },
    },
    {
      title: <Text strong>Año de modelo</Text>,
      dataIndex: "modelYear",
      key: "device.modelYear",
      align: "left",
      sorter: {
        compare: (a, b) =>
          a.modelYear.toString().localeCompare(b.modelYear.toString()),
      },
    },
    {
      title: <Text strong>Tipo de transmisión</Text>,
      dataIndex: "trasmissionId",
      key: "device.trasmissionId",
      align: "left",
      sorter: {
        compare: (a, b) => a?.transmissionId?.localeCompare(b?.transmissionId),
      },
    },
    {
      title: <Text strong>Tracción</Text>,
      dataIndex: "driveTrainId",
      key: "device.driveTrainId",
      align: "left",
      width: 150,
      sorter: {
        compare: (a, b) => a.driveTrainId.localeCompare(b.driveTrainId),
      },
    },
    {
      title: <Text strong>Color</Text>,
      dataIndex: "exteriorId",
      key: "device.exteriorId",
      align: "left",
      sorter: {
        compare: (a, b) => a.exteriorId.localeCompare(b.exteriorId),
      },
    },
    {
      title: <Text strong>Fecha inicio contrato</Text>,
      dataIndex: "startDate",
      key: "contract.startDate",
      align: "left",
      width: 230,
      sorter: {
        compare: (a, b) => a.startDate.localeCompare(b.startDate),
      },
      render: (text, record) => moment(record?.startDate).format("DD/MM/YYYY"),
    },
    {
      title: <Text strong>Fecha termino contrato</Text>,
      dataIndex: "endDate",
      key: "contract.endDate",
      align: "left",
      width: 230,
      sorter: {
        compare: (a, b) => a.endDate.localeCompare(b.endDate),
      },
      render: (text, record) => moment(record?.endDate).format("DD/MM/YYYY"),
    },
    {
      title: <Text strong>Documentos</Text>,
      dataIndex: "documents",
      key: "documents",
      align: "center",

      render: (text, record) => (
        <span
          style={{
            backgroundColor: "rgba(255, 50, 0, 0.08)",
            color: "#FF3200",
            cursor: "pointer",
            padding: "7px 7px 7px 7px",
            borderRadius: "100%",
            border: "1px solid #ff3200",
          }}
          onClick={() => handleVehicleDocuments(record)}
        >
          <FileOutlined />
        </span>
      ),
    },
    {
      title: <Text strong>Detalles</Text>,
      dataIndex: "details",
      key: "details",
      align: "left",

      render: (text, record) => (
        <span
          style={{
            backgroundColor: "rgba(255, 50, 0, 0.08)",
            color: "#FF3200",
            cursor: "pointer",
            padding: "7px 7px 7px 7px",
            borderRadius: "100%",
            border: "1px solid #ff3200",
          }}
          onClick={() => handleVehicleDetail(record)}
        >
          <EyeOutlined />
        </span>
      ),
    },
  ];

  const onChange = (checkedValues) => {
    setFilterTable(checkedValues);
  };

  const filteredTableColumns = useMemo(() => {
    return columns.filter((column) => filterTable.includes(column.dataIndex));
  }, [columns, filterTable]);

  const menu = (
    <Checkbox.Group onChange={onChange} value={filterTable}>
      <Menu
        style={{ width: "300px" }}
        items={[
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="deviceId">Patente</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "0",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="rentId">Orden de alquiler</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "1",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="rentname">Empresa</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "2",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="brandId">Marca</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "3",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="classId">Modelo</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "4",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="startDate">Fecha inicio contrato</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "5",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="endDate">Fecha termino contrato</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "6",
          },

          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="fuelId">Tipo de Combustible</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "7",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="modelYear">Año de modelo</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "8",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="trasmissionId">Tipo de transmisión</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "9",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="driveTrainId">Tracción</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "10",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="exteriorId">Color de vehiculo</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "11",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="details">Detalles</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "12",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="salesPrice">Tarifa (UF)</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "13",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="currencyCode">Divisa</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "14",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="documents">Documentos</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "15",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="deductible">Deducible</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "16",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="maxTravel">Kilometraje</Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "17",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="pickUpLocationId">
                    Ubicación de origen
                  </Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "18",
          },
          {
            label: (
              <Row justify="space-between" aling="center">
                <Col>
                  <Checkbox value="transferLocation">
                    Lugar de operación
                  </Checkbox>
                </Col>
                <Col>
                  <MenuOutlined />
                </Col>
              </Row>
            ),
            key: "19",
          },
        ]}
      />
    </Checkbox.Group>
  );

  const { Search } = Input;

  return (
    <>
      {width <= 640 ? (
        <>
          {window.location.pathname === "/vehiculos" ? (
            <Row>
              <Search
                placeholder="Buscar patente/Orden de alquiler"
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onSearch={handleSearch}
                style={{
                  width: "80%",
                }}
              />

              <Button
                onClick={showDrawer}
                style={{
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                  color: "#FF3200",
                  fontSize: "20px",
                  border: "none",
                  padding: "6px 10px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                }}
                icon={<FunnelPlotOutlined />}
              />
            </Row>
          ) : null}
          {window.location.pathname === "/dashboard" ? (
            <Row>
              <Button
                onClick={showDrawer}
                style={{
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                  color: "#FF3200",
                  fontSize: "30px",
                  border: "none",
                  padding: "6px 10px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  marginRight: "15px",
                  width: "10%",
                }}
                icon={<FunnelPlotOutlined />}
              />
              <Button
                icon={
                  loadTemplate ? (
                    <Spin style={{ color: "white" }} indicator={antIcon} />
                  ) : (
                    <DownloadOutlined />
                  )
                }
                onClick={downloadVehiclesTemplate}
                className="gama-button"
                style={{ width: "80%", marginBottom: "15px", color: "white" }}
              >
                Descargar Reportes
              </Button>
            </Row>
          ) : null}
          {window.location.pathname === "/dashboard" ? (
            <Row justify="center" style={{ marginBottom: "12px" }}>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  width: "490px",
                  height: "152px",
                  borderRadius: "10px",
                  marginBottom: "12px",
                }}
              >
                <Col>
                  <img
                    style={{
                      height: "auto",
                      width: width <= 400 ? "100px" : "",
                    }}
                    src={usuariosLogo}
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "30px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "800",
                      fontSize: "24px",
                      marginBottom: "5px",
                    }}
                  >{`${
                    userCount?.totalUsersCount === undefined
                      ? "0"
                      : userCount?.totalUsersCount
                  } usuarios`}</Text>
                </Col>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  width: "490px",
                  height: "152px",
                  borderRadius: "10px",
                }}
              >
                <Col>
                  <img
                    style={{
                      height: "auto",
                      width: width <= 400 ? "100px" : "",
                    }}
                    src={flotaLogo}
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "30px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "800",
                      fontSize: width <= 400 ? "18px" : "24px",
                      marginBottom: "5px",
                    }}
                  >
                    {fleetCount?.fleetCount?.fleet === undefined
                      ? "0"
                      : fleetCount?.fleetCount?.fleet}
                  </Text>
                  <Text style={{ fontSize: width <= 400 ? "10px" : "" }}>
                    Total flota
                  </Text>
                  <Text style={{ fontSize: width <= 400 ? "10px" : "" }}>
                    de vehiculos
                  </Text>
                </Col>
              </Col>
            </Row>
          ) : null}
          <Table
            columns={mobileColumns}
            expandedRowRender={(record) => (
              <Row>
                <Col span={14}>
                  <Text strong>Marca</Text>
                </Col>
                <Col span={10}>{record?.brandId}</Col>
                <Col span={14}>
                  <Text strong>Modelo</Text>
                </Col>
                <Col span={10}>{record?.classId}</Col>
                <Col span={14}>
                  <Text style={{ fontSize: "15px" }} strong>
                    Fecha de entrega
                  </Text>
                </Col>
                <Col span={10}>{record?.startDate}</Col>
                <Col span={14}>
                  <Text style={{ fontSize: "15px" }} strong>
                    Fecha devolucion
                  </Text>
                </Col>
                <Col span={10}>{record?.endDate}</Col>
              </Row>
            )}
            expandIcon={(props) => {
              if (props.record.deviceId) {
                if (props.expanded) {
                  return (
                    <MinusSquareOutlined
                      onClick={(e) => {
                        props.onExpand(props.record, e);
                      }}
                    />
                  );
                } else {
                  return (
                    <PlusSquareOutlined
                      onClick={(e) => {
                        props.onExpand(props.record, e);
                      }}
                    />
                  );
                }
              }
            }}
            rowClassName={(record) => (record.deviceId ? "show" : "hidden")}
            expandedRowKeys={expandedRowKeys}
            onExpand={handleRowKeys}
            rowKey={(record) => record.deviceId}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              position: ["bottomCenter"],
            }}
            onChange={handlePaginationChange}
            dataSource={data}
          />
        </>
      ) : (
        <>
          <Row
            style={{
              borderRadius:
                window.location.pathname === "/dashboard" ? "15px" : "",
              padding:
                window.location.pathname === "/dashboard"
                  ? "8px 7px 7px 7px"
                  : "",
              backgroundColor:
                window.location.pathname === "/dashboard" ? "#FFFFFF" : "",
              marginBottom: "20px",
              marginTop: "15px",
              display: window.location.pathname === "/dashboard" ? "flex" : "",
              justifyContent:
                window.location.pathname === "/dashboard"
                  ? "space-between"
                  : "",
            }}
          >
            {window.location.pathname === "/dashboard" ? null : (
              <Row>
                <Col sm={6}>
                  <Search
                    placeholder="Buscar patente/Orden de alquiler"
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                    onSearch={handleSearch}
                    style={{
                      width: 220,
                      paddingLeft: "15px",
                      marginRight: "15px",
                      display:
                        window.location.pathname === "/dashboard" ? "none" : "",
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              {window.location.pathname === "/dashboard" ? null : (
                <Divider
                  style={{ height: "100%", marginRight: "25px" }}
                  type="vertical"
                />
              )}
              {userData?.type === "CLIENT" ? null : (
                <Col>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={filters.customer || undefined}
                    showSearch
                    onChange={(value) => {
                      handleFilterByCustomer(value);
                      setSelectedCustomer(value);
                      setCurrentPage(1);
                    }}
                    style={{
                      width: 250,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                    placeholder="Empresa"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={filteredCustomers.map((customer) => {
                      return {
                        label: customer.name,
                        value: customer.name,
                      };
                    })}
                  />
                </Col>
              )}
              <Col>
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  value={filters.brand || undefined}
                  placeholder="Marca"
                  style={{
                    width: 150,
                    borderRadius: "50px",
                    marginRight: "20px",
                  }}
                  onChange={(value) => {
                    handleFilterByBrand(value);
                    setSelectedBrand(value);
                    setCurrentPage(1);
                  }}
                  loading={
                    status === "PENDING" && selectedCustomer !== null
                      ? true
                      : false
                  }
                >
                  {brand
                    .filter((b) => b.customer === selectedCustomer)
                    .map((b) => (
                      <Option value={b.brand} key={`${b.brand}${b.customer}`}>
                        {b.brand}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col>
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  value={filters.model || undefined}
                  placeholder="Modelo"
                  style={{
                    width: 150,
                    borderRadius: "50px",
                    marginRight: "15px",
                    backgroundColor: "#FFFFFF",
                  }}
                  onChange={(value) => {
                    handleFilterByModel(value);
                    setCurrentPage(1);
                  }}
                  loading={
                    status === "PENDING" && selectedCustomer !== null
                      ? true
                      : false
                  }
                >
                  {model
                    .filter(
                      (m) =>
                        m.brand === selectedBrand &&
                        m.customer === selectedCustomer
                    )
                    .map((m) => (
                      <Option value={m.model} key={`${m.brand}${m.customer}`}>
                        {m.model}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col>
                <a
                  style={{
                    border: "none",
                    textAlign: "bottom",
                    color: "#FF3200",
                    textDecoration: "underline",
                    fontSize: "16px",
                    marginLeft:
                      window.location.pathname === "/dashboard" ? "15px" : "",
                  }}
                  onClick={onClearFilters}
                >
                  {window.location.pathname === "/dashboard"
                    ? "Limpiar"
                    : "Limpiar filtros"}
                </a>
              </Col>

              {window.location.pathname === "/dashboard" ? null : (
                <Col>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    className="drop-down-vehicles-filter"
                  >
                    <Button
                      onClick={(e) => e.preventDefault()}
                      style={{
                        backgroundColor: "rgba(255, 50, 0, 0.06)",
                        color: "#FF3200",
                        fontSize: "20px",
                        border: "none",
                        borderRadius: "5px",
                        marginLeft: "25px",
                      }}
                      icon={<TableLogo />}
                    />
                  </Dropdown>
                </Col>
              )}
            </Row>
            {window.location.pathname === "/dashboard" ? (
              <Col>
                <Button
                  icon={
                    loadTemplate ? (
                      <Spin style={{ color: "white" }} indicator={antIcon} />
                    ) : (
                      <DownloadOutlined />
                    )
                  }
                  onClick={downloadVehiclesTemplate}
                  className="gama-button"
                  style={{ width: "100%", color: "white" }}
                >
                  Descargar Reportes
                </Button>
              </Col>
            ) : null}
          </Row>

          {window.location.pathname === "/dashboard" ? (
            <Row justify="center" style={{ marginBottom: "12px" }}>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  width: biggerThan1500 ? "48%" : "46%",
                  height: "152px",
                  borderRadius: "10px",
                }}
              >
                <Col>
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={usuariosLogo}
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "800",
                      fontSize: "24px",
                      marginBottom: "5px",
                    }}
                  >{`${
                    userCount?.totalUsersCount === undefined
                      ? "0"
                      : userCount?.totalUsersCount
                  } usuarios`}</Text>
                </Col>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  width: biggerThan1500 ? "48%" : "46%",
                  height: "152px",
                  borderRadius: "10px",
                  marginRight: "19px",
                  marginLeft: "19px",
                }}
              >
                <Col>
                  <img
                    style={{ height: "auto", width: "100%" }}
                    src={flotaLogo}
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "800",
                      fontSize: "24px",
                      marginBottom: "5px",
                    }}
                  >
                    {fleetCount?.fleetCount?.fleet === undefined
                      ? "0"
                      : fleetCount?.fleetCount?.fleet}
                  </Text>
                  <Text>Total flota</Text>
                  <Text>de vehiculos</Text>
                </Col>
              </Col>
            </Row>
          ) : null}

          <Col
            style={{
              backgroundColor: "#FFFFFF",
              padding: "10px 24px 24px",
              borderRadius: "18px",
            }}
          >
            {window.location.pathname === "/dashboard" ? (
              <Col>
                <Text
                  style={{
                    fontWeight: 800,
                    fontSize: "18px",
                    color: "#141414",
                  }}
                >
                  Flota de vehículos
                </Text>
              </Col>
            ) : null}
            <Table
              dataSource={data}
              onSearch={onSearch}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total,
                position: ["bottomCenter"],
              }}
              onChange={handlePaginationChange}
              columns={
                window.location.pathname === "/dashboard"
                  ? dashBoardColumns
                  : filteredTableColumns
              }
              sortDirection={["ASC", "DESC"]}
              scroll={{ x: "max-content" }}
              showSorterTooltip={false}
            />
          </Col>
        </>
      )}

      <VehicleDetails
        vehicleData={vehicleData}
        open={showDetail}
        onClose={handleDrawerClose}
      />

      <VehicleDocumentDetail
        documentsData={documentsData}
        open={showVehicleDocuments}
        onClose={handleDrawerClose}
        loadingDocs={loadingDocs}
      />

      <Drawer
        width="100%"
        title={
          <>
            <Row align="middle" justify="space-between">
              <span
                style={{
                  fontWeight: "600",
                  fontSize: biggerThan900 ? "20px" : "15px",
                }}
              >
                Filtros
              </span>
              <Col>
                <CloseOutlined
                  onClick={onClose}
                  style={{
                    color: "#FF3200",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                    padding: "7px 7px 7px 7px",
                    marginRight: "15px",
                    fontSize: "16px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </Row>
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        {userData?.type === "CLIENT" ? null : (
          <Col>
            <Select
              suffixIcon={<CaretDownOutlined />}
              showSearch
              value={filters.customer || undefined}
              onChange={(value) => {
                handleFilterByCustomer(value);
                setSelectedCustomer(value);
                setCurrentPage(1);
              }}
              style={{
                width: "100%",
                marginBottom: "15px",
              }}
              placeholder="Empresa"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={
                formCustomers &&
                formCustomers.map((customer) => {
                  return {
                    label: customer.name,
                    value: customer.name,
                  };
                })
              }
            />
          </Col>
        )}
        <Col>
          <Select
            suffixIcon={<CaretDownOutlined />}
            value={filters.brand || undefined}
            placeholder="Marca"
            style={{
              width: "100%",
              borderRadius: "50px",
              marginRight: "20px",
              marginBottom: "16px",
            }}
            onChange={(value) => {
              handleFilterByBrand(value);
              setSelectedBrand(value);
              setCurrentPage(1);
            }}
            loading={
              status === "PENDING" && selectedCustomer !== null ? true : false
            }
          >
            {brand
              .filter((b) => b.customer === selectedCustomer)
              .map((b) => (
                <Option key={b.brand} value={b.brand}>
                  {b.brand}
                </Option>
              ))}
          </Select>
        </Col>
        <Col>
          <Select
            suffixIcon={<CaretDownOutlined />}
            value={filters.model || undefined}
            placeholder="Modelo"
            style={{
              width: "100%",
              borderRadius: "50px",
              marginRight: "15px",
              backgroundColor: "#FFFFFF",
            }}
            onChange={(value) => {
              handleFilterByModel(value);
              setCurrentPage(1);
            }}
            loading={
              status === "PENDING" && selectedCustomer !== null ? true : false
            }
          >
            {model
              .filter(
                (m) =>
                  m.brand === selectedBrand && m.customer === selectedCustomer
              )
              .map((m) => (
                <Option value={m.model} key={`${m.brand}${m.customer}`}>
                  {m.model}
                </Option>
              ))}
          </Select>
        </Col>

        <Button
          onClick={onClose}
          className="gama-button"
          style={{
            width: "100%",
            marginTop: "56px",
            marginBottom: "16px",
            color: "white",
          }}
        >
          Aplicar Filtros
        </Button>

        <Button
          onClick={onClearFilters}
          className="gama-outline"
          style={{ width: "100%" }}
        >
          Limpiar Filtros
        </Button>
      </Drawer>
    </>
  );
}
