import axios from "axios";

import { STRAPI_API_URL } from "../../url";

const BASE_URL = STRAPI_API_URL;

export const getGamaUsadosMainContent = async () => {
  const { data: navbar } = await axios.get(
    `${BASE_URL}/gu-mains/1?populate=Navbar.Logo,Navbar.TopLinks,Navbar.NavLinks,Navbar.NavButton`
  );
  const { data: header } = await axios.get(
    `${BASE_URL}/gu-mains/1?populate=Header.BannerImage,Header.Title,Header.Button`
  );
  const { data: sections } = await axios.get(
    `${BASE_URL}/gu-mains/1?populate=Sections.Image,Sections.Card.Icon,Sections.Button`
  );

  return {
    navbar: navbar?.data?.attributes,
    header: header?.data?.attributes,
    sections: sections?.data?.attributes,
    // footer: footer.data[0].attributes,
  };
};

export const getGamaUsadosContactContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gu-mains/2?populate=Header.BannerImage,Header.Title,Header.Button`
  );
  const { data: sider } = await axios.get(
    `${BASE_URL}/gu-mains/2?populate=Sections.Image,Sections.Card.Icon,Sections.Button`
  );
  return {
    header: header?.data?.attributes.Header,
    sections: sider?.data?.attributes.Sections,
  };
};
