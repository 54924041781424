import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import {
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import {
  getMyProfileRequest,
  updateMyProfile,
  updateProfilePicture,
} from "../../requests/profile";
import "./index.less";
import Button from "../../components/Button";
import Notifications from "../../components/Notifications";
import { getUserData } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getUserRequest } from "../../requests/users";
import { isExecutive, isSuperAdmin, isSupervisor } from "../../utils/roles";
import { getCustomerAssignmentRequest } from "../../requests/customers";
import { CaretDownOutlined } from "@ant-design/icons";
import useTableQuery from "../../hooks/useTableQuery";
import { getAuthProfile, getPermissions } from "../../store/auth/thunks";
import { useMediaPredicate } from "react-media-hook";

const { Title } = Typography;
const { Option } = Select;

var stopClickEvent = 1;

const Profile = () => {
  const [form] = Form.useForm();
  const [profile, setProfile] = useState(null);
  const [access, setAccess] = useState([]);
  const userData = useSelector(getUserData);
  const [executive, setExecutive] = useState();
  const [supervisor, setSupervisor] = useState();
  const [customers, setCustomers] = useState([]);
  const { query } = useTableQuery();
  const dispatch = useDispatch();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const fileTypes = ["image/jpeg", "image/png", "'image/jpg'"];

  useEffect(() => {
    const getMyProfile = async () => {
      try {
        const { data } = await getMyProfileRequest();
        const { data: roles } = await getUserRequest(userData.id);

        // EJECUTIVOS
        if (
          !userData?.defaultCompany &&
          userData?.accessToCustomers[0]?.executiveId
        ) {
          const responseExecutive = await getUserRequest(
            userData?.accessToCustomers[0]?.executiveId
          );
          const responseSupervisor = await getUserRequest(
            userData?.accessToCustomers[0]?.supervisorId
          );
          setExecutive(responseExecutive?.data);
          setSupervisor(responseSupervisor?.data);
        }
        //EJECUTIVOS

        setAccess(roles.accesses);
        setProfile(data);
      } catch (error) {
        console.error(error);
        Notifications.error(
          "Error al cargar",
          "Ha ocurrido un error al cargar tu perfil"
        );
      }
    };
    getMyProfile();
  }, [userData?.profileImageUrl]);

  useEffect(() => form.setFieldsValue(profile), [profile]);

  useEffect(() => {
    if (isSupervisor(userData.accesses) || isExecutive(userData.accesses)) {
      try {
        const fetchCustomers = async (query) => {
          const { data } = await getCustomerAssignmentRequest({
            ...query,
            pageSize: 9999,
          });
          setCustomers(data);
        };
        fetchCustomers(query);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const onFinish = async ({ rut, ...values }) => {
    const newValues = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber.toString(),
      phonePrefix: values.phonePrefix.toString(),
    };

    try {
      await updateMyProfile(newValues);
      Notifications.success("Terminado", "Se ha actualizado su perfil");
    } catch (error) {
      Notifications.error(
        "Error al actualizar",
        "Ha ocurrido un error al actualizar tu perfil"
      );
    }
  };

  const handleChange = async (info) => {
    try {
      if (info.fileList[0].originFileObj > 1) {
        info.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "No se puede subir mas de una imagen."
        );
      } else if (!fileTypes.includes(info?.fileList[0]?.originFileObj?.type)) {
        info.fileList.pop();
        Modal.error({
          content: (
            <>
              <div>Archivo no valido</div>
              <div>Archivos permitidos: </div>
              <span>jpeg, png.</span>
            </>
          ),
        });
      } else if (info?.fileList[0]?.originFileObj?.size >= 20000000) {
        info.fileList.pop();
        Notifications.error(
          "Error al cargar",
          "El peso del archivo supera el máximo de 20MB."
        );
      } else {
        if (stopClickEvent === 3) {
          await updateProfilePicture(info?.file?.originFileObj);
          Notifications.success(
            "Cambio de imagen",
            "Se ha actualizado su imagen de perfil satisfactoriamente."
          );
          stopClickEvent = 1;
          dispatch(getAuthProfile());
          dispatch(getPermissions());
        }
      }
    } catch (error) {
      Notifications.error(
        "Error al cargar",
        "Ocurrio un error al cargar la imagen."
      );
    }
  };

  return (
    <AppLayout title="Mi perfil" noContent>
      <div
        style={{
          width: biggerThan900 ? "80%" : "100%",
          marginLeft: biggerThan900 ? "-30px" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Row
            style={{ height: "100%", width: "100%", margin: 10 }}
            gutter={20}
          >
            <Col sm={24} md={12} style={{ width: biggerThan900 ? "" : "100%" }}>
              <div className="box-content">
                <Title level={4}>Mis datos personales</Title>
                <Upload
                  showUploadList={false}
                  onChange={(info) => {
                    if (stopClickEvent === 3) {
                      handleChange(info);
                      stopClickEvent = 1;
                    } else {
                      stopClickEvent = stopClickEvent + 1;
                    }
                  }}
                  accept=".png,.jpg,.jpeg,.webp"
                >
                  <img
                    style={{
                      borderRadius: "50%",
                      cursor: "pointer",
                      height: "80px",
                      width: "80px",
                    }}
                    src={userData?.profileImageUrl}
                  />
                </Upload>
                <Form
                  name="profile"
                  form={form}
                  initialValues={{}}
                  style={{ margin: 10 }}
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Form.Item label="RUT" name="rut" style={{ width: "60%" }}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Nombre"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese el nombre",
                      },
                      {
                        whitespace: true,
                      },
                      {
                        //Mayusculas, minusculas, acentos, espacio, apostrofe, guines
                        pattern: /^([-A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]){3,50}$/,
                        message:
                          "Los nombres sólo pueden incluir letras y guiones, debe ingresar al menos 3 caracteres.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Apellidos"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese el apellido",
                      },
                      {
                        whitespace: true,
                      },
                      {
                        //Mayusculas, minusculas, acentos, espacio, apostrofe
                        pattern: /^([-A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]){2,50}$/,
                        message:
                          "El apellido sólo debe incluir letras y al menos 2 caracteres.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Correo"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Por favor ingrese un correo válido",
                      },
                      {
                        max: 100,
                        message:
                          "No puede ingresar más de 100 carácteres en un correo",
                      },
                      {
                        required: true,
                        message: "Por favor ingrese el correo",
                      },
                      {
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Input.Group compact>
                    <Form.Item
                      name="phonePrefix"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa el prefijo",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<CaretDownOutlined />}
                        style={{ width: 100 }}
                      >
                        <Option value={"56"}>+56</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el número de telefono",
                        },
                        {
                          //Numeros, signo +
                          pattern: /^[+]*\d{7,10}$/,
                          message:
                            "Ingrese un número válido, sin letras y entre 8 y 10 dígitos.",
                        },
                      ]}
                    >
                      <Input placeholder="Teléfono" />
                    </Form.Item>
                  </Input.Group>
                </Form>
              </div>
            </Col>
            {!isSuperAdmin(access) && (
              <Col
                sm={24}
                md={12}
                style={{ width: biggerThan900 ? "" : "100%" }}
              >
                <div className="box-content">
                  {(isSupervisor(access) || isExecutive(access)) &&
                  userData.type === "GAMA" ? (
                    <>
                      <Title level={4}>Cartera de clientes</Title>
                      <Row>
                        <Col span={24}>
                          <div className="container-subempresas">
                            {customers.customers
                              ?.filter(
                                ({ auxData }) =>
                                  auxData.executive === userData.id ||
                                  auxData.supervisor === userData.id
                              )
                              .map(({ name, rut }) => (
                                <p key={name}>
                                  {name} · {rut}
                                </p>
                              ))}
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Title level={4}>Contactos Gama</Title>
                      <Row style={{ marginTop: "1rem" }}>
                        <Col
                          className="responsive-error"
                          style={{ width: biggerThan900 ? "" : "100%" }}
                          span={24}
                        >
                          <Row align="center">
                            <Col span={8}>
                              <h4
                                style={{
                                  fontSize: biggerThan900 ? "18px" : "14px",
                                }}
                              >
                                Ejecutivo
                              </h4>
                            </Col>
                            <Col span={16}>
                              <p style={{ marginTop: "11px" }}>
                                {executive?.firstName === ""
                                  ? "No aplica"
                                  : executive?.firstName}{" "}
                                {executive?.lastName}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={8}>
                              <h4
                                style={{
                                  fontSize: biggerThan900 ? "18px" : "14px",
                                }}
                              >
                                Correo
                              </h4>
                            </Col>
                            <Col span={16}>
                              <p style={{ marginTop: "11px" }}>
                                {executive?.email}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={8}>
                              <h4
                                style={{
                                  fontSize: biggerThan900 ? "18px" : "14px",
                                }}
                              >
                                Teléfono
                              </h4>
                            </Col>
                            <Col span={16}>
                              <p style={{ marginTop: "11px" }}>
                                +{executive?.phonePrefix}{" "}
                                {executive?.phoneNumber}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row style={{ marginTop: "1rem" }}>
                        <Col span={24}>
                          <Row>
                            <Col span={8}>
                              <h4
                                style={{
                                  fontSize: biggerThan900 ? "18px" : "14px",
                                }}
                              >
                                Supervisor
                              </h4>
                            </Col>
                            <Col span={16}>
                              <p style={{ marginTop: "11px" }}>
                                {supervisor?.firstName} {supervisor?.lastName}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={8}>
                              <h4
                                style={{
                                  fontSize: biggerThan900 ? "18px" : "14px",
                                }}
                              >
                                Correo
                              </h4>
                            </Col>
                            <Col span={16}>
                              <p style={{ marginTop: "11px" }}>
                                {executive?.email}
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={8}>
                              <h4
                                style={{
                                  fontSize: biggerThan900 ? "18px" : "14px",
                                }}
                              >
                                Teléfono
                              </h4>
                            </Col>
                            <Col span={16}>
                              <p style={{ marginTop: "11px" }}>
                                +{executive?.phonePrefix}{" "}
                                {executive?.phoneNumber}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            )}
          </Row>
          <Button onClick={() => form.submit()}>Guardar</Button>
        </div>
      </div>
    </AppLayout>
  );
};

export default Profile;
