import { Col, Row, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import {
  getCustomerAssignmentRequest,
  getCustomersRequest,
  searchCustomer,
} from "../../requests/customers";
import CustomerForm from "./CustomerForm";
import CustomersTable from "./CustomersTable";
import ShowCustomer from "./ShowCustomer";
import ExecutiveForm from "./ExecutiveForm";
import BulkUploadForm from "./BulkUploadForm";
import { getUserData } from "../../store/auth";
import { isAnotherGamaRole, isSuperAdmin } from "../../utils/roles";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import useTableQuery from "../../hooks/useTableQuery";
import * as DOMPurify from "dompurify";

const { Search } = Input;
const { Text } = Typography;

const Customers = () => {
  const [customers, setCustomers] = useState();
  const [loading, setLoading] = useState(true);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [openCreateCustomer, setOpenCreateCustomer] = useState(false);
  const [openShowCustomer, setOpenShowCustomer] = useState(false);
  const [openExecutiveForm, setOpenExecutiveForm] = useState(false);
  const [openBulkForm, setOpenBulkForm] = useState(false);
  const { height, width } = useWindowDimensions();
  const { accesses, type } = useSelector(getUserData);
  const [total, setTotal] = useState();
  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    query,
  } = useTableQuery();

  const loadCustomers = async (query) => {
    try {
      if (isSuperAdmin(accesses) || isAnotherGamaRole(accesses, type)) {
        const { data } = await getCustomersRequest(query);
        const newObj = data.customers.map((item) => ({
          ...item,
          isExpandable: true,
        }));
        setTotal(data.count);
        setCustomers(newObj);
        return;
      }

      const { data } = await getCustomerAssignmentRequest(query);
      const newObj = data.customers.map((item) => ({
        ...item,
        isExpandable: true,
      }));
      setTotal(data.count);
      setCustomers(newObj);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const mobileColumns = [
    {
      key: "name",
      title: <Text strong>Empresa</Text>,
      dataIndex: "name",

      // align: "left",
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      key: "rut",
      title: <Text strong>Rut</Text>,
      dataIndex: "rut",
      // align: "left",

      sorter: {
        compare: (a, b) => a?.rut?.localeCompare(b?.rut),
      },
    },
  ];

  const handleSearch = async (value) => {
    let cleanValue = DOMPurify.sanitize(value);
    try {
      if (isSuperAdmin(accesses)) {
        const { data } = await searchCustomer(cleanValue.replace(/\./g, ""));
        const newObj = data.customers.map((item) => ({
          ...item,
          isExpandable: true,
        }));
        setTotal(data.count);
        setCustomers(newObj);
        return;
      }
      const { data } = await getCustomerAssignmentRequest(query, cleanValue);
      const newObj = data.customers.map((item) => ({
        ...item,
        isExpandable: true,
      }));
      setTotal(data.count);
      setCustomers(newObj);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const clearSearch = (value) => {
    if (value === "") {
      loadCustomers(query);
    }
  };

  const onFormFinish = () => loadCustomers(query);

  const onSelectCustomer = (customer, action) => {
    if (action === "update") setOpenCreateCustomer(true);
    if (action === "show") setOpenShowCustomer(true);
    if (action === "executive") setOpenExecutiveForm(true);

    setCurrentCustomer(customer);
  };

  useEffect(() => {
    loadCustomers(query);
  }, [query]);

  return (
    <AppLayout title="Administrador de Empresas">
      {width <= 640 ? (
        <>
          <Row
            style={{
              backgroundColor: "#ffffff",
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              marginTop: "20px",
            }}
          >
            <Col sm={24} md={12}>
              <Search
                placeholder="Busqueda por Rut o Nombre"
                onSearch={handleSearch}
                onChange={(e) => clearSearch(e.target.value)}
                style={{
                  width: 240,
                }}
              />
            </Col>
            {/* <Col
        sm={24}
        md={12}
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          className="gama-outline"
          style={{
            marginRight: "1rem",
          }}
          icon={<UploadOutlined />}
          onClick={() => setOpenBulkForm(true)}
        >
          Cargar o actualizar empresas
        </Button>
        <Button type="primary" onClick={() => setOpenCreateCustomer(true)}>
          <PlusOutlined />
          Crear nueva empresa
        </Button>
      </Col> */}
          </Row>

          <CustomersTable
            mobileColumns={mobileColumns}
            loading={loading}
            customers={customers}
            onSelectCustomer={onSelectCustomer}
            hasEditAccess={isSuperAdmin(accesses)}
            showSorterTooltip={false}
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
            total={total}
          />
          <CustomerForm
            open={openCreateCustomer}
            onClose={() => {
              setOpenCreateCustomer(false);
              setCurrentCustomer(null);
            }}
            customerToUpdate={currentCustomer}
            onFormFinish={onFormFinish}
            customers={customers}
          />

          <ShowCustomer
            open={openShowCustomer}
            onClose={() => {
              setOpenShowCustomer(false);
              setCurrentCustomer(null);
            }}
            customerToShow={currentCustomer}
            customers={customers}
          />

          <ExecutiveForm
            open={openExecutiveForm}
            onClose={() => setOpenExecutiveForm(false)}
            customerToShow={currentCustomer}
            onFormFinish={onFormFinish}
          />

          <BulkUploadForm
            open={openBulkForm}
            onClose={() => setOpenBulkForm(false)}
            onFormFinish={onFormFinish}
          />
        </>
      ) : (
        <>
          <Row
            style={{
              backgroundColor: "#ffffff",
              borderTopLeftRadius: "0.5rem",
              borderTopRightRadius: "0.5rem",
              marginTop: "20px",
              marginLeft: "15px",
            }}
          >
            <Col sm={24} md={12}>
              <Search
                placeholder="Busqueda por Rut o Nombre"
                onSearch={handleSearch}
                onChange={(e) => clearSearch(e.target.value)}
                style={{
                  width: 240,
                }}
              />
            </Col>
            {/* <Col
        sm={24}
        md={12}
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          className="gama-outline"
          style={{
            marginRight: "1rem",
          }}
          icon={<UploadOutlined />}
          onClick={() => setOpenBulkForm(true)}
        >
          Cargar o actualizar empresas
        </Button>
        <Button type="primary" onClick={() => setOpenCreateCustomer(true)}>
          <PlusOutlined />
          Crear nueva empresa
        </Button>
      </Col> */}
          </Row>

          <CustomersTable
            loading={loading}
            customers={customers}
            onSelectCustomer={onSelectCustomer}
            hasEditAccess={isSuperAdmin(accesses)}
            showSorterTooltip={false}
            currentPage={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
            total={total}
          />

          <CustomerForm
            open={openCreateCustomer}
            onClose={() => {
              setOpenCreateCustomer(false);
              setCurrentCustomer(null);
            }}
            customerToUpdate={currentCustomer}
            onFormFinish={onFormFinish}
            customers={customers}
          />

          <ShowCustomer
            open={openShowCustomer}
            onClose={() => {
              setOpenShowCustomer(false);
              setCurrentCustomer(null);
            }}
            customerToShow={currentCustomer}
            customers={customers}
          />

          <ExecutiveForm
            open={openExecutiveForm}
            onClose={() => setOpenExecutiveForm(false)}
            customerToShow={currentCustomer}
            onFormFinish={onFormFinish}
          />

          <BulkUploadForm
            open={openBulkForm}
            onClose={() => setOpenBulkForm(false)}
            onFormFinish={onFormFinish}
          />
        </>
      )}
    </AppLayout>
  );
};

export default Customers;
