import React from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';
import './index.less';

const CustomerCard = ({
  name,
  rut
}) => {
  return (
    <Row className='container-customer-card'>
      <Col>
        <Avatar shape='square' size={48} icon={<IdcardOutlined />} />
      </Col>

      <Col style={{ marginLeft: '.5rem', display: 'flex', alignItems: 'center' }}>
        <div>
          <h3>{name}</h3>
          <span>{rut}</span>
        </div>
      </Col>
    </Row>
  )
}

export default CustomerCard;