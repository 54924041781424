import React from "react";
import { Divider, Menu } from "antd";
import { Link } from "react-router-dom";
import { getUserData } from "../../../../store/auth";
import { useSelector } from "react-redux";

const ProfileMenu = ({ user, onSignOut }) => {
  const userData = useSelector(getUserData);
  return (
    <Menu
      items={[
        {
          key: "0",
          label: (
            <>
            <Link to="/perfil">
              <div style={{ display: "flex" }}>
              <img
                style={{ borderRadius: "50%", cursor: "pointer", height: "50px", width: "50px" }}
                src={userData?.profileImageUrl}
              />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{ fontSize: "1rem", padding: "0 .5rem" }}
                  >{`${user.name} ${user.lastname}`}</span>
                  <span style={{ padding: "0 .5rem" }}>{user.email}</span>
                </div>
              </div>
              </Link>
              <Divider style={{margin:0}}/>
            </>
          ),
        },
        {
          key: "1",
          label: <Link to="/perfil">Mi perfil</Link>,
        },
        {
          key: "2",
          label: <Link to="/password">Cambiar contraseña</Link>,
        },
        {
          key: "3",
          label: (
            <>
              <Divider style={{margin:0}}/>
              <a rel="noopener noreferrer" onClick={onSignOut}>
                Cerrar sesión
              </a>
            </>
          ),
        },
      ]}
    />
  );
};

export default ProfileMenu;
