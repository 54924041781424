import React, { useEffect, useState } from "react"
import ReportsLayout from "../../components/layouts/ReportsLayout"
import { Col, Row, Select, Spin, Typography } from "antd";
import { useSelector } from "react-redux";
import { getCustomers } from "../../store/customers";
import { CaretDownOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import reportsVehicles from "../../assets/img/reportsVehicles.png"
import DemoPie from "./pieComponent/index.js"
import rafiki from "../../assets/img/rafiki.png"
import Button from "../../components/Button";
import * as XLSX from "xlsx/xlsx.mjs";
import { exportVehiclesTemplate, getReportsBrands, getReportsBrandsClient, getReportsTransmissions, getReportsTransmissionsClient, getReportsTypes, getReportsTypesClient } from "../../requests/vehicles";
import { saveAs } from "file-saver";
import { exportTemplate, getAllUserCust } from "../../requests/users";
import { getUserData } from "../../store/auth";
import { useMediaPredicate } from "react-media-hook";
import { isAnotherGamaRole, isExecutive, isSuperAdmin, isSupervisor } from "../../utils/roles";

const Reports = () => {
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [loadTemplate, setLoadTemplate] = useState(false);
    const [reportsTypes, setReportsTypes] = useState();
    const [reportsBrands, setReportsBrands] = useState();
    const [sumOfValues, setSumOfValues] = useState()
    const [sumOfTrans, setSumOfTrans] = useState()
    const [sumOfTypes, setSumOfTypes] = useState()
    const biggerThan1460 = useMediaPredicate("(min-width: 1460px)");
    const biggerThan900 = useMediaPredicate("(min-width: 900px)");
    const [reportsTransmissions, setReportsTransmissions] = useState();
    const userData = useSelector(getUserData);
    const [loadingTrans, setLoadingTrans] = useState(true)
    const [loadingBrands, setLoadingBrands] = useState(true)
    const customers = useSelector(getCustomers);
    const [templateName, setTemplateName] = useState()
    const { accesses } = useSelector(getUserData);
    const [show, setShow] = useState(userData.type === "CLIENT" ? true : false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [allCustomers, setAllCustomers] = useState()
    const formCustomers = customers.customers ? customers.customers : customers;
    const filteredCustomers =
      formCustomers &&
      formCustomers?.filter(
        (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
      );
      const handleDownloadReport = (value) => {
        setTemplateName(value)
        setIsDisabled(false)
      }

      const handleSearch = (value) => {
        setReportsBrands([])
        setReportsTypes([])
        setReportsTransmissions([])
        setShow(true)
        setSelectedCustomer(value);
        
      }

      useEffect( ()=> {
        const getAllCust = async () => {
          try {
            const { data } = await getAllUserCust()
    
            setAllCustomers(data?.customers)     
          } catch (error) {
            console.error(error)
          }
        }
        getAllCust()
      }, [accesses])

      const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      );

      useEffect(() => {
        if(userData.type === "CLIENT"){
          const getReports =  async () => {
            try {
              const responseTypes = await getReportsTypesClient()
              const newObj = responseTypes?.data?.map(item => ({
                type: item.name,
                value: item.count
              }))
              const filteredNewObj = newObj.filter(item => item.value !== 0)
              const sum = filteredNewObj.reduce((acc, item) => acc + parseInt(item.value), 0);
              setSumOfTypes(sum)
              setReportsTypes(filteredNewObj)
  
            } catch (error) {
              console.error(error)
            }

            try {
              setLoadingBrands(true)
              const responseBrands = await getReportsBrandsClient()
              const newObj = responseBrands?.data?.map(item => ({
                type: item.name,
                value: item.count
              }))

              const filteredNewObj = newObj.filter(item => item.value !== 0)
              setReportsBrands(filteredNewObj)
              const sum = filteredNewObj.reduce((acc, item) => acc + parseInt(item.value), 0);
              setSumOfValues(sum)
            } catch (error) {
              console.error(error)
              setLoadingBrands(false)
            } finally{
              setLoadingBrands(false)
            }
            
            try {
              setLoadingTrans(true)
              const responseTransmissions = await getReportsTransmissionsClient()
              const newObj = responseTransmissions?.data?.map(item => ({
                type: item.name,
                value: item.count
              }))
              const filteredNewObj = newObj.filter(item => item.value !== 0)
              const sum = filteredNewObj.reduce((acc, item) => acc + parseInt(item.value), 0);
              setSumOfTrans(sum)
              setReportsTransmissions(filteredNewObj)
            
            } catch (error) {
              console.error(error)
              setLoadingTrans(false)
            } finally{
              setLoadingTrans(false)
            }
          }
          getReports()
        } else {
          const handleReportSearch = async() => {
            try {
              const responseTypes = await getReportsTypes(selectedCustomer)
              const newObj = responseTypes?.data?.map(item => ({
                type: item.name,
                value: item.count
              }))
              const filteredNewObj = newObj.filter(item => item.value !== 0)
              const sum = filteredNewObj.reduce((acc, item) => acc + parseInt(item.value), 0);
              setSumOfTypes(sum)
              setReportsTypes(filteredNewObj)
              
            } catch (error) {
              console.error(error)
            }

            try {
              setLoadingBrands(true)
              const responseBrands = await getReportsBrands(selectedCustomer)
              const newObj = responseBrands?.data?.map(item => ({
                type: item.name,
                value: item.count
              }))

              const filteredNewObj = newObj.filter(item => item.value !== 0)
              setReportsBrands(filteredNewObj)
              const sum = filteredNewObj.reduce((acc, item) => acc + parseInt(item.value), 0);
              setSumOfValues(sum)
            } catch (error) {
              console.error(error)
              setLoadingBrands(false)
            } finally{
              setLoadingBrands(false)
            }
            
            try {
              setLoadingTrans(true)
              const responseTransmissions = await getReportsTransmissions(selectedCustomer)
              const newObj = responseTransmissions?.data?.map(item => ({
                type: item.name,
                value: item.count
              }))
              const filteredNewObj = newObj.filter(item => item.value !== 0)
              const sum = filteredNewObj.reduce((acc, item) => acc + parseInt(item.value), 0);
              setSumOfTrans(sum)
              setReportsTransmissions(filteredNewObj)
              
            } catch (error) {
              console.error(error)
              setLoadingTrans(false)
            } finally{
              setLoadingTrans(false)
            }
          }
          handleReportSearch()
        }
      }, [selectedCustomer, accesses])

      const generateExcelBuffer = (data) => {
        const sheet = XLSX.utils.json_to_sheet(data);
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet, "Sheet1");
        const buffer = XLSX.write(book, { type: "buffer", bookType: "xlsx" });
        return buffer;
      };
    
      const downloadExcelFile = (buffer, fileName) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      };
    
      const downloadVehiclesTemplate = async () => {
        setLoadTemplate(true);
        try {
          let result = [];
          const pageSize = 1000;
          const { data } = await exportVehiclesTemplate({ page: 1 });
          let totalPages = Math.trunc(data.count / pageSize) + 1;
          for (let page = 1; page <= totalPages; page++) {
            const { data } = await exportVehiclesTemplate({ page });
            result = [...result, ...data.data];
          }
          const bufferData = generateExcelBuffer(result);
          downloadExcelFile(bufferData, "Reporte de Vehiculos");
        } catch (error) {
          console.log(error);
        } finally {
          setLoadTemplate(false);
        }
      };

      const downloadTemplate = async () => {
        try {
          const { data } = await exportTemplate();
          downloadExcelFile(data, "Reporte de Solicitudes");
        } catch (error) {
          console.error(error);
        }
      };

    return(
        <ReportsLayout title="Reportes">
          {isSuperAdmin(accesses) || isSupervisor(accesses) || isExecutive(accesses) || isAnotherGamaRole(accesses) ? 
          	<Col style={{backgroundColor: "#FFFFFF", width: biggerThan900 ? "20%" : "100%", padding: "5px 5px 5px 5px", borderRadius: "6px"}}>
            <Select
            suffixIcon={<CaretDownOutlined />}
            showSearch
            onChange={handleSearch}
            style={{
                width: "100%",
                borderRadius: "50px",
                marginRight: "20px",

            }}
            placeholder="Empresa"
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(
                input.toLowerCase()
                )
            }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare(
                    (optionB?.label ?? "").toLowerCase()
                )
            }
            options={ isSuperAdmin(accesses) ? 
              formCustomers?.map((customer) => {
                return {
                label: customer.name,
                value: customer.name,
                };
            })
              :
              allCustomers?.map((customer) => {
                    return {
                    label: customer.name,
                    value: customer.name,
                    };
                })
            }
            />
          </Col>

        : null}
          
          {show && 
            <Row style={{width: "100%", height: "100%", marginTop: "15px"}}>
							<Row style={{display:"flex", flexDirection: "column"}} >
								<Col style={{width: "100%"}}>
										<Row>
											<Col style={{height: "30%", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "10px", boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)", marginBottom: "15px"}}>
                    <Spin
                      spinning={loadingTrans}
                      style={{ color: "#FF3200" }}
                      indicator={antIcon}
                    >
												<Typography style={{fontSize: "24px", fontWeight: "700", paddingTop: "20px", paddingLeft: "20px"}}>Vehiculos</Typography>
                        <Row style={{display: "flex", marginLeft: biggerThan900 ? "73px" : "20px", marginTop: "16px"}}>
                          <Col>
                            <img style={{height: biggerThan900 ? "" : "auto", width: biggerThan900 ? "" : "100%"}} src={reportsVehicles} />
                          </Col>
                          <Col style={{marginLeft: "20px"}}>
                            <Typography style={{fontWeight: "800", fontSize: biggerThan900 ? "26px" : "15px"}}>Total Vehiculos</Typography>
                            <Typography style={{fontWeight: "800", fontSize: biggerThan900 ? "26px" : "15px"}}>{sumOfTypes}</Typography>
                          </Col>
                        </Row>
                    
                        <Row justify="space-around">

                        <Row style={{display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", backgroundColor: "#F7F7F7",borderRadius: "6px", marginBottom: "15px", marginTop: "20px", marginLeft: "20px", marginRight: "20px", paddingLeft: "15px", paddingRight: "15px"}}>
                            {reportsTypes?.map(item => {
                              return (
                                <Row key={item.value} style={{ paddingTop: "5px", paddingBottom: "5px"}}>
                                  <Col>
                                    <Typography style={{ fontSize: biggerThan900 ? "" : "10px"}}><span style={{fontWeight: "700", fontSize: biggerThan900 ? "" : "10px"}}>{item.value}</span> {item.type}</Typography>
                                  </Col>
                                </Row>
                              )
                            })}
                            </Row>
                        </Row>
                      </Spin>
											</Col>
										</Row>
								</Col>
								<Col>
                    <Spin
                      spinning={loadingTrans}
                      style={{ color: "#FF3200" }}
                      indicator={antIcon}
                    >
                      <Col style={{height: "274px", width: "519px", backgroundColor: "#FFFFFF", borderRadius: "10px", boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)", marginBottom: "15px"}}>
                        <Typography style={{fontSize: "24px", fontWeight: "700", paddingTop: "20px", paddingLeft: "20px"}}>Transmisión</Typography>
                          <DemoPie data={reportsTransmissions} />
                        <Typography style={{fontWeight: "700", fontSize: "16px", backgroundColor: "#F0F0F0", width: "190px", marginLeft: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>Total Vehículos: {sumOfTrans}</Typography>
                      </Col>
                    </Spin>
                    
								</Col>
								<Col>
                  <Spin spinning={loadingBrands} style={{ color: "#FF3200" }} indicator={antIcon}>
                      <Col style={{height: "274px", width: "519px", backgroundColor: "#FFFFFF", borderRadius: "10px", boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)", marginBottom: "15px"}}>
                        <Typography style={{fontSize: "24px", fontWeight: "700", paddingTop: "20px", paddingLeft: "20px"}}>Marca de vehículos</Typography>
                           <DemoPie data={reportsBrands} />  
                        <Typography style={{fontWeight: "700", fontSize: "16px", backgroundColor: "#F0F0F0", width: "190px", marginLeft: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>Total Vehículos: {sumOfValues}</Typography>
                      </Col>
                    </Spin>
                                      
								</Col>
							</Row>
							<Row style={{height: biggerThan900 ? "100%" : "", marginLeft: biggerThan900 ? "15px" : ""}}>
								<Col>
										<Col style={{height: biggerThan900 ? "852px" : "400px", width: biggerThan1460 ? "519px" : "400px", backgroundColor: "#FFFFFF", borderRadius: "10px", boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)"}}>
                      <Row align="middle" justify="center" style={{paddingTop: biggerThan900 ? "160px" : "22px"}}>
                        <img style={{width: biggerThan900 ? "302px" : "150px", height: "auto"}} src={rafiki} />
                      </Row>
                      <Typography style={{fontSize: biggerThan900 ? "24px" : "18px", fontWeight: "700", paddingTop: "20px", paddingLeft: "20px", marginTop: biggerThan900 ? "96px" : "10px", marginLeft: biggerThan900 ? "52px" : "16px"}}>Descargar reporte</Typography>
                      <Typography style={{fontSize: "14px", fontWeight: "400", paddingLeft: "20px", marginLeft: biggerThan900 ? "52px" : "15px"}}>Selecciona el tipo de reporte a descargar</Typography>
                      <Typography style={{fontSize: "14px", fontWeight: "400", paddingLeft: "20px", marginLeft: biggerThan900 ? "52px" : "15px", marginTop:  biggerThan900 ? "24px" : "10px"}}>Tipo de reporte</Typography>

                      <Select
                        label="Tipo de reporte"
                        style={{ width: "70%",marginLeft: biggerThan900 ? "68px" : "30px" }}
                        placeholder="Seleccionar"
                        suffixIcon={<CaretDownOutlined />}
                        onChange={handleDownloadReport}
                      >
                        <Select.Option value="Solicitudes">
                          Solicitudes
                        </Select.Option>                          
                        <Select.Option value="Vehiculos">
                          Vehiculos
                        </Select.Option>                                          
                      </Select>

                      <Row justify="center">
                      <Button
                      disabled={isDisabled}
                        icon={
                          loadTemplate ? (
                            <Spin
                            style={{ color: "white" }}
                            indicator={antIcon}
                            />
                            ) : (
                              <DownloadOutlined />
                              )
                            }
                            onClick={templateName === "Solicitudes" ? downloadTemplate : downloadVehiclesTemplate    }
                            className="gama-button"
                            style={{ width: "70%", marginBottom: "15px", color: "white", marginTop: "16px", marginLeft: biggerThan900 ? "" : "-35px"  }}
                            >
                        Descargar Reportes
                      </Button> 
                      </Row>
                    </Col>
								</Col>
							</Row>
            </Row>
          }

        </ReportsLayout>
    )
}

export default Reports