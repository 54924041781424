import React from "react";
import Footer from "../../components/layouts/HomeLayout/Footer";
import { useMediaPredicate } from "react-media-hook";
import backgroundImg from "../../assets/img/maintenance-background-image.jpeg";
import { useNavigate } from "react-router-dom";

function Maintenance() {
  const navigate = useNavigate();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Overlay con opacidad */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Fondo oscuro
          }}
        ></div>

        {/* Contenido sobre la imagen */}
        <div
          style={{
            position: "relative", // Para estar por encima del overlay
            color: "white",
            textAlign: "center",
            zIndex: 1, // Asegurarse que el texto esté por encima del overlay
            padding: "20px",
          }}
        >
          <h1 style={{ fontSize: biggerThan900 ? "3rem" : "2rem", marginBottom: "1rem", color: '#FFFFFF', textAlign: "center", }}>
            Estamos trabajando para mejorar su experiencia
          </h1>
          <p style={{ fontSize: "1.2rem", marginBottom: "2rem", textAlign: "center" }}>
            El portal está en mantenimiento y estará disponible en unos minutos. <br />
            Agradecemos su paciencia y comprensión.
          </p>
          <button
            style={{
              backgroundColor: "#FF6600",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={() => navigate('/')}
          >
            Volver al Home
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Maintenance;
