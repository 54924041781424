import React from "react";
import { Tabs as AntdTabs } from "antd";
import "./index.less";

const { TabPane } = AntdTabs;

const Tabs = ({ client, gama, handleTab }) => {
  return (
    <div className="card-container">
      <AntdTabs onChange={handleTab} type="card" size={"large"}>
        <TabPane tab="Clientes" key="client">
          {client}
        </TabPane>
        <TabPane tab="Gama" key="gama">
          {gama}
        </TabPane>
      </AntdTabs>
    </div>
  );
};

export default Tabs;
