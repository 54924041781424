import request from "../request";


import {
  BASE_USERS_API_URL,
} from '../url';


export const createRequest = async (data) => {
  return await request({
    url: `${BASE_USERS_API_URL}/requests`,
    method: "POST",
    data,
  });
};

export const getAllRequests = async (filters, page = 1, query ) => {
  console.log(query, "REQUESTS")
  const queryParams = new URLSearchParams(filters).toString();
  return await request({
    url: `${BASE_USERS_API_URL}/requests?page=${page}&orderByProperty=${query?.orderByProperty || "id"}&orderBy=${query?.orderBy || "DESC"}&` + queryParams,
    method: "GET",
  });
};

export const updateRequest = async (requestId, data) => {
  return await request({
    url: `${BASE_USERS_API_URL}/requests/${requestId}`,
    method: "PATCH",
    data: data,
  });
};

export const deleteRequest = async (requestId) => {
  return await request({
    url: `${BASE_USERS_API_URL}/requests/${requestId}`,
    method: "DELETE",
  });
};
