import { Col, Image, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import GoToTop from "../../components/GoToTop";
import Burger from "../../components/layouts/HomeLayout/Burger";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-mobility.webp";
import Footer from "../../components/layouts/HomeLayout/Footer";

function LegalWarning() {
  const [isHome, setIsHome] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (window.location.pathname === "/legalWarning") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const { scrollDirection } = useScroll();

  const burguerData = [
    {
      title: "Sucursales",
      link: "/branches",
    },
    {
      title: "Nuestros servicios",
      link: "/mobility#services",
    },
    {
      title: "Quienes Somos",
      link: "/mobility#us",
    },
    { title: "Beneficios", link: "/mobility#benefits" },
    { title: "Publicaciones", link: "/mobility#posts" },
  ];

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };
  return (
    <div>
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `12vh`,
          }}
        >
          <Row className="header-contain">
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "/mobility"
                            ? "#FF3200"
                            : "white",
                      }}
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image preview={false} src={Logo} width={120} />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contactMobility">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/branches">
                                Sucursales
                              </a>
                              <a smooth href="#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#us">
                                Quienes Somos
                              </a>
                              <a smooth href="#benefits">
                                Beneficios
                              </a>
                              <a smooth href="#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/branches">Sucursales</Link>
                              <a smooth href="/mobility#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="/mobility#us">
                                Quienes Somos
                              </a>
                              <a href="/mobility#benefits">Beneficios</a>
                              <a href="/mobility#posts">Publicaciones</a>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          modal={visible}
                          boton={false}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
        </section>
      </Layout>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
          fontSize: "32px",
          fontWeight: "600",
        }}
      >
        <Col style={{ fontSize: biggerThan900 ? "32px" : "21px" }}>
          AVISOS LEGALES
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: biggerThan900 ? "50%" : "90%",
          }}
        >
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            1. Términos Generales.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            El acceso y uso total en todo el territorio chileno del sitio
            www.gamaleasing.cl se regirá por los presentes Términos y
            Condiciones Generales de Uso y por la legislación chilena. Estos
            serán aplicables a toda persona que visiten y realicen cualquier
            transacción a través del sitio.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
            }}
          >
            En cuanto al uso y contratación de servicios de Gama Leasing
            Operativo Spa., en adelante “Gama”, toda persona (“usuario”) que
            utilice o contrate servicios implica la aceptación con carácter
            obligatorio de los Términos y Políticas de Privacidad.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
              marginTop: "5px",
            }}
          >
            2. Condiciones de acceso y uso del Sitio.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La contratación y uso de servicio del sitio gamaleasing.cl está
            limitado a personas que estén habilitadas legalmente.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La persona o usuario está obligado a proporcionar información
            fidedigna, verdadera y vigente en la solicitud de servicios. Si la
            información debiese ser cambiada por diversos motivos, debe
            comunicarse con la empresa “Gama” para la actualización de dichos
            datos. De igual manera, el usuario está consciente de que la empresa
            corroborará si la información entregada es veraz y correcta. La
            empresa no se hace responsable por la información errónea entregada
            por el usuario o cliente.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La creación de una cuenta en el Sitio (Renting) o (Gama Rent)
            requerirá varios campos de carácter obligatorio y la elección de una
            contraseña de carácter personal e intransferible. La
            confidencialidad y uso de la misma es responsabilidad total del
            usuario. En el mismo sentido, el usuario será responsable de las
            transacciones realizadas a través de su cuenta. En caso de que esté
            en riesgo la seguridad de su cuenta y contraseña comunicarse con la
            empresa a través del correo electrónico contacto@gamaleasing.cl.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            {" "}
            3. Propiedad Intelectual.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            Todo material gráfico, video, sonidos, información, marca, logo,
            claims y, en general, todo material aquí expuesto o publicado es
            propiedad de Gama Leasing Operativo Spa. Protegido así por las leyes
            chilenas e internacionales de propiedad intelectual de contenido.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "5px",
              fontSize: "16px",
            }}
          >
            Se podrá modificar sin aviso previo y en cualquier momento el
            material y configuración del Sitio.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            4. Domicilio de la empresa.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La empresa tiene fijado su domicilio en Américo Vespucio Norte
            Express 1300, Pudahuel – Santiago.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            5. Modificaciones de los Términos.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
              marginBottom: "50px",
            }}
          >
            La empresa se reserva el derecho de modificación de cualquiera de
            los términos anteriormente mencionados sin previo aviso, los cuales
            entrarán en vigencia inmediatamente al concretarse su modificación.
          </Col>
        </Row>
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default LegalWarning;
