import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CaretDownOutlined,
  EllipsisOutlined,
  PlusOutlined,
  FileDoneOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Drawer,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment/dist/moment";
import { useSelector } from "react-redux";
import { getCustomersFilter } from "../../store/customers";
import Button from "../../components/Button";
import { isSuperAdmin, isExecutive, isSupervisor } from "../../utils/roles";
import requestLogo from "../../assets/img/requests.png";
import AppLayout from "../../components/layouts/AppLayout";
import useTableQuery from "../../hooks/useTableQuery";
import Notifications from "../../components/Notifications";
import * as DOMPurify from "dompurify";
import { getUserData } from "../../store/auth";
import { useMediaPredicate } from "react-media-hook";
import circleTable from "../../assets/img/circletable.png";
import circleTable2 from "../../assets/img/circletable2.png";
import Dropdown from "../../components/Dropdown";
import {
  deleteRequest,
  getAllRequests,
  updateRequest,
} from "../../requests/requests";
import RequestForm from "./RequestForm";
import { getDevicesId } from "../../requests/vehicles";

export default function Requests() {
  const [data, setData] = useState([]);
  const { height, width } = useWindowDimensions();
  const { customers } = useSelector(getCustomersFilter);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTerminateOpen, setIsTerminateOpen] = useState(false);
  const [requestState, setRequestState] = useState();
  const [open, setOpen] = useState(false);
  const userData = useSelector(getUserData);
  const { accesses } = useSelector(getUserData);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [showCustomers, setShowCustomers] = useState(false);
  const [requestFilter, setRequestFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [devices, setDevices] = useState();
  const [total, setTotal] = useState(0);
  const [inputValue, setInputValue] = useState("");

  const [OpenDrawer, setOpenDrawer] = useState(false);
  const handleFilters = (key, value) => {
    setFilters((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const handleSearch = (value) => {
    setInputValue(value);
    setCurrentPage(1);
    let cleanValue = DOMPurify.sanitize(value);
    handleFilters("keyword", cleanValue.replace(/\./g, ""));
  };
  const handleFilterByStatus = (value) => handleFilters("status", value);
  const handleFilterByCustomer = (value) => handleFilters("customer", value);
  const handleFilterByReason = (value) => handleFilters("reason", value);
  const handleClearFilters = () => {
    setFilters({});
    setInputValue("");
  };

  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    onSearch,
    query,
    setCurrentPage,
  } = useTableQuery();

  moment.locale("es");

  useEffect(() => {
    const fetchDevicesId = async () => {
      try {
        const { data } = await getDevicesId();
        setDevices(data);
      } catch (error) {
        console.error(error);
        Notifications.error(
          "Error al cargar",
          "Hubo un error al cargar las solicitudes."
        );
      }
    };
    fetchDevicesId();
  }, [accesses]);

  const requestObj = useMemo(() => {
    return data?.requests?.map((request) => ({
      id: request.id,
      deviceId: request.deviceId,
      rentId: request.rentId,
      customerName: request.customerName,
      requestDate: request.requestDate,
      reason: request.reason,
      resolutionDate: request.resolutionDate,
      status: request.status,
      user: request.user,
      isExpandable: true,
    }));
  }, [data]);

  const memoizedFetchRequests = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getAllRequests(filters, currentPage, query);
      setTotal(data?.count);
      setData(data);
    } catch (error) {
      console.error(error);
      Notifications.error(
        "Error al cargar",
        "Hubo un error al cargar las solicitudes."
      );
    } finally {
      setLoading(false);
    }
  }, [filters, currentPage, query, accesses]);

  useEffect(() => {
    memoizedFetchRequests();
  }, [memoizedFetchRequests, query, accesses]);

  const onClearFilters = () => {
    handleClearFilters();
  };

  const showTerminate = (record) => {
    setRequestState(record);
    setIsTerminateOpen(true);
  };

  React.useEffect(() => {
    setShowCustomers(isSuperAdmin(accesses));
  }, [userData.type]);

  const showModal = (record) => {
    setRequestState(record);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsTerminateOpen(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const { Text } = Typography;

  let expandedRowKeys = [];

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.id;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  const handleStatusChange = async (id) => {
    const requestBody = { status: "Terminado" };
    try {
      await updateRequest(id, requestBody);

      Notifications.success(
        "Cambio de estado con éxito",
        `Se ha cambiado el estado de la solicitud ${id} exitosamente.`
      );
      memoizedFetchRequests();
      setIsModalOpen(false);
      setIsTerminateOpen(false);
    } catch (error) {
      Notifications.error(
        "Error al cargar",
        "Hubo un error al cambiar el estado de la solicitud."
      );
      console.error(error);
    }
  };

  const handleTerminateChange = async (id) => {
    try {
      await deleteRequest(id);
      Notifications.success(
        "Solicitud eliminada con éxito",
        `Se ha eliminado la solicitud ${id} exitosamente.`
      );
      memoizedFetchRequests();
      setIsModalOpen(false);
      setIsTerminateOpen(false);
    } catch (error) {
      Notifications.error(
        "Error al cargar",
        "Hubo un error al eliminar la solicitud."
      );
      console.error(error);
    }
  };

  const renderMenu = (record) => {
    return (
      <Menu>
        {userData.type === "CLIENT" ? (
          <Menu.Item onClick={() => showTerminate(record)} key={"1"}>
            Eliminar solicitud
          </Menu.Item>
        ) : (
          <>
            {isExecutive(accesses) || isSupervisor(accesses) ? (
              <Menu.Item onClick={() => showModal(record)} key={"2"}>
                Cambiar estado
              </Menu.Item>
            ) : (
              <>
                <Menu.Item onClick={() => showModal(record)} key={"1"}>
                  Cambiar estado
                </Menu.Item>
                <Menu.Item onClick={() => showTerminate(record)} key={"2"}>
                  Eliminar solicitud
                </Menu.Item>
              </>
            )}
          </>
        )}
      </Menu>
    );
  };

  const mobileColumns = [
    {
      key: "id",
      title: (
        <Text style={{ fontSize: "12px" }} strong>
          Nº solicitud
        </Text>
      ),
      dataIndex: "id",
      width: "40%",
      sorter: {
        compare: (a, b) => a.id?.toString().localeCompare(b.id.toString()),
      },
    },
    {
      key: "customerName",
      title: (
        <Text style={{ fontSize: "13px", width: "100%" }} strong>
          Empresa
        </Text>
      ),
      dataIndex: "customerName",
      sorter: {
        compare: (a, b) => a?.customerName?.localeCompare(b?.customerName),
      },
      render: (text, record) => {
        return <Text style={{ fontSize: "13px" }}>{record.customerName}</Text>;
      },
    },
  ];

  const columns = [
    {
      title: <Text strong>Nº Solicitud</Text>,
      dataIndex: "id",
      key: "id",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a.id?.toString().localeCompare(b.id.toString()),
      },
    },
    {
      title: <Text strong>Orden de alquiler</Text>,
      dataIndex: "rentId",
      key: "rentId",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.rentId?.localeCompare(b?.rentId),
      },
    },
    {
      title: <Text strong>Empresa</Text>,
      dataIndex: "customerName",
      key: "customerName",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.customerName?.localeCompare(b?.customerName),
      },
    },
    {
      title: <Text strong>Fecha de solicitud</Text>,
      dataIndex: "requestDate",
      key: "requestDate",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.requestDate?.localeCompare(b?.requestDate),
      },
      render: (text, record) => {
        return (
          <Text>
            {record?.requestDate === null
              ? "-"
              : moment(record?.requestDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
          </Text>
        );
      },
    },
    {
      title: <Text strong>Patente</Text>,
      dataIndex: "deviceId",
      key: "deviceId",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.deviceId?.localeCompare(b?.deviceId),
      },
    },
    {
      title: <Text strong>Motivo</Text>,
      dataIndex: "reason",
      key: "reason",
      align: "left",
      sorter: {
        compare: (a, b) => a?.reason?.localeCompare(b?.reason),
      },
    },
    {
      title: <Text strong>Solicitante</Text>,
      dataIndex: "user",
      key: "user",
      align: "left",
      sorter: {
        compare: (a, b) => a?.user?.localeCompare(b?.user),
      },
    },
    {
      title: <Text strong>Fecha resolucion </Text>,
      dataIndex: "resolutionDate",
      key: "resolutionDate",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.resolutionDate?.localeCompare(b?.resolutionDate),
      },
      render: (text, record) => {
        return (
          <Text>
            {record?.resolutionDate === null
              ? "-"
              : moment(record?.resolutionDate, "DD-MM-YYYY").format(
                  "DD/MM/YYYY"
                )}
          </Text>
        );
      },
    },

    {
      title: <Text strong>Estado</Text>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
      },
      render: (text, record) => {
        return (
          <span
            style={{
              backgroundColor:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "#FAFAFA"
                  : "#FFFBE6",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "1px solid #BFBFBF"
                  : "1px solid #FFE58F",
              color:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "#434343"
                  : "#FAAD14",
            }}
          >
            {record?.status}
          </span>
        );
      },
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={renderMenu(record)} placement="bottomLeft">
          <Button className="action-table-button">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleFilterRequest = (value) => {
    setRequestFilter(value);
  };
  const clientColumns = [
    {
      title: <Text strong>Nº Solicitud</Text>,
      dataIndex: "id",
      key: "id",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.id?.toString().localeCompare(b?.id?.toString()),
      },
    },
    {
      title: <Text strong>Fecha de solicitud</Text>,
      dataIndex: "requestDate",
      key: "requestDate",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.requestDate?.localeCompare(b?.requestDate),
      },
      render: (text, record) => {
        return (
          <Text>
            {record?.requestDate === null
              ? "-"
              : moment(record?.requestDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
          </Text>
        );
      },
    },
    {
      title: <Text strong>Patente</Text>,
      dataIndex: "deviceId",
      key: "deviceId",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.deviceId?.localeCompare(b?.deviceId),
      },
    },
    {
      title: <Text strong>Motivo</Text>,
      dataIndex: "reason",
      key: "reason",
      align: "left",
      sorter: {
        compare: (a, b) => a?.reason?.localeCompare(b?.reason),
      },
    },
    {
      title: <Text strong>Solicitante</Text>,
      dataIndex: "customerName",
      key: "customerName",
      align: "left",
      sorter: {
        compare: (a, b) => a?.customerName?.localeCompare(b?.customerName),
      },
    },
    {
      title: <Text strong>Fecha resolucion </Text>,
      dataIndex: "resolutionDate",
      key: "resolutionDate",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.resolutionDate?.localeCompare(b?.resolutionDate),
      },
      render: (text, record) => {
        return (
          <Text>
            {record?.resolutionDate === null
              ? "-"
              : moment(record?.resolutionDate, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}
          </Text>
        );
      },
    },

    {
      title: <Text strong>Estado</Text>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      render: (text, record) => {
        return (
          <span
            style={{
              backgroundColor:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "#FAFAFA"
                  : "#FFFBE6",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "1px solid #BFBFBF"
                  : "1px solid #FFE58F",
              color:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "#434343"
                  : "#FAAD14",
            }}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: "Acciones",
      key: "action",
      render: (role) => (
        <Dropdown overlay={renderMenu(role)} placement="bottomLeft">
          <Button className="action-table-button">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const executiveColumns = [
    {
      title: <Text strong>Nº Solicitud</Text>,
      dataIndex: "id",
      key: "id",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.id?.toString()?.localeCompare(b?.id?.toString()),
      },
    },
    {
      title: <Text strong>Orden de alquiler</Text>,
      dataIndex: "rentId",
      key: "rentId",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.rentId.localeCompare(b?.rentId),
      },
    },
    {
      title: <Text strong>Fecha de solicitud</Text>,
      dataIndex: "requestDate",
      key: "requestDate",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.requestDate?.localeCompare(b?.requestDate),
      },
      render: (text, record) => {
        return (
          <Text>
            {record?.requestDate === null
              ? "-"
              : moment(record?.requestDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
          </Text>
        );
      },
    },
    {
      title: <Text strong>Patente</Text>,
      dataIndex: "deviceId",
      key: "deviceId",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.deviceId?.localeCompare(b?.deviceId),
      },
    },
    {
      title: <Text strong>Motivo</Text>,
      dataIndex: "reason",
      key: "reason",
      align: "left",
      sorter: {
        compare: (a, b) => a?.reason?.localeCompare(b?.reason),
      },
    },
    {
      title: <Text strong>Solicitante</Text>,
      dataIndex: "customerName",
      key: "customerName",
      align: "left",
      sorter: {
        compare: (a, b) => a?.customerName?.localeCompare(b?.customerName),
      },
    },
    {
      title: <Text strong>Fecha resolucion </Text>,
      dataIndex: "resolutionDate",
      key: "resolutionDate",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a?.resolutionDate?.localeCompare(b?.resolutionDate),
      },
      render: (text, record) => {
        return (
          <Text>
            {record?.resolutionDate === null
              ? "-"
              : moment(record?.resolutionDate, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}
          </Text>
        );
      },
    },

    {
      title: <Text strong>Estado</Text>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
      },
      render: (text, record) => {
        return (
          <span
            style={{
              backgroundColor:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "#FAFAFA"
                  : "#FFFBE6",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "1px solid #BFBFBF"
                  : "1px solid #FFE58F",
              color:
                record?.status?.toUpperCase() === "TERMINADO"
                  ? "#434343"
                  : "#FAAD14",
            }}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: "Acciones",
      key: "action",
      render: (record, text) => (
        <Dropdown
          trigger={["click"]}
          overlay={renderMenu(record)}
          placement="bottomLeft"
        >
          <Button className="action-table-button">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const { Search } = Input;
  return (
    <>
      <AppLayout boton={true} title="Solicitudes">
        <div className="container-vehicles-table">
          {width <= 640 ? (
            <>
              <Row
                span={4}
                // align="start"
                // justify="center"
              >
                {userData.type === "CLIENT" ? null : (
                  <Col span={24}>
                    <Search
                      placeholder="Buscar Solicitud"
                      onSearch={handleSearch}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      style={{
                        width: "100%",
                        marginTop: "16px",
                      }}
                    />
                  </Col>
                )}
                <Row style={{ width: "100%" }} align="middle">
                  <Col
                    onClick={onOpenDrawer}
                    style={{
                      backgroundColor: "rgba(255, 50, 0, 0.06)",
                      padding: "2px 8px 2px 8px",
                      borderRadius: "6px",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <img alt="filter-request" src={requestLogo} />
                  </Col>

                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "80%",
                    }}
                  >
                    <Button
                      style={{
                        width: biggerThan900 ? "" : "100%",
                        marginTop: biggerThan900 ? "" : "15px",
                        marginBottom: biggerThan900 ? "" : "15px",
                      }}
                      type="primary"
                      onClick={showDrawer}
                      icon={<PlusOutlined />}
                    >
                      Nueva solicitud
                    </Button>
                  </Col>
                </Row>
                {/* <Col>
              <Button
                onClick={() => showDrawer}
                style={{
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                  color: "#FF3200",
                  fontSize: "20px",
                  border: "none",
                  padding: "6px 10px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                }}
                icon={<FunnelPlotOutlined />}
              />
            </Col> */}
              </Row>

              <Table
                columns={mobileColumns}
                expandedRowRender={(record) => (
                  <>
                    <Row>
                      {isSuperAdmin(accesses) ? (
                        <>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Orden de alquiler</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.id}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Patente</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.deviceId}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha Solicitud</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.requestDate ? record?.requestDate : "-"}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Motivo</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.reason}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Solicitante</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.customerName}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha Resolucion</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.resolutionDate
                              ? record?.resolutionDate
                              : "-"}
                          </Col>
                          <Col span={14}>
                            <Text style={{ fontSize: "15px" }} strong>
                              Estado
                            </Text>
                          </Col>
                        </>
                      ) : isExecutive(accesses) ? (
                        <>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Nº de orden</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.id}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha solicitud</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.requestDate ? record?.requestDate : "-"}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Patente</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.deviceId}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Solicitante</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.customerName}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Motivo</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.reason}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha resolución</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.resolutionDate
                              ? record?.resolutionDate
                              : "'"}
                          </Col>
                          <Col span={14}>
                            <Text style={{ fontSize: "15px" }} strong>
                              Estado
                            </Text>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Motivo</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.reason}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Solicitante</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.customerName}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Motivo</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.reason}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha solicitud</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.requestDate ? record?.requestDate : "-"}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha Resolucion</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.resolutionDate
                              ? record?.resolutionDate
                              : "-"}
                          </Col>
                          <Col span={14}>
                            <Text style={{ fontSize: "15px" }} strong>
                              Estado
                            </Text>
                          </Col>
                        </>
                      )}
                      <Col
                        style={{
                          backgroundColor:
                            record?.status?.toUpperCase() === "TERMINADO"
                              ? "#FAFAFA"
                              : "#FFFBE6",
                          padding: "4px 7px 4px 7px",
                          borderRadius: "6px",
                          border:
                            record?.status?.toUpperCase() === "TERMINADO"
                              ? "1px solid #BFBFBF"
                              : "1px solid #FFE58F",
                          color:
                            record?.status?.toUpperCase() === "TERMINADO"
                              ? "#434343"
                              : "#FAAD14",
                        }}
                      >
                        {record?.status}
                      </Col>

                      <Col span={10}>{record?.deviceGroupId}</Col>
                    </Row>
                    <Row style={{ marginTop: "15px" }} justify="space-evenly">
                      <Col xs={10}>
                        {isSupervisor(accesses) ||
                        isExecutive(accesses) ||
                        isSuperAdmin(accesses) ? (
                          <Button
                            style={{ width: "130px", fontSize: "10px" }}
                            className="gama-outline"
                            onClick={() => showModal(record)}
                          >
                            Cambiar Estado
                          </Button>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        {userData.type === "CLIENT" ||
                        isSuperAdmin(accesses) ? (
                          <Button
                            style={{
                              width: "130px",
                              marginLeft: "10px",
                              fontSize: "10px",
                            }}
                            className="gama-outline"
                            onClick={() => showTerminate(record)}
                          >
                            Eliminar Solicitud
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
                expandIcon={(props) => {
                  if (props.record.isExpandable) {
                    if (props.expanded) {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <MinusSquareOutlined
                            style={{ width: "30px" }}
                            onClick={(e) => {
                              props.onExpand(props.record, e);
                            }}
                          />
                          <span>
                            {props?.record?.status.toUpperCase() ===
                            "EN PROCESO" ? (
                              <img src={circleTable} />
                            ) : (
                              <img src={circleTable2} />
                            )}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <PlusSquareOutlined
                            style={{ width: "30px" }}
                            onClick={(e) => {
                              props.onExpand(props.record, e);
                            }}
                          />
                          <span>
                            {props?.record?.status.toUpperCase() ===
                            "EN PROCESO" ? (
                              <img src={circleTable} />
                            ) : (
                              <img src={circleTable2} />
                            )}
                          </span>
                        </div>
                      );
                    }
                  }
                }}
                rowClassName={(record) =>
                  record?.isExpandable ? "show" : "hidden"
                }
                expandedRowKeys={expandedRowKeys}
                onExpand={handleRowKeys}
                rowKey={(record) => record?.id}
                dataSource={requestObj}
                showSorterTooltip={false}
                pagination={{
                  current: currentPage,
                  pageSize,
                  total,
                  position: ["bottomCenter"],
                }}
                onChange={handlePaginationChange}
                sortDirection={["ASC", "DESC"]}
              />
            </>
          ) : (
            <>
              <Row
                justify="space-between"
                style={{
                  marginBottom: "20px",
                  marginTop: "15px",
                  width: "100%",
                }}
              >
                <Row>
                  {userData.type === "CLIENT" ? null : (
                    <Col sm={6}>
                      <Search
                        placeholder="Buscar solicitud"
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                        onSearch={handleSearch}
                        style={{
                          width: 220,
                          paddingLeft: "15px",
                          marginRight: "15px",
                        }}
                      />
                    </Col>
                  )}
                  <Divider
                    style={{
                      height: "40px",
                      marginRight: "20px",
                      marginLeft: "20px",
                    }}
                    type="vertical"
                  />
                  <Col>
                    {showCustomers && (
                      <Select
                        suffixIcon={<CaretDownOutlined />}
                        value={filters.customer || undefined}
                        showSearch
                        onChange={(value) => {
                          handleFilterByCustomer(value);
                          setCurrentPage(1);
                        }}
                        style={{
                          width: 200,
                          borderRadius: "50px",
                          marginRight: "20px",
                        }}
                        placeholder="Empresa"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label.toLowerCase() ?? "").includes(
                            input.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={customers.map((customer) => {
                          return {
                            label: customer.name,
                            value: customer.name,
                          };
                        })}
                      />
                    )}
                  </Col>
                  <Col>
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      value={filters?.status || undefined}
                      placeholder="Estado"
                      style={{
                        width: 150,
                        borderRadius: "50px",
                        marginRight: "20px",
                      }}
                      onChange={(value) => {
                        handleFilterByStatus(value);
                        setCurrentPage(1);
                      }}
                    >
                      <Select.Option value="En proceso">
                        En Proceso
                      </Select.Option>
                      <Select.Option value="Terminado">Terminado</Select.Option>
                    </Select>
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      value={filters?.reason || undefined}
                      placeholder="Motivo"
                      style={{
                        width: 150,
                        borderRadius: "50px",
                        marginRight: "20px",
                      }}
                      onChange={(value) => {
                        handleFilterByReason(value);
                        setCurrentPage(1);
                      }}
                    >
                      <Select.Option value="Copia contrato">
                        Copia contrato
                      </Select.Option>
                      <Select.Option value="Envío anexo">
                        Envío Anexo
                      </Select.Option>
                      <Select.Option value="Copia llaves">
                        Copia de llave
                      </Select.Option>
                      <Select.Option value="Carta combustible">
                        Carta combustible
                      </Select.Option>
                      <Select.Option value="Certificado multa">
                        Certificado multa
                      </Select.Option>
                      <Select.Option value="Certificado servicios">
                        Certificado servicios
                      </Select.Option>
                      <Select.Option value="Agendamiento">
                        Agendamiento
                      </Select.Option>
                      <Select.Option value="Otros">Otros</Select.Option>
                    </Select>
                  </Col>
                  <Col>
                    <a
                      style={{
                        border: "none",
                        textAlign: "bottom",
                        color: "#FF3200",
                        textDecoration: "underline",
                        fontSize: "16px",
                      }}
                      onClick={onClearFilters}
                    >
                      Limpiar
                    </a>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      type="primary"
                      style={{
                        height: "40px",
                        // width: "100%",
                      }}
                      onClick={showDrawer}
                      // onClick={onOpenForm}
                      icon={<PlusOutlined />}
                    >
                      Nueva solicitud
                    </Button>
                  </Col>
                </Row>
              </Row>

              <Table
                className="sius"
                dataSource={requestObj || []}
                onSearch={onSearch}
                loading={loading}
                pagination={{
                  current: currentPage,
                  pageSize,
                  total: total || 1,
                  position: ["bottomCenter"],
                }}
                onChange={handlePaginationChange}
                sortDirection={["ASC", "DESC"]}
                columns={
                  userData.type === "CLIENT"
                    ? clientColumns
                    : isExecutive(accesses)
                    ? executiveColumns
                    : columns
                }
                scroll={{ x: "max-content" }}
                showSorterTooltip={false}
              />
            </>
          )}
          {/* <DocumentsDetails
          vehicleData={vehicleData}
          open={showVehicleDocuments}
          onClose={handleDrawerClose}
        /> */}

          <Modal
            bodyStyle={{ borderRadius: "60px" }}
            footer={false}
            closable={false}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: biggerThan900 ? "20px" : "15px",
                    }}
                  >
                    ¿Quieres cambiar estado de solicitud?
                  </span>
                  <Col>
                    <CloseOutlined
                      onClick={() => setIsModalOpen(false)}
                      style={{
                        color: "#FF3200",
                        backgroundColor: "rgba(255, 50, 0, 0.06)",
                        padding: "7px 7px 7px 7px",
                        marginRight: "15px",
                        fontSize: "16px",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
              </>
            }
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FileDoneOutlined
                style={{
                  color: "#262626",
                  backgroundColor: "#F0F0F0",
                  padding: "7px 7px 7px 7px",
                  marginRight: "15px",
                  fontSize: "26px",
                  borderRadius: "6px",
                }}
              />
              <div style={{ lineHeight: "5px" }}>
                <Text strong style={{ margin: "0" }}>
                  Nº de orden: {requestState?.id}
                </Text>
                <div style={{ display: "flex" }}>
                  <p style={{ margin: "0" }}>
                    {requestState?.customerName?.toUpperCase()}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px", fontWeight: "600" }}>
              Estado actual:{" "}
              <span
                style={{
                  marginLeft: "6px",
                  backgroundColor:
                    requestState?.status?.toUpperCase() === "TERMINADO"
                      ? "#FAFAFA"
                      : "#FFFBE6",
                  padding: "4px 7px 4px 7px",
                  borderRadius: "6px",
                  border:
                    requestState?.status?.toUpperCase() === "TERMINADO"
                      ? "1px solid #BFBFBF"
                      : "1px solid #FFE58F",
                  color:
                    requestState?.status?.toUpperCase() === "TERMINADO"
                      ? "#434343"
                      : "#FAAD14",
                }}
              >
                {requestState?.status}
              </span>
            </div>
            <Row
              justify="start"
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              <Col>
                <Button
                  onClick={handleCancel}
                  style={{ height: "40px" }}
                  className="gama-outline"
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => handleStatusChange(requestState?.id)}
                  style={{ marginLeft: "16px", height: "40px" }}
                >
                  Terminar
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            bodyStyle={{ borderRadius: "60px" }}
            footer={false}
            closable={false}
            title={
              <>
                <Row align="middle" justify="space-between">
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: biggerThan900 ? "20px" : "15px",
                    }}
                  >
                    ¿Estas seguro de eliminar la solicitud?
                  </span>
                  <Col>
                    <CloseOutlined
                      onClick={() => setIsTerminateOpen(false)}
                      style={{
                        color: "#FF3200",
                        backgroundColor: "rgba(255, 50, 0, 0.06)",
                        padding: "7px 7px 7px 7px",
                        marginRight: "15px",
                        fontSize: "16px",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
              </>
            }
            visible={isTerminateOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FileDoneOutlined
                style={{
                  color: "#262626",
                  backgroundColor: "#F0F0F0",
                  padding: "7px 7px 7px 7px",
                  marginRight: "15px",
                  fontSize: "26px",
                  borderRadius: "6px",
                }}
              />
              <div style={{ lineHeight: "5px" }}>
                <Text strong style={{ margin: "0" }}>
                  Nº de orden: {requestState?.id}
                </Text>
                <div style={{ display: "flex" }}>
                  <p style={{ margin: "0" }}>
                    {requestState?.customerName?.toUpperCase()}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px", fontWeight: "600" }}>
              Estado actual:{" "}
              <span
                style={{
                  marginLeft: "6px",
                  backgroundColor:
                    requestState?.status?.toUpperCase() === "TERMINADO"
                      ? "#FAFAFA"
                      : "#FFFBE6",
                  padding: "4px 7px 4px 7px",
                  borderRadius: "6px",
                  border:
                    requestState?.status?.toUpperCase() === "TERMINADO"
                      ? "1px solid #BFBFBF"
                      : "1px solid #FFE58F",
                  color:
                    requestState?.status?.toUpperCase() === "TERMINADO"
                      ? "#434343"
                      : "#FAAD14",
                }}
              >
                {requestState?.status}
              </span>
            </div>
            <Row justify={biggerThan900 ? "" : "center"}>
              <Row
                justify={biggerThan900 ? "start" : "center"}
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  flexDirection: biggerThan900 ? "" : "column",
                }}
              >
                <Col>
                  <Button
                    onClick={handleCancel}
                    style={{ height: "40px", width: "172px" }}
                    className="gama-outline"
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleTerminateChange(requestState?.id)}
                    style={{
                      marginLeft: biggerThan900 ? "16px" : "",
                      height: "40px",
                      marginTop: biggerThan900 ? "0px" : "10px",
                      width: "172px",
                    }}
                  >
                    Eliminar
                  </Button>
                </Col>
              </Row>
            </Row>
          </Modal>
          <RequestForm
            data={devices}
            handleFilterRequest={handleFilterRequest}
            requestFilter={requestFilter}
            onClose={onClose}
            open={open}
            memoizedFetchRequests={memoizedFetchRequests}
          />
        </div>
      </AppLayout>

      <Drawer
        width="100%"
        title={
          <>
            <Row align="middle" justify="space-between">
              <span
                style={{
                  fontWeight: "600",
                  fontSize: biggerThan900 ? "20px" : "15px",
                }}
              >
                Filtros
              </span>
              <Col>
                <CloseOutlined
                  onClick={onCloseDrawer}
                  style={{
                    color: "#FF3200",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                    padding: "7px 7px 7px 7px",
                    marginRight: "15px",
                    fontSize: "16px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </Row>
          </>
        }
        placement="right"
        closable={false}
        onClose={onCloseDrawer}
        open={OpenDrawer}
      >
        <Row>
          <Col
            xs={24}
            style={{
              width: "100%",
              borderRadius: "50px",
              marginRight: "20px",
              marginBottom: "16px",
            }}
          >
            {showCustomers && (
              <Select
                suffixIcon={<CaretDownOutlined />}
                value={filters.customer || undefined}
                showSearch
                onChange={(value) => {
                  handleFilterByCustomer(value);
                  setCurrentPage(1);
                }}
                style={{
                  width: "100%",
                  borderRadius: "50px",
                  marginRight: "20px",
                }}
                placeholder="Empresa"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={customers.map((customer) => {
                  return {
                    label: customer.name,
                    value: customer.name,
                  };
                })}
              />
            )}
          </Col>
          <Col
            xs={24}
            style={{
              width: "100%",
              borderRadius: "50px",
              marginRight: "20px",
              marginBottom: "16px",
            }}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              value={filters?.status || undefined}
              placeholder="Estado"
              style={{
                width: "100%",
                borderRadius: "50px",
                marginRight: "20px",
              }}
              onChange={(value) => {
                handleFilterByStatus(value);
                setCurrentPage(1);
              }}
            >
              <Select.Option value="En proceso">En Proceso</Select.Option>
              <Select.Option value="Terminado">Terminado</Select.Option>
            </Select>
          </Col>
          <Col
            xs={24}
            style={{
              width: "100%",
              borderRadius: "50px",
              marginRight: "20px",
              marginBottom: "16px",
            }}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              value={filters?.reason || undefined}
              placeholder="Motivo"
              style={{
                width: "100%",
                borderRadius: "50px",
                marginRight: "20px",
              }}
              onChange={(value) => {
                handleFilterByReason(value);
                setCurrentPage(1);
              }}
            >
              <Select.Option value="Copia contrato">
                Copia contrato
              </Select.Option>
              <Select.Option value="Envío anexo">Envío Anexo</Select.Option>
              <Select.Option value="Copia llaves">Copia de llave</Select.Option>
              <Select.Option value="Carta combustible">
                Carta combustible
              </Select.Option>
              <Select.Option value="Certificado multa">
                Certificado multa
              </Select.Option>
              <Select.Option value="Certificado servicios">
                Certificado servicios
              </Select.Option>
              <Select.Option value="Agendamiento">Agendamiento</Select.Option>
            </Select>
          </Col>
        </Row>
        <Button
          onClick={onCloseDrawer}
          className="gama-button"
          style={{
            width: "100%",
            marginTop: "56px",
            marginBottom: "16px",
            color: "white",
          }}
        >
          Aplicar Filtros
        </Button>

        <Button
          onClick={onClearFilters}
          className="gama-outline"
          style={{ width: "100%" }}
        >
          Limpiar Filtros
        </Button>
      </Drawer>
    </>
  );
}
