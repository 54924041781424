import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CaretDownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Drawer,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import { Option } from "antd/lib/mentions";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment/dist/moment";
import { useSelector } from "react-redux";
import { getCustomers } from "../../store/customers";
import Button from "../../components/Button";
import circleTable2 from "../../assets/img/circletable2.png";
import circleTable33 from "../../assets/img/circleTable33.png";
import AppLayout from "../../components/layouts/AppLayout";
import requestLogo from "../../assets/img/requests.png";
import * as DOMPurify from "dompurify";

import useTableQuery from "../../hooks/useTableQuery";
import DocumentsDetails from "./DocumentsDetails";
import documentsIcon from "../../assets/img/documents.png";
import { getUserData } from "../../store/auth";
import { isSuperAdmin } from "../../utils/roles";
import { getAllDocuments } from "../../requests/vehicles";
import { useMediaPredicate } from "react-media-hook";
import { getAllUserCust } from "../../requests/users";

export default function Documents() {
  const [vehicleData, setVehicleData] = useState([]);
  const { height, width } = useWindowDimensions();
  const [showVehicleDocuments, setShowVehicleDocuments] = useState(false);
  const customers = useSelector(getCustomers);
  const [filters, setFilters] = useState({});
  const userData = useSelector(getUserData);
  const { accesses } = useSelector(getUserData);
  const [inputValue, setInputValue] = useState("");
  const [allCustomers, setAllCustomers] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    onSearch,
    query,
    setCurrentPage,
  } = useTableQuery();

  moment.locale("es");

  const handleFilters = (key, value) => {
    setFilters((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const handleSearch = (value) => {
    setCurrentPage(1);
    let cleanValue = DOMPurify.sanitize(value);
    handleFilters("keyword", cleanValue);
    setInputValue(value);
  };
  const handleFilterByStatus = (value) => handleFilters("status", value);
  const handleFilterByCustomer = (value) => handleFilters("customer", value);
  const handleClearFilters = () => {
    setFilters({});
    setInputValue("");
  };
  const formCustomers = customers.customers ? customers.customers : customers;
  const [OpenDrawer, setOpenDrawer] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const memoizedFetchRequests = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getAllDocuments(filters, currentPage, query);
      const documentsObj = data.documents.map((document) => {
        return {
          endDate: document.endDate,
          fleet: document.fleet,
          rentId: document.rentId,
          rentName: document.rentName,
          rentStatus: document.rentStatus,
          startDate: document.startDate,
          customerId: document.customerId,
          isExpandable: true,
        };
      });
      setTotal(data?.count);
      setData(documentsObj);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [filters, currentPage, query, accesses]);

  useEffect(() => {
    const getAllCust = async () => {
      try {
        const { data } = await getAllUserCust();

        setAllCustomers(data?.customers);
      } catch (error) {
        console.error(error);
      }
    };
    getAllCust();
  }, [accesses]);

  useEffect(() => {
    memoizedFetchRequests();
  }, [memoizedFetchRequests, accesses]);

  const onClearFilters = () => {
    handleClearFilters();
  };

  const { Text } = Typography;

  let expandedRowKeys = [];

  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.rentId;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  const handleVehicleDocuments = (vehicleData) => {
    setVehicleData(vehicleData);
    setShowVehicleDocuments(true);
  };

  const filteredCustomers =
    formCustomers &&
    formCustomers?.filter(
      (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
    );

  const handleDrawerClose = () => {
    setShowVehicleDocuments(false);
    setVehicleData(null);
  };

  const mobileColumns = [
    {
      key: "rentId",
      title: (
        <Text style={{ fontSize: "11px" }} strong>
          Nº de orden
        </Text>
      ),
      dataIndex: "rentId",
      width: "40%",
      sorter: {
        compare: (a, b) => a.rentId.localeCompare(b.rentId),
      },
      render: (text, record) => {
        return <Text style={{ fontSize: "11px" }}>{record.rentId}</Text>;
      },
    },
    {
      key: "rentName",
      title: (
        <Text style={{ fontSize: "11px" }} strong>
          Empresa
        </Text>
      ),
      dataIndex: "rentName",
      sorter: {
        compare: (a, b) => a?.rentName?.localeCompare(b?.rentName),
      },
      render: (text, record) => {
        return <Text style={{ fontSize: "11px" }}>{record.rentName}</Text>;
      },
    },
  ];

  const mobileColumnsClient = [
    {
      key: "rentId",
      title: <Text strong>Orden de alquiler</Text>,
      dataIndex: "rentId",
      sorter: {
        compare: (a, b) => a?.rentId?.localeCompare(b?.rentId),
      },
    },
    {
      key: "fleet",
      title: <Text strong>Flota</Text>,
      dataIndex: "fleet",
      width: "30%",
      sorter: {
        compare: (a, b) =>
          a.fleet?.toString().localeCompare(b?.fleet?.toString()),
      },
    },
  ];

  const clientColumns = [
    {
      title: <Text strong>Orden de alquiler</Text>,
      dataIndex: "rentId",
      key: "rentId",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.rentId?.localeCompare(b?.rentId),
      },
    },
    {
      title: <Text strong>Empresa</Text>,
      dataIndex: "rentName",
      key: "rentName",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.rentName?.localeCompare(b?.rentName),
      },
    },
    {
      title: <Text strong>Flota</Text>,
      dataIndex: "fleet",
      key: "fleet",
      align: "left",
      sorter: (a, b) => a?.fleet - b?.fleet,
    },

    {
      title: <Text strong>Fecha inicio </Text>,
      dataIndex: "startDate",
      key: "startDate",
      align: "left",
      width: 230,
      sorter: (a, b) => a?.startDate - b?.startDate,
    },
    {
      title: <Text strong>Fecha término</Text>,
      dataIndex: "endDate",
      key: "endDate",
      align: "left",
      width: 230,
      sorter: (a, b) => a?.endDate - b?.endDate,
    },
    {
      title: <Text strong>Estado</Text>,
      dataIndex: "rentStatus",
      key: "rentStatus",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a?.rentStatus?.localeCompare(b?.rentStatus),
      },
      render: (text, record) => {
        return (
          <span
            style={{
              backgroundColor:
                record?.rentStatus?.toUpperCase() === "ACTIVO"
                  ? "#F6FFED"
                  : "#FAFAFA",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                record?.rentStatus?.toUpperCase() === "ACTIVO"
                  ? "1px solid #B7EB8F"
                  : "1px solid #BFBFBF",
              color:
                record?.rentStatus?.toUpperCase() === "ACTIVO"
                  ? "#52C41A"
                  : "#434343",
            }}
          >
            {record.rentStatus}
          </span>
        );
      },
    },

    {
      title: <Text strong>Documentos</Text>,
      dataIndex: "documents",
      key: "documents",
      width: 130,
      align: "center",
      render: (text, record) => (
        <span
          style={{
            backgroundColor: "rgba(255, 50, 0, 0.08)",
            color: "#FF3200",
            cursor: "pointer",
            padding: "7px 7px 7px 7px",
            borderRadius: "100%",
            border: "1px solid #ff3200",
          }}
          onClick={() => handleVehicleDocuments(record)}
        >
          <img src={documentsIcon} alt="table documents icon" />
        </span>
      ),
    },
  ];

  const columns = [
    {
      title: <Text strong>Nº de orden</Text>,
      dataIndex: "rentId",
      key: "rentId",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.rentId?.localeCompare(b?.rentId),
      },
    },
    {
      title: <Text strong>Empresa</Text>,
      dataIndex: "rentName",
      key: "rentName",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a?.rentName?.localeCompare(b?.rentName),
      },
    },
    {
      title: <Text strong>Flota</Text>,
      dataIndex: "fleet",
      key: "fleet",
      align: "left",
      sorter: (a, b) => a?.fleet - b?.fleet,
    },

    {
      title: <Text strong>Fecha inicio </Text>,
      dataIndex: "startDate",
      key: "startDate",
      align: "left",
      width: 230,
      sorter: (a, b) => a?.startDate - b?.startDate,
    },
    {
      title: <Text strong>Fecha término</Text>,
      dataIndex: "endDate",
      key: "endDate",
      align: "left",
      width: 230,
      sorter: (a, b) => a?.endDate - b?.endDate,
    },
    {
      title: <Text strong>Estado</Text>,
      dataIndex: "rentStatus",
      key: "rentStatus",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a?.rentStatus?.localeCompare(b?.rentStatus),
      },
      render: (text, record) => {
        return (
          <span
            style={{
              backgroundColor:
                record?.rentStatus?.toUpperCase() === "ACTIVO"
                  ? "#F6FFED"
                  : record?.rentStatus?.toUpperCase() === "APROBADO"
                  ? "#FFFBE6"
                  : "#FAFAFA",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                record?.rentStatus?.toUpperCase() === "ACTIVO"
                  ? "1px solid #B7EB8F"
                  : record?.rentStatus?.toUpperCase() === "APROBADO"
                  ? "1px solid #FFC069"
                  : "1px solid #BFBFBF",
              color:
                record?.rentStatus?.toUpperCase() === "ACTIVO"
                  ? "#52C41A"
                  : record?.rentStatus?.toUpperCase() === "APROBADO"
                  ? "#FA8C16"
                  : "#434343",
            }}
          >
            {record?.rentStatus}
          </span>
        );
      },
    },

    {
      title: <Text strong>Documentos</Text>,
      dataIndex: "documents",
      key: "documents",
      width: 130,
      align: "center",
      render: (text, record) => (
        <span
          style={{
            backgroundColor: "rgba(255, 50, 0, 0.08)",
            color: "#FF3200",
            cursor: "pointer",
            padding: "7px 7px 7px 7px",
            borderRadius: "100%",
            border: "1px solid #ff3200",
          }}
          onClick={() => handleVehicleDocuments(record)}
        >
          <img src={documentsIcon} alt="table documents icon" />
        </span>
      ),
    },
  ];

  const { Search } = Input;

  return (
    <AppLayout customer={true} title="Documentación legal">
      <div className="container-vehicles-table">
        {width <= 640 ? (
          <>
            <Row
              span={4}
              // align="start"
              // justify="center"
              style={{
                paddingBottom: "15px",
                marginTop: "16px",
              }}
            >
              <Col span={18}>
                <Search
                  placeholder="Buscar orden de alquiler/Empresa"
                  onSearch={handleSearch}
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  style={{
                    width: "100%",
                  }}
                />
              </Col>
              <Col
                onClick={onOpenDrawer}
                style={{
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                  padding: "2px 8px 2px 8px",
                  borderRadius: "6px",

                  marginLeft: "15px",
                  cursor: "pointer",
                }}
              >
                <img alt="filter-request" src={requestLogo} />
              </Col>
            </Row>
            <Table
              columns={
                userData.type === "CLIENT" ? mobileColumnsClient : mobileColumns
              }
              expandedRowRender={(record) =>
                isSuperAdmin(accesses) ? (
                  <Row>
                    <Col span={14}>
                      <Text strong>Flota</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {record?.fleet}
                    </Col>
                    <Col span={14}>
                      <Text strong>Fecha de inicio</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {moment(record?.startDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </Col>
                    <Col span={14}>
                      <Text strong>Fecha de término</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {moment(record?.endDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </Col>
                    <Col span={14}>
                      <Text style={{ fontSize: "15px" }} strong>
                        Estado
                      </Text>
                    </Col>
                    <Col
                      style={{
                        backgroundColor:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "#F6FFED"
                            : "#FAFAFA",
                        padding: "4px 7px 4px 7px",
                        borderRadius: "6px",
                        border:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "1px solid #B7EB8F"
                            : "1px solid #BFBFBF",
                        color:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "#52C41A"
                            : "#434343",
                      }}
                    >
                      {record?.rentStatus}
                    </Col>

                    <Col span={10}>{record?.deviceId}</Col>
                    <Col style={{ marginLeft: "60px", marginTop: "15px" }}>
                      <Button onClick={() => handleVehicleDocuments(record)}>
                        Ver Documentos
                      </Button>
                    </Col>
                  </Row>
                ) : userData.type === "CLIENT" ? (
                  <Row>
                    <Col span={14}>
                      <Text strong>Fecha de inicio</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {moment(record?.startDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </Col>
                    <Col span={14}>
                      <Text strong>Fecha de término</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {moment(record?.endDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </Col>
                    <Col span={14}>
                      <Text style={{ fontSize: "15px" }} strong>
                        Estado
                      </Text>
                    </Col>
                    <Col
                      style={{
                        backgroundColor:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "#F6FFED"
                            : "#FAFAFA",
                        padding: "4px 7px 4px 7px",
                        borderRadius: "6px",
                        border:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "1px solid #B7EB8F"
                            : "1px solid #BFBFBF",
                        color:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "#52C41A"
                            : "#434343",
                      }}
                    >
                      {record?.rentStatus}
                    </Col>

                    <Col span={10}>{record?.deviceId}</Col>
                    <Col style={{ marginLeft: "60px", marginTop: "15px" }}>
                      <Button onClick={() => handleVehicleDocuments(record)}>
                        Ver Documentos
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={14}>
                      <Text strong>Flota</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {record?.fleet}
                    </Col>
                    <Col span={14}>
                      <Text strong>Fecha de inicio</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {moment(record?.startDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </Col>
                    <Col span={14}>
                      <Text strong>Fecha de término</Text>
                    </Col>
                    <Col span={10} style={{ marginBottom: "10px" }}>
                      {moment(record?.endDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </Col>
                    <Col span={14}>
                      <Text style={{ fontSize: "15px" }} strong>
                        Estado
                      </Text>
                    </Col>
                    <Col
                      style={{
                        backgroundColor:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "#F6FFED"
                            : "#FAFAFA",
                        padding: "4px 7px 4px 7px",
                        borderRadius: "6px",
                        border:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "1px solid #B7EB8F"
                            : "1px solid #BFBFBF",
                        color:
                          record?.rentStatus?.toUpperCase() === "ACTIVO"
                            ? "#52C41A"
                            : "#434343",
                      }}
                    >
                      {record?.rentStatus}
                    </Col>

                    <Col span={10}>{record?.deviceId}</Col>
                    <Col style={{ marginLeft: "60px", marginTop: "15px" }}>
                      <Button onClick={() => handleVehicleDocuments(record)}>
                        Ver Documentos
                      </Button>
                    </Col>
                  </Row>
                )
              }
              expandIcon={(props) => {
                if (props.record.isExpandable) {
                  if (props.expanded) {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <MinusSquareOutlined
                          style={{ width: "50px" }}
                          onClick={(e) => {
                            props.onExpand(props.record, e);
                          }}
                        />
                        <span>
                          {props.record?.rentStatus?.toUpperCase() ===
                          "TERMINADO" ? (
                            <img src={circleTable2} />
                          ) : (
                            <img src={circleTable33} />
                          )}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PlusSquareOutlined
                          style={{ width: "50px" }}
                          onClick={(e) => {
                            props.onExpand(props.record, e);
                          }}
                        />
                        <span>
                          {props.record?.rentStatus?.toUpperCase() ===
                          "TERMINADO" ? (
                            <img src={circleTable2} />
                          ) : (
                            <img src={circleTable33} />
                          )}
                        </span>
                      </div>
                    );
                  }
                }
              }}
              rowClassName={(record) =>
                record.isExpandable ? "show" : "hidden"
              }
              expandedRowKeys={expandedRowKeys}
              onExpand={handleRowKeys}
              rowKey={(record) => record.rentId}
              dataSource={data}
              showSorterTooltip={false}
              pagination={{
                current: currentPage,
                pageSize,
                total,
                position: ["bottomCenter"],
              }}
              onChange={handlePaginationChange}
              sortDirection={["ASC", "DESC"]}
            />
          </>
        ) : (
          <>
            <Row style={{ marginBottom: "20px", marginTop: "15px" }}>
              <Row>
                <Col sm={6}>
                  <Search
                    placeholder="Buscar orden de alquiler/Empresa"
                    onSearch={handleSearch}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                    style={{
                      width: 220,
                      paddingLeft: "15px",
                      marginRight: "15px",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Divider
                  style={{ height: "100%", marginRight: "25px" }}
                  type="vertical"
                />
                <Col>
                  {userData.type === "CLIENT" ? null : isSuperAdmin(
                      accesses
                    ) ? (
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      value={filters.customer || undefined}
                      showSearch
                      onChange={(value) => {
                        handleFilterByCustomer(value);
                        setCurrentPage(1);
                      }}
                      style={{
                        width: 250,
                        borderRadius: "50px",
                        marginRight: "20px",
                      }}
                      placeholder="Empresa"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={filteredCustomers.map((customer) => {
                        return {
                          label: customer.name,
                          value: customer.name,
                        };
                      })}
                    />
                  ) : (
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      value={filters.customer || undefined}
                      showSearch
                      onChange={(value) => {
                        handleFilterByCustomer(value);
                        setCurrentPage(1);
                      }}
                      style={{
                        width: 250,
                        borderRadius: "50px",
                        marginRight: "20px",
                      }}
                      placeholder="Empresa"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={allCustomers?.map((customer) => {
                        return {
                          label: customer.name,
                          value: customer.name,
                        };
                      })}
                    />
                  )}
                </Col>
                <Col>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={filters?.status || undefined}
                    placeholder="Estado"
                    style={{
                      width: 150,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                    onChange={(value) => {
                      handleFilterByStatus(value);
                      setCurrentPage(1);
                    }}
                  >
                    <Option value="Activo">Activo</Option>
                    <Option value="Aprobado">Aprobado</Option>
                    <Option value="Creado">Creado</Option>
                  </Select>
                </Col>

                <Col>
                  <a
                    style={{
                      border: "none",
                      textAlign: "bottom",
                      color: "#FF3200",
                      textDecoration: "underline",
                      fontSize: "16px",
                    }}
                    onClick={onClearFilters}
                  >
                    Limpiar filtros
                  </a>
                </Col>
              </Row>
            </Row>
            <Table
              className="sius"
              dataSource={data}
              onSearch={onSearch}
              loading={loading}
              pagination={{
                current: currentPage,
                pageSize,
                total,
                position: ["bottomCenter"],
              }}
              onChange={handlePaginationChange}
              sortDirection={["ASC", "DESC"]}
              columns={userData.type === "CLIENT" ? clientColumns : columns}
              scroll={{ x: "max-content" }}
              showSorterTooltip={false}
            />
          </>
        )}
        <DocumentsDetails
          vehicleData={vehicleData}
          open={showVehicleDocuments}
          onClose={handleDrawerClose}
        />

        <Drawer
          width="100%"
          title={
            <>
              <Row align="middle" justify="space-between">
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: biggerThan900 ? "20px" : "15px",
                  }}
                >
                  Filtros
                </span>
                <Col>
                  <CloseOutlined
                    onClick={onCloseDrawer}
                    style={{
                      color: "#FF3200",
                      backgroundColor: "rgba(255, 50, 0, 0.06)",
                      padding: "7px 7px 7px 7px",
                      marginRight: "15px",
                      fontSize: "16px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </>
          }
          placement="right"
          closable={false}
          onClose={onCloseDrawer}
          open={OpenDrawer}
        >
          <Row>
            {userData?.type === "CLIENT" ? null : (
              <Col
                xs={24}
                style={{
                  width: "100%",
                  borderRadius: "50px",
                  marginRight: "20px",
                  marginBottom: "16px",
                }}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  value={filters.customer || undefined}
                  showSearch
                  onChange={(value) => {
                    handleFilterByCustomer(value);
                    setCurrentPage(1);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "50px",
                    marginRight: "20px",
                  }}
                  placeholder="Empresa"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    formCustomers &&
                    formCustomers?.map((customer) => {
                      return {
                        label: customer.name,
                        value: customer.name,
                      };
                    })
                  }
                />
              </Col>
            )}

            <Col
              xs={24}
              style={{
                width: "100%",
                borderRadius: "50px",
                marginRight: "20px",
                marginBottom: "16px",
              }}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                value={filters?.status || undefined}
                placeholder="Estado"
                style={{
                  width: "100%",
                  borderRadius: "50px",
                  marginRight: "20px",
                }}
                onChange={(value) => {
                  handleFilterByStatus(value);
                  setCurrentPage(1);
                }}
              >
                <Option value="Activo">Activo</Option>
                <Option value="Aprobado">Aprobado</Option>
                <Option value="Creado">Creado</Option>
              </Select>
            </Col>
          </Row>
          <Button
            onClick={onCloseDrawer}
            className="gama-button"
            style={{
              width: "100%",
              marginTop: "56px",
              marginBottom: "16px",
              color: "white",
            }}
          >
            Aplicar Filtros
          </Button>

          <Button
            onClick={onClearFilters}
            className="gama-outline"
            style={{ width: "100%" }}
          >
            Limpiar Filtros
          </Button>
        </Drawer>
      </div>
    </AppLayout>
  );
}
