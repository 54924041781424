import {
  CaretDownOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import dayjs from "dayjs";

import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/index";
import Banner from "../../components/layouts/HomeLayout/Banner";
import Footer from "../../components/layouts/HomeLayout/Footer";
import ContactBanner from "../../assets/img/contact-banner.webp";
import { clean, format, validate } from "rut.js";
import { sendSinister, sendZohoPayload } from "../../requests/contact";
import { useLocation } from "react-router-dom";
import GoToTop from "../../components/GoToTop";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import locale from "antd/es/date-picker/locale/de_DE";

import {
  getContactHeaderContent,
  getContactSiderContent,
} from "../../store/strapi/gamaleasing";
import { getGamaLeasingContactContent } from "../../store/strapi/gamaleasing/thunks";
import axios from "axios";
import { SINISTER_API_URL } from "../../requests/url";
function SinisterDeclaration() {
  const [form] = Form.useForm();
  const search = useLocation().search;
  const utmMedium = new URLSearchParams(search).get("utm_medium") || null;
  const utmSource = new URLSearchParams(search).get("utm_source") || null;
  const utmCampaign = new URLSearchParams(search).get("utm_campaign") || null;
  const [isTheSame, setIsTheSame] = useState(false);
  const [policeInvolved, setPoliceInvolved] = useState(false);
  const [evaluation, setEvaluation] = useState(false);

  const [selected, setSelected] = useState();

  const [sinisterType, setSinisterType] = useState("");
  const [thirdParty, setThirdParty] = useState(false);
  const [thirdType, setThirdType] = useState("");
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");
  const [telf, setTelf] = useState("");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const reRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamaLeasingContactContent());
  }, []);

  const contactSider = useSelector(getContactSiderContent);

  const contactHeader = useSelector(getContactHeaderContent);
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;

  useEffect(() => {
    form.setFieldsValue({
      "vehicle-number": 1,
      phonePrefix: "56",
      "prefijo-policial": "56",
      "prefijo-declarante": "56",
      "nombre-razon-conductor": isTheSame ? name : null,
      "rut-pasaporte": isTheSame ? format(rut) : null,
      "telefono-conductor": isTheSame ? telf : null,
      email: isTheSame ? email : null,
    });
  }, [isTheSame, thirdParty]);

  const getName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };
  const getEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const getTelf = (e) => {
    e.preventDefault();
    setTelf(e.target.value);
  };

  const getRut = (e) => {
    console.log(e, "e");
    setRut(e.target.value);
  };

  console.log(rut, "rut");

  const postUrl = SINISTER_API_URL;

  const onFinish = async (values) => {
    reRef.current.reset();

    values["hora-ocurrencia-siniestro-daño"] =
      values["hora-ocurrencia-siniestro-daño"].replaceAll("AM", "") +
      " " +
      values["tipo-hora"];
    try {
      const payload = { ...values };
      axios
        .post(postUrl, payload)
        .then((response) => {
          setDisabled(true);
          return Modal.success({
            content: "Datos enviados con éxito",
            // onOk() {
            //   window.location.replace("/");
            // },
          });
        })
        .catch((error) => {
          return Modal.error({
            content: "Ha ocurrido un error al enviar los datos",
          });
        });
    } catch (error) {
      if (error) console.error(error, "error api");
    }
  };

  const onChange = (checked) => {
    setIsTheSame(checked);
  };

  const onChangeThirdPart = (checked) => {
    setSelected(null);
    setThirdParty(checked);
  };

  //Temporalmente deshabilitado
  // useEffect(() => {
  //   if (utmMedium) document.cookie = `utm_medium=${utmMedium}`;
  //   if (utmSource) document.cookie = `utm_source=${utmSource}`;
  //   if (utmCampaign) document.cookie = `utm_campaign=${utmCampaign}`;
  // }, []);

  const { Option } = Select;
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const handleDate = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleTypeChange = (value) => {
    setSinisterType(value);
  };

  const handleThirdType = (value) => {
    setSelected(value);
    setThirdType(value);
  };

  const { Text } = Typography;
  return (
    <div className="required-none">
      <GoToTop />
      <Banner
        urlImage={
          contactHeader?.length === 0 || contactHeader === null
            ? ContactBanner
            : contactHeader?.bannerImage?.data[0]?.attributes?.url
        }
        height="60"
        fontSize="24px"
        title={"Declarar siniestro"}
        titleAlign="left"
      />

      <Row>
        {biggerThan900 ? (
          <>
            <Col span={5}>
              <Row
                style={{
                  backgroundColor: "#2D2D2D",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "left",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      marginTop: " 40px",
                    }}
                  >
                    {contactSider.length === 0 || contactSider === null
                      ? "Contacto"
                      : contactSider?.[0]?.Title}
                  </Text>
                  <Row
                    justify="start"
                    style={{ color: "white", marginBottom: "5px" }}
                  >
                    <Col>
                      <PhoneOutlined
                        style={{ color: "#FF3200", marginRight: "15px" }}
                      />
                    </Col>
                    <Col style={{ color: "white" }}>+56 9 9672 3943</Col>
                  </Row>
                  <Row>
                    <Col>
                      <MailOutlined
                        style={{ color: "#FF3200", marginRight: "15px" }}
                      />
                    </Col>
                    <Col style={{ color: "white" }}>
                      siniestros@gamaleasing.cl
                    </Col>
                  </Row>
                  <Text
                    style={{
                      color: "white",
                      marginTop: "30px",
                      marginBottom: "15px",
                    }}
                  >
                    {contactSider.length === 0 || contactSider === null
                      ? "Siguenos"
                      : contactSider?.[1]?.Title}
                  </Text>
                  <Row justify="space-evenly" style={{ width: "200px" }}>
                    <Col>
                      <a
                        href={
                          contactSider?.length === 0 || contactSider === null
                            ? "https://www.facebook.com/gamaleasing2013"
                            : contactSider[0]?.Paragraph
                        }
                      >
                        <FacebookFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={
                          contactSider?.length || contactSider === null
                            ? "https://www.instagram.com/gamaleasing/"
                            : contactSider[1]?.Paragraph
                        }
                      >
                        <InstagramFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={
                          contactSider?.length || contactSider === null
                            ? "https://www.linkedin.com/company/gamaleasing/"
                            : contactSider[2]?.Paragraph
                        }
                      >
                        <LinkedinFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={
                          contactSider?.length || contactSider === null
                            ? "https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q"
                            : contactSider[3]?.Paragraph
                        }
                      >
                        <YoutubeFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                  </Row>
                </Row>
              </Row>
            </Col>
          </>
        ) : null}
        <Col xs={24} sm={24} md={24} lg={14}>
          <Row
            style={{
              flexDirection: "column",
              border: "1px solid rgba(255, 50, 0, 0.1)",
              margin: "50px 30px 50px 60px",
              padding: biggerThan900
                ? "30px 0px 0px 30px"
                : "25px 25px 0px 25px",
              borderRadius: "17px",
            }}
          >
            <Col>
              <Text
                strong
                style={{
                  fontSize: "20px",
                }}
              >
                ¿Necesitas declarar un siniestro?
              </Text>
            </Col>
            <Col style={{ paddingBottom: "30px" }}>
              <Text>
                Llena el siguiente formulario y te responderemos a la brevedad.
              </Text>
            </Col>
            <Col>
              <Text
                strong
                style={{
                  fontSize: "18px",
                }}
              >
                Datos Cliente
              </Text>
            </Col>
            <Col>
              <Form
                name="contact"
                initialValues={{ contact: [[{}]] }}
                form={form}
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                layout="vertical"
              >
                <Form.Item
                  name="patente-cliente"
                  rules={[
                    { required: true, message: "Ingrese la patente" },
                    {
                      max: 6,
                      message: "Debe tener máximo 6 caracteres",
                    },
                  ]}
                  label="Patente Gama"
                  style={{ fontWeight: "bold", marginBottom: "40px" }}
                >
                  <Input style={{ width: "90%" }} />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Razón Social" },
                    {
                      pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                      message:
                        "La razón social no puede incluir caracteres especiales",
                    },
                  ]}
                  name="nombre-razon-social-cliente"
                  label="Nombre o Razón Social"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  normalize={(value) => format(value)}
                  rules={[
                    { required: true, message: "Ingrese el rut" },
                    {
                      validator: (_, value) => {
                        if (validate(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("El rut ingresado es inválido");
                      },
                    },
                  ]}
                  name="rut-cliente"
                  label="Rut Cliente"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>

                <Col>
                  <Text
                    strong
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Datos Declarante
                  </Text>
                </Col>
                <Form.Item
                  rules={[{ required: true, message: "Razón Social" }]}
                  name="nombre-completo-declarante"
                  label="Nombre Completo"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input onChange={(e) => getName(e)} />
                </Form.Item>
                <Form.Item
                  normalize={(value) => format(value)}
                  rules={[
                    { required: true, message: "Ingrese el rut" },
                    {
                      validator: (_, value) => {
                        if (validate(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("El rut ingresado es inválido");
                      },
                    },
                  ]}
                  name="rut-declarante"
                  label="Rut Declarante"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input onChange={(e) => getRut(e)} />
                </Form.Item>
                <Input.Group compact>
                  <Form.Item
                    label={<Text strong>Prefijo</Text>}
                    name="prefijo-declarante"
                  >
                    <Select disabled defaultValue="56" style={{ width: 80 }}>
                      <Option value={"56"}>+56</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<Text strong>Telefono Declarante</Text>}
                    name="telefono-declarante"
                    style={{
                      width: "75%",
                    }}
                    rules={[
                      {
                        pattern: /^[+]*\d{9}$/,
                        message:
                          "Ingrese un número válido, sin letras y de 9 dígitos.",
                      },
                    ]}
                  >
                    <Input onChange={(e) => getTelf(e)} />
                  </Form.Item>
                </Input.Group>
                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="email-declarante"
                  label="Email Declarante"
                  rules={[
                    {
                      type: "email",
                      message: "Por favor ingrese un correo válido",
                    },
                    {
                      max: 40,
                      message:
                        "No puede ingresar más de 40 caracteres en un correo",
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input onChange={(e) => getEmail(e)} />
                </Form.Item>
                <Form.Item
                  label={<strong>Confirmar email</strong>}
                  style={{ width: "90%" }}
                  name="confirmar-email"
                  dependencies={["email-declarante"]}
                  rules={[
                    {
                      required: true,
                      message: "Confirma tu email",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("email-declarante") === value
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "Los email ingresados no coinciden, por favor vuelve a intentarlo"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "¿Es el conductor el mismo declarante?",
                    },
                  ]}
                  initialValue={false}
                  name="declarante-cliente"
                  label="¿Es el conductor la misma persona que el declarante?"
                  style={{ fontWeight: "bold", marginBottom: "40px" }}
                >
                  <Switch
                    checkedChildren="Si"
                    unCheckedChildren="No"
                    onChange={onChange}
                  />
                </Form.Item>
                <Divider />
                <Col>
                  <Text
                    strong
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Datos conductor
                  </Text>
                </Col>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su nombre o razon social",
                    },
                    {
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Debe tener al menos 2 caracteres",
                    },
                    {
                      max: 50,
                      message: "Debe tener máximo 50 caracteres",
                    },
                    {
                      pattern: /^[A-Za-z\s.'Ññ]+$/,
                      message: "El nombre solo debe incluir letras.",
                    },
                  ]}
                  name="nombre-razon-conductor"
                  label="Nombre Completo del conductor"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su rut o pasaporte",
                    },
                  ]}
                  name="rut-pasaporte"
                  label="Ingrese Rut/Pasaporte del conductor"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Input.Group compact>
                  <Form.Item
                    label={<Text strong>Prefijo</Text>}
                    name="phonePrefix"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select disabled defaultValue="56" style={{ width: 80 }}>
                      <Option value={"56"}>+56</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<Text strong>Telefono del conductor</Text>}
                    name="telefono-conductor"
                    style={{
                      width: "75%",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Ingrese el número de telefono",
                      },
                      {
                        pattern: /^[+]*\d{9}$/,
                        message:
                          "Ingrese un número válido, sin letras y de 9 dígitos.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Input.Group>
                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="email"
                  label="Email del conductor"
                  rules={[
                    {
                      type: "email",
                      message: "Por favor ingrese un correo válido",
                    },
                    {
                      required: true,
                      message: "Por favor ingrese el correo",
                    },
                    {
                      max: 40,
                      message:
                        "No puede ingresar más de 40 caracteres en un correo",
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "¿Se considera culpable?",
                    },
                  ]}
                  name="conductor-culpable"
                  initialValue={false}
                  label="¿El conductor se considera culpable?"
                  style={{ fontWeight: "bold", marginBottom: "40px" }}
                >
                  <Switch checkedChildren="Si" unCheckedChildren="No" />
                </Form.Item>

                <Divider />
                <Col>
                  <Text
                    strong
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Antecedentes del siniestro
                  </Text>
                </Col>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese la fecha del siniestro",
                    },
                  ]}
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="fecha-siniestro-daño-tercero"
                  label="Fecha de ocurrencia del siniestro"
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={["Fecha inicio"]}
                  />
                </Form.Item>
                <p style={{ paddingTop: "16px" }}>
                  Colocar fecha del siniestro
                </p>
                <Row>
                  <Col span={14}>
                    <Form.Item
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="hora-ocurrencia-siniestro-daño"
                      label="Hora de ocurrencia del siniestro (formato 24 horas)"
                    >
                      {/* <Input /> */}
                      <input
                        style={{
                          backgroundColor: "#F5f5f5",
                          border: "none",
                          paddingLeft: "6px",
                          borderRadius: "6px",
                        }}
                        type="time"
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="tipo-hora"
                      initialValue="AM"
                      label={<span></span>}
                    >
                      <Select
                        suffixIcon={<CaretDownOutlined />}
                        id="siniester-type"
                        style={{ width: "80px" }}
                        defaultValue="AM"
                      >
                        <Select.Option value="AM">AM</Select.Option>
                        <Select.Option value="PM">PM</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="direccion-ocurrencia-siniestro-daño"
                  label="Direccion de siniestro"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="comuna-ocurrencia-siniestro-daño"
                  label="Comuna donde ocurrio el siniestro"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="region-ocurrencia-siniestro-daño"
                  label="Region de siniestro"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="tipo-siniestro"
                  label="Tipo de siniestro"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese el tipo de siniestro",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    id="siniester-type"
                    placeholder="Seleccione el tipo de siniestro"
                  >
                    <Select.Option value="Robo">Robo</Select.Option>
                    <Select.Option value="Daño">Daño</Select.Option>
                    <Select.Option value="Solo daño a terceros">
                      Solo daño a terceros
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  rules={[
                    { required: true, message: "Este campo es requerido" },
                  ]}
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="existe-parte-policial"
                  label="¿Existe parte policial?"
                  initialValue={false}
                >
                  <Switch checkedChildren="Si" unCheckedChildren="No" />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="relato-siniestro"
                  label="Relato del siniestro"
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="daño-a-declarar"
                  label="Daños a declarar del vehiculo"
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  rules={[
                    { required: true, message: "Este campo es requerido" },
                  ]}
                  name="terceros-bienes-involucrados"
                  label="¿Hay terceros y/o bienes involucrados?"
                  initialValue={false}
                >
                  <Switch
                    checkedChildren="Si"
                    unCheckedChildren="No"
                    onChange={onChangeThirdPart}
                  />
                </Form.Item>

                {thirdParty ? (
                  <Form.Item
                    style={{
                      width: "90%",
                      fontWeight: "bold",
                      marginBottom: "40px",
                    }}
                    name="terceros-corresponde-vehiculos-bienes"
                    label="¿El daño corresponde a un vehiculo o bienes/personas?"
                  >
                    <Select
                      value={selected}
                      onChange={handleThirdType}
                      suffixIcon={<CaretDownOutlined />}
                      id="damage-type"
                      placeholder="Seleccione el tipo de siniestro"
                    >
                      <Select.Option value="Autos">Autos</Select.Option>
                      <Select.Option value="Bienes/personas">
                        Bienes/personas
                      </Select.Option>
                    </Select>
                  </Form.Item>
                ) : null}

                {thirdParty && thirdType === "Autos" ? (
                  <>
                    <Form.Item
                      name="patente-tercero-involucrado"
                      rules={[
                        {
                          max: 6,
                          message: "Debe tener máximo 6 caracteres",
                        },
                      ]}
                      label="Patente Terceros"
                      style={{ fontWeight: "bold", marginBottom: "40px" }}
                    >
                      <Input style={{ width: "90%" }} />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                          message:
                            "El nombre no puede incluir caracteres especiales",
                        },
                      ]}
                      name="nombre-completo-tercero-involucrado"
                      label="Nombre y apellido del tercero"
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      normalize={(value) => format(value)}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (validate(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "El rut ingresado es inválido"
                            );
                          },
                        },
                      ]}
                      name="rut-tercero"
                      label="Rut del Tercero"
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={<Text strong>Telefono Del Tercero</Text>}
                      name="telefono-tercero"
                      style={{
                        width: "90%",
                      }}
                      rules={[
                        {
                          pattern: /^[+]*\d{9}$/,
                          message:
                            "Ingrese un número válido, sin letras y de 9 dígitos.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="email-tercero"
                      label="Email Del Tercero"
                      rules={[
                        {
                          type: "email",
                          message: "Por favor ingrese un correo válido",
                        },
                        {
                          max: 40,
                          message:
                            "No puede ingresar más de 40 caracteres en un correo",
                        },
                        {
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="daño-al-tercero"
                      label="Daño al tercero"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </>
                ) : null}

                {thirdParty && thirdType === "Bienes/personas" ? (
                  <>
                    <Form.Item
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="detalle-daño-bienes-personas"
                      label="Detalle del bien"
                    >
                      <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                      rules={[
                        {
                          pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                          message:
                            "El nombre no puede incluir caracteres especiales",
                        },
                      ]}
                      name="nombre-completo-bienes"
                      label="Nombre y apellido del tercero"
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={<Text strong>Telefono Del Tercero</Text>}
                      name="telefono-bienes"
                      style={{
                        width: "90%",
                      }}
                      rules={[
                        {
                          pattern: /^[+]*\d{9}$/,
                          message:
                            "Ingrese un número válido, sin letras y de 9 dígitos.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      style={{
                        width: "90%",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      name="email-bienes"
                      label="Email Del Tercero"
                      rules={[
                        {
                          type: "email",
                          message: "Por favor ingrese un correo válido",
                        },
                        {
                          max: 40,
                          message:
                            "No puede ingresar más de 40 caracteres en un correo",
                        },
                        {
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                ) : null}

                <p style={{ fontWeight: 600 }}>Declaracion Bajo Juramento</p>
                <p style={{ width: "90%", textAlign: "justify" }}>
                  DECLARO BAJO JURAMENTO que la información aportada es
                  expresión fiel de las circunstancias que dieron lugar a los
                  hechos constitutivos del siniestro denunciado para el vehículo
                  asegurado antes individualizado. Declaro expresamente, por el
                  presente instrumento, que entiendo y acepto que cualquier
                  reticencia o declaración falsa de mi parte, faculta
                  expresamente a la Compañía Aseguradora para rechazar el pago
                  de la indemnización reclamada. Lo anterior conforme al deber
                  del asegurado contemplado en la legislación vigente, de
                  acreditar la ocurrencia del siniestro denunciado, declarando
                  fielmente y sin reticencia, sus circunstancias y
                  consecuencias. Declaro estar en conocimiento que la Compañía
                  Aseguradora ejercerá las acciones legales que correspondan,
                  por el delito de Fraude al Seguro, cuando se obtuviere
                  maliciosamente el pago de la indemnización; ya sea por haber
                  simulado la existencia del siniestro; por haberlo provocado
                  intencionalmente; o por haberlo presentado a la Compañía
                  Aseguradora en virtud de causas o circunstancias distintas de
                  las verdaderas, ocultando la cosa asegurada o aumentando
                  fraudulentamente las pérdidas sufridas. Finalmente queda
                  establecido que esta declaración jurada no exime de la
                  obligación del asegurado de dejar la correspondiente Denuncia
                  Policial en caso de Robo (total o parcial del vehículo) y
                  Choque o colisión con lesionados involucrados. Conforme a la
                  normativa vigente, en caso de siniestro de daños a su vehículo
                  y/o a de terceros, para efecto del seguro se reemplaza la
                  constancia policial por esta declaración jurada simple, la
                  cual debe llenar y firmar el asegurado o el conductor.
                </p>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor marcar la declaracion bajo juramento.",
                    },
                  ]}
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="declaracion-jurada"
                >
                  <Switch checkedChildren="Si" unCheckedChildren="No" />
                </Form.Item>

                <Row
                  align="middle"
                  justify="center"
                  style={{ marginTop: "55px", marginBottom: "30px" }}
                >
                  <Button
                    disabled={disabled}
                    type="primary"
                    onClick={() => form.submit()}
                  >
                    Enviar
                  </Button>
                </Row>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  ref={reRef}
                  size="invisible"
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </div>
  );
}

export default SinisterDeclaration;
