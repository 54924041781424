import {
  EllipsisOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Col, Menu, message, Row, Table, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import Notifications from "../../../components/Notifications";
import { setEnabledCustomerRequest } from "../../../requests/customers";
import "./index.less";

const CustomersTable = ({
  customers,
  onSelectCustomer,
  loading,
  hasEditAccess,
  mobileColumns,
  currentPage,
  pageSize,
  onChange,
  total,
}) => {
  const [data, setData] = useState([]);

  const [filterTable] = useState([]);
  const { Text } = Typography;
  let expandedRowKeys = [];

  const filteredCustomers = customers?.filter(
    (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
  );

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.id;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }
  const setEnabled = async ({ id, enabled }) => {
    try {
      const newData = data.map((element) => {
        if (element.id === id) element.enabled = !enabled;
        return element;
      });

      setData(newData);

      await setEnabledCustomerRequest(id);
      Notifications.success("Éxito", "Se ha completado la operación");
    } catch (error) {
      message.error({
        content: "Error al cambiar el estado",
        duration: 5,
      });
    }
  };

  useEffect(() => {
    setData(customers);
  }, [data, customers]);

  const columns = [
    {
      title: "Empresa",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Rut",
      dataIndex: "rut",
      key: "rut",
      width: 130,
      sorter: (a, b) => a?.rut?.slice(0, -2) - b?.rut?.slice(0, -2),
    },
    // {
    //   title: "Subempresas",
    //   key: "subCompanies",
    //   sorter: {
    //     compare: (a, b) =>
    //       a.subCompanies.toString().localeCompare(b.subCompanies.toString()),
    //   },
    //   render: (c) => (
    //     <span>
    //       {c.subCompanies === 0
    //         ? "No aplica"
    //         : c.subCompanies > 1
    //         ? c.subCompanies + " " + "empresas"
    //         : c.subCompanies + " " + "empresa"}
    //     </span>
    //   ),
    // },
    {
      title: "Ejecutivo",
      key: "executiveId",
      sorter: {
        compare: (a, b) => a?.executiveId?.localeCompare(b?.executiveId),
      },
      render: (customer) => (
        <span>{customer.executiveId ? customer.executiveId : "No aplica"}</span>
      ),
    },
    {
      title: "Supervisor",
      key: "supervisorId",
      sorter: {
        compare: (a, b) => a?.supervisorId?.localeCompare(b?.supervisorId),
      },
      render: (customer) => (
        <span>
          {customer.supervisorId ? customer.supervisorId : "No aplica"}
        </span>
      ),
    },
    {
      title: "Detalle",
      key: "detalle",
      render: (customer) => (
        <a
          href="#!"
          className={!customer.enabled ? "disabled-row" : undefined}
          onClick={() => onSelectCustomer(customer, "show")}
        >
          <u>Ver detalle</u>
        </a>
      ),
    },
    {
      title: "Acción",
      key: "id",
      render: (customer) => {
        if (hasEditAccess)
          return (
            <Dropdown overlay={renderMenu(customer)} placement="bottomLeft">
              <Button className="action-table-button">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          );
      },
    },
  ];

  const renderMenu = (customer) => (
    <Menu className="ant-table-dropdown-menu">
      {/* <Menu.Item
        key={"1"}
        onClick={() => onSelectCustomer(customer, "executive")}
      >
        {customer.executiveId ? "Editar Ejecutivo" : "Asignar Ejecutivo"}
      </Menu.Item> */}
      {/* <Menu.Item onClick={() => onSelectCustomer(customer, "update")} key={"2"}>
        Agregar Subempresa
      </Menu.Item> */}
      <Menu.Item onClick={() => setEnabled(customer)} key={"3"}>
        {customer.enabled ? "Desactivar" : "Activar"}
      </Menu.Item>
    </Menu>
  );

  const filteredTableColumns = useMemo(() => {
    if (!hasEditAccess) columns.pop();
    return columns.filter((column) => !filterTable.includes(column.dataIndex));
  }, [columns, filterTable]);

  return (
    <div>
      {mobileColumns ? (
        <div className="responsive-customer">
          <Table
            className="customers-table-container"
            expandedRowRender={(record) => (
              <Row>
                <Col span={14}>
                  <Text strong>Ejecutivo</Text>
                </Col>
                <Col span={10}>
                  {!record?.executiveId ? "No disponible" : record?.executiveId}
                </Col>
                <Col span={14}>
                  <Text strong>Supervisor</Text>
                </Col>
                <Col span={10}>
                  {!record?.supervisorId
                    ? "No disponible"
                    : record?.supervisorId}
                </Col>

                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <Button
                      className={!record.enabled ? "disabled-row" : undefined}
                      onClick={() => onSelectCustomer(record, "show")}
                      style={{ width: "180px" }}
                    >
                      Ver detalles
                    </Button>
                  </Col>
                  <Col style={{ marginLeft: "15px" }}>
                    {hasEditAccess ? (
                      <Dropdown
                        trigger={["click"]}
                        overlay={renderMenu(record)}
                        placement="bottomLeft"
                      >
                        <Button className="action-table-button">
                          <EllipsisOutlined />
                        </Button>
                      </Dropdown>
                    ) : null}
                  </Col>
                </Row>
              </Row>
            )}
            expandIcon={(props) => {
              if (props.record.isExpandable) {
                if (props.expanded) {
                  return (
                    <MinusSquareOutlined
                      style={{ width: "50px", padding: "1px 1px" }}
                      onClick={(e) => {
                        props.onExpand(props.record, e);
                      }}
                    />
                  );
                } else {
                  return (
                    <PlusSquareOutlined
                      style={{ width: "50px" }}
                      onClick={(e) => {
                        props.onExpand(props.record, e);
                      }}
                    />
                  );
                }
              }
            }}
            dataSource={filteredCustomers}
            loading={loading}
            columns={mobileColumns}
            // rowClassName={(record) => (record.isExpandable ? "show" : "hidden")}
            rowKey={(record) => record.id}
            expandedRowKeys={expandedRowKeys}
            onExpand={handleRowKeys}
            rowClassName={(customer) => !customer.enabled && "disabled-row"}
            showSorterTooltip={false}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              position: ["bottomCenter"],
            }}
            sortDirection={["ASC", "DESC"]}
            onChange={onChange}
          />
        </div>
      ) : (
        <Table
          dataSource={filteredCustomers}
          loading={loading}
          columns={filteredTableColumns}
          rowClassName={(customer) => !customer.enabled && "disabled-row"}
          showSorterTooltip={false}
          pagination={{
            current: currentPage,
            total,
            pageSize,
            position: ["bottomCenter"],
          }}
          sortDirection={["ASC", "DESC"]}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default CustomersTable;
