import React, { useEffect, useState } from "react";
import {
    Col,
    Modal,
    Row,
    Button,
    Upload,
} from "antd";
import {
    CloseOutlined,
    DownloadOutlined,
    DeleteOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import Notifications from "../../../components/Notifications";
import { useMediaPredicate } from "react-media-hook";
import {
  uploadFile,
  changeStatusInvoicePropose,
  deleteFileInvoicePropose,
  downloadFileToInvoicePropose,
  getInvoiceProposeById,
} from "../../../requests/invoicePropose";
import FileExcel from '../../../assets/img/FileExcel.png';

export const AdjuntFileModal = ({
  open,
  setOpen,
  recordModal,
  setUpdatedInvoicePropose,
  showButtonToAprove = true,
}) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [ docs, setDocs ] = useState(recordModal?.presignedDocs || []);

  const secureRandomNumber = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / 4294967295; // normalize the value between 0 and 1
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg'
    || file.type === 'image/png'
    || file.type === 'application/pdf'
    || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    || file.type === 'application/msword';
    
    console.log(file.type);
    if (!isJpgOrPng) {
      Notifications.error("Error al subir archivo", 'El formato de archivo que intenta subir no es valido!');
    }
    const isLt5M = file.size / 1024 / 1024 < 10;
    if (!isLt5M) {
      Notifications.error("Error al subir archivo", 'Solo se permiten archivos de 10MB!');
    }
    return isJpgOrPng && isLt5M;
  };
  
  useEffect(async () => {
    setDocs(recordModal?.presignedDocs);
    if (recordModal?.id) {
      try {
        const { data } = await getInvoiceProposeById(recordModal.id);
        setDocs(data?.presignedDocs);
      } catch (error) {
        console.error(error);
      }
    }

  }, [recordModal]);

  const cancelApproveInvoice = () => {
    setOpen(false);
  }

  const handleDownload = async (fileURL, filename) => {
    try {
      const { data } = await downloadFileToInvoicePropose(fileURL);
      const url = window.URL.createObjectURL(
        new Blob([data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const AproveInvoicePropose = async () => {
    const { id, displayId } = recordModal;
    try {
      await changeStatusInvoicePropose(id, 1, '');
      setUpdatedInvoicePropose(secureRandomNumber());
    } catch (error) {
      return Notifications.error(
        "Error al Cambiar estado",
        `Hubo un error al cambiar el estado de la propuesta de facturación ${displayId || id}.`
      );
    }
    cancelApproveInvoice();
    Notifications.success(
      "Se cambio el estado correctamente",
      `Se cambio el estado a Aprobado la propuesta de facturación ${displayId || id}.`
    );
  }

  return (
      <>
        <Modal
        bodyStyle={{ borderRadius: "60px" }}
        footer={false}
        closable={false}
        title={
          <>
            <Row align="middle" justify="space-between">
              <span
                style={{
                  fontWeight: "600",
                  fontSize: biggerThan900 ? "20px" : "15px",
                }}
              >
                ¿Desea adjuntar documentos?
              </span>
              <Col>
                <CloseOutlined
                  onClick={cancelApproveInvoice}
                  style={{
                    color: "#FF3200",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                    padding: "7px 7px 7px 7px",
                    marginRight: "15px",
                    fontSize: "16px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </Row>
          </>
        }
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Row
          justify="center"
          className="button-upload-modal"
        >

            <Col xs={24}>
                <p>
                  Los archivos deben ser JPG, PNG, PDF, WORD, EXCEL y/o PowerPoint con un tamaño máximo de 10MB.
                </p>
            </Col>

            { 
              docs?.length > 0 &&  docs?.map((file) => {

                return (
                  <Col  key={file?.documentName}
                    xs={24} 
                    style={{
                      borderRadius: '10px',
                      border: '1px solid #FF3200',
                      minHeight: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    <Row justify='space-between'>
                      <Col style={{
                        marginLeft: '10px'
                      }}>
                        <img src={FileExcel} alt="FileExcel" style={{
                          marginRight: '10px'
                        }}/>
                          <span>
                              {file?.documentName}
                          </span>
                      </Col>

                      <Col style={{
                        marginRight: '10px'
                      }}>
                        <Button 
                          icon={<DownloadOutlined />} 
                          style={{
                            borderColor: 'transparent',
                            color: '#FF3200',
                            marginRight: '1rem',
                          }}
                          onClick={() => handleDownload(file?.url, file?.documentName)}
                        />

                        <Button 
                          icon={<DeleteOutlined />} 
                          style={{
                            borderColor: 'transparent',
                            color: '#FF3200',
                            marginRight: '1rem',
                          }} 
                          onClick={async () => {
                            try {
                              const { data } = await deleteFileInvoicePropose(recordModal.id, file?.documentName)
                              setDocs(data?.presignedDocs);
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        />
                      </Col>


                    </Row>
                  </Col>)
                })
              }

                  
            <Col xs={24} style={{
              marginBottom: '20px'
            }}>
              <Upload
                accept=".png,.jpg,.pdf,.jpeg,.docx,.doc,.xlsx,.pptx"
                showUploadList={false}
                beforeUpload={beforeUpload}
                maxCount={1}
                listType=".png,.jpg,.pdf,.jpeg,.docx,.doc,.xlsx,.pptx"
                style={{
                  display: 'block',
                  width: '100%'
                }}
                multiple={false}
                action={async (file) => {
                  try {
                    const { data } = await uploadFile(file, recordModal.id);
                    setDocs(data?.presignedDocs);
                    Notifications.success(
                      "Archivo subido",
                      "Archivo subido con exito."
                    );
                  } catch (error) {
                    Notifications.error(
                      "Error al cargar",
                      "Hubo un error al subir el arhivo."
                    );
                  }
                  return false;
                }}
              >
                <Button
                  type="dashed"
                    style={{
                      borderColor: '#FF3200',
                      color: '#FF3200',
                      marginRight: '1rem',
                      width: '100%'
                    }}
                    size="large"
                    icon={<UploadOutlined />}
                  >Subir archivo
                </Button>
              </Upload>
            </Col>
            <Col className="buttons-mobile">
                <Button
                onClick={cancelApproveInvoice}
                style={{ height: "40px" }}
                className="gama-outline"
                >
                  {showButtonToAprove ? 'Cancelar': 'Cerrar'}
                </Button>
            </Col>
            { 
              showButtonToAprove && 
              <Col className="buttons-mobile">
                  <Button
                    onClick={AproveInvoicePropose}
                    style={{ marginLeft: "16px", height: "40px" }}
                    className="gama-button"
                  >
                    Aceptar
                  </Button>
              </Col> 
            }
            
        </Row>
        </Modal>
      </>
  )
}


export const ApproveInvoiceModal = ({
    open,
    setOpen,
    recordModal,
    updatedInvoicePropose,
    setUpdatedInvoicePropose,
}) => {

    const [ openAdjuntFileModal, setOpenAdjuntFileModal] = useState(false);
    const biggerThan900 = useMediaPredicate("(min-width: 992px)");

    const cancelApproveInvoice = () => {
      setOpen(false);
    }

    return (
        <>
          <Modal
          bodyStyle={{ borderRadius: "60px" }}
          footer={false}
          closable={false}
          title={
            <>
              <Row align="middle" justify="space-between">
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: biggerThan900 ? "20px" : "15px",
                  }}
                >
                  Aprobar propuesta {recordModal?.displayId}
                </span>
                <Col>
                  <CloseOutlined
                    onClick={cancelApproveInvoice}
                    style={{
                      color: "#FF3200",
                      backgroundColor: "rgba(255, 50, 0, 0.06)",
                      padding: "7px 7px 7px 7px",
                      marginRight: "15px",
                      fontSize: "16px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </>
          }
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
        >
          <Row
            justify="center"
            className="button-upload-modal"
          >
              <Col >
                  <p>
                      Recuerde que después de cambiar el estado, no es posible volver atrás 
                      ¿Está de acuerdo con cambiar el estado de esta propuesta?
                  </p>
              </Col>

              <Col className="buttons-mobile">
                  <Button
                  onClick={cancelApproveInvoice}
                  style={{ height: "40px" }}
                  className="gama-outline"
                  >
                    Cancelar
                  </Button>
              </Col>
              <Col className="buttons-mobile">
                  <Button
                    onClick={() => {
                      setOpen(false);
                      setOpenAdjuntFileModal(true);
                    }}
                    style={{ marginLeft: "16px", height: "40px" }}
                    className="gama-button"
                  >
                    Aprobar
                  </Button>
              </Col>
          </Row>
          </Modal>

          <AdjuntFileModal
            open={openAdjuntFileModal}
            setOpen={setOpenAdjuntFileModal}
            recordModal={recordModal}
            updatedInvoicePropose={updatedInvoicePropose}
            setUpdatedInvoicePropose={setUpdatedInvoicePropose}
          />
        </>
    )
}