import React from "react";
import { Form, Input } from "antd";
import Button from "../../../Button";
import "./index.less";
import ReCAPTCHA from "react-google-recaptcha";

const ChangePasswordForm = ({ onSubmit, temporal = true, reRef }) => {
  return (
    <div className="change-password-form">
      <Form layout="vertical" onFinish={onSubmit}>
        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
          {temporal ? "Cree una nueva contraseña" : "Nueva contraseña"}
        </p>
        <p style={{ fontSize: "14px" }}>
          La nueva contraseña debe tener al menos 8 caracteres y tiene que
          contener al menos una letra mayúscula, una letra minúscula y un
          número.
        </p>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Debes ingresar tu nueva contraseña",
            },
            {
              pattern: /^(?=.{8,})/,
              message: "La contraseña debe tener al menos 8 caracteres",
            },
            {
              pattern: /[A-Z]/,
              message: "La contraseña debe tener al menos una letra mayúscula",
            },
            {
              pattern: /[a-z]/,
              message: "La contraseña debe tener al menos una letra minúscula",
            },
            {
              pattern: /\d/,
              message: "La contraseña debe tener al menos un número",
            },
          ]}
        >
          <Input.Password placeholder="Ingresa tu nueva contraseña" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Confirma tu nueva contraseña",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(
                    "Las contraseñas ingresadas no coinciden, por favor vuelve a intentarlo"
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirma tu nueva contraseña" />
        </Form.Item>
        <Button
          className="gama-button"
          style={{
            display: "block",
            margin: "0 auto",
          }}
          htmlType="submit"
        >
          Cambiar contraseña
        </Button>
      </Form>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        ref={reRef}
        size="invisible"
      />
    </div>
  );
};

export default ChangePasswordForm;
