import Button from "../../../Button/index";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.less";
import { useSelector } from "react-redux";
import { getHomeHeaderContent } from "../../../../store/strapi/gamaleasing";

const Burger = ({ modal, onClose, openAuthForm, data, boton = true }) => {
  const root = document.querySelector("body");
  const [isHome, setIsHome] = useState(false);
  const headerContent = useSelector(getHomeHeaderContent);

  useEffect(() => {
    if (window.location.pathname === "/mobility") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (modal === true) {
      root.style.overflow = "hidden";
    } else {
      root.style.overflow = "auto";
    }
  }, [modal]);

  const cleanModal = () => {
    document.getElementById("checkbox4").click();
    onClose();
  };

  const redirectData = [
    {
      title: "Sucursales",
      link: "/branches",
    },
    {
      title: "Nuestros servicios",
      link: "/mobility#services",
    },
    {
      title: "Quienes Somos",
      link: "/mobility#us",
    },
    { title: "Beneficios", link: "/mobility#benefits" },
    { title: "Publicaciones", link: "/mobility#posts" },
  ];

  return (
    <>
      <input
        type="checkbox"
        id="checkbox4"
        className="checkbox4 visuallyHidden"
      ></input>
      <label htmlFor="checkbox4" onClick={() => onClose()}>
        <div className="hamburger hamburger4">
          <span className="bar bar1"></span>
          <span className="bar bar2"></span>
          <span className="bar bar3"></span>
          <span className="bar bar4"></span>
          <span className="bar bar5"></span>
        </div>
      </label>

      <div className={modal === true ? "modal modal-active" : "modal"}>
        <div className="delimiter"></div>
        <div className="container-modal">
          <div className="header-modal" style={{ height: "55%" }}>
            <div className="nav-group">
              {!isHome
                ? data?.map((item) => (
                  <a smooth key={item.link} href={item.link} onClick={cleanModal}>
                    {item.title}
                  </a>
                ))
                : redirectData?.map((item) => (
                  <a smooth key={item.link} href={item.link} onClick={cleanModal}>
                    {item.title}
                  </a>
                ))}
            </div>

            <div className="button-group">
              {boton ? (
                <Button
                  className="gama-button portal-button"
                  onClick={() => openAuthForm()}
                >
                  Portal Clientes
                </Button>
              ) : null}

              <Link to="/contact">
                <Button className="gama-outline talk-button">
                  {headerContent
                    ? headerContent?.bannerButton.Label
                    : "Nuevo Arriendo"}
                </Button>
              </Link>
            </div>
          </div>
          <div
            className="footer-modal"
            style={{ height: "45%", backgroundColor: "#2D2D2D" }}
          >
            <ul>
              <li>
                <a
                  target="_blank"
                  href={"https://gamamobility.cl/"}
                  rel="noreferrer"
                >
                  GAMA MOBILITY
                </a>
              </li>
              <li>
                <Link
                  style={{
                    color:
                      window.location.pathname === "/" ? "#FF3200" : "white",
                  }}
                  to={"/"}
                >
                  GAMA LEASING
                </Link>
              </li>

              <li>
                <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
              </li>
              <li>
                <a href={"https://www.tripp.cl/"}>TRIPP</a>
              </li>
              <li>
                <Link to={"/usados"}>GAMA USADOS</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Burger;
