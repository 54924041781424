import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../utils/status";
import { getFiltersData } from "./thunk";

const initialState = {
  rentals: [],
  contracts: [],
  devices: [],
  filters: {
    model: [],
    brand: []
  },
  status: STATUS.IDLE,
  error: null
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setRentals: (state, action) => {
      state.rentals = action.payload;
    },
    setContracts: (state, action) => {
      state.contracts = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearAll: (state, action) => {
      state.rentals = initialState.rentals;
      state.contracts = initialState.contracts;
      state.devices = initialState.devices;
    },
    clearVehiclesFilters: (state) => {
      state.filters = initialState.filters;
    }
  },extraReducers(builder) {
    builder
    .addCase(getFiltersData.pending, (state, action) => {
      state.status = STATUS.PENDING;
    })
    .addCase(getFiltersData.fulfilled, (state, action) => {
      const { brand, model } = action.payload;
      state.status = STATUS.SUCCESS;
      state.filters.brand = brand;
      state.filters.model = model;
    })
    .addCase(getFiltersData.rejected, (state, action) => {
      const { error } = action.payload;
      state.status = STATUS.FAILED;
      state.error = error;
    })
  }
});

export const getRentals = (state) => state.vehicles.rentals;
export const getContracts = (state) => state.vehicles.contracts;
export const getDevices = (state) => state.vehicles.devices;
export const getFilters = (state) => state.vehicles.filters;
export const getStatus = (state) => state.vehicles.status;

export const { setRentals, setContracts, setDevices, clearAll, setFilters, clearVehiclesFilters } =
vehiclesSlice.actions;

export default vehiclesSlice.reducer;
