import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileDoneOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Col, Divider, Drawer, Row, Typography, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment/dist/moment";
import "moment/dist/locale/es";
import { useMediaPredicate } from "react-media-hook";
import Button from "../../../components/Button";
import {
  downloadDocument,
  getDocumentsByRut,
  viewDocument,
} from "../../../requests/sharepoint";
import "jspdf-autotable";

export default function DocumentsDetails({ vehicleData, open, onClose }) {
  moment.locale("es");
  const { Text } = Typography;
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const memoizedFetchRequests = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getDocumentsByRut({
        rut: vehicleData?.customerId,
      });
      setDocuments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [vehicleData?.customerId]);

  useEffect(() => {
    memoizedFetchRequests();
  }, [memoizedFetchRequests]);

  const handleClose = () => {
    setDocuments([]);
    onClose();
  };

  const handleDownload = async (filename) => {
    try {
      setLoadingDocuments(true);
      const { data } = await downloadDocument(filename);
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocuments(false);
    }
  };

  const handleView = async (filename) => {
    try {
      setLoadingDocuments(true);
      const { data } = await viewDocument(filename);
      const file = new Blob([data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocuments(false);
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
      <Drawer
        title={
          <div>
            <Row justify="space-between" align="middle">
              <Col>Documentos</Col>
              <Col>
                <Button
                  onClick={handleClose}
                  style={{
                    border: "none",
                    padding: "5px 8px",
                    color: "#FF3200",
                    borderRadius: "6px",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        }
        width={biggerThan900 ? "480px" : "100%"}
        closable={false}
        placement="right"
        onClose={handleClose}
        visible={open}
      >
        <Spin spinning={loadingDocuments} tip="Cargando...">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileDoneOutlined
              style={{
                color: "#262626",
                backgroundColor: "#F0F0F0",
                padding: "7px 7px 7px 7px",
                marginRight: "15px",
                fontSize: "26px",
              }}
            />
            <div style={{ lineHeight: "5px" }}>
              <Text strong style={{ margin: "0" }}>
                Nº de orden: {vehicleData?.rentId}
              </Text>
              <div style={{ display: "flex" }}>
                <p style={{ margin: "0" }}>
                  {vehicleData?.rentName?.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <Divider />

          <Row>
            <Col span={15}>
              <Text strong>Nombre de documento</Text>
            </Col>
            <Col span={3}>
              <Text strong>Ver</Text>
            </Col>
            <Col span={6}>
              <Text strong>Descargar</Text>
            </Col>
          </Row>

          {loading ? (
            <Row style={{ marginTop: "16px" }} align="middle" justify="center">
              <Spin size="large" indicator={antIcon} />
            </Row>
          ) : (
            documents?.map((document) => {
              return (
                <Row
                  key={document.filename}
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    backgroundColor: "#FAFAFA",
                    padding: "5px 5px 5px 15px",
                    borderRadius: "8px",
                  }}
                  align="middle"
                  justify="space-between"
                >
                  <Col span={14} style={{ fontSize: "14px" }}>
                    {document.detail}
                  </Col>
                  <Col span={9}>
                    <EyeOutlined
                      onClick={() => handleView(document.filename)}
                      style={{
                        fontSize: "20px",
                        color: "#FF3200",
                        marginRight: "50px",
                        cursor: "pointer",
                      }}
                    />

                    <DownloadOutlined
                      onClick={() => handleDownload(document.filename)}
                      style={{
                        fontSize: "20px",
                        color: "#FF3200",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
              );
            })
          )}

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <Button onClick={handleClose} style={{ width: "300px" }}>
              Cerrar
            </Button>
          </Row>
        </Spin>
      </Drawer>
    </>
  );
}
