import { Col, Image, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Burger from "../../components/layouts/HomeLayout/Burger";
import Footer from "../../components/layouts/HomeLayout/Footer";
import { useScroll } from "../../utils/useScroll";
import PaymentBanner from "../../assets/img/bannerpay.webp";
import Logo from "../../assets/img/gama-logo.svg";
import payment1 from "../../assets/img/payment1.webp";
import payment2 from "../../assets/img/payment2.webp";
import payment3 from "../../assets/img/payment3.webp";
import payment4 from "../../assets/img/payment4.webp";
import bro from "../../assets/img/bro.webp";
import { useDispatch, useSelector } from "react-redux";

import {
  getPaymentHeaderContent,
  getPaymentSectionsContent,
} from "../../store/strapi/gamaleasing";
import { getGamaLeasingPaymentContent } from "../../store/strapi/gamaleasing/thunks";
function Payment() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  const [isHome, setIsHome] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamaLeasingPaymentContent());
  }, []);

  const paymentHeader = useSelector(getPaymentHeaderContent);
  const paymentSections = useSelector(getPaymentSectionsContent);

  const burguerData = [
    {
      title: "Sucursales",
      link: "/branches",
    },
    {
      title: "Nuestros servicios",
      link: "/mobilty#services",
    },
    {
      title: "Quienes Somos",
      link: "/mobilty#us",
    },
    { title: "Beneficios", link: "/mobilty#benefits" },
    { title: "Publicaciones", link: "/mobilty#posts" },
  ];

  const { scrollDirection } = useScroll();

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  useEffect(() => {
    if (window.location.pathname === "/payment") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  return (
    <div>
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `80vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                paymentHeader?.length === 0 || paymentHeader === null
                  ? PaymentBanner
                  : paymentHeader?.bannerImage?.data[0]?.attributes?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/mobility"}>
                      <Image
                        preview={false}
                        src={Logo}
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contact">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/branches">
                                Sucursales
                              </a>
                              <a smooth href="#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#us">
                                Quienes Somos
                              </a>
                              <a smooth href="#benefits">
                                Beneficios
                              </a>
                              <a smooth href="#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/branches">Sucursales</Link>
                              <Link to="/mobility">Nuestros Servicios</Link>
                              <Link to="/mobility#us">Quienes Somos</Link>
                              <Link to="/mobility#benefits">Beneficios</Link>
                              <Link to="/mobility#posts">Publicaciones</Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "0",
                paddingLeft: "0",
                color: "#ff5f00",
              }}
            >
              {paymentHeader?.length === 0 || paymentHeader === null
                ? "Pagos online"
                : paymentHeader?.title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "40px",
                fontSize: "28.8px",
                fontWeight: "400",
                width: biggerThan900 ? "650px" : "100%",
                // maxWidth: biggerThan900 ? "800px" : "",
                // marginRight: biggerThan900 ? "1120px" : "",
                paddingLeft: "0",
              }}
            >
              {paymentHeader?.length === 0 || paymentHeader === null
                ? "Encuentra toda la información que necesitas acerca de nuestros métodos y procesos de pago"
                : paymentHeader?.title?.Detail}
            </h3>

            <a smooth href="#pagos">
              <Button
                id="services"
                style={{
                  width: "11rem",
                  marginTop: "1.5rem",
                  color: "white",
                  backgroundColor: "#ff5f00",
                  border: "none",
                }}
              >
                Portal de Pagos
              </Button>
            </a>
          </div>
        </section>
      </Layout>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            style={{
              color: "black",
              fontSize: "32px",
              marginTop: "70px",
              marginBottom: "60px",
              fontWeight: "700",
              width: biggerThan900 ? "" : "300px",
            }}
          >
            {paymentSections?.length === 0 || paymentSections === null
              ? "Paga tu cuenta de forma rápida y segura"
              : paymentSections && paymentSections[0]?.Title}
          </Col>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "70px",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              border: "0.5px solid rgba(255, 95, 0, 0.4)",
              marginRight: "30px",
              borderRadius: "15px",
              marginBottom: "15px",
              marginLeft: "30px",
              padding: "20px 30px",
            }}
          >
            <Col>
              <img
                style={{
                  backgroundColor: "rgba(255, 95, 0, 0.06)",
                  padding: "15px",
                  borderRadius: "6px",
                }}
                src={
                  paymentSections?.length === 0
                    ? payment2
                    : paymentSections &&
                      paymentSections[0]?.Card[0]?.Icon?.data?.attributes?.url
                }
              />
            </Col>
            <Col
              style={{
                fontWeight: "400",
                fontSize: "24px",
                width: "132px",
                marginLeft: "15px",
              }}
            >
              {paymentSections?.length === 0 || paymentSections === null
                ? "Pagos online 24/7"
                : paymentSections && paymentSections[0]?.Card[0]?.Title}
            </Col>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              border: "0.5px solid rgba(255, 95, 0, 0.4)",
              marginRight: "30px",
              borderRadius: "15px",
              marginBottom: "15px",
              marginLeft: "30px",
              padding: "20px 30px",
              width: "273px",
            }}
          >
            <Col>
              <img
                style={{
                  backgroundColor: "rgba(255, 95, 0, 0.06)",
                  padding: "15px",
                  borderRadius: "6px",
                }}
                src={
                  paymentSections?.length === 0
                    ? payment1
                    : paymentSections &&
                      paymentSections[0]?.Card[1]?.Icon?.data?.attributes?.url
                }
              />
            </Col>
            <Col
              style={{
                fontWeight: "400",
                fontSize: "24px",
                width: "124px",
                marginLeft: "15px",
              }}
            >
              {paymentSections?.length === 0 || paymentSections === null
                ? "Seguridad garantizada"
                : paymentSections && paymentSections[0]?.Card[1]?.Title}
            </Col>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              border: "0.5px solid rgba(255, 95, 0, 0.4)",
              marginRight: "30px",
              borderRadius: "15px",
              marginBottom: "15px",
              marginLeft: "30px",
              padding: "20px 30px",
            }}
          >
            <Col>
              <img
                style={{
                  backgroundColor: "rgba(255, 95, 0, 0.06)",
                  padding: "15px",
                  borderRadius: "6px",
                }}
                src={
                  paymentSections?.length === 0 || paymentSections === null
                    ? payment4
                    : paymentSections &&
                      paymentSections[0]?.Card[2]?.Icon?.data?.attributes?.url
                }
              />
            </Col>
            <Col
              style={{
                fontWeight: "400",
                fontSize: "24px",
                width: "124px",
                marginLeft: "15px",
              }}
            >
              {paymentSections?.length === 0 || paymentSections === null
                ? "Rápido y sencillo"
                : paymentSections && paymentSections[0]?.Card[2]?.Title}
            </Col>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              border: "0.5px solid rgba(255, 95, 0, 0.4)",
              marginRight: "30px",
              borderRadius: "15px",
              marginBottom: "15px",
              marginLeft: "30px",
              padding: "20px 30px",
            }}
          >
            <Col>
              <img
                style={{
                  backgroundColor: "rgba(255, 95, 0, 0.06)",
                  padding: "15px",
                  borderRadius: "6px",
                }}
                src={
                  paymentSections?.length === 0 || paymentSections === null
                    ? payment3
                    : paymentSections &&
                      paymentSections[0]?.Card[3]?.Icon?.data?.attributes?.url
                }
              />
            </Col>
            <Col
              style={{
                fontWeight: "400",
                fontSize: "24px",
                width: "124px",
                marginLeft: "15px",
              }}
            >
              {paymentSections?.length === 0 || paymentSections === null
                ? "Todo medio de pago"
                : paymentSections && paymentSections[0]?.Card[3]?.Title}
            </Col>
          </Col>
        </Row>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
          backgroundColor: "rgba(45, 45, 45, 0.04)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "55px",
            marginBottom: "55px",
          }}
        >
          <Col>
            <img
              style={{ marginRight: "100px" }}
              src={
                paymentSections?.length === 0 || paymentSections === null
                  ? bro
                  : paymentSections &&
                    paymentSections[1]?.Image?.data?.attributes?.url
              }
            />
          </Col>

          <Row style={{ display: "flex", flexDirection: "column" }}>
            <Row>
              <Col
                style={{
                  width: biggerThan900 ? "" : "350px",
                  marginLeft: biggerThan900 ? "" : "40px",
                  fontWeight: "600",
                  fontSize: "32px",
                  marginBottom: "40px",
                }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "¿Qué servicios puedo pagar en el portal?"
                  : paymentSections && paymentSections[1]?.Title}
              </Col>
            </Row>
            <Row
              style={{
                display: biggerThan900 ? "grid" : "flex",
                justifyContent: biggerThan900 ? "" : "center",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              }}
            >
              <Col
                style={{ fontSize: "14px", fontWeight: "500", width: "300px" }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "• Anticipo o Cuota Inicial"
                  : paymentSections && paymentSections[1]?.Button[0]?.Label}
              </Col>
              <Col
                id="pagos"
                style={{ fontSize: "14px", fontWeight: "500", width: "300px" }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "• Bolsas de Kilómetros"
                  : paymentSections && paymentSections[1]?.Button[3]?.Label}
              </Col>
              <Col
                style={{ fontSize: "14px", fontWeight: "500", width: "300px" }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "• Reserva"
                  : paymentSections && paymentSections[1]?.Button[1]?.Label}
              </Col>
              <Col
                style={{ fontSize: "14px", fontWeight: "500", width: "300px" }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "• Multas"
                  : paymentSections && paymentSections[1]?.Button[4]?.Label}
              </Col>
              <Col
                style={{ fontSize: "14px", fontWeight: "500", width: "300px" }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "• Despacho y Retiro a Domicilio Multas"
                  : paymentSections && paymentSections[1]?.Button[2]?.Label}
              </Col>
              <Col
                style={{ fontSize: "14px", fontWeight: "500", width: "300px" }}
              >
                {paymentSections?.length === 0 || paymentSections === null
                  ? "• Permisos Internacionales"
                  : paymentSections && paymentSections[1]?.Button[5]?.Label}
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "70px",
          marginTop: "70px",
        }}
      >
        <Col
          style={{
            border: " 0.5px solid rgba(255, 95, 0, 0.6)",
            padding: "15px 30px",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <Col
            style={{
              height: "30px",
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "16px",
            }}
          >
            {paymentSections?.length === 0 || paymentSections === null
              ? "Si eres Cliente Leasing Operativo"
              : paymentSections && paymentSections[1]?.Card[0]?.Title}
          </Col>
          {/* <Col
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#FF5F00",
              marginBottom: "15px",
            }}
          >
            Leasing Operativo
          </Col> */}
          <Col
            style={{ width: "250px", lineHeight: "20px", marginBottom: "40px" }}
          >
            {paymentSections?.length === 0 || paymentSections === null
              ? "Ingresa al siguiente link para gestionar tus pagos:"
              : paymentSections && paymentSections[1]?.Card[0]?.Paragraph}
          </Col>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <a href="https://www.webpay.cl/company/33000">
              <Button style={{ backgroundColor: "#FF5F00", height: "42px" }}>
                Paga tu leasing
              </Button>
            </a>
          </Row>
        </Col>
        <Col
          style={{
            border: " 0.5px solid rgba(255, 95, 0, 0.6)",
            padding: "15px 30px",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <Col
            style={{
              height: "30px",
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            {paymentSections?.length === 0 || paymentSections === null
              ? "Si eres Cliente Renting-Tripp"
              : paymentSections && paymentSections[1]?.Card[1]?.Title}
          </Col>
          {/* <Col
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#FF5F00",
              marginBottom: "15px",
            }}
          >
            Renting-tripp
          </Col> */}
          <Col
            style={{ width: "250px", lineHeight: "20px", marginBottom: "35px" }}
          >
            {paymentSections?.length === 0 || paymentSections === null
              ? "Ingresa al siguiente link para gestionar tus pagos:"
              : paymentSections && paymentSections[1]?.Card[1]?.Paragraph}
          </Col>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <a href="https://pagos.gamamobility.cl/">
              <Button style={{ backgroundColor: "#FF5F00", height: "42px" }}>
                Paga tu tripp
              </Button>
            </a>
          </Row>
        </Col>
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default Payment;
