import React, { useState } from 'react';
import Tooltip from '../Tooltip';
import './index.less';

const IconButton = ({ icon, onClick = () => {}, className, defaultMessage = '',  actionMessage = ''}) => {
  const [toolTipMessage, setToolTipMessage] = useState(false);

  const onChange = () => {
    onClick();
    setToolTipMessage(true);
    setTimeout(() => setToolTipMessage(false), 1000);
  }

  return (
    <div
      className={`container-icon-button ${className}`}
      onClick={onChange}
    >
      <Tooltip //Las props de tooltip son opcionales
        onChangeMessage={toolTipMessage}
        defaultMessage={defaultMessage}
        actionMessage={actionMessage}
      >
        {icon}
      </Tooltip>
    </div>
  );
};

export default IconButton;