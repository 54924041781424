import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../domain/Home";
import Dashboard from "../domain/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Users from "../domain/Users";
import NotFound from "../domain/NotFound";
import GuestRoute from "./GuestRoute";
import Customers from "../domain/Customers";
import Profile from "../domain/Profile";
import Roles from "../domain/Roles";
import Vehicles from "../domain/Vehicles";
import Fleet from "../domain/Fleet/index";
import Contact from "../domain/Contact/index";
import ContactUsed from "../domain/ContactUsed/index";
import Password from "../domain/Password";
import Mobility from "../domain/Mobility";
import Used from "../domain/Used";
import MobilityUS from "../domain/MobilityUS";
import Branches from "../domain/Branches";
import ContactMobility from "../domain/ContactMobility";
import Sinister from "../domain/Sinister";
import Payment from "../domain/Payment";
import FAQ from "../domain/FAQ";
import Posts from "../domain/Posts";
import LegalWarning from "../domain/LegalWarning";
import PrivacyPolices from "../domain/PrivacyPolices";
import Complaints from "../domain/Complaints";
import KiaRedirect from "../domain/KiaRedirect";
import Documents from "../domain/Documents";
import Requests from "../domain/Requests";
import Login from "../domain/Login";
import DownloadPDF from "../domain/downloadPDF";
import DownloadPDFAuto from "../domain/DownloadPDFAuto";
import Siniestros from "../domain/Siniestros";
import Invoices from "../domain/Invoices";
import Services from "../domain/Services";
import Reports from "../domain/Reports";
import InvoicesPropose from "../domain/InvoicePropose";
import ProtocolRedirect from "../domain/ProtocolRedirect";
import Maintenance from "../domain/Maintenance";
import SwaggerDocs from "../domain/Swagger";
import {
  getGamaAuthDoc,
  getGamaSharepointDoc,
  getGamaUsersDoc,
  getGamaVehiclesDoc,
} from "../requests/swagger";
import SinisterDeclaration from "../domain/SinisterDeclaration";
import SeePDF from "../domain/SeePDF";
import DriverPerfil from "../domain/DriverPerfil";
import DriverDocs from "../domain/DriverDocs";
import DriverNotifications from "../domain/DirverNotifications";

const isDevEnvironment = process.env.REACT_APP_ENV === "dev";
const isMaintenancePage = process.env.REACT_APP_ENABLE_MAINTENANCE_PAGE?.toLocaleLowerCase() === "true";

const RoutesWrapper = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {isDevEnvironment && (
            <>
              <Route
                path="/api/v1/docs/gama-auth"
                element={
                  <GuestRoute>
                    <SwaggerDocs callback={getGamaAuthDoc} />
                  </GuestRoute>
                }
              />

              <Route
                path="/api/v1/docs/gama-users"
                element={
                  <GuestRoute>
                    <SwaggerDocs callback={getGamaUsersDoc} />
                  </GuestRoute>
                }
              />

              <Route
                path="/api/v1/docs/gama-vehicles"
                element={
                  <GuestRoute>
                    <SwaggerDocs callback={getGamaVehiclesDoc} />
                  </GuestRoute>
                }
              />

              <Route
                path="/api/v1/docs/gama-sharepoint"
                element={
                  <GuestRoute>
                    <SwaggerDocs callback={getGamaSharepointDoc} />
                  </GuestRoute>
                }
              />
            </>
          )}

          <Route
            path="/"
            element={
              <GuestRoute>
                <Home />
              </GuestRoute>
            }
          />

          <Route
            path="/flota"
            element={
              <GuestRoute>
                <Fleet />
              </GuestRoute>
            }
          />

          <Route
            path="/contact"
            element={
              <GuestRoute>
                <Contact />
              </GuestRoute>
            }
          />

          <Route
            path="/declarar-siniestro"
            element={
              <GuestRoute>
                <SinisterDeclaration />
              </GuestRoute>
            }
          />

          <Route
            path="/posts/declaracionmemoriaGAMA2023.pdf"
            element={
              <GuestRoute>
                <SeePDF />
              </GuestRoute>
            }
          />

          <Route
            path="/complaints"
            element={
              <GuestRoute>
                <Complaints />
              </GuestRoute>
            }
          />

          <Route
            path="/maintenance"
            element={
              <GuestRoute>
                <Maintenance />
              </GuestRoute>
            }
          />

          <Route
            path="/legalWarning"
            element={
              <GuestRoute>
                <LegalWarning />
              </GuestRoute>
            }
          />

          <Route
            path="/privacyPolices"
            element={
              <GuestRoute>
                <PrivacyPolices />
              </GuestRoute>
            }
          />

          <Route
            path="/portal-de-siniestros"
            element={
              <GuestRoute>
                <Sinister />
              </GuestRoute>
            }
          />

          <Route
            path="/payment"
            element={
              <GuestRoute>
                <Payment />
              </GuestRoute>
            }
          />

          <Route
            path="/FAQ"
            element={
              <GuestRoute>
                <FAQ />
              </GuestRoute>
            }
          />

          <Route
            path="/posts"
            element={
              <GuestRoute>
                <Posts />
              </GuestRoute>
            }
          />

          <Route
            path="/login"
            element={
              isMaintenancePage ?
              <GuestRoute>
                <Maintenance/>
              </GuestRoute>
              :
              <GuestRoute>
                <Login />
              </GuestRoute>
            }
          />
          {
            isMaintenancePage && <Route
            path="/provisional-login"
            element={
              <GuestRoute>
                <Login />
              </GuestRoute>
            }
          />
          }


          <Route
            path="/wp-content/uploads/2022/05/Terminos-y-Condiciones-RAC-20-04-2022.pdf"
            element={
              <GuestRoute>
                <DownloadPDF />
              </GuestRoute>
            }
          />

          <Route
            path="/contactMobility"
            element={
              <GuestRoute>
                <ContactMobility />
              </GuestRoute>
            }
          />

          <Route
            path="/branches"
            element={
              <GuestRoute>
                <Branches />
              </GuestRoute>
            }
          />

          <Route
            path="/mobilityUs"
            element={
              <GuestRoute>
                <MobilityUS />
              </GuestRoute>
            }
          />

          <Route
            path="/contactUsed"
            element={
              <GuestRoute>
                <ContactUsed />
              </GuestRoute>
            }
          />

          <Route
            path="/usados"
            element={
              <GuestRoute>
                <Used />
              </GuestRoute>
            }
          />

          <Route
            path="/mobility"
            element={
              <GuestRoute>
                <Mobility />
              </GuestRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Ver documentos"
            element={
              <ProtectedRoute>
                <DriverDocs />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Mis datos"
            element={
              <ProtectedRoute>
                <DriverPerfil />
              </ProtectedRoute>
            }
          />

          <Route
            path="/notificaciones"
            element={
              <ProtectedRoute>
                <DriverNotifications />
              </ProtectedRoute>
            }
          />

          <Route
            path="/reportes"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />

          <Route
            path="/usuarios"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vehiculos"
            element={
              <ProtectedRoute>
                <Vehicles />
              </ProtectedRoute>
            }
          />

          <Route
            path="/protocolo-de-siniestros"
            element={
              <GuestRoute>
                <ProtocolRedirect />
              </GuestRoute>
            }
          />
          <Route
            path="/solicitudes"
            element={
              <ProtectedRoute>
                <Requests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/empresas"
            element={
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perfil"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/password"
            element={
              <ProtectedRoute>
                <Password />
              </ProtectedRoute>
            }
          />
          <Route
            path="/roles"
            element={
              <ProtectedRoute>
                <Roles />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts/declaracionmemoriaGAMA.pdf"
            element={
              <GuestRoute>
                <DownloadPDFAuto />
              </GuestRoute>
            }
          />

          <Route
            path="/contratos"
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/siniestros"
            element={
              <ProtectedRoute>
                <Siniestros />
              </ProtectedRoute>
            }
          />
          <Route
            path="/facturas"
            element={
              <ProtectedRoute>
                <Invoices />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Propuesta de Facturación"
            element={
              <ProtectedRoute>
                <InvoicesPropose />
              </ProtectedRoute>
            }
          />

          <Route
            path="/servicios"
            element={
              <ProtectedRoute>
                <Services />
              </ProtectedRoute>
            }
          />

          <Route path="/kiajustdrive/" element={<KiaRedirect />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RoutesWrapper;
