import { Dropdown as AntDropdownn } from 'antd'
import React from 'react'
import "./index.less";

const Dropdown = ({
  children,
  overlay,
  placement = "bottomLeft",
  overlayClassName = "container-custom-dropdown",
  ...rest
}) => {
  return (
      <AntDropdownn
        overlay={overlay}
        placement={placement}
        overlayClassName={overlayClassName}
        {...rest}
      >
        {children}
      </AntDropdownn>
  )
}

export default Dropdown;