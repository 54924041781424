import { Image, Typography } from "antd";
import React from "react";
import { formatRut } from "../../../utils/stringsTools";
const { Text } = Typography;

const ProfileUserCard = ({ urlImage, name, rut }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Image
        style={{
          borderRadius: 50,
          height: "80px", width: "80px"
        }}
        placeholder={true}
        src={urlImage}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          marginLeft: "15px",
        }}
      >
        <Text strong>{name}</Text>
        <Text>RUT: {formatRut(rut || "")}</Text>
      </div>
    </div>
  );
};

export default ProfileUserCard;
