import React from "react";
import "./index.less";

const BenefitCard = ({ children, icon, title, imagen }) => {
  return (
    <div className="container-benefit-card">
      {icon ? <div className="benefit-card-icon">{icon}</div> : null}
      {imagen ? (
        <img
          src={imagen}
          style={{ width: "350px", height: "200px", marginBottom: "20px" }}
        />
      ) : null}

      <h3 className="benefit-card-title">{title}</h3>
      <p className="benefit-card-description">{children}</p>
    </div>
  );
};

export default BenefitCard;
