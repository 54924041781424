import { GiftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Divider, Image, Layout, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Camioneta from "../../assets/img/kisspng-2018.webp";
import Logo1 from "../../assets/img/image 73.webp";
import Logo2 from "../../assets/img/image 74.webp";
import Logo3 from "../../assets/img/image 75.webp";
import Footer from "../../components/layouts/HomeLayout/Footer";

import Publicacion1 from "../../assets/img/Diseno-sin-titulo-1-768x585.webp";
import Publicacion2 from "../../assets/img/Portadas-Blog-GAMA-1-2-768x576.webp";
import Publicacion3 from "../../assets/img/Portadas-Blog-GAMA-1-3-768x576.webp";
import Logo from "../../assets/img/logo-mobility.webp";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { Link } from "react-router-dom";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import MobilityBanner from "../../assets/img/mobilitybanner.webp";
import { useScroll } from "../../utils/useScroll";
import Burger from "../../components/layouts/HomeLayout/Burger";
import MobiliySvg1 from "../../assets/img/rent a car.svg";
import MobiliySvg2 from "../../assets/img/leasing.svg";
import MobiliySvg3 from "../../assets/img/renting.svg";
import MobiliySvg4 from "../../assets/img/usados.svg";
import icon1 from "../../assets/img/1.webp";
import icon2 from "../../assets/img/2.webp";
import icon3 from "../../assets/img/3.webp";
import icon4 from "../../assets/img/4.webp";
import GoToTop from "../../components/GoToTop";

function Mobility() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  const { Text } = Typography;

  const [isHome, setIsHome] = useState();

  useEffect(() => {
    if (window.location.pathname === "/mobility") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const burguerData = [
    {
      title: "Sucursales",
      link: "/branches",
    },
    {
      title: "Nuestros servicios",
      link: "/mobility#services",
    },
    {
      title: "Quienes Somos",
      link: "/mobility#us",
    },
    { title: "Beneficios", link: "/mobility#benefits" },
    { title: "Publicaciones", link: "/mobility#posts" },
  ];

  const ourServices = [
    {
      title: "Gama Leasing",
      description:
        "Arriendo de flota de vehículos para empresas por periodos de mediano a largo plazo con equipamiento para cada rubro, según las necesidades de su empresa",
      link: "https://gamaleasing.cl/",
      icon: MobiliySvg1,
    },
    {
      title: "Tripp",
      description:
        "Arriendo de autos 0 KM para personas por un periodo de 12 o 24 meses, todo incluido por solo una cuota mensual.",
      link: "https://www.tripp.cl/",
      icon: MobiliySvg2,
    },
    {
      title: "Gama Rent a car",
      description:
        "Arriendo de autos para personas y empresas por periodos determinados por el cliente, con una gama amplia desde autos categoría Economy a automóviles Premium.",
      link: "https://www.gamarent.cl/",
      icon: MobiliySvg3,
    },
    {
      title: "Gama Usados",
      description:
        "Venta de autos usados para particulares con los estándares de garantía y calidad de Gama.",
      link: "/usados",
      icon: MobiliySvg4,
    },
  ];

  const ourBenefits = [
    {
      title: "Rapidez y calidad de servicio",
      description:
        "Entregamos un servicio de calidad, enfocado en proporcionar soluciones rápidas a las necesidades de Movilidad de nuestros clientes.​",
      icon: icon1,
    },
    {
      title: "Satisfacción de clientes y proveedores",
      description:
        "Aseguramos la satisfacción de nuestros clientes, tanto durante la entrega de nuestros servicios como durante el proceso de post venta.",
      icon: icon2,
    },
    {
      title: "Eficiencia y continuidad operacional",
      description:
        "Aseguramos la continuidad operacional de tu flota y brindamos un servicio eficaz, 100% Movilidad a todos nuestros clientes, para que tú o tu empresa siempre estén en movimiento.",
      icon: icon3,
    },
    {
      title: "Soluciones de movilidad flexibles y personalizadas",
      description:
        "Contamos con soluciones hechas a medida para satisfacer cada uno de los requerimientos y necesidades de nuestros clientes, ya sea en arriendo de flotas para empresas o de autos para particulares.",
      icon: icon4,
    },
  ];

  const CarouselImages = [
    {
      imagen: Publicacion1,
      title: "¿Cómo planear una ruta de transporte para mi empresa?",
      description:
        "Para el rubro de transporte de bienes, productos o servicios, diseñar una buena ruta comercial es la clave del éxito.…",
    },
    {
      imagen: Publicacion2,
      title:
        "Tecnologías del futuro, hoy: Todo lo que necesitas saber para tu compañía",
      description:
        "Para todas las industrias, rubros y empresas, los avances en tecnología son cada vez más grandes y beneficiosos. Si nos…",
    },
    {
      imagen: Publicacion3,
      title:
        "Beneficios de transformarte en un especialista sobre los autos eléctricos",
      description:
        "De los diversos temas e innovaciones que conciernen al mundo automotriz en su extensa gama, existe una tendencia que está…",
    },
    {
      imagen: Publicacion1,
      title: "¿Cómo planear una ruta de transporte para mi empresa?",
      description:
        "Para el rubro de transporte de bienes, productos o servicios, diseñar una buena ruta comercial es la clave del éxito.…",
    },
    {
      imagen: Publicacion2,
      title:
        "Tecnologías del futuro, hoy: Todo lo que necesitas saber para tu compañía",
      description:
        "Para todas las industrias, rubros y empresas, los avances en tecnología son cada vez más grandes y beneficiosos. Si nos…",
    },
    {
      imagen: Publicacion3,
      title:
        "Beneficios de transformarte en un especialista sobre los autos eléctricos",
      description:
        "De los diversos temas e innovaciones que conciernen al mundo automotriz en su extensa gama, existe una tendencia que está…",
    },
  ];

  const { scrollDirection } = useScroll();

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const options = {
    rewind: true,
    type: "loop",
    // autoplay: true,
    perPage: biggerThan900 ? 3 : 1,
    perMove: 1,
  };

  return (
    <div>
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `100vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${MobilityBanner})`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "/mobility"
                            ? "#FF3200"
                            : "white",
                      }}
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/mobility"}>
                      <Image preview={false} src={Logo} width={170} />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contactMobility">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/branches">
                                Sucursales
                              </a>
                              <a smooth href="/mobility#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="/mobility#us">
                                Quienes Somos
                              </a>
                              <a smooth href="/mobility#benefits">
                                Beneficios
                              </a>
                              <a smooth href="/mobility#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/branches">Sucursales</Link>
                              <a smooth href="/mobility#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="/mobility#us">
                                Quienes Somos
                              </a>
                              <a href="/mobility#benefits">Beneficios</a>
                              <a href="/mobility#posts">Publicaciones</a>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          modal={visible}
                          boton={false}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "0",
                paddingLeft: "0",
              }}
            ></h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "35px",
                fontSize: "40px",
                fontWeight: "400",
                paddingLeft: "0",
              }}
            >
              Más de 10 años creando soluciones de movilidad para empresas y
              personas.
            </h3>

            <Link to="/contactMobility">
              <Button
                id="services"
                style={{ width: "11rem", marginTop: "1.5rem" }}
              >
                Conversemos
              </Button>
            </Link>
          </div>
        </section>
      </Layout>
      <Row
        justify="center"
        style={{
          marginTop: biggerThan900 ? "100px" : "0px",
          marginBottom: "80px",
        }}
      >
        {biggerThan900 ? (
          <Text strong style={{ fontSize: "40px", fontWeight: "700" }}>
            Nuestros Servicios
          </Text>
        ) : (
          <Text
            strong
            style={{ fontSize: "40px", fontWeight: "700", marginTop: "50px" }}
          >
            Nuestros Servicios
          </Text>
        )}
      </Row>
      {biggerThan900 ? (
        <Row style={{ display: "flex" }} justify="center">
          {ourServices.map((item) => (
            <Row key={item.title} align="top" justify="center">
              <Row
                gutter={[16, 16]}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                  lineHeight: "24px",
                  marginLeft: "50px",
                  marginRight: "50px",
                }}
              >
                <Col>
                  <img
                    src={item.icon}
                    style={{ fontSize: "50px", color: "#E06329" }}
                  />
                </Col>
                <Col style={{ fontSize: "25px", fontWeight: "700" }} id="us">
                  {item.title}
                </Col>
                <Col style={{ minHeight: "180px", color: "#717171" }}>
                  {item.description}
                </Col>
                <Col style={{ display: "flex", align: "center" }}>
                  <a href={item.link}>
                    <Button
                      style={{ backgroundColor: "black", borderColor: "black" }}
                    >
                      Quiero saber mas
                    </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          ))}
        </Row>
      ) : (
        <div
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            width: "90%",
          }}
          className="arrow"
        >
          <Splide
            hasTrack={false}
            options={options}
            aria-label="My Favorite Images"
          >
            <SplideTrack id="us">
              {ourServices.map((vehicle) => (
                <SplideSlide key={vehicle.title}>
                  <div
                    id="particular"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "150px",
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Col>
                        <GiftOutlined
                          style={{
                            fontSize: "50px",
                            color: "#E06329",
                            marginRight: "20px",
                          }}
                        />
                      </Col>
                      <Col>
                        <h5
                          style={{
                            fontSize: "20px",
                            width: "287px",
                            fontWeight: "700",
                          }}
                        >
                          {vehicle.title}
                        </h5>
                      </Col>

                      <p
                        style={{
                          lineHeight: "24px",
                          fontSize: "16px",
                          width: "216px",
                          color: "#717171",
                          minHeight: "150px",
                        }}
                      >
                        {vehicle.description}
                      </p>

                      <Col
                        style={{
                          display: "flex",
                          align: "center",
                          marginBottom: "50px",
                        }}
                      >
                        <a href={vehicle.link}>Quiero saber mas</a>
                      </Col>
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </SplideTrack>
          </Splide>
        </div>
      )}
      <Divider style={{ width: "100%" }} />
      <Row justify="center">
        <Row
          justify="center"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "50px",
            marginLeft: biggerThan900 ? "0px" : "40px",
          }}
        >
          <Col
            style={{
              fontSize: "40px",
              fontWeight: "700",
              marginBottom: "16px",
            }}
          >
            Quienes Somos
          </Col>
          <Col
            style={{
              width: biggerThan900 ? "500px" : "350px",
              lineHeight: "20px",
              marginBottom: "26px",
              color: "#717171",
            }}
          >
            Gama es una Sociedad compuesta por Empresas Penta, Grupo Indumotora
            y Eurofrance. Nace para satisfacer la demanda del mercado en el
            arrendamiento de flotas de vehículos para empresas o de autos para
            particulares en el país, a través de los servicios de Gama Leasing,
            Tripp, Gama Rent a Car y Gama Usados.
          </Col>
          <Row
            justify="center"
            align="middle"
            style={{
              display: biggerThan900 ? "0px" : "flex",
              flexDirection: biggerThan900 ? "0px" : "column",
              justifyContent: biggerThan900 ? "0px" : "space-between",
            }}
          >
            <img
              style={{
                width: "102px",
                height: "50px",
                marginRight: "15px",
                marginBottom: biggerThan900 ? "0px" : "15px",
              }}
              src={Logo2}
            />
            <img
              style={{
                width: "165px",
                height: "18px",
                marginRight: "15px",
                marginBottom: biggerThan900 ? "0px" : "15px",
              }}
              src={Logo1}
            />
            <img
              style={{
                width: "158px",
                height: "43px",
                marginRight: "15px",
                marginBottom: biggerThan900 ? "0px" : "15px",
              }}
              src={Logo3}
            />
          </Row>
          <Button
            style={{
              backgroundColor: "black",
              borderColor: "black",
              width: "343px",
              marginTop: biggerThan900 ? "60px" : "30px",
            }}
            id="benefits"
          >
            <Link to="/mobilityUS">Conoce mas sobre nosotros</Link>
          </Button>
        </Row>
        <Row>
          <img
            style={{
              marginTop: "80px",
              marginLeft: "26px",
              width: "auto",
              height: "200px",
            }}
            src={Camioneta}
          />
        </Row>
        <Divider
          style={{
            width: "80%",
            marginTop: "50px",
          }}
        />
        <Row
          justify="center"
          style={{
            width: "70%",
            marginTop: biggerThan900 ? "80px" : "60px",
            marginBottom: biggerThan900 ? "70px" : "10px",
          }}
        >
          <Text strong style={{ fontSize: "40px", fontWeight: "700" }}>
            Beneficios
          </Text>
        </Row>
        {biggerThan900 ? (
          <Row style={{ display: "flex" }} justify="center">
            {ourBenefits.map((item) => (
              <Row key={item.title} align="top" justify="center">
                <Row
                  gutter={[16, 16]}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "230px",
                    lineHeight: "24px",
                    marginLeft: "50px",
                    marginRight: "50px",
                  }}
                >
                  <Col>
                    <img
                      src={item.icon}
                      style={{ fontSize: "50px", color: "#E06329" }}
                    />
                  </Col>
                  <Col
                    style={{
                      minHeight: "80px",
                      fontSize: "25px",
                      fontWeight: "700",
                    }}
                  >
                    {item.title}
                  </Col>
                  <Col
                    id="posts"
                    style={{ minHeight: "180px", color: "#717171" }}
                  >
                    {item.description}
                  </Col>
                </Row>
              </Row>
            ))}
          </Row>
        ) : (
          <div
            style={{
              marginTop: "50px",
              marginBottom: "50px",
              width: "80%",
            }}
            className="arrow"
          >
            <Splide
              hasTrack={false}
              options={options}
              aria-label="My Favorite Images"
            >
              <SplideTrack>
                {ourBenefits.map((vehicle) => (
                  <SplideSlide key={vehicle.title}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Row align="middle" style={{ marginBottom: "20px" }}>
                          <Col>
                            <img
                              src={vehicle.icon}
                              style={{
                                fontSize: "50px",
                                color: "#E06329",
                                marginRight: "15px",
                                marginBottom: "15px",
                              }}
                            />
                          </Col>

                          <h5
                            style={{
                              fontSize: "20px",
                              width: "287px",
                              fontWeight: "700",
                            }}
                            id="posts"
                          >
                            {vehicle.title}
                          </h5>
                        </Row>
                        <p
                          style={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            width: biggerThan900 ? "223px" : "300px",
                            marginBottom: biggerThan900 ? "50px" : "5px",
                            color: "#717171",
                          }}
                        >
                          {vehicle.description}
                        </p>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </div>
        )}
      </Row>
      <Divider />
      <div
        style={{
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Row
          justify="center"
          style={{
            width: "100%",
            marginTop: biggerThan900 ? "100px" : "50px",
            marginBottom: "80px",
          }}
        >
          <Text strong style={{ fontSize: "40px", fontWeight: "700" }}>
            Publicaciones
          </Text>
        </Row>
        <Splide
          hasTrack={false}
          options={options}
          aria-label="My Favorite Images"
        >
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <span
                style={{
                  backgroundColor: "#FF3200",
                  color: "#FF3200",
                  cursor: "pointer",
                  padding: "6px 6px 6px 6px",
                  borderRadius: "100%",
                  border: "1px solid #ff3200",
                }}
              >
                <RightOutlined />
              </span>
            </button>
            <button className="splide__arrow splide__arrow--next">
              <span
                style={{
                  backgroundColor: "#FF3200",
                  color: "#FF3200",
                  cursor: "pointer",
                  padding: "6px 6px 6px 6px",
                  borderRadius: "100%",
                  border: "1px solid #ff3200",
                }}
              >
                <RightOutlined />
              </span>
            </button>
          </div>
          <SplideTrack>
            {CarouselImages.map((vehicle) => (
              <SplideSlide key={vehicle.title}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    // width: "50%",
                  }}
                >
                  <img
                    style={{
                      width: "368px",
                      height: "320px",
                      borderTopLeftRadius: "50px",
                      borderTopRightRadius: "50px",
                      marginBottom: "50px",
                    }}
                    src={vehicle.imagen}
                    alt="truck"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "20px",
                        marginBottom: "20px",
                        width: "287px",
                        fontWeight: "700",
                      }}
                    >
                      {vehicle.title}
                    </h5>
                    <p
                      style={{
                        lineHeight: "24px",
                        fontSize: "16px",
                        width: "262px",
                        marginBottom: "50px",
                      }}
                    >
                      {vehicle.description}
                    </p>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide>
      </div>
      <Footer logo={Logo} />
    </div>
  );
}

export default Mobility;
