import React from "react";
import { useSelector } from "react-redux";
import { getAccessToken } from "../store/auth";
import { Navigate } from "react-router-dom";
import { message } from "antd";

export const GuestRoute = ({ children }) => {
  const token = useSelector(getAccessToken);
  const searchParams = new URLSearchParams(document.location.search);

  if (+searchParams.get('status') === 401) {
    message.error({
      content: "Permisos insuficientes, por favor póngase en contacto con Equipo Soporte Gama",
      duration: 5,
    });
    return <Navigate to="/" />;
  }

  if (+searchParams.get('status') === 402) {
    message.error({
      content: "Enlace caducado, intente restablecer contraseña nuevamente",
      duration: 5,
    });
    return <Navigate to="/" />;
  }
  
  
  if (token) {
    return <Navigate to="/dashboard" />;
  } else {
    return children;
  } 
};

export default GuestRoute;
