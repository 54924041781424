import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGamaUsadosContactContent,
  getGamaUsadosMainContent,
} from "../../../requests/strapi/gamausados";

export const getGamaUsadosHomeContent = createAsyncThunk(
  "gamausados/getGamaUsadosHomeContent",
  async (_, { rejectWithValue }) => {
    try {
      const { navbar, header, sections } = await getGamaUsadosMainContent();
      
      return {
        header: header.Header,
        navbar: navbar.Navbar,
        sections: sections.Sections,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaUsadosContactContents = createAsyncThunk(
  "gamausados/getGamaUsadosContactContents",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getGamaUsadosContactContent();
      const { BannerImage, Title } = header;

      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        sider: sections,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);
