import React, { useState, useEffect } from "react";
import {
    Col,
    Modal,
    Row,
    Button,
    Input,
} from "antd";
import {
    CloseOutlined,
} from "@ant-design/icons";
import { useMediaPredicate } from "react-media-hook";
import {
  changeStatusInvoicePropose,
} from '../../../requests/invoicePropose';
import Notifications from "../../../components/Notifications";


const { TextArea } = Input;

export const SendComment = ({
  open,
  setOpen,
  recordModal,
  setUpdatedInvoicePropose,
  updateState = true,
}) => {

  const secureRandomNumber = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / 4294967295;
  };

  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [comment, setComment] = useState('');

  const cancelApproveInvoice = () => {
    setComment('');
    setOpen(false);
  }

  useEffect(() => {
    if (!updateState) {
      setComment(recordModal?.comments);
    }
  }, [recordModal]);

  const rejectInvoicePropose = async () => {
    if (comment.length > 5000) {
      return Notifications.error(
        "Limite de caracteres",
        `El campo de comentario sobrepaso en limite de 5000 caracteres.`
      );
    }
    const { id, displayId } = recordModal;
    try {
      await changeStatusInvoicePropose(id, updateState? -1: recordModal.state, comment);
    } catch (error) {
      return Notifications.error(
        "Error al Cambiar estado",
        `Hubo un error al cambiar el estado de la propuesta de facturación ${displayId || id}.`
      );
    }
    cancelApproveInvoice();
    Notifications.success(
      "Se cambio el estado correctamente",
      `Se cambio el estado ${updateState ? 'a rechazado': '' } de la propuesta de facturación ${displayId || id}.`
    );
    setUpdatedInvoicePropose(secureRandomNumber());

  }

  return (
      <>
        <Modal
        bodyStyle={{ borderRadius: "60px" }}
        footer={false}
        closable={false}
        title={
          <>
            <Row align="middle" justify="space-between">
              <span
                style={{
                  fontWeight: "600",
                  fontSize: biggerThan900 ? "20px" : "15px",
                }}
              >
                { updateState? `Rechazar la propuesta ${recordModal.displayId}`: `Actualizar comentario ${recordModal.displayId}` } 
              </span>
              <Col>
                <CloseOutlined
                  onClick={cancelApproveInvoice}
                  style={{
                    color: "#FF3200",
                    backgroundColor: "rgba(255, 50, 0, 0.06)",
                    padding: "7px 7px 7px 7px",
                    marginRight: "15px",
                    fontSize: "16px",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                />
              </Col>
            </Row>
          </>
        }
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Row
          justify="center"
          className="button-upload-modal"
        >
            <Col xs={24}>
                <TextArea 
                  rows={2} 
                  placeholder="Ingresar observaciones del rechazo" 
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #D9D9D9',
                    borderRadius: '2px',
                    marginBottom: '20px',
                  }}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}  
                />
            </Col>
            <Col className="buttons-mobile">
                <Button
                  onClick={cancelApproveInvoice}
                  style={{ height: "40px" }}
                  className="gama-outline"
                >
                  Volver
                </Button>
            </Col>
            <Col className="buttons-mobile">
                <Button
                  onClick={() => rejectInvoicePropose()}
                  style={{ marginLeft: "16px", height: "40px" }}
                  className="gama-button"
                >
                  Continuar
                </Button>
            </Col>
        </Row>
        </Modal>
      </>
  )
}


export const RejectInvoiceModal = ({
    open,
    setOpen,
    recordModal,
    updatedInvoicePropose,
    setUpdatedInvoicePropose,
}) => {
    const [ openSendComment, setOpenSendComment] = useState(false);
    const biggerThan900 = useMediaPredicate("(min-width: 992px)");

    const cancelApproveInvoice = () => {
      setOpen(false);
    }

    return (
        <>
          <Modal
          bodyStyle={{ borderRadius: "60px" }}
          footer={false}
          closable={false}
          title={
            <>
              <Row align="middle" justify="space-between">
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: biggerThan900 ? "20px" : "15px",
                  }}
                >
                  Rechazar la propuesta {recordModal.displayId}
                </span>
                <Col>
                  <CloseOutlined
                    onClick={cancelApproveInvoice}
                    style={{
                      color: "#FF3200",
                      backgroundColor: "rgba(255, 50, 0, 0.06)",
                      padding: "7px 7px 7px 7px",
                      marginRight: "15px",
                      fontSize: "16px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
              </Row>
            </>
          }
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
        >
          <Row
            justify="center"
            className="button-upload-modal"
          >
              <Col >
                  <p>
                      Recuerde que después de cambiar el estado, no es posible volver atrás 
                      ¿Está de acuerdo con cambiar el estado de esta propuesta?
                  </p>
              </Col>

              <Col className="buttons-mobile">
                  <Button
                  onClick={cancelApproveInvoice}
                  style={{ height: "40px" }}
                  className="gama-outline"
                  >
                    Cancelar
                  </Button>
              </Col>
              <Col className="buttons-mobile">
                  <Button
                    onClick={() => {
                      setOpen(false);
                      setOpenSendComment(true);
                    }}
                    style={{ marginLeft: "16px", height: "40px" }}
                    className="gama-button"
                  >
                    Rechazar
                  </Button>
              </Col>
          </Row>
          </Modal>

          <SendComment
            open={openSendComment}
            setOpen={setOpenSendComment} 
            recordModal={recordModal}
            updatedInvoicePropose={updatedInvoicePropose}
            setUpdatedInvoicePropose={setUpdatedInvoicePropose}
          />
        </>
    )
}