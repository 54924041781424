export const countries = [
  {
    iso_code_2: "AR",
    iso_code_3: "ARG",
    phone_code: "+54",
    flag: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
    name: {
      ar: "الأرجنتين",
      bg: "Аржентина",
      cs: "Argentina",
      da: "Argentina",
      de: "Argentinien",
      el: "Αργεντινή",
      en: "Argentina",
      eo: "Argentino",
      es: "Argentina",
      et: "Argentina",
      eu: "Argentina",
      fi: "Argentiina",
      fr: "Argentine",
      hr: "Argentina",
      hu: "Argentína",
      hy: "Արգենտինա",
      it: "Argentina",
      ja: "アルゼンチン",
      ko: "아르헨티나",
      lt: "Argentina",
      nl: "Argentinië",
      no: "Argentina",
      pl: "Argentyna",
      pt: "Argentina",
      ro: "Argentina",
      ru: "Аргентина",
      sk: "Argentína",
      sl: "Argentina",
      sr: "Argentina",
      sv: "Argentina",
      th: "อาร์เจนตินา",
      uk: "Аргентина",
      zh: "阿根廷",
      "zh-tw": "阿根廷",
    },
  },

  {
    iso_code_2: "BR",
    iso_code_3: "BRA",
    phone_code: "+55",
    flag: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
    name: {
      ar: "البرازيل",
      bg: "Бразилия",
      cs: "Brazílie",
      da: "Brasilien",
      de: "Brasilien",
      el: "Βραζιλία",
      en: "Brazil",
      eo: "Brazilo",
      es: "Brasil",
      et: "Brasiilia",
      eu: "Brasil",
      fi: "Brasilia",
      fr: "Brésil",
      hr: "Brazil",
      hu: "Brazília",
      hy: "Բրազիլիա",
      it: "Brasile",
      ja: "ブラジル",
      ko: "브라질",
      lt: "Brazilija",
      nl: "Brazilië",
      no: "Brasil",
      pl: "Brazylia",
      pt: "Brasil",
      ro: "Brazilia",
      ru: "Бразилия",
      sk: "Brazília",
      sl: "Brazilija",
      sr: "Brazil",
      sv: "Brasilien",
      th: "บราซิล",
      uk: "Бразилія",
      zh: "巴西",
      "zh-tw": "巴西",
    },
  },

  {
    iso_code_2: "VE",
    iso_code_3: "VEN",
    phone_code: "+58",
    flag: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
    name: {
      ar: "فنزويلا",
      bg: "Венецуела",
      cs: "Venezuela",
      da: "Venezuela",
      de: "Venezuela",
      el: "Βενεζουέλα",
      en: "Venezuela (Bolivarian Republic of)",
      eo: "Venezuelo",
      es: "Venezuela",
      et: "Venezuela",
      eu: "Venezuela",
      fi: "Venezuela",
      fr: "Venezuela",
      hr: "Venezuela",
      hu: "Venezuela",
      hy: "Վենեսուելա",
      it: "Venezuela",
      ja: "ベネズエラ・ボリバル共和国",
      ko: "베네수엘라",
      lt: "Venesuela",
      nl: "Venezuela",
      no: "Venezuela",
      pl: "Wenezuela",
      pt: "Venezuela",
      ro: "Venezuela",
      ru: "Венесуэла",
      sk: "Venezuela",
      sl: "Venezuela",
      sr: "Venecuela",
      sv: "Venezuela",
      th: "เวเนซุเอลา",
      uk: "Венесуела",
      zh: "委内瑞拉",
      "zh-tw": "委內瑞拉",
    },
  },

  {
    iso_code_2: "UY",
    iso_code_3: "URY",
    phone_code: "+598",
    flag: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
    name: {
      ar: "الأوروغواي",
      bg: "Уругвай",
      cs: "Uruguay",
      da: "Uruguay",
      de: "Uruguay",
      el: "Ουρουγουάη",
      en: "Uruguay",
      eo: "Urugvajo",
      es: "Uruguay",
      et: "Uruguay",
      eu: "Uruguai",
      fi: "Uruguay",
      fr: "Uruguay",
      hr: "Urugvaj",
      hu: "Uruguay",
      hy: "Ուրուգվայ",
      it: "Uruguay",
      ja: "ウルグアイ",
      ko: "우루과이",
      lt: "Urugvajus",
      nl: "Uruguay",
      no: "Uruguay",
      pl: "Urugwaj",
      pt: "Uruguai",
      ro: "Uruguay",
      ru: "Уругвай",
      sk: "Uruguaj",
      sl: "Urugvaj",
      sr: "Urugvaj",
      sv: "Uruguay",
      th: "อุรุกวัย",
      uk: "Уругвай",
      zh: "乌拉圭",
      "zh-tw": "烏拉圭",
    },
  },

  {
    iso_code_2: "BO",
    iso_code_3: "BOL",
    phone_code: "+591",
    flag: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
    name: {
      ar: "بوليفيا",
      bg: "Боливия",
      cs: "Bolívie",
      da: "Bolivia",
      de: "Bolivien",
      el: "Βολιβία",
      en: "Bolivia (Plurinational State of)",
      eo: "Bolivio",
      es: "Bolivia",
      et: "Boliivia",
      eu: "Bolivia",
      fi: "Bolivia",
      fr: "Bolivie",
      hr: "Bolivija",
      hu: "Bolívia",
      hy: "Բոլիվիա",
      it: "Bolivia",
      ja: "ボリビア多民族国",
      ko: "볼리비아",
      lt: "Bolivija",
      nl: "Bolivia",
      no: "Bolivia",
      pl: "Boliwia",
      pt: "Bolívia",
      ro: "Bolivia",
      ru: "Боливия",
      sk: "Bolívia",
      sl: "Bolivija",
      sr: "Bolivija",
      sv: "Bolivia",
      th: "โบลิเวีย",
      uk: "Болівія",
      zh: "玻利维亚",
      "zh-tw": "玻利維亞",
    },
  },

  {
    iso_code_2: "CR",
    iso_code_3: "CRI",
    phone_code: "+506",
    flag: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
    name: {
      ar: "كوستاريكا",
      bg: "Коста Рика",
      cs: "Kostarika",
      da: "Costa Rica",
      de: "Costa Rica",
      el: "Κόστα Ρίκα",
      en: "Costa Rica",
      eo: "Kostariko",
      es: "Costa Rica",
      et: "Costa Rica",
      eu: "Costa Rica",
      fi: "Costa Rica",
      fr: "Costa Rica",
      hr: "Kostarika",
      hu: "Costa Rica",
      hy: "Կոստա Ռիկա",
      it: "Costa Rica",
      ja: "コスタリカ",
      ko: "코스타리카",
      lt: "Kosta Rika",
      nl: "Costa Rica",
      no: "Costa Rica",
      pl: "Kostaryka",
      pt: "Costa Rica",
      ro: "Costa Rica",
      ru: "Коста-Рика",
      sk: "Kostarika",
      sl: "Kostarika",
      sr: "Kostarika",
      sv: "Costa Rica",
      th: "คอสตาริกา",
      uk: "Коста-Рика",
      zh: "哥斯达黎加",
      "zh-tw": "哥斯大黎加",
    },
  },

  {
    iso_code_2: "CO",
    iso_code_3: "COL",
    phone_code: "+57",
    flag: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
    name: {
      ar: "كولومبيا",
      bg: "Колумбия",
      cs: "Kolumbie",
      da: "Colombia",
      de: "Kolumbien",
      el: "Κολομβία",
      en: "Colombia",
      eo: "Kolombio",
      es: "Colombia",
      et: "Colombia",
      eu: "Kolonbia",
      fi: "Kolumbia",
      fr: "Colombie",
      hr: "Kolumbija",
      hu: "Kolumbia",
      hy: "Կոլումբիա",
      it: "Colombia",
      ja: "コロンビア",
      ko: "콜롬비아",
      lt: "Kolumbija",
      nl: "Colombia",
      no: "Colombia",
      pl: "Kolumbia",
      pt: "Colômbia",
      ro: "Columbia",
      ru: "Колумбия",
      sk: "Kolumbia",
      sl: "Kolumbija",
      sr: "Kolumbija",
      sv: "Colombia",
      th: "โคลอมเบีย",
      uk: "Колумбія",
      zh: "哥伦比亚",
      "zh-tw": "哥倫比亞",
    },
  },

  {
    iso_code_2: "CU",
    iso_code_3: "CUB",
    phone_code: "+53",
    flag: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
    name: {
      ar: "كوبا",
      bg: "Куба",
      cs: "Kuba",
      da: "Cuba",
      de: "Kuba",
      el: "Κούβα",
      en: "Cuba",
      eo: "Kubo",
      es: "Cuba",
      et: "Kuuba",
      eu: "Kuba",
      fi: "Kuuba",
      fr: "Cuba",
      hr: "Kuba",
      hu: "Kuba",
      hy: "Կուբա",
      it: "Cuba",
      ja: "キューバ",
      ko: "쿠바",
      lt: "Kuba",
      nl: "Cuba",
      no: "Cuba",
      pl: "Kuba",
      pt: "Cuba",
      ro: "Cuba",
      ru: "Куба",
      sk: "Kuba",
      sl: "Kuba",
      sr: "Kuba",
      sv: "Kuba",
      th: "คิวบา",
      uk: "Куба",
      zh: "古巴",
      "zh-tw": "古巴",
    },
  },

  {
    iso_code_2: "CL",
    iso_code_3: "CHL",
    phone_code: "+56",
    flag: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
    name: {
      ar: "تشيلي",
      bg: "Чили",
      cs: "Chile",
      da: "Chile",
      de: "Chile",
      el: "Χιλή",
      en: "Chile",
      eo: "Ĉilio",
      es: "Chile",
      et: "Tšiili",
      eu: "Txile",
      fi: "Chile",
      fr: "Chili",
      hr: "Čile",
      hu: "Chile",
      hy: "Չիլի",
      it: "Cile",
      ja: "チリ",
      ko: "칠레",
      lt: "Čilė",
      nl: "Chili",
      no: "Chile",
      pl: "Chile",
      pt: "Chile",
      ro: "Chile",
      ru: "Чили",
      sk: "Čile",
      sl: "Čile",
      sr: "Čile",
      sv: "Chile",
      th: "ชิลี",
      uk: "Чилі",
      zh: "智利",
      "zh-tw": "智利",
    },
  },

  {
    iso_code_2: "EC",
    iso_code_3: "ECU",
    phone_code: "+593",
    flag: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
    name: {
      ar: "الإكوادور",
      bg: "Еквадор",
      cs: "Ekvádor",
      da: "Ecuador",
      de: "Ecuador",
      el: "Ισημερινός",
      en: "Ecuador",
      eo: "Ekvadoro",
      es: "Ecuador",
      et: "Ecuador",
      eu: "Ekuador",
      fi: "Ecuador",
      fr: "Équateur",
      hr: "Ekvador",
      hu: "Ecuador",
      hy: "Էկվադոր",
      it: "Ecuador",
      ja: "エクアドル",
      ko: "에콰도르",
      lt: "Ekvadoras",
      nl: "Ecuador",
      no: "Ecuador",
      pl: "Ekwador",
      pt: "Equador",
      ro: "Ecuador",
      ru: "Эквадор",
      sk: "Ekvádor",
      sl: "Ekvador",
      sr: "Ekvador",
      sv: "Ecuador",
      th: "เอกวาดอร์",
      uk: "Еквадор",
      zh: "厄瓜多尔",
      "zh-tw": "厄瓜多",
    },
  },

  {
    iso_code_2: "SV",
    iso_code_3: "SLV",
    phone_code: "+503",
    flag: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
    name: {
      ar: "السلفادور",
      bg: "Салвадор",
      cs: "Salvador",
      da: "El Salvador",
      de: "El Salvador",
      el: "Ελ Σαλβαδόρ",
      en: "El Salvador",
      eo: "Salvadoro",
      es: "El Salvador",
      et: "El Salvador",
      eu: "El Salvador",
      fi: "El Salvador",
      fr: "Salvador",
      hr: "Salvador",
      hu: "Salvador",
      hy: "Սալվադոր",
      it: "El Salvador",
      ja: "エルサルバドル",
      ko: "엘살바도르",
      lt: "Salvadoras",
      nl: "El Salvador",
      no: "El Salvador",
      pl: "Salwador",
      pt: "El Salvador",
      ro: "El Salvador",
      ru: "Сальвадор",
      sk: "Salvádor",
      sl: "Salvador",
      sr: "Salvador",
      sv: "El Salvador",
      th: "เอลซัลวาดอร์",
      uk: "Сальвадор",
      zh: "萨尔瓦多",
      "zh-tw": "薩爾瓦多",
    },
  },

  {
    iso_code_2: "HN",
    iso_code_3: "HND",
    phone_code: "+504",
    flag: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
    name: {
      ar: "هندوراس",
      bg: "Хондурас",
      cs: "Honduras",
      da: "Honduras",
      de: "Honduras",
      el: "Ονδούρα",
      en: "Honduras",
      eo: "Honduro",
      es: "Honduras",
      et: "Honduras",
      eu: "Honduras",
      fi: "Honduras",
      fr: "Honduras",
      hr: "Honduras",
      hu: "Honduras",
      hy: "Հոնդուրաս",
      it: "Honduras",
      ja: "ホンジュラス",
      ko: "온두라스",
      lt: "Hondūras",
      nl: "Honduras",
      no: "Honduras",
      pl: "Honduras",
      pt: "Honduras",
      ro: "Honduras",
      ru: "Гондурас",
      sk: "Honduras",
      sl: "Honduras",
      sr: "Honduras",
      sv: "Honduras",
      th: "ฮอนดูรัส",
      uk: "Гондурас",
      zh: "洪都拉斯",
      "zh-tw": "宏都拉斯",
    },
  },

  {
    iso_code_2: "MX",
    iso_code_3: "MEX",
    phone_code: "+52",
    flag: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
    name: {
      ar: "المكسيك",
      bg: "Мексико",
      cs: "Mexiko",
      da: "Mexico",
      de: "Mexiko",
      el: "Μεξικό",
      en: "Mexico",
      eo: "Meksiko",
      es: "México",
      et: "Mehhiko",
      eu: "Mexiko",
      fi: "Meksiko",
      fr: "Mexique",
      hr: "Meksiko",
      hu: "Mexikó",
      hy: "Մեքսիկա",
      it: "Messico",
      ja: "メキシコ",
      ko: "멕시코",
      lt: "Meksika",
      nl: "Mexico",
      no: "Mexico",
      pl: "Meksyk",
      pt: "México",
      ro: "Mexic",
      ru: "Мексика",
      sk: "Mexiko",
      sl: "Mehika",
      sr: "Meksiko",
      sv: "Mexiko",
      th: "เม็กซิโก",
      uk: "Мексика",
      zh: "墨西哥",
      "zh-tw": "墨西哥",
    },
  },

  {
    iso_code_2: "NI",
    iso_code_3: "NIC",
    phone_code: "+505",
    flag: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
    name: {
      ar: "نيكاراغوا",
      bg: "Никарагуа",
      cs: "Nikaragua",
      da: "Nicaragua",
      de: "Nicaragua",
      el: "Νικαράγουα",
      en: "Nicaragua",
      eo: "Nikaragvo",
      es: "Nicaragua",
      et: "Nicaragua",
      eu: "Nikaragua",
      fi: "Nicaragua",
      fr: "Nicaragua",
      hr: "Nikaragva",
      hu: "Nicaragua",
      hy: "Նիկարագուա",
      it: "Nicaragua",
      ja: "ニカラグア",
      ko: "니카라과",
      lt: "Nikaragva",
      nl: "Nicaragua",
      no: "Nicaragua",
      pl: "Nikaragua",
      pt: "Nicarágua",
      ro: "Nicaragua",
      ru: "Никарагуа",
      sk: "Nikaragua",
      sl: "Nikaragva",
      sr: "Nikaragva",
      sv: "Nicaragua",
      th: "นิการากัว",
      uk: "Нікарагуа",
      zh: "尼加拉瓜",
      "zh-tw": "尼加拉瓜",
    },
  },

  {
    iso_code_2: "PA",
    iso_code_3: "PAN",
    phone_code: "+507",
    flag: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
    name: {
      ar: "بنما",
      bg: "Панама",
      cs: "Panama",
      da: "Panama",
      de: "Panama",
      el: "Παναμάς",
      en: "Panama",
      eo: "Panamo",
      es: "Panamá",
      et: "Panama",
      eu: "Panama",
      fi: "Panama",
      fr: "Panama",
      hr: "Panama",
      hu: "Panama",
      hy: "Պանամա",
      it: "Panama",
      ja: "パナマ",
      ko: "파나마",
      lt: "Panama",
      nl: "Panama",
      no: "Panama",
      pl: "Panama",
      pt: "Panamá",
      ro: "Panama",
      ru: "Панама",
      sk: "Panama",
      sl: "Panama",
      sr: "Panama",
      sv: "Panama",
      th: "ปานามา",
      uk: "Панама",
      zh: "巴拿马",
      "zh-tw": "巴拿馬",
    },
  },

  {
    iso_code_2: "PY",
    iso_code_3: "PRY",
    phone_code: "+595",
    flag: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
    name: {
      ar: "باراغواي",
      bg: "Парагвай",
      cs: "Paraguay",
      da: "Paraguay",
      de: "Paraguay",
      el: "Παραγουάη",
      en: "Paraguay",
      eo: "Paragvajo",
      es: "Paraguay",
      et: "Paraguay",
      eu: "Paraguai",
      fi: "Paraguay",
      fr: "Paraguay",
      hr: "Paragvaj",
      hu: "Paraguay",
      hy: "Պարագվայ",
      it: "Paraguay",
      ja: "パラグアイ",
      ko: "파라과이",
      lt: "Paragvajus",
      nl: "Paraguay",
      no: "Paraguay",
      pl: "Paragwaj",
      pt: "Paraguai",
      ro: "Paraguay",
      ru: "Парагвай",
      sk: "Paraguaj",
      sl: "Paragvaj",
      sr: "Paragvaj",
      sv: "Paraguay",
      th: "ปารากวัย",
      uk: "Парагвай",
      zh: "巴拉圭",
      "zh-tw": "巴拉圭",
    },
  },

  {
    iso_code_2: "PE",
    iso_code_3: "PER",
    phone_code: "+51",
    flag: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
    name: {
      ar: "بيرو",
      bg: "Перу",
      cs: "Peru",
      da: "Peru",
      de: "Peru",
      el: "Περού",
      en: "Peru",
      eo: "Peruo",
      es: "Perú",
      et: "Peruu",
      eu: "Peru",
      fi: "Peru",
      fr: "Pérou",
      hr: "Peru",
      hu: "Peru",
      hy: "Պերու",
      it: "Perù",
      ja: "ペルー",
      ko: "페루",
      lt: "Peru",
      nl: "Peru",
      no: "Peru",
      pl: "Peru",
      pt: "Peru",
      ro: "Peru",
      ru: "Перу",
      sk: "Peru",
      sl: "Peru",
      sr: "Peru",
      sv: "Peru",
      th: "เปรู",
      uk: "Перу",
      zh: "秘鲁",
      "zh-tw": "秘魯",
    },
  },

  {
    iso_code_2: "DO",
    iso_code_3: "DOM",
    phone_code: "+1849",
    flag: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
    name: {
      ar: "جمهورية الدومينيكان",
      bg: "Доминиканска република",
      cs: "Dominikánská republika",
      da: "Dominikanske Republik",
      de: "Dominikanische Republik",
      el: "Δομινικανή Δημοκρατία",
      en: "Dominican Republic",
      eo: "Dominika Respubliko",
      es: "República Dominicana",
      et: "Dominikaani Vabariik",
      eu: "Dominikar Errepublika",
      fi: "Dominikaaninen tasavalta",
      fr: "République dominicaine",
      hr: "Dominikanska republika",
      hu: "Dominikai Köztársaság",
      hy: "Դոմինիկյան Հանրապետություն",
      it: "Rep. Dominicana",
      ja: "ドミニカ共和国",
      ko: "도미니카 공화국",
      lt: "Dominikos Respublika",
      nl: "Dominicaanse Republiek",
      no: "Den dominikanske republikk",
      pl: "Dominikana",
      pt: "República Dominicana",
      ro: "Republica Dominicană",
      ru: "Доминиканская Республика",
      sk: "Dominikánska republika",
      sl: "Dominikanska republika",
      sr: "Dominikanska Republika",
      sv: "Dominikanska republiken",
      th: "สาธารณรัฐโดมินิกัน",
      uk: "Домініканська Республіка",
      zh: "多米尼加",
      "zh-tw": "多明尼加",
    },
  },

  {
    iso_code_2: "GT",
    iso_code_3: "GTM",
    phone_code: "+502",
    flag: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
    name: {
      ar: "غواتيمالا",
      bg: "Гватемала",
      cs: "Guatemala",
      da: "Guatemala",
      de: "Guatemala",
      el: "Γουατεμάλα",
      en: "Guatemala",
      eo: "Gvatemalo",
      es: "Guatemala",
      et: "Guatemala",
      eu: "Guatemala",
      fi: "Guatemala",
      fr: "Guatemala",
      hr: "Gvatemala",
      hu: "Guatemala",
      hy: "Գվատեմալա",
      it: "Guatemala",
      ja: "グアテマラ",
      ko: "과테말라",
      lt: "Gvatemala",
      nl: "Guatemala",
      no: "Guatemala",
      pl: "Gwatemala",
      pt: "Guatemala",
      ro: "Guatemala",
      ru: "Гватемала",
      sk: "Guatemala",
      sl: "Gvatemala",
      sr: "Gvatemala",
      sv: "Guatemala",
      th: "กัวเตมาลา",
      uk: "Гватемала",
      zh: "危地马拉",
      "zh-tw": "瓜地馬拉",
    },
  },

  {
    iso_code_2: "DM",
    iso_code_3: "DMA",
    phone_code: "+1767",
    flag: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
    name: {
      ar: "دومينيكا",
      bg: "Доминика",
      cs: "Dominika",
      da: "Dominica",
      de: "Dominica",
      el: "Δομινίκα",
      en: "Dominica",
      eo: "Dominiko",
      es: "Dominica",
      et: "Dominica",
      eu: "Dominika",
      fi: "Dominica",
      fr: "Dominique",
      hr: "Dominika",
      hu: "Dominikai Közösség",
      hy: "Դոմինիկա",
      it: "Dominica",
      ja: "ドミニカ国",
      ko: "도미니카 연방",
      lt: "Dominika",
      nl: "Dominica",
      no: "Dominica",
      pl: "Dominika",
      pt: "Dominica",
      ro: "Dominica",
      ru: "Доминика",
      sk: "Dominika",
      sl: "Dominika",
      sr: "Dominika",
      sv: "Dominica",
      th: "ดอมินีกา",
      uk: "Домініка",
      zh: "多米尼克",
      "zh-tw": "多米尼克",
    },
  },
];
