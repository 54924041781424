import axios from "axios";
const TOKEN_NAME = "gama.token";
/**
 * Create an Axios Client with defaults
 */
export const client = axios.create();

// Manejo de códigos de error del backend.
client.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
  if (!options.public) {
    client.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      TOKEN_NAME
    )}`;
  }

  const onSuccess = (response) => response;

  const onError = (error) => Promise.reject(error.response || error.message);

  return client(options).then(onSuccess).catch(onError);
};

export default request;
