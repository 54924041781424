import {
    PlusSquareOutlined,
    MinusSquareOutlined,
    EllipsisOutlined,
    ClearOutlined,
} from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Table,
  Typography,
  Button,
  Dropdown,
  Menu,
} from "antd";
import useWindowDimensions from "../../../hooks/useWindowsDimensions";
import React, { useEffect, useState } from "react";
import moment from "moment/dist/moment";
import { useSelector } from "react-redux";
import { isSuperAdmin, isExecutive } from "../../../utils/roles";
import useTableQuery from "../../../hooks/useTableQuery";
import documentsIcon from "../../../assets/img/documents.png";
import Notifications from "../../../components/Notifications";
import { getUserData } from "../../../store/auth";
import circleTable from "../../../assets/img/circletable2.png";
import {
  getAllInvoicePropose,
} from "../../../requests/invoicePropose";
import { ApproveInvoiceModal } from './approveModal';
import {
  RejectInvoiceModal
} from './rejectModal';
import InvoicesProposeDetails from './InvoiceProposeDetails';

export default function InvoicesProposePendingTable({
  monthFilter,
  yearFilter,
  updatedInvoicePropose,
  setUpdatedInvoicePropose,
}) {
    const [data, setData] = useState([]);
    const { height, width } = useWindowDimensions();
    const { accesses } = useSelector(getUserData);
    const userData = useSelector(getUserData);
    const [loading, setLoading] = useState(true);
    const [isApproveModalOpened, setIsApproveModalOpened] = useState(false);
    const [isRejectModalOpened, setIsRejectModalOpened] = useState(false);
    const [openInvoiceProposeDetails, setOpenInvoiceProposeDetails] = useState(false);
    const [recordModal, setRecordModal] = useState({});

    //Invoices Propose
    const [searchFilter, setSearchFilter] = useState('');
    const [search, setSearch] = useState('');
    const [showTable, setShowTable] = useState(true);

    const {
      currentPage,
      pageSize,
      handlePaginationChange,
      onSearch,
      setCurrentPage,
    } = useTableQuery();
    
    moment.locale("es");

  
    useEffect(async () => {
      setLoading(true);
      try {
        setShowTable(true);
        const data = await getAllInvoicePropose(yearFilter.format('YYYY'), monthFilter, searchFilter);
        setData(data.filter((r) => r.state === 0).map((r) => {
          return {
            ...r,
            expanded: false,
            isExpandable: 'hidden',
          }
        }));
        setCurrentPage(1);
      } catch (error) {
        Notifications.error(
          "Error al cargar",
          "Hubo un error al cargar las propuestas de facturación pendientes."
        );
      }
      setLoading(false);
    
    }, [monthFilter, yearFilter, searchFilter, updatedInvoicePropose, accesses]);
  
    const { Text } = Typography;
  
    let expandedRowKeys = [];
  
    function handleRowKeys(isExpansion, record) {
      let rowKey = record.id;
  
      if (isExpansion) {
        expandedRowKeys.push(rowKey);
      } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
    }

    const mobileColumns = [
      {
        title: <Text strong>ID</Text>,
        dataIndex: "displayId",
        key: "id",
        align: "left",
        isExpandable: true,
        sorter: {
          compare: (a, b) => a.displayId.localeCompare(b.displayId),
        },
      },
    ];
  

    const columns = [
      {
        title: <Text strong>ID</Text>,
        dataIndex: "displayId",
        key: "id",
        align: "left",
        isExpandable: true,
        sorter: {
          compare: (a, b) => a.displayId.localeCompare(b.displayId),
        },
      },
      {
        title: <Text strong>Tipo</Text>,
        dataIndex: "type",
        key: "type",
        align: "left",
        width: 170,
        sorter: {
          compare: (a, b) => a.type?.localeCompare(b.type),
        },
      },
      {
        title: <Text strong style={{ display: 'flex', justifyContent: 'right' }}>Periodo</Text>,
        dataIndex: "period",
        key: "period",
        align: "right",
        width: 170,
      },
      {
        title: <Text strong style={{ display: 'flex', justifyContent: 'center' }}>Propuesta</Text>,
        dataIndex: "id",
        key: "id3",
        align: "center",
        render: (_text, record) => (
          <span
            style={{
              backgroundColor: "rgba(255, 50, 0, 0.08)",
              color: "#FF3200",
              cursor: "pointer",
              padding: "7px 7px 7px 7px",
              borderRadius: "100%",
              border: "1px solid #ff3200",
            }}
            onClick={() => {
              setRecordModal(record);
              setOpenInvoiceProposeDetails(true)
            }}
          >
            <img src={documentsIcon} alt="table documents icon" />
          </span>
        ),
      },
      {
        title: <Text strong style={{ display: 'flex', justifyContent: 'center' }}>Estado</Text>,
        // dataIndex: "state",
        key: "state",
        align: "center",
        width: 150,
        render: () => {
          return (
            <span
              style={{
                backgroundColor: "#FAFAFA",
                padding: "4px 7px 4px 7px",
                borderRadius: "6px",
                border: "1px solid #BFBFBF",
                color: "#8C8C8C",
              }}
            >
              Pendiente
            </span>
          );
        },
      },
      {
        title: "Acciones",
        key: "action",
        align: "right",
        render: (_text, record) => (
          <div style={{
            display: 'flex',
            justifyContent: 'right'
          }}>
            <Dropdown overlay={renderMenu(record)} placement="bottomRight" >
              <Button className="action-table-button">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
      },
    ];

    const closeInvoiceProposeDetails = () => {
      setOpenInvoiceProposeDetails(false);
    };

    const renderMenu = (record) => {
      return (
        <Menu className="menu-invoice-propose">
          <Menu.Item 
            onClick={() => {
              setIsApproveModalOpened(true);
              setRecordModal(record);
            }}
            key={"1"}
          >
            Aprobar
          </Menu.Item>
          <Menu.Item 
            onClick={() => { 
              setIsRejectModalOpened(true);
              setRecordModal(record);
            }}
            key={"2"}
          >
            Rechazar
          </Menu.Item>
        </Menu>
      );
    };


    if((isSuperAdmin(accesses) || isExecutive(accesses))  && userData.type !== 'CLIENT') {
      columns.splice(1, 0, {
        title: <Text strong>RUT</Text>,
        dataIndex: "companyRun",
        key: "rut",
        align: "left",
        width: 170,
        // sorter: {
        //   compare: (a, b) => a.rut?.localeCompare(b.rut),
        // },
      });
      
      mobileColumns.push({
        title: <Text strong>RUT</Text>,
        dataIndex: "companyRun",
        key: "rut",
        align: "left",
        width: 170,
        // sorter: {
        //   compare: (a, b) => a.rut?.localeCompare(b.rut),
        // },
      })
    }  else {
      mobileColumns.push({
        title: <Text strong>Tipo</Text>,
        dataIndex: "type",
        key: "type",
        align: "left",
        width: 170,
        sorter: {
          compare: (a, b) => a.type?.localeCompare(b.type),
        },
      });
    }

    const { Search } = Input;
    return (
        <div className="container-vehicles-table">
          {width <= 640 ? (
            <>
              {showTable &&
                <>
                  <Row>
                    { userData.type !== 'CLIENT' && 
                    <>
                    <Col xs={21}>
                      <Search
                        placeholder="Buscar por RUT"
                        onSearch={(value) => {
                          setSearchFilter(value);
                        }}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        value={search}
                        style={{
                          width: '100%',
                        }} />
                    </Col>

                    <Col xs={3}>
                      <Button
                        onClick={() => {
                          setSearchFilter('');
                          setSearch('');
                        }}
                        style={{
                          backgroundColor: "rgba(255, 50, 0, 0.06)",
                          color: "#FF3200",
                          fontSize: "20px",
                          border: "none",
                          padding: "6px 10px",
                          marginLeft: "10px",
                          borderRadius: "5px",
                        }}
                        icon={<ClearOutlined />}
                      />
                    </Col>
                    </>
                    }
                  </Row>
                  
                  <Table
                    columns={mobileColumns}
                    expandedRowRender={(record) => (
                      <Row>
                            <Col style={{ marginBottom: "14px" }} span={14}>
                              <Text strong>Tipo</Text>
                            </Col>
                            <Col style={{ marginBottom: "14px" }} span={10}>
                              {record?.type}
                            </Col>
                            <Col style={{ marginBottom: "14px" }} span={14}>
                              <Text strong>Periodo</Text>
                            </Col>
                            <Col style={{ marginBottom: "14px" }} span={10}>
                              {record?.period}
                            </Col>
                            <Col style={{ marginBottom: "14px" }} span={14}>
                              <Text style={{ fontSize: "15px" }} strong>
                                Estado de factura
                              </Text>
                            </Col>
                        <Col
                          style={{
                            display: 'inline-flex',
                            backgroundColor: "#FAFAFA",
                            borderRadius: '6px',
                            padding: "4px 8px",
                            border: "1px solid #BFBFBF",
                            color: "#8C8C8C",
                            height: 'min-content',
                          }}
                        >
                           Pendiente
                        </Col>

                        <Col 
                          style={{ 
                            marginLeft: "60px",
                            marginTop: "15px",
                          }} 
                          xs={24}
                        >
                          <Dropdown 
                            overlay={() => {
                              return (
                                <div className="downdown-more-actions">
                                  <Row>
                                    <Col 
                                      xs={24}
                                      className="options-more-actions"
                                      onClick={() => {
                                        record.expanded = false;
                                        handleRowKeys(false, record);
                                        setRecordModal(record);
                                        setOpenInvoiceProposeDetails(true)
                                      }}
                                    >
                                      Ver propuesta
                                    </Col>
                                    <Col 
                                      xs={24} 
                                      className="options-more-actions"
                                      onClick={() => {
                                        record.expanded = false;
                                        handleRowKeys(false, record);
                                        setIsApproveModalOpened(true);
                                        setRecordModal(record);
                                      }}
                                    >
                                      Aprobar
                                    </Col>
                                    <Col 
                                      xs={24}
                                      className="options-more-actions"
                                      onClick={() => {
                                        record.expanded = false;
                                        handleRowKeys(false, record);
                                        setIsRejectModalOpened(true);
                                        setRecordModal(record);
                                      }}
                                    >
                                      Rechazar
                                    </Col>
                                  </Row>
                                </div>
                              )
                            }}
                            placement="bottomCenter" 
                            trigger={['click', 'hover']}
                          >
                            <Button 
                              className="button-more-actions"
                            >
                              Más acciones
                            </Button>
                          </Dropdown>
                        </Col>

                      </Row>
                    )}
                    expandIcon={(props) => {
            
                        if (props.record.expanded) {
                          return (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <MinusSquareOutlined
                                style={{ width: "30px" }}
                                onClick={(e) => {
                                  props.record.expanded = false;
                                  props.onExpand(props.record, e);
                                }}
                              />
                              <span>
                                  <img src={circleTable} />
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <PlusSquareOutlined
                                style={{ width: "30px" }}
                                onClick={(e) => {
                                  props.record.expanded = true;
                                  props.onExpand(props.record, e);
                                }}
                              />
                              <span>
                                  <img src={circleTable} />
                              </span>
                            </div>
                          );
                        }
                    }}
                    rowClassName={(record) =>
                      record?.isExpandable ? "show" : "hidden"
                    }
                    expandedRowKeys={expandedRowKeys}
                    onExpand={handleRowKeys}
                    rowKey={(record) => record.id}
                    dataSource={data ?? []}
                    showSorterTooltip={false}
                    pagination={{
                      current: currentPage,
                      pageSize,
                      total: data.length,
                      position: ["bottomCenter"],
                    }}
                    onChange={handlePaginationChange}
                    sortDirection={["ASC", "DESC"]}
                    loading={loading}
                  />
                </>
              }
            </>
          ) : (
            <>
              {showTable &&
                <>
                  <Row
                    style={{ marginBottom: "20px", marginTop: "15px", width: "100%" }}
                  >
                    <Col sm={24} md={24} xs={24} style={{
                      marginBottom: '10px'
                    }}>
                     <strong>Propuestas de facturas Pendientes</strong>
                    </Col>
                    { userData.type !== 'CLIENT' && 
                    <Row gutter={[24, 24]}>
                      <Col>
                        <Search
                          placeholder="Buscar por RUT"
                          onSearch={(value) => {
                            setSearchFilter(value);
                          }}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          value={search}
                          style={{
                            width: '300px',
                          }} />
                      </Col>
                      <Col>
                        <a
                          style={{
                            border: "none",
                            textAlign: "bottom",
                            color: "#FF3200",
                            textDecoration: "underline",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            setSearchFilter('');
                            setSearch('');
                          }}
                        >
                          Limpiar
                        </a>
                      </Col>
                    </Row>
                    }
                  </Row>


                  <Table
                    className="sius"
                    dataSource={data ?? []}
                    onSearch={onSearch}
                    loading={loading}
                    pagination={{
                      current: currentPage,
                      pageSize,
                      total: data.length,
                      position: ["bottomCenter"],
                    }}
                    onChange={handlePaginationChange}
                    sortDirection={["ASC", "DESC"]}
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    showSorterTooltip={false} />
                  </>
                
              }

            </>
          )}
          

          <ApproveInvoiceModal
            open={isApproveModalOpened}
            setOpen={setIsApproveModalOpened}
            recordModal={recordModal}
            updatedRequest={updatedInvoicePropose}
            setUpdatedInvoicePropose={setUpdatedInvoicePropose}
          />

          <InvoicesProposeDetails
            open={openInvoiceProposeDetails}
            onClose={closeInvoiceProposeDetails}
            recordModal={recordModal}
          />

          <RejectInvoiceModal
            open={isRejectModalOpened}
            setOpen={setIsRejectModalOpened}
            recordModal={recordModal}
            updatedRequest={updatedInvoicePropose}
            setUpdatedInvoicePropose={setUpdatedInvoicePropose}
          />

        </div>
    );
}
  