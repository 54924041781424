import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../components/layouts/DashboradLayout/index"
import useTableQuery from "../../hooks/useTableQuery";
import { getVehicles } from "../../requests/vehicles";
import { getUserData } from "../../store/auth";
import ClientTable from  "../Vehicles/ClientTable";
import VehiclesTable from "../Vehicles/VehiclesTable";
import "../Vehicles/index.less";
import { isSuperAdmin } from "../../utils/roles";
import { getAdminFiltersData } from "../../store/vehicles/thunk";
import * as DOMPurify from "dompurify";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const handleFilters = (key, value) => {
    setFilters((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const handleSearch = (value) => {
    setCurrentPage(1);
    let cleanValue = DOMPurify.sanitize(value);
    handleFilters("search", cleanValue.replace(/\./g, ""));
  };
  const handleFilterByBrand = (value) => handleFilters("brand", value);
  const handleFilterByCustomer = (value) => handleFilters("customer", value);
  const handleFilterByModel = (value) => handleFilters("model", value);
  const handleClearFilters = () => setFilters({});

  const userData = useSelector(getUserData);

  const clientData = useMemo(() => {
    if (!data?.results) return [];
  
    return data.results.map((item) => {
      const device = item.device || {};
      const contract = item.contract || {};
  
      return {
        deviceId: device.deviceId || "No disponible",
        brandId: device.brandId || "No disponible",
        classId: device.classId || "No disponible",
        rentname: contract.rentName || "No disponible",
        fuelId: device.fuelId || "No disponible",
        modelYear: (device.modelYear || "").toString() || "No disponible",
        trasmissionId: device.trasmissionId || "No disponible",
        exteriorId: device.exteriorId || "No disponible",
        rentId: contract.rentId || "No disponible",
        rentStatus: contract.rentStatus || "No disponible",
        deviceGroupId: device.deviceGroupId || "No disponible",
        startDate: (item.pickupDate || "").toString() || "No disponible",
        endDate: (item.scheduledReturnDate || "").toString() || "No disponible",
        contractId: contract.rentId || "No disponible",
        rentDeviceStatus: item.rentDeviceStatus || "No disponible",
        driveTrainId: device.driveTrainId || "No disponible",
        salesPrice: item.salesPrice || "No disponible",
        currencyCode: item.currencyCode || "No disponible",
        deductible: item.deductible || "",
        maxTravel: item.maxTravel || "",
        isExpandable: true,
      };
    });
  }, [data]);

const gamaData = useMemo(() => {
  if (!data?.results) return [];

  return data.results.map((item) => {
    const device = item.device || {};
    const contract = item.contract || {};

    return {
      deviceId: device.deviceId || "No disponible",
      itemId: device.itemId || "No disponible",
      masterId: device.masterId || "No disponible",
      deviceGroupId: device.deviceGroupId || "No disponible",
      brandId: device.brandId || "No disponible",
      classId: device.classId || "No disponible",
      deviceName: device.deviceName || "No disponible",
      driveTrainId: device.driveTrainId || "No disponible",
      fuelId: device.fuelId || "No disponible",
      modelYear: (device.modelYear || "").toString() || "No disponible",
      trasmissionId: device.trasmissionId || "No disponible",
      exteriorId: device.exteriorId || "No disponible",
      rentDeviceStatus: item.rentDeviceStatus || "No disponible",
      contractClassId: contract.classId || "No disponible",
      collectionStartDate: contract.collectionStartDate || "No disponible",
      collectionStatus: contract.collectionStatus || "No disponible",
      custAccount: contract.custAccount || "No disponible",
      rentName: contract.rentName || "No disponible",
      email: contract.email || "No disponible",
      phone: contract.phone || "No disponible",
      rentStatus: contract.rentStatus || "No disponible",
      deliveryPostalAddress: contract.deliveryPostalAddress || "No disponible",
      rentDeviceName: item.rentDeviceName || "No disponible",
      startDate: (item.pickupDate || "").toString() || "No disponible",
      endDate: (item.scheduledReturnDate || "").toString() || "No disponible",
      rentId: contract.rentId || "No disponible",
      isExpandable: true,
      class: item.classId || "No disponible",
      salesPrice: item.salesPrice || "No disponible",
      currencyCode: item.currencyCode || "No disponible",
      deductible: item.deductible === null || item.deductible === "0" ? "" : item.deductible,
      maxTravel: item.maxTravel === 0 || item.maxTravel === null ? "" : item.maxTravel,
    };
  });
}, [data]);

  const [loading, setLoading] = useState(false);

  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    onSearch,
    query,
    setCurrentPage,
  } = useTableQuery();

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        setLoading(true);

        if (
          Object.keys(filters).find((f) => f === "customer") &&
          !Object.keys(filters).find((f) => f === "brand") &&
          !Object.keys(filters).find((f) => f === "model") &&
          isSuperAdmin(userData.accesses)
        ) {
          dispatch(getAdminFiltersData({ filters, currentPage, query }));
        }

        const { data } = await getVehicles(filters, currentPage, query);
        setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, [currentPage, filters, query]);

  return (
    <DashboardLayout isDashboard={true} title="Mi Flota">
      {userData.type === "GAMA" ? (
        <VehiclesTable
        currentPage={currentPage}
        pageSize={pageSize}
        handleFilterByCustomer={handleFilterByCustomer}
        handleFilterByBrand={handleFilterByBrand}
        handlePaginationChange={handlePaginationChange}
        setCurrentPage={setCurrentPage}
        handleFilterByModel={handleFilterByModel}
        onSearched={onSearch}
        handleSearch={handleSearch}
        query={query}
        data={gamaData || []}
        total={data?.total || 1}
        loading={loading}
        handleClearFilters={handleClearFilters}
        filters={filters}
      />
      ) : (
        
        <ClientTable
        currentPage={currentPage}
        pageSize={pageSize}
        handleFilterByBrand={handleFilterByBrand}
        handleFilterByModel={handleFilterByModel}
        handleFilterByCustomer={handleFilterByCustomer}
        handlePaginationChange={handlePaginationChange}
        setCurrentPage={setCurrentPage}
        onSearched={onSearch}
        handleSearch={handleSearch}
        query={query}
        data={clientData || []}
        total={data?.total || 1}
        loading={loading}
        handleClearFilters={handleClearFilters}
        filters={filters}
        userData={userData}
      />
      )}
    </DashboardLayout>
  );
}
