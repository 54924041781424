import { useEffect, useState } from "react";

const useTableQuery = () => {
  const DEFAULT_CURRENT_PAGE = 1;
  const DEFAULT_PAGE_SIZE = 7;

  const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);

  const [search, setSearch] = useState(undefined);
  const [orderByProperty, setOrderByProperty] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);

  const handlePagination = ({ current }) => setCurrentPage(current);

  const handleSort = ({ columnKey, order }) => {
    setCurrentPage(DEFAULT_CURRENT_PAGE);
    setOrderBy(order);
    setOrderByProperty(order ? columnKey : undefined);
  };

  const handlePaginationChange = (_pagination, _filter, _sort) => {
    handleSort(_sort);
    handlePagination(_pagination);
  };

  const onSearch = (_search) => {
    setCurrentPage(DEFAULT_CURRENT_PAGE);
    setSearch(_search === "" ? undefined : _search);
  };

  const [query, setQuery] = useState({});
  useEffect(() => {
    setQuery({
      currentPage,
      pageSize,
      search,
      orderBy:
        orderBy === "ascend" ? "ASC" : orderBy === "descend" ? "DESC" : "DESC",
      orderByProperty,
    });
  }, [currentPage, orderBy, orderByProperty, pageSize, search]);

  return {
    currentPage: currentPage,
    pageSize: pageSize,
    search: search,
    orderBy: orderBy,
    orderByProperty: orderByProperty,
    handlePaginationChange,
    onSearch,
    setCurrentPage,
    query,
  };
};

export default useTableQuery;
