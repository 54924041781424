import { Form, Input, message } from "antd";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { passwordRecovery } from "../../../../../requests/users";
import Button from "../../../../Button";
import "./index.less";

const RecoveryPasswordForm = ({ onClose }) => {
  const reRef = useRef();

  const onFormFinish = async (values) => {
    try {
      document
        .querySelector("#send-recovery-email-button")
        .classList.add("disabled-button");
      const token = await reRef.current.executeAsync();
      reRef.current.reset();
      const { email } = values;
      await passwordRecovery({ email, reCaptcha: token });

      return message.success({
        content:
          "Si tienes una cuenta registrada recibirás un correo electrónico con las instrucciones a seguir",
        duration: 5,
      });
    } catch (error) {
      if (error.data) {
        return message.success({
          content:
            "Si tienes una cuenta registrada recibirás un correo electrónico con las instrucciones a seguir",
          duration: 5,
        });
      }
      return message.error({
        content: "Ha ocurrido un error en el servidor",
        duration: 5,
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Form className="container-recovery-form" onFinish={onFormFinish}>
        <Form.Item
          name={"email"}
          rules={[
            {
              type: "email",
              required: true,
              message: "Ingrese un correo",
            },
          ]}
        >
          <Input placeholder="Correo electrónico" />
        </Form.Item>
        <Button htmlType="submit" id="send-recovery-email-button">
          Enviar correo de recuperación
        </Button>
      </Form>
      <div className="footer-auth-form">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          ref={reRef}
          size="invisible"
        />
        <span style={{ color: "white", textAlign: "center" }}>
          ¿Necesitas ayuda?
        </span>
        <span
          style={{ color: "#595959", display: "flex", flexDirection: "column" }}
        >
          <p
            style={{
              textAlign: "center",
              margin: 0,
              fontSize: "14px",
              color: "white",
            }}
          >
            Comunícate con nosotros al +56 44 222 9975{" "}
          </p>
        </span>
      </div>
    </>
  );
};

export default RecoveryPasswordForm;
