import {
    CaretDownOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Select,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import InvoiceProposeLayout from "../../components/layouts/InvoiceProposeLayout";
import InvoicesProposePendingTable from './PendingTable';
import InvoicesProposeAprovedAndRejects from './ApprovedAndRejectsTable';
import './index.less';
import {
  // isSuperAdmin,
  isExecutive,
  isSupervisor 
} from "../../utils/roles";
import { useSelector } from "react-redux";
import { getUserData } from "../../store/auth";
import moment from 'moment';

export default function InvoicesPropose() {
    const [monthFilter, setMonthFilter] = useState(null);
    const [yearFilter, setYearFilter] = useState(null);
    const [updatedInvoicePropose, setUpdatedInvoicePropose] = useState(0);
    const { accesses } = useSelector(getUserData);


    const Months = ({ style }) => {
      return <Select
            suffixIcon={<CaretDownOutlined />}
            value={monthFilter}
            placeholder="Mes"
            style={style} 
            onChange={(value) => {
              setMonthFilter(value);
            }}
          >
            <Select.Option value="01">Enero</Select.Option>
            <Select.Option value="02">Febrero</Select.Option>
            <Select.Option value="03">Marzo</Select.Option>
            <Select.Option value="04">Abril</Select.Option>
            <Select.Option value="05">Mayo</Select.Option>
            <Select.Option value="06">Junio</Select.Option>
            <Select.Option value="07">Julio</Select.Option>
            <Select.Option value="08">Agosto</Select.Option>
            <Select.Option value="09">Septiembre</Select.Option>
            <Select.Option value="10">Octubre</Select.Option>
            <Select.Option value="11">Noviembre</Select.Option>
            <Select.Option value="12">Diciembre</Select.Option>
        </Select>
    }


    return (
      <InvoiceProposeLayout title="Propuesta de Facturación">

            <section
                className={"site-layout-background"}
                style={{
                  padding: "10px 24px 24px 24px",
                  marginTop: "1.5rem",
                  minHeight: '30px',
                  height: '30px !important',
                  borderTopLeftRadius: 15,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Row>
                    <Col xs={24}>
                        <strong>Ingrese año y mes para realizar la búsqueda</strong>
                    </Col>
                </Row>

                <Row gutter={[24, 24]} >
                  
                  <Col sm={4} xs={24}>
                        <DatePicker 
                            picker='year' 
                            placeholder="Año"
                            onChange={(value) => {
                              setYearFilter(value);
                            }}
                            style={{
                              width: '100%',
                              borderRadius: "10px"
                            }}
                            value={yearFilter}
                            disabledDate={(current) => current.isBefore(moment('2000', 'YYYY'))}
                        />

                    </Col>

                    <Col sm={4} xs={24} >
                        <Months style={{
                            width: '100%',
                            borderRadius: "50px"
                        }}/>
                    </Col>

                </Row>

            </section>
            
            {
              monthFilter && yearFilter &&  
              <> 
              {
                (!isSupervisor(accesses) && !isExecutive(accesses)) &&
                <section
                    className="site-layout-background"
                    style={{
                        padding: "10px 24px 24px 24px",
                        marginTop: "1.5rem",
                        minHeight: 280,
                        borderTopLeftRadius: 15,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                  <InvoicesProposePendingTable
                    yearFilter={yearFilter}
                    monthFilter={monthFilter}
                    updatedInvoicePropose={updatedInvoicePropose}
                    setUpdatedInvoicePropose={setUpdatedInvoicePropose}
                  />
                </section>
              }

                <section
                    className="site-layout-background"
                    style={{
                        padding: "10px 24px 24px 24px",
                        marginTop: "1.5rem",
                        minHeight: 280,
                        borderTopLeftRadius: 15,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                  <InvoicesProposeAprovedAndRejects
                    yearFilter={yearFilter}
                    monthFilter={monthFilter}
                    updatedInvoicePropose={updatedInvoicePropose}
                    setUpdatedInvoicePropose={setUpdatedInvoicePropose}
                  />
                </section>
              </>
            }

        
      </InvoiceProposeLayout>
    );
}
  