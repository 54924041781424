import react, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { Checkbox, Col, Row, Select, Spin, Table, Typography } from "antd";
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useMediaPredicate } from "react-media-hook";
import {
  getBlobForDocument,
  getDocName,
  getPlates,
} from "../../requests/users";
import Notifications from "../../components/Notifications";

export default function DriverDocs() {
  const biggerThan900 = useMediaPredicate("(min-width: 900px)");
  const [plates, setPlates] = useState();
  const [selectedPlate, setSelectedPlate] = useState();
  const [disabledButton, setDisabledButton] = useState(true);
  const [loadingView, setLoadingView] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(null);

  useEffect(() => {
    const getPlatesFromUser = async () => {
      try {
        const response = await getPlates();
        setPlates(response?.data);
      } catch (error) {
        if (error?.status === 404) {
          Notifications.error(
            "Error al cargar",
            "El usuario no tiene patentes asignadas."
          );
        }
        console.error(error);
      }
    };
    getPlatesFromUser();
  }, []);

  const handleSelectedPlate = (value) => {
    setSelectedPlate(value);
    setDisabledButton(false);
  };

  const getBlobType = (filename) => {
    if (filename.indexOf(".pdf") > -1) {
      return "application/pdf";
    }

    if (filename.indexOf(".jpg") > -1 || filename.indexOf(".jpeg") > -1) {
      return "image/jpeg";
    }

    if (filename.indexOf(".png") > -1) {
      return "image/png";
    }

    return null;
  };

  const handleDownload = async (record) => {
    setLoadingDownload(record.key);
    const checkChildren =
      record?.name === "Padrón del vehículo"
        ? "Padrón"
        : record?.name === "Permiso de circulación"
        ? "Permiso de Circulación"
        : record?.name === "Seguro obligatorio"
        ? "Soap"
        : record?.name === "Revisión técnica"
        ? "Revisión Técnica"
        : "";
    try {
      const response = await getBlobForDocument(selectedPlate, checkChildren);

      console.log(response, "response");

      // Crear un enlace dinámicamente
      const link = document.createElement("a");

      // Asignar el href al base64 del documento PDF
      link.href = response?.data?.stringBuffer;

      // Asignar el nombre del archivo para la descarga
      link.download = `${checkChildren + "_" + selectedPlate}.pdf`;

      // Agregar el enlace al DOM temporalmente
      document.body.appendChild(link);

      // Disparar el clic para iniciar la descarga
      link.click();

      // Eliminar el enlace del DOM después de la descarga
      document.body.removeChild(link);
    } catch (error) {
      setLoadingDownload(null);
      Notifications.error(
        "Error al buscar",
        "No se encontraron documentos para esta patente."
      );
      console.error(error);
    } finally {
      setLoadingDownload(null);
    }
  };

  const handleView = async (record) => {
    setLoadingView(record.key);
    const checkChildren =
      record?.name === "Padrón del vehículo"
        ? "Padrón"
        : record?.name === "Permiso de circulación"
        ? "Permiso de Circulación"
        : record?.name === "Seguro obligatorio"
        ? "Soap"
        : record?.name === "Revisión técnica"
        ? "Revisión Técnica"
        : "";

    console.log("entrando en segundo request ");
    try {
      const response = await getBlobForDocument(selectedPlate, checkChildren);
      console.log(response, "response");
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(
          `<html>
            <head>
              <title>PDF Viewer</title>
              <style>
                body { margin: 0; padding: 0; }
                iframe { width: 100%; height: 100vh; border: none; }
              </style>
            </head>
            <body>
              <iframe src="${response?.data?.stringBuffer}" alt="pdf-viewer"></iframe>
            </body>
          </html>`
        );
        newWindow.document.close();
      }
      // const file = new Blob([response?.data], { type: "application/pdf" });

      // const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
    } catch (error) {
      setLoadingView(null);
      Notifications.error(
        "Error al buscar",
        "No se encontraron documentos para esta patente."
      );
      console.error(error);
    } finally {
      setLoadingView(null);
    }
  };

  const dataSource = [
    // {
    //   key: "1",
    //   name: (
    //     <Checkbox>
    //       <span style={{ fontWeight: "600" }}>Seleccionar todo</span>
    //     </Checkbox>
    //   ),
    //   age: 32,
    //   address: "10 Downing Street",
    // },
    {
      key: "2",
      name: "Padrón del vehículo",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "3",
      name: "Revisión técnica",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "4",
      name: "Permiso de circulación",
      age: 42,
      address: "10 Downing Street",
    },
    {
      key: "5",
      name: "Seguro obligatorio",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Documentos",
      dataIndex: "name",
      key: "name",
      width: "86%",
    },
    {
      title: "Acciones",
      dataIndex: "age",
      align: "center",
      key: "age",
      render: (text, record) => {
        console.log(record, "record");
        return (
          <Row justify={"space-evenly"} key={record.key}>
            {record?.name?.props?.children?.props?.children ===
            "Seleccionar todo" ? null : loadingView === record.key ? (
              <Row align={"center"}>
                <Spin
                  style={{ fontSize: "24px", marginTop: "5px" }}
                  indicator={<LoadingOutlined spin />}
                />
              </Row>
            ) : (
              <EyeOutlined
                onClick={() => (disabledButton ? {} : handleView(record))}
                style={{
                  fontSize: "24px",
                  color: disabledButton ? "#8C8C8C" : "#FF5F00",
                  cursor: "pointer",
                }}
              />
            )}
            {loadingDownload === record.key ? (
              <Spin
                style={{ fontSize: "24px", marginTop: "5px" }}
                indicator={<LoadingOutlined spin />}
              />
            ) : (
              <DownloadOutlined
                onClick={() => (disabledButton ? {} : handleDownload(record))}
                style={{
                  fontSize: "24px",
                  color: disabledButton ? "#8C8C8C" : "#FF5F00",
                  cursor: "pointer",
                  marginTop: "9px",
                  marginBottom: "9px",
                }}
              />
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <AppLayout title="Mis documentos">
      <Row align={biggerThan900 ? "" : "center"}>
        <Typography
          style={{ fontWeight: "600", fontSize: "20px", marginTop: "16px" }}
        >
          Documentos Disponibles
        </Typography>
      </Row>
      <Row align={biggerThan900 ? "" : "center"}>
        <span
          style={{
            marginTop: "10px",
            width: "88%",
            fontSize: "14px",
            textAlign: biggerThan900 ? "" : "center",
          }}
        >
          En esta sección, podrá visualizar y descargar todos los archivos
          disponibles por patente. Asegúrese de ingresar la patente en el
          buscador o seleccionarla del listado desplegable.
        </span>
      </Row>
      <Row
        style={{ marginTop: "32px", marginLeft: biggerThan900 ? "" : "16px" }}
      >
        <span>Patentes asignadas</span>
      </Row>
      <Row>
        <Select
          onChange={(value) => handleSelectedPlate(value)}
          style={{
            width: biggerThan900 ? "40%" : "100%",
            marginLeft: biggerThan900 ? "" : "16px",
          }}
          placeholder="Seleccionar"
        >
          {plates?.map((item) => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            );
          })}
        </Select>
      </Row>

      <Table
        style={{ marginTop: "50px", marginLeft: biggerThan900 ? "" : "16px" }}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </AppLayout>
  );
}
