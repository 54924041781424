import axios from "axios";

import { STRAPI_API_URL } from "../../url";

const BASE_URL = STRAPI_API_URL;

export const getGamaMainContent = async () => {
  const { data: navbar } = await axios.get(
    `${BASE_URL}/gmlsmains/1?populate=Navbar.Logo,Navbar.TopLinks,Navbar.NavLinks,Navbar.NavButton`
  );

  const { data: header } = await axios.get(
    `${BASE_URL}/gmlsmains/1?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gmlsmains/1?populate=Sections.Image,Sections.Card.Icon,Sections.Card.Button,Sections.Button`
  );

  const { data: footer } = await axios.get(
    `${BASE_URL}/gmlsmains/1?populate=Footer.Logo,Footer.SocialNetwork.Icon,Footer.FooterLinks.FooterLink,Footer.FooterNewsletter.NewsletterButton,Footer.ContactCard.Icon,Footer.BottomArea.BottomLinks,Footer.BottomArea.BottomLinks,Footer.BottomArea.Trademark`
  );

  return {
    navbar: navbar?.data?.attributes.Navbar,
    header: header?.data?.attributes.Header,
    sections: sections?.data?.attributes.Sections,
    footer: footer?.data?.attributes.Footer,
  };
};

export const getGamaFleetContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gmlsmains/2?populate=Header.BannerImage,Header.Title`
  );
  const { data: gallery } = await axios.get(
    `${BASE_URL}/gmlsmains/2?populate=Sections.Image,Sections.Card.Icon,Sections.Button`
  );
  return {
    header: header?.data?.attributes,
    gallery: gallery?.data?.attributes.Sections[0].Card,
  };
};

export const getGamaContactContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gmlsmains/3?populate=Header.BannerImage,Header.Title`
  );
  const { data: sider } = await axios.get(
    `${BASE_URL}/gmlsmains/3?populate=Sections.Image,Sections.Card.Icon,Sections.Button`
  );

  return {
    header: header?.data?.attributes,
    sections: sider?.data?.attributes.Sections,
  };
};

export const getGamaBranchesContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/8?populate=Header.BannerImage,Header.Title`
  );
  const { data: branches } = await axios.get(
    `${BASE_URL}/gm-mains/8?populate=Sections.Image,Sections.Card.Button`
  );
  return {
    header: header?.data?.attributes,
    branches: branches?.data?.attributes.Sections,
  };
};
export const getSinisterContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/4?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/4?populate=Sections.Image,Sections.Card`
  );

  return {
    header: header?.data?.attributes,
    sections: sections?.data?.attributes,
  };
};

export const getPaymentContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/5?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/5?populate=Sections.Image,Sections.Title,Sections.Button,Sections.Card.Icon`
  );

  return {
    header: header?.data?.attributes,
    sections: sections?.data?.attributes,
  };
};

export const getFQAContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/7?populate=Header.BannerImage,Header.Title,Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/7?populate=Sections.Card`
  );

  return {
    header: header?.data?.attributes,
    sections: sections?.data?.attributes,
  };
};

export const getPostsContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/6?populate=Header.BannerImage,Header.Title,Header.Button`
  );
  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/6?populate=Sections.Card.Icon`
  );
  return {
    header: header?.data?.attributes,
    sections: sections?.data?.attributes,
  };
};
