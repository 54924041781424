import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../../utils/status";
import {
  getGamaUsadosContactContents,
  getGamaUsadosHomeContent,
} from "./thunks";

const initialState = {
  common: {
    navbar: null,
    footer: null,
  },
  home: {
    header: null,
    sections: [],
  },
  contact: {
    header: null,
    sider: null,
    navbar: null,
  },
  status: STATUS.IDLE,
  error: null,
};

export const gamausadosSlice = createSlice({
  name: "gamausados",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGamaUsadosHomeContent.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaUsadosHomeContent.fulfilled, (state, action) => {
        state.common.navbar = action.payload.navbar;
        state.home.header = action.payload.header;
        state.home.sections = action.payload.sections;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaUsadosHomeContent.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
      .addCase(getGamaUsadosContactContents.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getGamaUsadosContactContents.fulfilled, (state, action) => {
        state.contact.header = action.payload.header;
        state.contact.sider = action.payload.sider;
        state.contact.navbar = action.payload.navbar;
        state.status = STATUS.SUCCESS;
      })
      .addCase(getGamaUsadosContactContents.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      });
  },
});
//Common
export const getUsedFooterContent = (state) => state.gamausados.common.footer;
export const getUsedNavbarContent = (state) => state.gamausados.common.navbar;

//Home
export const getUsedHeaderContent = (state) => state.gamausados.home.header;
export const getUsedSectionsContent = (state) => state.gamausados.home.sections;

//Contact
export const getUsedContactHeaderContent = (state) =>
  state.gamausados.contact.header;
export const getUsedContactSiderContent = (state) =>
  state.gamausados.contact.sider;

export const getUsedContactNavBar = (state) => state.gamausados.contact.navbar;

export const getUsedStatus = (state) => state.gamausados.status;

export default gamausadosSlice.reducer;
