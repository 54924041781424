import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppLayout from '../../components/layouts/AppLayout'
import ChangePasswordForm from '../../components/layouts/AppLayout/ChangePasswordForm'
import Notifications from '../../components/Notifications'
import { passwordChange } from '../../requests/users'
import { getUserData } from '../../store/auth'

const Password = () => {
  const userData = useSelector(getUserData);
  const navigate = useNavigate();
  const reRef = useRef();

  const onSubmit = async (values) => {
    try {
      const { id } = userData;
      const data = { password: values.password };
      reRef.current.reset();
      await passwordChange({ userId: id, data });
      Notifications.success("Terminado", "Se ha actualizado la contraseña");
      navigate("/dashboard");
    } catch (error) {
      if (error) {
        return Notifications.error(
          "Error al cambiar contraseña",
          "Ha ocurrido un error al cambiar la contraseña"
        );
      }
    }
  };

  return (
    <div className={'profile-change-password-container'}>
      <AppLayout
        noContent
      >
        <ChangePasswordForm onSubmit={onSubmit} temporal={false} reRef={reRef}/>
      </AppLayout>
    </div>

  )
}

export default Password