import React, { useEffect, useState } from "react";
import { Drawer, Form, Row, Select } from "antd";
import Button from "../../../components/Button";
import CustomerCard from "../../../components/CustomerCard";
import { getGamaRequest } from "../../../requests/users";
import "./index.less";
import Notifications from "../../../components/Notifications";
import {
  unassingExecutive,
  unassingSupervisor,
  updateCustomerRequest,
} from "../../../requests/customers";
import { useMediaPredicate } from "react-media-hook";
import { CaretDownOutlined, DeleteOutlined } from "@ant-design/icons";
import { getPermissionsData, getUserData } from "../../../store/auth";
import { useSelector } from "react-redux";

const { Option } = Select;

const ExecutiveForm = ({ open, onClose, customerToShow, onFormFinish }) => {
  const [form] = Form.useForm();
  const [customer, setCustomer] = useState();
  const [supervisors, setSupervisors] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [executiveId, setExecutiveId] = useState();
  const [supervisorId, setSupervisorId] = useState();
  const permissions = useSelector(getPermissionsData);
  const userData = useSelector(getUserData);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const onFinish = async () => {
    try {
      const { id, name, rut, parentId } = customer;
      await updateCustomerRequest(id, {
        rut,
        name,
        parentId,
        executiveId,
        supervisorId,
      });
      onClose();
      onFormFinish();
      setExecutiveId();
      setSupervisorId();
      Notifications.success("Éxito", "Se ha completado la operación");
    } catch (error) {
      if (error) {
        return Notifications.error(
          "Error al asignar ejecutivo",
          "Ha ocurrido un error al asignar el registro"
        );
      }
    }
  };

  const loadUsers = async () => {
    try {
      const { data } = await getGamaRequest();
      const renderExecutives = data.users
        .filter(({ role }) => role.code.toUpperCase() === "EJECUTIVO")
        .map(({ firstName, lastName, id }) => (
          <Option key={id}>{firstName + " " + lastName}</Option>
        ));

      const renderSupervisors = data.users
        .filter(({ role }) => role.code.toUpperCase() === "SUPERVISOR")
        .map(({ firstName, lastName, id }) => (
          <Option key={id}>{firstName + " " + lastName}</Option>
        ));

      setExecutives(renderExecutives);
      setSupervisors(renderSupervisors);
    } catch (error) {
      console.log(error);
      Notifications.error(
        "Error",
        "Ocurrió un problema al cargar los usuarios"
      );
    }
  };

  const handleUnassingExecutive = async () => {
    if (customerToShow) {
      try {
        await unassingExecutive(customerToShow?.id);
        Notifications.success(
          "Usuario eliminado con éxito",
          `Se ha eliminado el ejecutivo exitosamente.`
        );
        onFormFinish();
        onClose();
      } catch (error) {
        Notifications.error(
          "Error",
          "El ejecutivo que trata de eliminar no existe."
        );
        console.error(error);
      }
    }
  };

  const handleUnassingSupervisor = async (values) => {
    if (customerToShow) {
      try {
        await unassingSupervisor(customerToShow?.id);
        Notifications.success(
          "Usuario eliminado con éxito",
          `Se ha eliminado el supervisor exitosamente.`
        );
        onFormFinish();
        onClose();
      } catch (error) {
        Notifications.error(
          "Error",
          "El supervisor que trata de eliminar no existe."
        );
        console.error(error);
      }
    }
  };

  useEffect(() => {
    for (let permission of userData?.permissions || []) {
      const foundPermission = permissions?.find((p) => p.id === permission);
      const description = foundPermission?.description.toLowerCase();

      if (
        description === "usuarios" ||
        description === "crear / editar usuarios" ||
        description === "ver / listar usuarios" ||
        description === "desactivar usuarios"
      ) {
        loadUsers();
      }
    }
    setCustomer(customerToShow);
  }, [customerToShow]);

  const handleSupervisor = (value) => {
    setSupervisorId(value);
  };

  const handleExecutive = (value) => {
    setExecutiveId(value);
  };

  return (
    <Drawer
      title={
        customerToShow?.executiveId
          ? "Actualizar ejecutivo"
          : "Asignar ejecutivo"
      }
      placement="right"
      onClose={onClose}
      visible={open}
      className="container-drawer-executive"
      destroyOnClose={true}
      width={biggerThan900 ? 480 : "100%"}
    >
      {customer && (
        <>
          <CustomerCard name={customer.name.toUpperCase()} rut={customer.rut} />
          <Form name="add" form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              span={12}
              label="Seleccione ejecutivo que desea asignar"
              name="ejecutivo"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                style={{ width: "90%" }}
                showSearch
                onChange={handleExecutive}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                size="large"
                placeholder="Ejecutivo"
                defaultValue={customerToShow && customerToShow.executiveId}
              >
                {executives.map((executive) => {
                  return (
                    <Select.Option key={executive.key} value={executive.key}>
                      {executive.props.children}
                    </Select.Option>
                  );
                })}
              </Select>
              <DeleteOutlined
                onClick={handleUnassingExecutive}
                style={{
                  cursor: "pointer",
                  marginLeft: "20px",
                  color: "#FF5C00",
                  alignSelf: "center",
                }}
              />
            </Form.Item>

            <Form.Item label="Supervisor" name="supervisor">
              <Select
                style={{ width: "90%" }}
                suffixIcon={<CaretDownOutlined />}
                onChange={handleSupervisor}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
                size="large"
                placeholder="Supervisor"
                defaultValue={customerToShow && customerToShow.supervisorId}
              >
                {supervisors.map((supervisor) => {
                  return (
                    <Select.Option key={supervisor.key} value={supervisor.key}>
                      {supervisor.props.children}
                    </Select.Option>
                  );
                })}
              </Select>
              <DeleteOutlined
                onClick={handleUnassingSupervisor}
                style={{
                  cursor: "pointer",
                  marginLeft: "20px",
                  color: "#FF5C00",
                  alignSelf: "center",
                }}
              />
            </Form.Item>
            <Row style={{ marginTop: 30 }}>
              <Button
                className="gama-outline"
                style={{ width: "40%", margin: 5 }}
                onClick={() => onClose()}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                style={{ width: "50%", margin: 5 }}
                onClick={() => form.submit()}
              >
                {customerToShow?.executiveId ? "Actualizar" : "Asignar"}
              </Button>
            </Row>
          </Form>
        </>
      )}
    </Drawer>
  );
};

export default ExecutiveForm;
