import { notification, Typography } from "antd";

import "./index.less";
const { Text } = Typography;

const success = (title = "", description = "") => {
  notification.success({
    message: <Text style={{ fontWeight: "600" }}>{title}</Text>,
    description,
    duration: 5,
    className: "gama-notification",
  });
};

const info = (title, description, placement, key, duration) => {
  notification.info({
    message: <Text style={{ fontWeight: "600" }}>{title}</Text>,
    description,
    duration: duration,
    placement,
    key,
  });
};

const warning = (title, description) => {
  notification.warning({
    message: <Text style={{ fontWeight: "600" }}>{title}</Text>,
    description,
    duration: 5,
  });
};

const error = (title, description) => {
  notification.error({
    message: <Text style={{ fontWeight: "600" }}>{title}</Text>,
    description,
    duration: 5,
  });
};

const Notifications = {
  success,
  info,
  warning,
  error,
};

export default Notifications;
