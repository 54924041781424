import { Col, Divider, Image, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import GoToTop from "../../components/GoToTop";
import Burger from "../../components/layouts/HomeLayout/Burger";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-mobility.webp";
import MobilityBanner from "../../assets/img/mobilitybanner.webp";
import Footer from "../../components/layouts/HomeLayout/Footer";
import Base64Downloader from "common-base64-downloader-react";
import gamaSeguro from "../../assets/img/images-left/gama_seguro-1.webp";
import icon01 from "../../assets/img/images-left/icon-001.svg";
import icon02 from "../../assets/img/images-left/icon-002.svg";
import icon04 from "../../assets/img/images-left/icon-004.svg";

import { protocolodesiniestropdf } from "../Posts/utils/basePDF";
import { useDispatch, useSelector } from "react-redux";
import { getGamaLeasingSinisterContent } from "../../store/strapi/gamaleasing/thunks";
import {
  getSinisterHeaderContent,
  getSinisterSectionsContent,
} from "../../store/strapi/gamaleasing";

function Sinister() {
  const { scrollDirection } = useScroll();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  const [isHome, setIsHome] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamaLeasingSinisterContent());
  }, []);

  const sinisterHeader = useSelector(getSinisterHeaderContent);
  const sinisterSections = useSelector(getSinisterSectionsContent);

  useEffect(() => {
    if (window.location.pathname === "/portal-de-siniestros") {
      window.location.replace("https://pss.gamamobility.cl/home");
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const burguerData = [
    {
      title: "Sucursales",
      link: "/branches",
    },
    {
      title: "Nuestros servicios",
      link: "/mobility#services",
    },
    {
      title: "Quienes Somos",
      link: "/mobility#us",
    },
    { title: "Beneficios", link: "/mobility#benefits" },
    { title: "Publicaciones", link: "/mobility#posts" },
  ];

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };
  return (
    <div>
      <GoToTop />

      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `60vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                sinisterHeader?.length === 0 || sinisterHeader === null
                  ? MobilityBanner
                  : sinisterHeader?.bannerImage?.data?.attributes?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/mobility"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "/usados"
                            ? "#FF3200"
                            : "white",
                      }}
                      to={"/usados"}
                    >
                      GAMA USADOS
                    </Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/mobility"}>
                      <Image
                        preview={false}
                        src={Logo}
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <a href="https://gamaleasing.cl/contact">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </a>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/branches">
                                Sucursales
                              </a>
                              <a smooth href="#services">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#us">
                                Quienes Somos
                              </a>
                              <a smooth href="#benefits">
                                Beneficios
                              </a>
                              <a smooth href="#posts">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/branches">Sucursales</Link>
                              <Link to="/mobility">Nuestros Servicios</Link>
                              <Link to="/mobility#us">Quienes Somos</Link>
                              <Link to="/mobility#benefits">Beneficios</Link>
                              <Link to="/mobility#posts">Publicaciones</Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          boton={false}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "0",
                paddingLeft: "0",
              }}
            >
              {sinisterSections?.length === 0 || sinisterSections === null
                ? "Siniestros"
                : sinisterHeader?.title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "35px",
                fontSize: "40px",
                fontWeight: "400",
                // maxWidth: biggerThan900 ? "800px" : "",
                // marginRight: biggerThan900 ? "1120px" : "",
                paddingLeft: "0",
              }}
            >
              {sinisterSections?.length === 0 || sinisterSections === null
                ? "Ingresa a nuestro portal de siniestros y declara tu accidente"
                : sinisterHeader?.title?.Detail}
            </h3>

            <Link to="/maintenance">
              <Button
                id="services"
                style={{ width: "11rem", marginTop: "1.5rem" }}
              >
                Portal de Siniestros
              </Button>
            </Link>
          </div>
        </section>
      </Layout>
      <Row
        style={{
          display: "flex",
          backgroundColor: "#F9F9F9",
          justifyContent: "center",
          flexDirection: biggerThan900 ? "" : "column",
          paddingLeft: biggerThan900 ? "" : "25px",
          paddingRight: biggerThan900 ? "" : "25px",
          paddingTop: biggerThan900 ? "" : "50px",
        }}
      >
        <Row
          style={{
            display: "flex",
            width: biggerThan900 ? "30%" : "100%",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#F9F9F9",
          }}
        >
          <Col
            style={{
              fontWeight: "600",
              fontSize: "24px",
              width: "300px",
              marginBottom: "26px",
            }}
          >
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "TRANQUILO, EN GAMA TE TENEMOS CUBIERTO"
              : sinisterSections && sinisterSections[0]?.Title}
          </Col>
          <Col
            style={{
              width: "500px",
              lineHeight: "20px",
              fontSize: "16px",
              marginBottom: "26px",
            }}
          >
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "Declara tu siniestro directamente a Gama a través de nuestro sitio web y no directamente a REALE."
              : sinisterSections && sinisterSections[0].Paragraph}
          </Col>
          {/* <Col
            style={{
              width: "400px",
              lineHeight: "20px",
              fontSize: "16px",
              marginBottom: "26px",
            }}
          >
            La declaración Web les permitirá a nuestros clientes realizar un
            proceso rápido y hacer seguimiento al estado de los siniestros
            declarados.
          </Col> */}

          <Base64Downloader
            style={{
              backgroundColor: "#F32000",
              border: "none",
              color: "white",
              borderRadius: "36px",
              width: biggerThan900 ? "60%" : "100%",
              fontSize: "18px",
              padding: "10px 15px 10px 15px",
              cursor: "pointer",
            }}
            base64={protocolodesiniestropdf}
            downloadName="protocolo de siniestro.pdf"
          >
            Descargar protocolos de siniestro
          </Base64Downloader>
        </Row>
        <Row>
          <img
            style={{
              widht: biggerThan900 ? "" : "auto",
              height: biggerThan900 ? "" : "200px",
              marginLeft: biggerThan900 ? "" : "75px",
            }}
            alt="main-sinister-logo"
            src={gamaSeguro}
          />
        </Row>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "600",
          fontSize: "32px",
          marginTop: "26px",
        }}
      >
        {sinisterSections?.length === 0 || sinisterSections === null
          ? "Puntos de Contacto"
          : sinisterSections && sinisterSections[1].Title}
      </Row>
      <Row
        align="middle"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alingItems: "center",
          marginTop: "70px",
          marginBottom: "70px",
        }}
      >
        <Row
          align="middle"
          style={{
            display: "flex",
            flexDirection: "column",
            alingItems: "center",
            marginBottom: biggerThan900 ? "0px" : "30px",
          }}
        >
          <Col style={{ minHeight: "56px" }}>
            <img alt="assistence-24-7" src={icon01} />
          </Col>
          <Col
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#FF3200",
              fontWeight: "600",
            }}
          >
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "Grúa y Asistencia en Ruta 24/7"
              : sinisterSections && sinisterSections[1].Card[0].Title}
          </Col>
          <Col>
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "+56 2 3278 5997"
              : sinisterSections && sinisterSections[1].Card[0].Paragraph}
          </Col>
        </Row>
        {biggerThan900 ? (
          <Divider style={{ height: "150px" }} type="vertical" />
        ) : null}
        <Row
          align="middle"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: biggerThan900 ? "0px" : "30px",
          }}
        >
          <Col style={{ minHeight: "56px" }}>
            <img alt="24-7-emergency-channel" src={icon02} />
          </Col>
          <Col
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#FF3200",
              width: "250px",
              fontWeight: "600",
            }}
          >
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "Emergencias Gama 24/7 Aviso de Robo Vehículo aquí"
              : sinisterSections && sinisterSections[1].Card[1].Title}
          </Col>
          <Col>+56 2 2757 4450</Col>
        </Row>
        {biggerThan900 ? (
          <Divider style={{ height: "150px" }} type="vertical" />
        ) : null}
        <Row
          align="middle"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: biggerThan900 ? "0px" : "30px",
          }}
        >
          <Col style={{ minHeight: "56px" }}>
            <img alt="Doubts-Declaration-of-Loss" src={icon04} />
          </Col>
          <Col
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#FF3200",
              fontWeight: "600",
            }}
          >
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "Dudas Declaración de Siniestro"
              : sinisterSections && sinisterSections[1].Card[2].Title}
          </Col>
          <Col>
            {sinisterSections?.length === 0 || sinisterSections === null
              ? "Siniestros@gamaleasing.cl"
              : sinisterSections && sinisterSections[1].Card[2].Paragraph}
          </Col>
        </Row>
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default Sinister;
