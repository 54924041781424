import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Tag, Typography } from "antd";
import ProfileUserCard from "../ProfileUserCard";
import { CloseOutlined } from "@ant-design/icons";
import CustomButton from "../../../components/Button/index";
import { useMediaPredicate } from "react-media-hook";
import { getProfilePicture } from "../../../requests/profile";

const { Text } = Typography;

const UserDetails = ({ open, onClose, loading, userDetails }) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [profileImage, setProfileImage] = useState(null);


  useEffect(async () => {
    if (userDetails) {
      const { data } = await getProfilePicture(userDetails.id);
      setProfileImage(data);
    }
  }, [userDetails]);


  const onCloseModal = () => {
    onClose();
  };
  return (
    <Drawer
      closable={false}
      title={
        <div>
          <Row justify="space-between" align="middle">
            <Col>Detalles</Col>
            <Col>
              <Button
                onClick={onClose}
                style={{
                  border: "none",
                  padding: "5px 8px",
                  color: "#FF3200",
                  borderRadius: "6px",
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                }}
              >
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      }
      placement="right"
      onClose={onCloseModal}
      visible={open}
      width={biggerThan900 ? 480 : "100%"}
    >
      <ProfileUserCard
        name={`${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`}
        rut={userDetails?.rut}
        urlImage={
          profileImage ||
          " https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
        }
      />

      <Divider />
      <div>
        <div style={{ paddingBottom: "10px" }}>
          <Text strong>Correo electrónico </Text>
          <Text>{userDetails?.email || ""}</Text>
        </div>
        <div>
          <Text strong>Teléfono </Text>
          <Text>{`+${userDetails?.phonePrefix || ""} ${
            userDetails?.phoneNumber || ""
          }`}</Text>
        </div>
      </div>
      <Divider />
      <Text strong>Perfiles y empresas</Text>
      <div style={{ marginTop: "15px" }}>
        {userDetails?.accesses.map((access) => {
          return (
            <>
              <Tag
                style={{
                  margin: 5,
                  backgroundColor: "#F0FDFF",
                  color: "#16BDE3",
                  border: "1px solid #16BDE3",
                  borderRadius: "5px",
                }}
              >
                {access.role.code}
              </Tag>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul
                  style={{
                    listStyleType: "square",
                    backgroundColor: "#FAFAFA",
                    paddingLeft: "40px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <li>
                    <Text>{access.customer.name}</Text>
                  </li>
                </ul>
              </div>
            </>
          );
        })}
        <Row align="middle" justify="center" style={{ marginTop: "50px" }}>
          <CustomButton onClick={onCloseModal}>Cerrar</CustomButton>
        </Row>
      </div>
    </Drawer>
  );
};

export default UserDetails;
