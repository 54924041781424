import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { Col, Input, Row, Tag, Typography } from "antd";
import {
  PlusOutlined,
} from "@ant-design/icons";
import Button from "../../components/Button";
import RolesTable from "./RolesTable";
import useTableQuery from "../../hooks/useTableQuery";
import {
  getAllPermissionsRequest,
  getPermissionsRequest,
  getRoleRequest,
  getRolesRequest,
  removeRoleRequest,
} from "../../requests/roles";
import RoleDetails from "./RoleDetails";
import RoleForm from "./RoleForm";
import Notifications from "../../components/Notifications";
import { useMediaPredicate } from "react-media-hook";
import { getUserData } from "../../store/auth";
import { useSelector } from "react-redux";

const { Search } = Input;
const { Text } = Typography;

const Roles = () => {
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const { accesses } = useSelector(getUserData);
  const [roleDetails, setRoleDetails] = useState(null);

  const [permissions, setPermissions] = useState([]);

  const [roles, setRoles] = useState([]);
  const [total, setTotal] = useState(0);
  const isRoleEnabled = (role) => role.enabled;

  const mobileColumns = [
    {
      title: "Rol",
      key: "code",
      width: 130,
      sorter: {
        compare: (a, b) => a.code.localeCompare(b.code),
      },
      render: (role) => {
        return (
          <span className={isRoleEnabled(role) ? "" : "disabled-row"}>
            {role.code}
          </span>
        );
      },
    },
    {
      title: "Estado",
      key: "enabled",
      render: (role) => (
        <span>
          {isRoleEnabled(role) ? (
            <Tag color="green">Activo</Tag>
          ) : (
            <Tag className="disabled-color">Inactivo</Tag>
          )}
        </span>
      ),
    },
  ];

  const fetchRoles = async (_query) => {
    setLoadingTable(true);
    try {
      const { data } = await getRolesRequest(_query);
      const newObj = data?.roles?.map((item) => ({
        code: item.code,
        createdAt: item.createdAt,
        customer: item.customer,
        enabled: item.enabled,
        id: item.id,
        relatedUsersCount: item.relatedUsersCount,
        isExpandable: true,
      }));
      setRoles(newObj);
      setTotal(data.total);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingTable(false);
    }
  };

  const fetchPermissions = async () => {
    try {
      const { data } = await getAllPermissionsRequest();
      setPermissions(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSelectDetails = async (roleId) => {
    try {
      setLoadingDrawer(true);
      setShowDetails(true);
      const { data } = await getRoleRequest(roleId);
      setRoleDetails(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingDrawer(false);
    }
  };

  const onSelectEdit = async (roleId) => {
    try {
      setLoadingDrawer(true);
      setShowForm(true);
      const { data } = await getRoleRequest(roleId);

      setRoleDetails(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingDrawer(false);
    }
  };

  const onOpenForm = () => {
    setShowForm(true);
  };

  const onCloseForm = () => {
    setShowForm(false);
    onCloseDetails();
  };

  const onCloseDetails = () => {
    setRoleDetails(null);
    setShowDetails(false);
  };

  const { currentPage, pageSize, handlePaginationChange, onSearch, query } =
    useTableQuery();

  useEffect(() => {
    fetchPermissions();
  }, [accesses]);

  useEffect(() => {
    fetchRoles(query);
  }, [query, accesses]);

  const onCreatedRole = () => fetchRoles(query);

  const onRemoveRole = async (roleId) => {
    try {
      await removeRoleRequest(roleId);
      setRoles((prevRoles) =>
        prevRoles.map((_role) => ({
          ..._role,
          enabled: _role.id === roleId ? !_role.enabled : _role.enabled,
        }))
      );
      Notifications.success("Exito", `Se ha completado la operación`);
    } catch (error) {
      console.log("error", error.data);
      if (error.data.statusCode === 409) {
        Notifications.warning(
          "Operación cancelada",
          "No puedes desactivar un rol que está en uso."
        );
      } else {
        Notifications.error("Error", "No se ha podido desactivar el rol");
      }
    }
  };

  return (
    <AppLayout
      title="Administrador de Roles
    "
    >
      <Row
        style={{
          margin: 10,
          marginTop: "20px",
          display: biggerThan900 ? "" : "flex",
          flexDirection: biggerThan900 ? "" : "column",
        }}
      >
        <Col sm={24} md={12}>
          <Search
            placeholder="Buscar rol"
            onSearch={onSearch}
            style={{
              width: biggerThan900 ? 240 : "100%",
            }}
          />
        </Col>
        <Col sm={24} md={12} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            style={{
              width: biggerThan900 ? "" : "100%",
              marginTop: biggerThan900 ? "" : "15px",
              marginBottom: biggerThan900 ? "" : "15px",
            }}
            type="primary"
            onClick={onOpenForm}
            icon={<PlusOutlined />}
          >
            Crear nuevo rol
          </Button>
        </Col>
      </Row>
      <RoleForm
        open={showForm}
        onClose={onCloseForm}
        permissionsList={permissions}
        onCreatedRole={onCreatedRole}
        roleToUpdate={roleDetails}
      />
      <RoleDetails
        loading={loadingDrawer}
        onClose={onCloseDetails}
        open={showDetails}
        role={roleDetails}
      />
      <RolesTable
        mobileColumns={mobileColumns}
        roles={roles}
        currentPage={currentPage}
        total={total}
        onChange={handlePaginationChange}
        loading={loadingTable}
        pageSize={pageSize}
        onSelectEdit={onSelectEdit}
        onSelectDetails={onSelectDetails}
        onRemove={onRemoveRole}
      />
    </AppLayout>
  );
};

export default Roles;
