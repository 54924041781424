import React from "react";
import { Descriptions, Divider, Drawer } from "antd";
import { useMediaPredicate } from "react-media-hook";

const RoleDetails = ({ open, onClose, loading, role }) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const onCloseModal = () => {
    onClose();
  };
  return (
    <Drawer
      title="Detalle"
      placement="right"
      onClose={onCloseModal}
      visible={open}
      width={biggerThan900 ? 480 : "100%"}
    >
      <Descriptions title="Rol">
        <Descriptions.Item>{role && role.code}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions title="Usuarios asociados al rol">
        <div
          style={{
            backgroundColor: "#FAFAFA",
            borderRadius: 12,
            width: "100%",
            padding: 10,
          }}
        >
          <ul style={{ listStyleType: "square", paddingLeft: 10 }}>
            {role &&
              role.relatedUsers.map((user) => (
                <li
                  style={{ fontWeight: 400, fontSize: "14px" }}
                >{`${user.firstName} ${user.lastName}`}</li>
              ))}
          </ul>
        </div>
      </Descriptions>
    </Drawer>
  );
};

export default RoleDetails;
