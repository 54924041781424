import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, getHasAccess, signOut } from "../../../store/auth";
import Notifications from "../../Notifications";
import Button from "../../Button";
import {
  exportTemplate,
  passwordChange,
  updateDefaultCompany,
} from "../../../requests/users";
import {
  CaretDownOutlined,
  DownloadOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import "./index.less";
import {
  attachAccess,
  getAuthProfile,
  getPermissions,
  refreshAuth,
} from "../../../store/auth/thunks";
import { useMediaPredicate } from "react-media-hook";
import { getCustomers } from "../../../store/customers";
import { isExecutive } from "../../../utils/roles";
import { exportVehiclesTemplate } from "../../../requests/vehicles";

import Sider from "../AppLayout/Sider";
import Header from "../AppLayout/Header";
import ChangePasswordForm from "../AppLayout/ChangePasswordForm";

const { Content } = Layout;
const { Text } = Typography;

const DashboardLayout = ({
  children,
  tabs = false,
  title,
  boton,
  customer,
  backgroundImg,
  agendamiento,
  isDashboard,
}) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const hasAccess = useSelector(getHasAccess);
  const [companies, setCompanies] = useState([]);
  const [token, setToken] = useState("");
  const { accesses } = useSelector(getUserData);
  const [loading, setLoading] = useState(false);
  const reRef = useRef();
  const { customers } = useSelector(getCustomers);

  useEffect(() => {
    dispatch(getAuthProfile());
    dispatch(getPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (userData.accessToCustomers.length) {
      const currentId = !userData.defaultCompany
        ? userData.accessToCustomers[0].id
        : userData.defaultCompany;

      onSelectCustomer(currentId);
      renderCompanies(userData);
    }
  }, [userData]);

  useEffect(() => {
    const token = localStorage.getItem("gama.token");
    if (token) setToken(token);
  }, []);

  const BASE_URL = `${process.env.REACT_APP_AGENDAMIENTO_URL}/auth/lop?token=${token}`;

  const onSelectCustomer = (customerId) => dispatch(attachAccess(customerId));
  const onSignOut = () => dispatch(signOut());

  const filteredCustomers = customers?.filter(
    (customer) => customer.name !== "GAMA LEASING OPERATIVO SPA"
  );

  const onSubmit = async (values) => {
    try {
      const { id } = userData;
      const data = { password: values.password };
      reRef.current.reset();

      await passwordChange({ userId: id, data });
      Notifications.success("Terminado", "Se ha actualizado la contraseña");
      window.location.reload();
    } catch (error) {
      if (error) {
        return Notifications.error(
          "Error al cambiar contraseña",
          "Ha ocurrido un error al cambiar la contraseña"
        );
      }
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const generateExcelBuffer = (data) => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, "Sheet1");
    const buffer = XLSX.write(book, {
      type: "buffer",
      bookType: "xlsx",
    });
    return buffer;
  };

  const downloadExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };

  const downloadTemplate = async () => {
    setLoading(true);
    try {
      const { data } = await exportTemplate();
      downloadExcelFile(data, "Reporte de Solicitudes");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadVehiclesTemplate = async () => {
    setLoading(true);
    try {
      let result = [];
      const pageSize = 1000;
      const { data } = await exportVehiclesTemplate({ page: 1 });
      let totalPages = Math.trunc(data.count / pageSize) + 1;
      for (let page = 1; page <= totalPages; page++) {
        const { data } = await exportVehiclesTemplate({ page });
        result = [...result, ...data.data];
      }
      const bufferData = generateExcelBuffer(result);
      downloadExcelFile(bufferData, "Reporte de Vehiculos");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const dataLoad = async (companyId) => {
    try {
      await updateDefaultCompany(companyId);
      dispatch(getAuthProfile());
      dispatch(refreshAuth());
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanyName = (id) => {
    const company = userData.accessToCustomers.find((el) => el.id === id);
    if (company) return company.name;
    return userData.accessToCustomers[0].name;
  };

  const renderCompanies = (data) => {
    const orderData = [];
    data.accessToCustomers.forEach((el) => {
      el.id === userData.defaultCompany
        ? orderData.unshift(el)
        : orderData.push(el);
    });
    setCompanies(orderData);
  };

  const renderCustomerLabel = (customer) => (
    <a onClick={() => dataLoad(customer?.id)}>
      <div
        style={{
          fontSize: "14px",
          color: "#262626",
        }}
      >
        {customer.id === userData.defaultCompany ? (
          <StarFilled style={{ marginRight: ".5rem", color: "#FF3200" }} />
        ) : (
          <StarOutlined style={{ marginRight: ".5rem" }} />
        )}
        {`${customer.name} · ${customer.rut}`}
        <p
          style={{
            marginLeft: "1.4rem",
            color: "#8C8C8C",
            fontSize: "14px",
          }}
        >
          {userData.defaultCompany === customer.id && "(predeterminada)"}
        </p>
      </div>
    </a>
  );

  const renderCustomerMenuItem = (customer) => (
    <Menu.Item key={customer.id}>{renderCustomerLabel(customer)}</Menu.Item>
  );

  const renderCustomerDropdown = () => (
    <Dropdown
      trigger={["click"]}
      overlay={renderCustomersMenu()}
      placement="bottomRight"
    >
      <Button
        style={{
          width: "300px",
          textAlign: "left",
          backgroundColor: "#FFFFFF",
          color: "#262626",
          borderRadius: "6px",
          border: "none",
        }}
      >
        <Row justify="space-between">
          {userData.defaultCompany
            ? getCompanyName(
                userData.defaultCompany.length >= 35
                  ? userData.defaultCompany.length.slice(0, 35) + "..."
                  : userData.defaultCompany.length
              )
            : userData.accessToCustomers?.[0]?.name.length >= 35
            ? userData.accessToCustomers?.[0]?.name?.slice(0, 35) + "..."
            : userData.accessToCustomers?.[0]?.name}
          <CaretDownOutlined />
        </Row>
      </Button>
    </Dropdown>
  );

  const renderCustomersMenu = () => (
    <Menu className="customers-list" id="customers-list">
      {companies.map(renderCustomerMenuItem)}
    </Menu>
  );

  const renderButton = () => (
    <Button
      icon={
        loading ? (
          <Spin style={{ color: "white" }} indicator={antIcon} />
        ) : (
          <DownloadOutlined />
        )
      }
      onClick={
        window.location.pathname === "/vehiculos"
          ? downloadVehiclesTemplate
          : downloadTemplate
      }
      className="gama-outline"
      style={{ width: "100%" }}
    >
      Descargar Reportes
    </Button>
  );

  const renderAgendamientoButton = () => (
    <Button className="gama-button" style={{ width: "100%" }}>
      <a href={BASE_URL}>Ir a agendamiento</a>
    </Button>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header hasAccess={hasAccess} user={userData} onSignOut={onSignOut} />

      <Layout className="layout-container">
        {userData.type === "CLIENT" && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              right: biggerThan900 ? 0 : "",
              margin: biggerThan900 ? "15px 50px 0px 0" : "15px 20px 0px 20px",
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                margin: 0,
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              Empresa:
            </p>
            {renderCustomerDropdown()}
          </div>
        )}

        {userData.passwordUpdate ? (
          <ChangePasswordForm onSubmit={onSubmit} reRef={reRef} />
        ) : (
          <>
            <Sider hasAccess={hasAccess} />
            <Layout
              style={{
                padding: biggerThan900 ? "0 24px 24px" : "0px",
                marginTop: userData?.type === "CLIENT" ? "70px" : "15px",
              }}
            >
              <Row justify="space-between" align="middle">
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "24px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "24px",
                      marginTop: "15px",
                    }}
                    strong
                  >
                    Hola {isDashboard ? userData?.name + "," : title}
                  </Text>
                  <Text>Revisa el estado general de tu flota.</Text>
                </Col>
                <Row>
                  {biggerThan900 ? (
                    <Col>
                      {boton && renderButton()}
                      {agendamiento && renderAgendamientoButton()}
                    </Col>
                  ) : (
                    <Col span={24}>
                      {boton && renderButton()}
                      {agendamiento && renderAgendamientoButton()}
                    </Col>
                  )}
                </Row>
              </Row>

              <Content
                style={{
                  padding: tabs ? 0 : "10px 24px 24px 24px",
                  minHeight: 280,
                  borderTopLeftRadius: tabs ? 0 : 15,
                  backgroundImage: backgroundImg ? `url(${backgroundImg})` : "",
                  display: backgroundImg ? "flex" : "",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  justifyContent: backgroundImg ? "center" : "",
                  alignItems: backgroundImg ? "center" : "",
                }}
              >
                {children}
              </Content>
            </Layout>
          </>
        )}
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
