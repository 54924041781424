import { Carousel as AntCarousel } from "antd";
import React from "react";
import "./index.less";

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const Carousel = ({ children, autoplay }) => (
  <div className="car-carousel-contain">
    <AntCarousel autoplay={autoplay}>{children}</AntCarousel>
  </div>
);

export default Carousel;
