import { CloseOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Checkbox,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import { format, validate } from "rut.js";
import { decodeClientHash } from "../../../../requests/auth";
import Button from "../../../Button";
import AuthModal from "./AuthModal";
import "./index.less";
import RecoveryPasswordForm from "./RecoveryPassword";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_AUTH_API_URL } from "../../../../requests/url";
import microsoftLogo from "../../../../assets/img/Microsoft_logo.svg.png";
import whatsappLogo from "../../../../assets/img/whatsapp.png";
import { countries } from "../../../../utils/countries";

const { Text } = Typography;
const { Option } = Select;

const AuthForm = ({ open, close, onFinish, form, loading }) => {
  const root = document.querySelector("body");
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [pageForm, setPageForm] = useState(1);
  const localHash = localStorage.getItem("local.pass");
  const reRef = useRef();
  const [valuePhone, setValuePhone] = useState({
    phone_code: "+56",
    stringValue: "",
  });

  console.log(valuePhone, "valyuePhone");

  const [code, setCode] = useState(new Array(6).fill(""));

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Automatically move to next input
      if (index < 5 && value) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newCode = [...code];
      if (newCode[index]) {
        // Clear the current input
        newCode[index] = "";
      } else if (index > 0) {
        // If current input is already empty, move to the previous input and clear it
        newCode[index - 1] = "";
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      setCode(newCode);
    }
  };

  const payload = {
    rut: localHash ? localStorage.getItem("local.usuario") : undefined,
    password: undefined,
    remember: localHash ? true : false,
  };

  let formInitialValues = payload;

  const onClose = () => {
    setPageForm(1);
  };

  const onSubmit = () => {
    form.submit();
  };

  const onFormFinish = async (values) => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    const payload = { ...values, reCaptcha: token };
    onFinish(payload);
    close();
  };

  useEffect(() => {
    async function decodeHash(hash) {
      const { data } = await decodeClientHash({ hash });
      form.setFieldsValue({
        password: data,
      });
    }

    if (open === true) {
      root.style.overflow = "hidden";
      localStorage.getItem("local.usuario") && decodeHash(localHash);

      return;
    }

    root.style.overflow = "auto";
    root.style.width = "auto";
    return () => setTimeout(setPageForm(1), 2000);
  }, [open]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" && open) {
        close();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <div
        style={{
          width: biggerThan900 ? "" : "320px",
          overflow: "hidden",
          borderRadius: biggerThan900 ? "" : "20px",
        }}
        className="container-auth-form"
      >
        {/* <div className="icons-container">
          <div
            className="arrow"
            style={{ transform: biggerThan900 ? "translateX(-2rem)" : "" }}
          >
            {pageForm === 2 && (
              <ArrowLeftOutlined onClick={() => setPageForm(1)} />
            )}
          </div>
          <div className="close" style={{ transform: "translateX(2rem)" }}>
            {biggerThan900 && <CloseOutlined onClick={onClose} />}
          </div>
        </div> */}

        <div style={{ paddingTop: "1rem" }} className="contain-modal-header">
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <h3
                style={{
                  paddingBottom: "0px",
                  color: "white",
                }}
              >
                {pageForm === 1 ? "Sistema de Portal Cliente" : null}
                {pageForm === 2 ? "¿Olvidaste tu contraseña?" : null}
                {pageForm === 3 ? "Verificación de datos" : null}
                {pageForm === 4 ? "Te hemos enviado un código" : null}
              </h3>
            </Col>
            <Col>
              <Link to="/">
                <CloseOutlined style={{ color: "white", cursor: "pointer" }} />
              </Link>
            </Col>
          </Row>

          <Text className="info-text" style={{ color: "white" }}>
            {pageForm === 1 ? "Ingresa tus datos para comenzar" : null}
            {pageForm === 2
              ? "Ingresa tu correo y te enviaremos los pasos a seguir para reestablecer tu contraseña."
              : null}
            {pageForm === 3 ? (
              <>
                Ingresa el número de WhatsApp registrado con{" "}
                <span style={{ fontWeight: 600 }}>XXXX XXXX 6789</span>, para
                validar tu información.
              </>
            ) : null}

            {pageForm === 4 ? (
              <>
                Ingresa el número de WhatsApp registrado con{" "}
                <span style={{ fontWeight: 600 }}>XXXX XXXX 6789</span>, para
                iniciar sesión, este código tiene una{" "}
                <span style={{ fontWeight: 600 }}>validez de 10 minutos.</span>
              </>
            ) : null}
          </Text>
        </div>
        {pageForm === 1 && (
          <>
            <Form
              name="auth"
              layout="vertical"
              form={form}
              initialValues={formInitialValues}
              onFinish={onFormFinish}
            >
              <div>
                <Form.Item
                  style={{ color: "white", paddingTop: "16px" }}
                  label="Rut"
                  name="rut"
                  rules={[
                    { required: true, message: "Ingrese el rut." },
                    {
                      validator: (_, value) => {
                        if (validate(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("El rut ingresado es inválido.");
                      },
                    },
                  ]}
                  normalize={(value) => format(value)}
                >
                  <Input style={{ borderRadius: "6px" }} placeholder="RUT" />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    { required: true, message: "Ingrese la contraseña." },
                  ]}
                >
                  <Input.Password
                    style={{ borderRadius: "6px" }}
                    required
                    placeholder="Contraseña"
                    type={"password"}
                  />
                </Form.Item>
              </div>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox style={{ color: "white" }}>
                  Recordar mi usuario
                </Checkbox>
              </Form.Item>

              <Button
                type="primary"
                className="gama-button auth-button"
                onClick={() => onSubmit()}
                loading={loading}
                style={{
                  marginBottom: "15px",
                  backgroundColor: "#FF5F00",
                  border: "0px solid white",
                }}
              >
                {loading ? "Esperando" : "Iniciar Sesion"}
              </Button>

              <a
                style={{
                  marginBottom: "20px",
                  color: "white",
                  fontSize: "14px",
                }}
                onClick={() => setPageForm(2)}
              >
                ¿Olvidaste tu contraseña?
              </a>
            </Form>
            <Divider
              style={{
                width: "50px",
                color: "white",
                border: "0px solid white",
                fontSize: "16px",
              }}
            >
              o
            </Divider>
            <Row
              align="middle"
              justify="center"
              style={{ borderRadius: "1px solid gray", marginBottom: "6px" }}
            >
              {/* <Button
                onClick={() => setPageForm(3)}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid white",
                  width: "286px",
                  marginBottom: "16px",
                }}
              >
                <img
                  alt="microsoft-login-logo"
                  style={{ height: "15px" }}
                  src={whatsappLogo}
                />
                Ingresar código vía WhatsApp
              </Button> */}

              <a
                href={`${BASE_AUTH_API_URL}/ms/auth`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid white",
                    width: "286px",
                  }}
                >
                  <img
                    alt="microsoft-login-logo"
                    style={{ height: "15px" }}
                    src={microsoftLogo}
                  />
                  Ingresar con Microsoft
                </Button>
              </a>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                ref={reRef}
                size="invisible"
              />
            </Row>
            <div className="footer-auth-form">
              {/* <span style={{ color: "#595959", textAlign: "center" }}>
                ¿Necesitas ayuda?
              </span> */}
              {/* <span
                style={{
                  color: "#595959",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p style={{ textAlign: "center", margin: 0, fontSize: "14px" }}>
                  Comunícate con nosotros en{" "}
                </p>
                <HashLink smooth to="#home-layout-footer" onClick={onClose}>
                  servicio al cliente
                </HashLink>
              </span> */}
            </div>
          </>
        )}
        {pageForm === 2 && <RecoveryPasswordForm onClose={onClose} />}

        {pageForm === 3 && (
          <>
            <Form className="container-number">
              <Row>
                <Form.Item name="phone_code" noStyle>
                  <Select
                    style={{ height: "40px", width: "23%" }}
                    placeholder="Search to Select"
                    value={valuePhone.phone_code}
                    defaultValue="+56"
                    optionFilterProp="children"
                    onChange={(value) => console.log(value, "optionA")}
                    options={countries.map((item) => ({
                      value: item?.phone_code,
                      label: (
                        <img
                          src={`https://flagcdn.com/w20/${item.iso_code_2.toLowerCase()}.png`}
                          alt={item.name.es}
                        />
                      ),
                      key: item?.name.es,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginLeft: "12px", width: "70%" }}
                  name={"Number"}
                  rules={[
                    {
                      required: true,
                      message: "Ingrese un numero de telefono",
                    },
                  ]}
                >
                  <Input placeholder="Numero de telefono" />
                </Form.Item>
              </Row>
              <Button
                onClick={() => setPageForm(4)}
                style={{
                  marginBottom: "16px",
                  backgroundColor: "#FF5F00",
                  border: "1px solid #FF5F00",
                }}
                htmlType="submit"
                id="send-recovery-email-button"
              >
                Siguiente
              </Button>
              <Button
                onClick={() => setPageForm(1)}
                style={{
                  marginBottom: "32px",
                  backgroundColor: "transparent",
                  border: "1px solid #FF5F00",
                }}
              >
                Volver
              </Button>
            </Form>
          </>
        )}

        {pageForm === 4 && (
          <>
            <Form>
              <div className="otp-input-container">
                {code.map((digit, index) => (
                  <Form.Item key={index}>
                    <Input
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      className="otp-input-box"
                    />
                  </Form.Item>
                ))}
              </div>
            </Form>
            <span style={{ color: "white", marginTop: "-32px" }}>
              ¿No te ha llegado el código?{" "}
              <span style={{ color: "#FF5F00" }}>Reenviar código</span>{" "}
            </span>

            <Button
              onClick={() => setPageForm(4)}
              style={{
                marginBottom: "16px",
                backgroundColor: "#FF5F00",
                border: "1px solid #FF5F00",
                width: "100%",
                marginTop: "46px",
              }}
              htmlType="submit"
              id="send-recovery-email-button"
            >
              Iniciar Sesion
            </Button>
            <Button
              onClick={() => setPageForm(3)}
              style={{
                marginBottom: "32px",
                backgroundColor: "transparent",
                border: "1px solid #FF5F00",
                width: "100%",
              }}
            >
              Volver
            </Button>
          </>
        )}
      </div>

      <AuthModal open={open} />
    </>
  );
};

export default AuthForm;
