import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../utils/status";
import { getAssignmentCustomersFilter } from "./thunks";

const initialState = {
  activeCustomer: null,
  customers: [],
  filters: {
    customers: []
  },
  status: STATUS.IDLE,
  error: null
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    clearCustomers: (state, action) => {
      state.customers = initialState.customers;
      state.activeCustomers = initialState.activeCustomers;
    },
    setActiveCustomers: (state, action) => {
      const { companyId } = action.payload;
      state.activeCustomers = companyId;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAssignmentCustomersFilter.pending, (state, action) => {
        state.status = STATUS.PENDING;
      })
      .addCase(getAssignmentCustomersFilter.fulfilled, (state, action) => {
        const data  = action.payload;
        state.status = STATUS.SUCCESS;
        state.filters.customers = data;
      })
      .addCase(getAssignmentCustomersFilter.rejected, (state, action) => {
        const { error } = action.payload;
        state.status = STATUS.FAILED;
        state.error = error;
      })
  }
});

export const getActiveCustomer = (state) => state.customers.activeCustomer;
export const getCustomers = (state) => state.customers.customers;
export const getCustomersFilter = (state) => state.customers.filters;

export const { setCustomers, clearCustomers, setActiveCustomers } =
  customersSlice.actions;

export default customersSlice.reducer;
