import request from "../request";
import { BASE_USERS_API_URL, BASE_USER_URL } from "../url";

export const sendZohoPayload = async (data) => {
  return await request({
    url: `${BASE_USER_URL}/contact/zoho`,
    method: "POST",
    data,
  });
};

export const sendUsedPayload = async (data) => {
  return await request({
    url: `${BASE_USER_URL}/contact/used`,
    method: "POST",
    data,
  });
};

export const sendReportChannelPayload = async (data) => {
  return await request({
    url: `${BASE_USER_URL}/contact/report`,
    method: "POST",
    data,
  });
};

export const sendReportChannelPayloadWithFile = async (data, files) => {
  return await request({
    url: `${BASE_USER_URL}/contact/report/files`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...data,
      files,
    },
  });
};

export const uploadFilesOnReportChannelBucket = async (file) => {
  return await request({
    url: `${BASE_USERS_API_URL}/upload/dev-gama-denuncias/${file?.name}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: file,
  });
};
