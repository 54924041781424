import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, message, Row, Tree, Spin } from "antd";
import Button from "../../../components/Button";
import { createRoleRequest, updateRoleRequest } from "../../../requests/roles";
import { getUserData } from "../../../store/auth";
import { useSelector } from "react-redux";
import { useMediaPredicate } from "react-media-hook";

const RoleForm = ({
  onClose,
  open,
  permissionsList = [],
  onCreatedRole,
  roleToUpdate,
}) => {
  const [form] = Form.useForm();
  const userData = useSelector(getUserData);
  const [isDisabled, setIsDisabled] = useState(false)

  const mapChilds = (_permissionsList) => {
    return _permissionsList.map((permission) => ({
      title: permission.description,
      key: permission.id,
      children: permission.children
        ? mapChilds(permission.children)
        : undefined,
    }));
  };

  const treeData = mapChilds(permissionsList);
  const clientData = treeData.filter(rol => rol.title !== "Servicios")
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const cleanData = () => {
    form.resetFields();
    setCheckedKeys([]);
    setSelectedKeys([]);
    setExpandedKeys([]);
  };

  const onCloseModal = () => {
    cleanData();
    onClose();
  };

  const onFormFinish = async (values) => {
    setIsDisabled(true)
    // setSubmited(true);
    const payload = {
      ...values,
      permissions: checkedKeys,
    };
    try {
      if (roleToUpdate) {
        await updateRoleRequest(roleToUpdate.id, payload);
      } else {
        await createRoleRequest(payload);
      }
      onCreatedRole();
      message.success({
        content: (roleToUpdate)? "Se ha actualizado el rol correctamente" :"Se ha creado el rol correctamente" ,
        duration: 5,
      });
      onCloseModal();
    } catch (error) {
      if (error.status === 406) {
        message.error({
          content: "Existe otro rol con el mismo nombre, por favor cambie el nombre.",
          duration: 5,
        });
      } else {
        setIsDisabled(false)
        message.error({
          content: "Error al crear el rol",
          duration: 5,
        });
      } 
    } finally{
      setIsDisabled(false)
    }
      
  };

  useEffect(() => {
    if (roleToUpdate) {
      form.setFieldsValue({ code: roleToUpdate.code });
      setCheckedKeys(roleToUpdate.permissions);
    } else {
      cleanData();
    }
  }, [roleToUpdate]);

  return (
    <Drawer
      title={roleToUpdate ? "Editar rol" : "Crear nuevo rol"}
      placement="right"
      onClose={onCloseModal}
      visible={open}
      destroyOnClose={true}
      width={biggerThan900 ? 480 : "100%"}
    >
      <Form
        form={form}
        initialValues={{}}
        layout="vertical"
        onFinish={onFormFinish}
      >
        <Form.Item
          label="¿Qué nombre tendrá este rol?"
          name="code"
          rules={[
            { required: true, message: "Ingrese el nombre del rol." },
            { max: 20, message: "El rol no puede tener más de 20 carácteres" },
            { min: 3, message: "El rol debe tener al menos 3 carácteres" },
            {
              pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
              message: "Los roles no pueden incluir caracteres especiales",
            },
          ]}
        >
          <Input placeholder="Nombre del rol" />
        </Form.Item>
        <Form.Item label="¿Que puede hacer o ver el usuario?">
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            s
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={userData?.type === "CLIENT" ? clientData : treeData}
          />
        </Form.Item>
      </Form>
      <Row>
        <Button
          style={{ width: "40%", margin: 5 }}
          className="gama-outline"
          onClick={() => onCloseModal()}
        >
          Cancelar
        </Button>
        <Button
        disabled={isDisabled}
            style={{ width: "50%", margin: 5 }}
            onClick={() => form.submit()}
          >
            Guardar
          </Button>
      </Row>
    </Drawer>
  );
};

export default RoleForm;
