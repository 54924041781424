import React, { useState, useEffect } from "react";
import {
  Col,
  Divider,
  Dropdown,
  Image,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import Logo from "../../../../assets/img/gama-loco.webp";
import {
  CaretDownOutlined,
  MenuOutlined,
  PhoneFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ProfileMenu from "./ProfileMenu";
import { useMediaPredicate } from "react-media-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPermissionsData, getUserData } from "../../../../store/auth";
import { removeDuplicatesFromArray } from "../../../../utils/removeDuplicates";

const { Header: AntHeader } = Layout;

const { Text } = Typography;

const menu = (
  <Menu style={{ width: "314px", marginRight: "50px" }}>
    <Col style={{ fontSize: "14px" }}>Comunícate a nuestro call center</Col>
    <Row>
      <Col>
        <PhoneFilled
          style={{
            fontSize: "20px",
            marginLeft: "5px",
            color: "#FF3200",
            marginRight: "15px",
          }}
        />
      </Col>
      <Col style={{ fontWeight: "800", fontSize: "24px" }}>600 586 8686</Col>
      <Divider style={{ margin: 0 }} />
      <Text style={{ fontWeight: "700" }}>Horario de atención</Text>
      <Text>Lunes a Viernes de 09:00 a 18:00 hrs</Text>
    </Row>
  </Menu>
);

const Header = ({ user, onSignOut, hasAccess }) => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  let location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");
  const [current] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const navigate = useNavigate();
  const redirect = (route) => navigate(route);
  const handleClick = (e) => {
    localStorage.setItem("dashboardSelector", e.key);
  };
  const userData = useSelector(getUserData);
  const permissions = useSelector(getPermissionsData);

  useEffect(() => {
    const localStorageSelector = localStorage.getItem("dashboardSelector");

    if (localStorageSelector && current !== "/dashboard") {
      setSelectedItem(localStorageSelector);
    }
    renderMenuItems();
  }, [userData]);

  const getItem = (label, key, redirect, children, group = "") => {
    return {
      key,
      redirect,
      children,
      label,
      group,
    };
  };

  const renderMenuItems = () => {
    let count = 1;
    let adminItems = [];
    let menuItems = [];
    if (userData && userData.permissions) {
      for (let permission of userData.permissions) {
        const foundPermission = permissions?.find((p) => p.id === permission);
        if (
          foundPermission?.description.toLowerCase() === "usuarios" ||
          foundPermission?.description.toLowerCase() ===
            "crear / editar usuarios" ||
          foundPermission?.description.toLowerCase() ===
            "ver / listar usuarios" ||
          foundPermission?.description.toLowerCase() === "desactivar usuarios"
        )
          adminItems.push(getItem(`Usuarios`, `${++count}`, `/usuarios`));

        if (foundPermission?.description.toLowerCase() === "roles")
          adminItems.push(
            getItem(
              `${foundPermission.description}`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "empresas")
          adminItems.push(
            getItem(
              `${foundPermission.description}`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "notificaciones")
          menuItems.push(
            getItem(
              `${foundPermission.description}`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );
        if (foundPermission?.description.toLowerCase() === "mis datos")
          menuItems.push(
            getItem(
              `${foundPermission.description}`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );
        if (foundPermission?.description.toLowerCase() === "ver documentos")
          menuItems.push(
            getItem(
              `${foundPermission.description}`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "vehículos")
          menuItems.push(
            getItem(
              `Vehículos`,
              `${++count}`,
              `/${
                foundPermission.description.toLowerCase() === "vehículos"
                  ? "vehiculos"
                  : foundPermission.description.toLowerCase()
              }`
            )
          );

        if (foundPermission?.description.toLowerCase() === "documentación")
          menuItems.push(
            getItem(
              `Documentación`,
              `${++count}`,
              `/${
                foundPermission.description.toLowerCase() === "documentación"
                  ? "documentacion"
                  : foundPermission.description.toLowerCase()
              }`
            )
          );

        if (foundPermission?.description.toLowerCase() === "solicitudes")
          menuItems.push(
            getItem(
              `Solicitudes`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "siniestros")
          menuItems.push(
            getItem(
              `Declarar siniestros`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "facturas")
          menuItems.push(
            getItem(
              `Facturas`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "servicios")
          menuItems.push(
            getItem(
              `Servicios`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (
          foundPermission?.description.toLowerCase() ===
          "propuesta de facturación"
        )
          menuItems.push(
            getItem(
              `Propuesta de Facturación`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );

        if (foundPermission?.description.toLowerCase() === "reportes")
          menuItems.push(
            getItem(
              foundPermission.description,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );
        if (foundPermission?.description.toLowerCase() === "contratos")
          menuItems.push(
            getItem(
              `Contratos`,
              `${++count}`,
              `/${foundPermission.description.toLowerCase()}`
            )
          );
      }
    }

    menuItems = [
      getItem("Dashboard", "1", "/dashboard"),
      getItem(
        "Administrador",
        "sub1",
        "",
        removeDuplicatesFromArray(adminItems),
        "group"
      ),

      ...menuItems,
    ];

    if (userData?.accesses?.[0]?.role.code === "Conductor") {
      menuItems.push(getItem("Declarar siniestros", "16", "/siniestros"));
    }

    if (adminItems.length === 0) menuItems.splice(1, 1);

    const updatedMenuItems = menuItems.map((item) => {
      if (item.label === "Ver documentos") {
        return { ...item, label: "Documentos vehículos" };
      }
      return item;
    });

    if (userData?.accesses?.[0]?.role.code === "Conductor") {
      return updatedMenuItems
        .filter((item) => item.redirect !== "/dashboard")
        .sort((a, b) => a.redirect.localeCompare(b.redirect));
    } else {
      return menuItems.filter((item) => item.redirect !== "/ver documentos");
    }
  };

  const renderItem = (item) => {
    if (item.key.startsWith("sub")) {
      return (
        <Menu.SubMenu key={item.key} title={item.label}>
          {item.children.map((child) => (
            <Menu.Item key={child.key} onClick={() => redirect(child.redirect)}>
              {child.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={item.key} onClick={() => redirect(item.redirect)}>
        {item.label}
      </Menu.Item>
    );
  };

  return (
    <AntHeader className="site-layout-header">
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          borderBottomLeft: "5px",
        }}
      >
        <Col style={{ paddingLeft: "15px" }}>
          {biggerThan900 ? null : (
            <MenuOutlined
              onClick={showModal}
              style={{
                fontSize: "18px",
                marginLeft: "10px",
                marginRight: "15px",
              }}
            />
          )}
          <Image preview={false} width={biggerThan900 ? 150 : 80} src={Logo} />
          <Divider
            style={{ backgroundColor: "#8C8C8C", height: "50%" }}
            type="vertical"
          />
          <Text>{biggerThan900 ? "PORTAL DE CLIENTES" : ""}</Text>
        </Col>

        <Col
          sm={0}
          md={0}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          {biggerThan900 ? (
            <Dropdown overlay={menu}>
              <Row
                align="middle"
                style={{
                  paddingRight: "30px",
                  cursor: "pointer",
                }}
              >
                <a>Centro de ayuda</a>
                <QuestionCircleOutlined
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    color: "#FF3200",
                  }}
                />
              </Row>
            </Dropdown>
          ) : null}
          <div
            style={{
              cursor: "pointer",
              paddingRight: biggerThan900 ? "15px" : "",
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={<ProfileMenu user={user} onSignOut={onSignOut} />}
              placement="bottomLeft"
            >
              <div>
                <img
                  style={{
                    borderRadius: "50%",
                    cursor: "pointer",
                    height: "45px",
                    width: "45px",
                  }}
                  src={userData?.profileImageUrl}
                />
                <span
                  style={{ fontSize: "1rem", padding: "0 .5rem" }}
                >{`${user?.name} ${user?.lastname}`}</span>
                <CaretDownOutlined />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>
      {isModalOpen ? (
        <div
          className="sidebar site-layout-background"
          style={{
            transition: "ease-in 0.2s",
            height: "1300px",
            width: "100%",
            backgroundColor: "black",
            position: "absolute",
            borderRadius: "0px",
            zIndex: "1000",
          }}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            defaultOpenKeys={["sub1", "sub2"]}
            forceSubMenuRender={true}
            onClick={handleClick}
            selectedKeys={[selectedItem]}
          >
            {hasAccess &&
              userData &&
              renderMenuItems()?.map((item) => renderItem(item))}
          </Menu>
        </div>
      ) : null}
    </AntHeader>
  );
};

export default Header;
