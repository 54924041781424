import {
  CaretDownOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Col, Drawer, Form, Input, Row, Select, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import Notifications from "../../../components/Notifications";
import { createRequest } from "../../../requests/requests";
import { getUserData } from "../../../store/auth";
import {
  isAnotherGamaRole,
  isExecutive,
  isSuperAdmin,
  isSupervisor,
} from "../../../utils/roles";
import { BASE_AUTH_API_URL } from "../../../requests/url";

function RequestForm({
  handleFilterRequest,
  requestFilter,
  onClose,
  open,
  data,
  memoizedFetchRequests,
}) {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const userData = useSelector(getUserData);
  const [showInput, setShowInput] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [token, setToken] = useState("");
  const { accesses, type } = useSelector(getUserData);
  useEffect(() => {
    const token = localStorage.getItem("gama.token");
    if (token) setToken(token);
  }, []);
  const BASE_URL = `${process.env.REACT_APP_AGENDAMIENTO_URL}/auth/lop?token=${token}`;

  const onFinish = async (values) => {
    setIsDisabled(true);
    try {
      const { data } = await createRequest(values);
      Notifications.success(
        "Solicitud creada con éxito",
        `Se ha creado la solicitud ${data.id}  exitosamente.`
      );
      memoizedFetchRequests();
      onClose(true);
      form.resetFields();
    } catch (error) {
      Notifications.error(
        "Ha ocurrido un error",
        `Por favor intente mas tarde.`
      );
      console.error(error);
    } finally {
      setIsDisabled(false);
    }
  };

  const hanleOthers = (value) => {
    console.log(value);
    if (value.toUpperCase() === "OTROS") {
      setShowInput(true);
    } else {
      setShowInput(false);
      if (value.toUpperCase() === "OTROS") {
        setShowInput(true);
      } else {
        setShowInput(false);
      }
    }
  };

  const handleClose = () => {
    onClose(true);
    form.resetFields();
  };

  const handleRedirectAndCreate = () => {
    form.submit();
    setTimeout(() => {
      window.open(BASE_URL, "_blank", "noopener,noreferrer");
    }, 3000);
  };

  return (
    <Drawer
      closable={false}
      width={biggerThan900 ? 480 : "100%"}
      title={
        <>
          <Row align="middle" justify="space-between">
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Crear nueva solicitud
            </span>
            <Col>
              <CloseOutlined
                onClick={handleClose}
                style={{
                  color: "#FF3200",
                  backgroundColor: "rgba(255, 50, 0, 0.06)",
                  padding: "7px 7px 7px 7px",
                  marginRight: "15px",
                  fontSize: "16px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              />
            </Col>
          </Row>
        </>
      }
      placement="right"
      onClose={handleClose}
      visible={open}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <FileDoneOutlined
          style={{
            color: "#262626",
            backgroundColor: "#F0F0F0",
            padding: "7px 7px 7px 7px",
            marginRight: "15px",
            fontSize: "26px",
            borderRadius: "6px",
          }}
        />
        <div style={{ lineHeight: "5px" }}>
          <Text strong>Nº de solicitud: </Text>
          <div style={{ display: "flex" }}>
            <p style={{ marginTop: biggerThan900 ? "" : "10px" }}></p>
          </div>
        </div>
      </div>
      <Form
        name="requestForm"
        initialValues={{ requestForm: [[{}]] }}
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 24,
        }}
        layout="vertical"
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "Debe ingresar una patente",
            },
          ]}
          style={{ marginTop: "16px", marginBottom: "0px" }}
          name="deviceId"
          label="Patente"
        >
          <Select
            notFoundContent={!data ? <Spin size="small" /> : null}
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Ingresar o buscar"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={data?.map((device) => {
              return {
                label: device,
                value: device,
              };
            })}
          />
        </Form.Item>

        <Row>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Debe ingresar un tipo de solicitud",
                },
              ]}
              style={{ marginTop: "5px", marginBottom: "0px" }}
              label="Tipo de solicitud"
              name="requestType"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Seleccionar tipo de solicitud"
                suffixIcon={<CaretDownOutlined />}
                onChange={handleFilterRequest}
                // id="vehicle-selector"
              >
                {isSuperAdmin(accesses) ||
                isAnotherGamaRole(accesses, type) ||
                isSupervisor(accesses) ||
                userData.type === "CLIENT" ? (
                  <Select.Option value="Supervisor">Supervisor</Select.Option>
                ) : null}
                {isSuperAdmin(accesses) ||
                isAnotherGamaRole(accesses, type) ||
                isExecutive(accesses) ||
                userData.type === "CLIENT" ? (
                  <Select.Option value="Ejecutivo">Ejecutivo</Select.Option>
                ) : null}
                {isSuperAdmin(accesses) ||
                isAnotherGamaRole(accesses, type) ||
                isSupervisor(accesses) ||
                userData.type === "CLIENT" ? (
                  <Select.Option value="Certificado servicios">
                    Certificado servicios
                  </Select.Option>
                ) : null}
                {userData.accesses[0].role.code.toUpperCase() ===
                  "ADMINISTRADOR" || userData.type === "CLIENT" ? (
                  <Select.Option value="Agendamiento">
                    Agendamiento
                  </Select.Option>
                ) : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{ marginTop: "5px", marginBottom: "0px" }}
              name="reason"
              label="Motivo"
              rules={[
                {
                  required: true,
                  message: "Debe ingresar un motivo",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Seleccionar"
                onChange={hanleOthers}
                suffixIcon={<CaretDownOutlined />}
              >
                {isSuperAdmin(accesses) ||
                isAnotherGamaRole(accesses, type) ||
                isSupervisor(accesses) ||
                userData.type === "CLIENT" ? (
                  requestFilter.toUpperCase() === "SUPERVISOR" ? (
                    <>
                      <Select.Option value="Copia llaves">
                        Copia llaves
                      </Select.Option>
                      <Select.Option value="Carta combustible">
                        Carta combustible
                      </Select.Option>
                      <Select.Option value="Certificado multa">
                        Certificado multa
                      </Select.Option>
                      <Select.Option onClick={() => hanleOthers} value="Otros">
                        Otros
                      </Select.Option>
                    </>
                  ) : requestFilter.toUpperCase() ===
                    "CERTIFICADO SERVICIOS" ? (
                    <Select.Option value="Certificado servicios">
                      Certificado servicios
                    </Select.Option>
                  ) : (requestFilter.toUpperCase() === "AGENDAMIENTO" &&
                      userData?.accesses[0]?.role?.code.toUpperCase() ===
                        "ADMINISTRADOR") ||
                    userData.type === "CLIENT" ? (
                    <Select.Option value="Agendamiento">
                      Agendamiento
                    </Select.Option>
                  ) : null
                ) : null}

                {isSuperAdmin(accesses) ||
                isAnotherGamaRole(accesses, type) ||
                isExecutive(accesses) ||
                userData.type === "CLIENT" ? (
                  requestFilter.toUpperCase() === "EJECUTIVO" ? (
                    <>
                      <Select.Option value="Envío anexo">
                        Envío anexo
                      </Select.Option>
                      <Select.Option value="Copia contrato">
                        Copia contrato
                      </Select.Option>
                      <Select.Option value="Otros">Otros</Select.Option>
                    </>
                  ) : null
                ) : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            {showInput && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Debe escribir un motivo.",
                  },
                  {
                    max: 250,
                    message: "Debe tener máximo 250 caracteres",
                  },
                  {
                    //Mayusculas, minusculas, acentos, espacio, apostrofe
                    pattern: /^[A-Za-z\'\s\.\,Ññ]+$/,
                    message: "El nombre solo debe incluir letras.",
                  },
                ]}
                style={{ marginTop: "16px", marginBottom: "0px" }}
                name="comment"
                label="Otros"
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Ingresar"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>

      <Row align="middle" style={{ marginTop: "32px" }}>
        {biggerThan900 ? (
          <>
            <Col span={biggerThan900 ? "" : 24}>
              <Button
                onClick={handleClose}
                className="gama-outline"
                style={{ marginRight: "16px", width: "100%" }}
              >
                Cancelar
              </Button>
            </Col>
            <Col span={biggerThan900 ? "" : 24}>
              {requestFilter.toUpperCase() === "AGENDAMIENTO" ? (
                <Button
                  style={{ width: "100%", marginLeft: "25px" }}
                  onClick={handleRedirectAndCreate}
                >
                  Crear nueva solicitud
                </Button>
              ) : (
                <Button
                  disabled={isDisabled}
                  style={{ width: "100%", marginLeft: "25px" }}
                  onClick={() => form.submit()}
                >
                  Crear nueva solicitud
                </Button>
              )}
            </Col>
          </>
        ) : (
          <>
            <Col span={biggerThan900 ? "" : 24}>
              {requestFilter.toUpperCase() === "AGENDAMIENTO" ? (
                <Button
                  style={{ width: "100%" }}
                  onClick={handleRedirectAndCreate}
                >
                  Crear nueva solicitud
                </Button>
              ) : (
                <Button
                  disabled={isDisabled}
                  style={{ width: "100%" }}
                  onClick={() => form.submit()}
                >
                  Crear nueva solicitud
                </Button>
              )}
            </Col>
            <Col span={biggerThan900 ? "" : 24}>
              <Button
                onClick={handleClose}
                className="gama-outline"
                style={{
                  marginRight: "16px",
                  width: "100%",
                  marginTop: "16px",
                }}
              >
                Cancelar
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Drawer>
  );
}

export default RequestForm;
