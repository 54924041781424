export const roles = {
  superAdmin: 'SUPER ADMINISTRADOR',
  executive: 'EJECUTIVO',
  supervisor: 'SUPERVISOR'
}

export const isSuperAdmin = (accesses) => {
  for (let access of accesses) {
    if (access.role.code.toUpperCase() === roles.superAdmin) return true;
  }
  return false;
};

export const isSupervisor = (accesses) => {
  for (let access of accesses) {
    if (access.role.code.toUpperCase() === roles.supervisor) return true;
  }
  return false;
};

export const isExecutive = (accesses) => {
  for (let access of accesses) {
    if (access.role.code.toUpperCase() === roles.executive) return true;
  }
  return false;
};

export const isAnotherGamaRole = (accesses, type) => {
  return !(isSuperAdmin(accesses) || isSupervisor(accesses) || isExecutive(accesses)) && type === 'GAMA';
}