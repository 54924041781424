import request from "../request";

import {
  BASE_USERS_API_URL,
} from '../url';


export const getMyProfileRequest = async () => {
  return await request({
    url: `${BASE_USERS_API_URL}/profile`,
    method: "GET",
  });
};

export const updateMyProfile = async (data) => {
  return await request({
    url: `${BASE_USERS_API_URL}/profile`,
    method: "PATCH",
    data: data,
  });
};

export const uploadProfilePictureOnBucket = async (file) => {
  return await request({
    url: `${BASE_USERS_API_URL}/upload/dev-gama-profile-images/user-images/${file?.name}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: file
  })
}

export const updateProfilePicture = async (file, userId = undefined) => {
  return request({
    url: `${BASE_USERS_API_URL}/users/profile/image`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      file,
      userId
    }
  })
}

export const getProfilePicture = async (userId = undefined) => {
  const url = userId 
    ? `${BASE_USERS_API_URL}/users/profile/image?userId=${userId}`
    : `${BASE_USERS_API_URL}/users/profile/image` 
  return await request({
    url,
    method: "GET",
  })
}