import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CaretDownOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Select,
  Table,
  Typography,
  DatePicker,
  Button,
  Spin,
} from "antd";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import React, { useEffect, useState } from "react";
import moment from "moment/dist/moment";
import { useSelector } from "react-redux";
import { isSuperAdmin, isExecutive, isSupervisor } from "../../utils/roles";
import AppLayout from "../../components/layouts/AppLayout";
import useTableQuery from "../../hooks/useTableQuery";
import documentsIcon from "../../assets/img/documents.png";
import Notifications from "../../components/Notifications";
import currency from "currency.js";
import { getUserData } from "../../store/auth";
import circleTable from "../../assets/img/circletable.png";
import circleTable33 from "../../assets/img/circleTable33.png";
import { getInvoices, getDocument } from "../../requests/invoices";
import { v4 as uuidv4 } from "uuid";

const months = {
  "01": "ENERO",
  "02": "FEBRERO",
  "03": "MARZO",
  "04": "ABRIL",
  "05": "MAYO",
  "06": "JUNIO",
  "07": "JULIO",
  "08": "AGOSTO",
  "09": "SEPTIEMBRE",
  10: "OCTUBRE",
  11: "NOVIEMBRE",
  12: "DICIEMBRE",
};

export default function Invoices() {
  const [data, setData] = useState([]);
  const { height, width } = useWindowDimensions();
  const { accesses } = useSelector(getUserData);
  const userData = useSelector(getUserData);
  const [loading, setLoading] = useState(true);

  //Invoices
  const [monthFilter, setMonthFilter] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState();
  const [searchFilter, setSearchFilter] = useState("");
  const [search, setSearch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showSpin, setShowSpin] = useState("");
  const [total, setTotal] = useState();

  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    onSearch,
    setCurrentPage,
    query,
  } = useTableQuery();

  console.log(query, "query");
  moment.locale("es");

  const handleInvoiceDocuments = async (record) => {
    try {
      setShowSpin(record.nro_factura);

      const response = await getDocument(
        yearFilter.format("YYYY"),
        months[monthFilter],
        record.nro_factura
      );
      const { stringBuffer } = response.data;
      const base64Document = stringBuffer.replace(
        "data:application/pdf;base64,",
        ""
      );
      var byteCharacters = atob(base64Document);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: "application/pdf;base64" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      Notifications.error(
        "Error al cargar el documento",
        "Documento no encontrado. Por favor contactar ejecutivo."
      );
    }
    setShowSpin("");
  };

  useEffect(async () => {
    if (yearFilter && monthFilter) {
      setLoading(true);
      try {
        setShowTable(true);
        const { data } = await getInvoices(
          yearFilter.format("YYYY"),
          monthFilter,
          stateFilter,
          searchFilter,
          query
        );
        setTotal(data?.count);
        setData(
          data?.items.map((d) => {
            return {
              ...d,
              id: uuidv4(),
              expanded: false,
            };
          })
        );
      } catch (error) {
        Notifications.error(
          "Error al cargar",
          "Hubo un error al cargar las Facturas."
        );
      }
      setLoading(false);
    }
  }, [monthFilter, yearFilter, stateFilter, searchFilter, accesses, query]);

  const { Text } = Typography;

  let expandedRowKeys = [];

  function handleRowKeys(isExpansion, record) {
    let rowKey = record.id;

    if (isExpansion) {
      expandedRowKeys.push(rowKey);
    } else expandedRowKeys.splice(expandedRowKeys.indexOf(rowKey), 1);
  }

  const mobileColumns = [
    {
      key: "1",
      title: (
        <Text style={{ fontSize: "12px" }} strong>
          Nº Documento
        </Text>
      ),
      dataIndex: "nro_factura",
      sorter: {
        compare: (a, b) => a.id.localeCompare(b.id),
      },
    },
  ];

  const columns = [
    {
      title: <Text strong>Nº documento</Text>,
      dataIndex: "nro_factura",
      key: "nro_factura",
      align: "left",
      isExpandable: true,
      sorter: {
        compare: (a, b) => a.nro_factura.localeCompare(b.nro_factura),
      },
    },
    {
      title: <Text strong>Fecha de emision</Text>,
      dataIndex: "fecha_emision_factura",
      key: "fecha_emision_factura",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) =>
          a.fecha_emision_factura?.localeCompare(b.fecha_emision_factura),
      },
      render: (fecha) => {
        return moment(fecha.substr(0, 10), "YYYY-MM-DD").format("DD/MM/YYYY");
      },
    },
    {
      title: <Text strong>Descripción</Text>,
      dataIndex: "tipo",
      key: "tipo",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a.tipo?.localeCompare(b.tipo),
      },
    },
    {
      title: <Text strong>Fecha vencimiento</Text>,
      dataIndex: "fecha_vencimiento_factura",
      key: "fecha_vencimiento_factura",
      align: "left",
      sorter: {
        compare: (a, b) =>
          a.fecha_vencimiento_factura?.localeCompare(
            b.fecha_vencimiento_factura
          ),
      },
      render: (fecha) => {
        return moment(fecha.substr(0, 10), "YYYY-MM-DD").format("DD/MM/YYYY");
      },
    },
    {
      title: <Text strong>Total factura</Text>,
      dataIndex: "monto_total_factura",
      key: "monto_total_factura",
      align: "left",
      sorter: (a, b) => a.monto_total_factura - b.monto_total_factura,
      render: (amount) => {
        return currency(amount, { fromCents: true, precision: 3 }).format();
      },
    },
    {
      title: <Text strong>Saldo pendiente</Text>,
      dataIndex: "saldo_pendiente",
      key: "saldo_pendiente",
      align: "left",
      width: 170,
      sorter: (a, b) => a.saldo_pendiente - b.saldo_pendiente,
      render: (amount) => {
        return currency(amount, { fromCents: true, precision: 3 }).format();
      },
    },
    {
      title: <Text strong>Estado</Text>,
      dataIndex: "estado",
      key: "estado",
      align: "center",
      width: 150,
      sorter: {
        compare: (a, b) => a.estado.localeCompare(b.estado),
      },
      render: (text) => {
        return (
          <span
            style={{
              backgroundColor:
                text.toUpperCase() === "CERRADO" ? "#F6FFED" : "#FFFBE6",
              padding: "4px 7px 4px 7px",
              borderRadius: "6px",
              border:
                text.toUpperCase() === "CERRADO"
                  ? "1px solid #BFBFBF"
                  : "1px solid #FFE58F",
              color: text.toUpperCase() === "CERRADO" ? "#52C41A" : "#FAAD14",
            }}
          >
            {text.toUpperCase() !== "CERRADO" ? "Pendiente" : "Pagado"}
          </span>
        );
      },
    },
    {
      title: <Text strong>Factura</Text>,
      dataIndex: "factura",
      key: "factura",
      align: "center",
      width: 150,
      render: (_text, record) => (
        <>
          {record.nro_factura === showSpin && <Spin />}

          {record.nro_factura !== showSpin && (
            <span
              style={{
                backgroundColor: "rgba(255, 50, 0, 0.08)",
                color: "#FF3200",
                cursor: "pointer",
                padding: "7px 7px 7px 7px",
                borderRadius: "100%",
                border: "1px solid #ff3200",
              }}
              onClick={() => handleInvoiceDocuments(record)}
            >
              <img src={documentsIcon} alt="table documents icon" />
            </span>
          )}
        </>
      ),
    },
  ];

  if (
    (isSuperAdmin(accesses) ||
      isExecutive(accesses) ||
      isSupervisor(accesses)) &&
    userData.type !== "CLIENT"
  ) {
    columns.splice(1, 0, {
      title: <Text strong>Empresa</Text>,
      dataIndex: "empresa",
      key: "empresa",
      align: "left",
      width: 170,
      sorter: {
        compare: (a, b) => a.empresa?.localeCompare(b.empresa),
      },
    });

    mobileColumns.push({
      key: "2",
      title: (
        <Text style={{ fontSize: "13px", width: "100%" }} strong>
          Empresa
        </Text>
      ),
      dataIndex: "empresa",
      sorter: {
        compare: (a, b) => a.empresa.localeCompare(b.empresa),
      },
      render: (text, record) => {
        return <Text style={{ fontSize: "13px" }}>{text}</Text>;
      },
    });
  } else {
    mobileColumns.push({
      key: "2",
      title: (
        <Text style={{ fontSize: "13px", width: "100%" }} strong>
          Descripción
        </Text>
      ),
      dataIndex: "tipo",
      sorter: {
        compare: (a, b) => a.tipo.localeCompare(b.tipo),
      },
      render: (text) => {
        return <Text style={{ fontSize: "13px" }}>{text}</Text>;
      },
    });
  }

  const Months = ({ style }) => {
    return (
      <Select
        suffixIcon={<CaretDownOutlined />}
        value={monthFilter}
        placeholder="Mes"
        style={style}
        onChange={(value) => {
          setCurrentPage(1);
          setMonthFilter(value);
        }}
      >
        <Select.Option value="01">Enero</Select.Option>
        <Select.Option value="02">Febrero</Select.Option>
        <Select.Option value="03">Marzo</Select.Option>
        <Select.Option value="04">Abril</Select.Option>
        <Select.Option value="05">Mayo</Select.Option>
        <Select.Option value="06">Junio</Select.Option>
        <Select.Option value="07">Julio</Select.Option>
        <Select.Option value="08">Agosto</Select.Option>
        <Select.Option value="09">Septiembre</Select.Option>
        <Select.Option value="10">Octubre</Select.Option>
        <Select.Option value="11">Noviembre</Select.Option>
        <Select.Option value="12">Diciembre</Select.Option>
      </Select>
    );
  };

  const States = ({ style }) => {
    return (
      <Select
        suffixIcon={<CaretDownOutlined />}
        value={stateFilter}
        placeholder="Estado"
        style={style}
        onChange={(value) => {
          setStateFilter(value);
          setCurrentPage(1);
        }}
      >
        <Select.Option value="CERRADO">Pagado</Select.Option>
        <Select.Option value="ABIERTO">Pendiente</Select.Option>
        {/* <Select.Option value="ANY">Todos</Select.Option> */}
      </Select>
    );
  };

  const { Search } = Input;
  return (
    <AppLayout customer={false} boton={false} title="Facturas">
      <div className="container-vehicles-table">
        {width <= 640 ? (
          <>
            <Row style={{ marginBottom: "20px", marginTop: "15px" }}>
              <Col xs={24}>
                <strong>Ingrese año y mes para realizar la búsqueda</strong>
              </Col>
            </Row>
            <Row
              style={{ marginBottom: "20px", marginTop: "15px", width: "100%" }}
              gutter={[24, 24]}
            >
              <Col xs={24}>
                <DatePicker
                  picker="year"
                  placeholder="Año"
                  onChange={(value) => {
                    setCurrentPage(1);
                    setYearFilter(value);
                  }}
                  value={yearFilter}
                  style={{
                    width: "100%",
                  }}
                  disabledDate={(current) =>
                    current.isBefore(moment("2000", "YYYY"))
                  }
                />
              </Col>

              <Col xs={24}>
                <Months
                  style={{
                    width: "100%",
                    borderRadius: "50px",
                  }}
                />
              </Col>

              <Col xs={24}>
                <States
                  style={{
                    width: "100%",
                    borderRadius: "50px",
                    marginRight: "20px",
                  }}
                />
              </Col>
            </Row>

            {showTable && (
              <>
                <Row>
                  <Col xs={19}>
                    <Search
                      placeholder={
                        isSuperAdmin(accesses)
                          ? "N° documento / Empresa"
                          : "N° documento / Empresa"
                      }
                      onChange={(event) => {
                        setCurrentPage(1);
                        setSearch(event.target.value);
                      }}
                      onSearch={(value) => {
                        setCurrentPage(1);
                        setSearchFilter(value);
                      }}
                      value={search}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Col>
                  <Col xs={5}>
                    <Button
                      onClick={() => {
                        setCurrentPage(1);
                        setSearchFilter("");
                        setStateFilter(null);
                        setSearch("");
                      }}
                      style={{
                        backgroundColor: "rgba(255, 50, 0, 0.06)",
                        color: "#FF3200",
                        fontSize: "20px",
                        border: "none",
                        padding: "6px 10px",
                        marginLeft: "10px",
                        borderRadius: "5px",
                      }}
                      icon={<ClearOutlined />}
                    />
                  </Col>
                </Row>

                <Table
                  columns={mobileColumns}
                  expandedRowRender={(record) => (
                    <Row>
                      {isSuperAdmin(accesses) ? (
                        <>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Documento de referencia</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.nro_factura}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha emisión</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {moment(
                              record?.fecha_emision_factura.substr(0, 10),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Descripción</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.tipo}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha vencimiento</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {moment(
                              record?.fecha_vencimiento_factura.substr(0, 10),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Total factura</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {currency(record.monto_total_factura, {
                              fromCents: true,
                              precision: 3,
                            }).format()}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Saldo pendiente</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {currency(record.saldo_pendiente, {
                              fromCents: true,
                              precision: 3,
                            }).format()}
                          </Col>
                          <Col span={14}>
                            <Text style={{ fontSize: "15px" }} strong>
                              Estado
                            </Text>
                          </Col>
                        </>
                      ) : isExecutive(accesses) ? (
                        <>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Documento de referencia</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.nro_factura}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha emisión</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {moment(
                              record?.fecha_emision_factura.substr(0, 10),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Descripción</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.tipo}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha vencimiento</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {moment(
                              record?.fecha_vencimiento_factura.substr(0, 10),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Total factura</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {currency(record.monto_total_factura, {
                              fromCents: true,
                              precision: 3,
                            }).format()}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Saldo pendiente</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {currency(record.saldo_pendiente, {
                              fromCents: true,
                              precision: 3,
                            }).format()}
                          </Col>
                          <Col xs={14}>
                            <Text style={{ fontSize: "15px" }} strong>
                              Estado
                            </Text>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Documento de referencia</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.nro_factura}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha emisión</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {moment(
                              record?.fecha_emision_factura.substr(0, 10),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Descripción</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {record?.tipo}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Fecha vencimiento</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {moment(
                              record?.fecha_vencimiento_factura.substr(0, 10),
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Total factura</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {currency(record.monto_total_factura, {
                              fromCents: true,
                              precision: 3,
                            }).format()}
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={14}>
                            <Text strong>Saldo pendiente</Text>
                          </Col>
                          <Col style={{ marginBottom: "14px" }} span={10}>
                            {currency(record.saldo_pendiente, {
                              fromCents: true,
                              precision: 3,
                            }).format()}
                          </Col>
                          <Col span={14}>
                            <Text style={{ fontSize: "15px" }} strong>
                              Estado
                            </Text>
                          </Col>
                        </>
                      )}
                      <Col
                        style={{
                          backgroundColor:
                            record.estado.toUpperCase() === "CERRADO"
                              ? "#F6FFED"
                              : "#FFFBE6",
                          padding: "4px 7px 4px 7px",
                          borderRadius: "6px",
                          border:
                            record.estado.toUpperCase() === "CERRADO"
                              ? "1px solid #BFBFBF"
                              : "1px solid #FFE58F",
                          color:
                            record.estado.toUpperCase() === "CERRADO"
                              ? "#52C41A"
                              : "#FAAD14",
                        }}
                      >
                        {record?.estado}
                      </Col>

                      <Col
                        style={{
                          marginLeft: "60px",
                          marginTop: "15px",
                        }}
                        xs={24}
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleInvoiceDocuments(record);
                          }}
                          style={{
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #FF3200",
                            borderRadius: "36px",
                            width: "136px",
                            height: "38px",
                            color: "#FF3200",
                          }}
                        >
                          {record.nro_factura === showSpin && <Spin />}
                          {record.nro_factura !== showSpin && "Descargar"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  expandIcon={(props) => {
                    console.log(props.record);

                    if (props.record.expanded) {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <MinusSquareOutlined
                            style={{ width: "30px" }}
                            onClick={(e) => {
                              props.record.expanded = false;
                              props.onExpand(props.record, e);
                            }}
                          />
                          <span>
                            {props?.record?.estado.toUpperCase() ===
                            "CERRADO" ? (
                              <img src={circleTable33} />
                            ) : (
                              <img src={circleTable} />
                            )}
                          </span>
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <PlusSquareOutlined
                            style={{ width: "30px" }}
                            onClick={(e) => {
                              props.record.expanded = true;
                              props.onExpand(props.record, e);
                            }}
                          />
                          <span>
                            {props?.record?.estado.toUpperCase() ===
                            "CERRADO" ? (
                              <img src={circleTable33} />
                            ) : (
                              <img src={circleTable} />
                            )}
                          </span>
                        </div>
                      );
                    }
                  }}
                  rowClassName={(record) =>
                    record?.isExpandable ? "show" : "hidden"
                  }
                  expandedRowKeys={expandedRowKeys}
                  onExpand={handleRowKeys}
                  rowKey={(record) => record.id}
                  dataSource={data}
                  showSorterTooltip={false}
                  pagination={{
                    current: currentPage,
                    pageSize,
                    total: total,
                    position: ["bottomCenter"],
                  }}
                  onChange={handlePaginationChange}
                  sortDirection={["ASC", "DESC"]}
                  loading={loading}
                />
              </>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <Row
                style={{
                  marginBottom: "20px",
                  marginTop: "15px",
                  width: "100%",
                }}
              >
                <Col xs={24}>
                  <strong>Ingrese año y mes para realizar la búsqueda</strong>
                </Col>
              </Row>

              <Row
                style={{
                  marginBottom: "20px",
                  marginTop: "15px",
                  width: "100%",
                }}
                gutter={[24, 24]}
              >
                <Col>
                  <DatePicker
                    picker="year"
                    placeholder="Año"
                    onChange={(value) => {
                      setCurrentPage(1);
                      setYearFilter(value);
                    }}
                    value={yearFilter}
                    disabledDate={(current) =>
                      current.isBefore(moment("2000", "YYYY"))
                    }
                  />
                </Col>

                <Col>
                  <Months
                    style={{
                      width: 150,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                  />
                </Col>

                <Col>
                  <States
                    style={{
                      width: 150,
                      borderRadius: "50px",
                      marginRight: "20px",
                    }}
                  />
                </Col>
              </Row>
            </div>

            <>
              {showTable && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col>
                      <Search
                        placeholder={
                          isSuperAdmin(accesses)
                            ? "N° documento / Empresa"
                            : "N° documento / Empresa"
                        }
                        //onSearch={handleSearch}
                        onChange={(event) => {
                          setSearch(event.target.value);
                        }}
                        onSearch={(value) => {
                          setCurrentPage(1);
                          setSearchFilter(value);
                        }}
                        value={search}
                        style={{
                          width: 270,
                        }}
                      />
                    </Col>

                    <Col>
                      <a
                        style={{
                          border: "none",
                          textAlign: "bottom",
                          color: "#FF3200",
                          textDecoration: "underline",
                          fontSize: "16px",
                        }}
                        onClick={() => {
                          setCurrentPage(1);
                          setSearchFilter("");
                          setStateFilter(null);
                          setSearch("");
                        }}
                      >
                        Limpiar
                      </a>
                    </Col>
                  </Row>

                  <Table
                    className="sius"
                    dataSource={data ?? []}
                    onSearch={onSearch}
                    loading={loading}
                    pagination={{
                      current: currentPage,
                      pageSize,
                      total: total,
                      position: ["bottomCenter"],
                    }}
                    onChange={handlePaginationChange}
                    sortDirection={["ASC", "DESC"]}
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    showSorterTooltip={false}
                  />
                </>
              )}
            </>
          </>
        )}
      </div>
    </AppLayout>
  );
}
