import request from "../request";
import { BASE_VEHICULE_API_URL, BASE_SHAREPOINT_API_URL } from "../url";

export const getInvoices = (
  year,
  month,
  state,
  nOrderOrCompany = undefined,
  query
) => {
  const params = {
    year,
    month,
  };

  if (nOrderOrCompany) {
    params.nOrderOrCompany = nOrderOrCompany;
  }

  if (state) {
    params.state = state;
  }

  console.log(params, "params", query, "query");

  return request({
    url:
      `${BASE_VEHICULE_API_URL}/invoice?pageSize=${query?.pageSize || 7}&page=${
        query?.currentPage || 1
      }&orderByProperty=${
        query?.orderByProperty || "fecha_emision_factura"
      }&orderBy=${query?.orderBy || "DESC"}&` +
      new URLSearchParams(params).toString(),
    method: "GET",
  });
};

export const getDocument = (folder, subFolder, deviceId) => {
  const params = {
    folder,
    subFolder,
    deviceId,
  };

  console.log(params, "params");

  return request({
    url:
      `${BASE_SHAREPOINT_API_URL}/documents/invoices?` +
      new URLSearchParams(params).toString(),
    method: "GET",
  });
};
