import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVehicles } from "../../requests/vehicles";


export const getFiltersData = createAsyncThunk(
  "customers/getFiltersData",
  async (_, { rejectWithValue }) => {
    try {
      const filters = localStorage.getItem("gama.vehicles.filter");
      if (filters) return JSON.parse(filters);

      const { data } = await getVehicles(_, 1, {orderBy:"DESC", orderByProperty: "deviceContract.id"});

      const brand = [];
      const model = [];

      for (let i = 0; i < data.total / data.limits; ++i) {
        const { data } = await getVehicles(_, i + 1, {orderBy:"DESC", orderByProperty: "deviceContract.id"});

        data.results.forEach(v => { if (v.device) brand.push({ brand: v.device.brandId, customer: v.contract.rentName, key: `${v.device.brandId}${v.contract.rentName}` }) });
        data.results.forEach(v => { if (v.device) model.push({ model: v.device.classId, brand: v.device.brandId, customer: v.contract.rentName, key: `${v.device.classId}${v.device.brandId}${v.contract.rentName}` }) });
      }

      localStorage.setItem("gama.vehicles.filter", JSON.stringify({
        brand: removeDuplicates(brand, "key"),
        model: removeDuplicates(model, "key")
      }));

      return {
        brand: removeDuplicates(brand, "key"),
        model: removeDuplicates(model, "key")
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getAdminFiltersData = createAsyncThunk(
  "customers/getFiltersData",
  async ({filters, currentPage, query}, { rejectWithValue }) => {
    try {
      const { data } = await getVehicles(filters, currentPage, query);

      const brand = [];
      const model = [];

      for (let i = 0; i < data.total / data.limits; ++i) {
        const { data } = await getVehicles(filters, i + 1, query);

        data.results.forEach(v => { if (v.device) brand.push({ brand: v.device.brandId, customer: v.contract.rentName, key: `${v.device.brandId}${v.contract.rentName}` }) });
        data.results.forEach(v => { if (v.device) model.push({ model: v.device.classId, brand: v.device.brandId, customer: v.contract.rentName, key: `${v.device.classId}${v.device.brandId}${v.contract.rentName}` }) });
      }

      return {
        brand: removeDuplicates(brand, "key"),
        model: removeDuplicates(model, "key")
      };
    } catch (error) {
      console.log(error)
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject = {};

  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}