import React, { useEffect } from "react";
import { Spin } from "antd";
import myPdf from "../../assets/protocolo de siniestro.pdf";

const ProtocolRedirect = () => {
    
useEffect(() => {
    document.getElementById("thebutton").click();
    window.location.replace("/");
    });


  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" style={{ marginBottom: "50px" }} />
      <h1>Descargando Protocolo, espere por favor</h1>
      {/* <Link to="dashboard" style={{ fontSize: "1.4rem" }}>
        Volver al inicio
      </Link> */}

    <a
      id="thebutton"
      href={myPdf}
      download="protocolo de siniestro.pdf"
    >
    </a>
    </div>
  );
};

export default ProtocolRedirect;
